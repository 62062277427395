import { Button } from 'components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';
import { ContainerImage, StyledLine } from 'styles/Login/Login.style';

export const LoginSide = () => {
  const navigate = useNavigate();
  return (
    <ContainerImage>
      <Flex $bold={700} $fs={1.2} $noFullHeight>
        DA LI IMATE PROFIL?
      </Flex>
      <Flex $column $gap="0.5rem" $noFullHeight>
        <Button width="100%" onClick={() => navigate('/checkout/details')}>
          Nastavite kao gost
        </Button>
        <Flex $jc="center" $noFullHeight>
          ili
        </Flex>
        <Button
          width="100%"
          styleType="color"
          onClick={() => navigate('/checkout/registration')}
        >
          Registrujte se
        </Button>
      </Flex>
      <Flex $bold={300} $noFullHeight>
        Šta dobijate registracijom na Esotiq?
      </Flex>
      <StyledLine />
      <Flex $bold={700} $fs={1.1} $noFullHeight>
        PRISTUP ISTORIJI KUPOVINE
      </Flex>
      {/* <Flex $bold={700} $fs={1.1} $noFullHeight>
        LIST OF FAVORITE PRODUCTS
      </Flex> */}
    </ContainerImage>
  );
};
