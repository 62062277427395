import { useInfiniteQuery } from '@tanstack/react-query';
import { getCustomerOrders } from 'api/orders.api';

export const useMyOrders = () => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['orders'],
      queryFn: ({ pageParam = 1 }) => {
        console.log(pageParam, 'Page param');
        return getCustomerOrders({ page: pageParam, limit: 20 });
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
    });

  const orders = data?.pages
    ?.map((data) => (data?.data ? data.data : []))
    .flat(1);

  return { orders, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage };
};
