import React from 'react';

export const WhiteLogo = () => {
  return (
    <svg
      width="129"
      height="41"
      viewBox="0 0 129 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_237_2785)">
        <path
          d="M94.3436 4.5176C93.1062 4.5176 92.0898 3.51851 92.0898 2.2588C92.0898 1.04252 93.1062 0 94.3436 0C95.5809 0 96.5973 0.999084 96.5973 2.2588C96.6415 3.51851 95.6251 4.5176 94.3436 4.5176Z"
          fill="#E30040"
        />
        <path
          d="M124.263 40.5274C123.203 41.1356 121.789 40.7881 121.17 39.7021C120.551 38.6161 120.905 37.2695 122.01 36.618C123.07 36.0098 124.484 36.3573 125.103 37.4433C125.722 38.5293 125.324 39.9193 124.263 40.5274Z"
          fill="#E30040"
        />
        <path
          d="M10.2537 24.4551C9.01638 24.4551 8 23.456 8 22.1963C8 20.98 9.01638 19.9375 10.2537 19.9375C11.4911 19.9375 12.5074 20.9366 12.5074 22.1963C12.5516 23.456 11.5352 24.4551 10.2537 24.4551Z"
          fill="#E30040"
        />
        <path
          d="M126.914 14.8124C124.837 11.2504 121.743 8.94817 117.722 7.94909C113.833 6.95 109.724 7.55814 106.188 9.55631C102.653 11.5979 100.134 14.7689 98.9854 18.6349C97.8806 22.4141 98.3667 26.4104 100.267 29.7117C102.3 33.2302 105.481 35.6194 109.459 36.6184C112.684 37.4438 116.087 37.1397 119.18 35.88L117.192 32.4918C115.115 33.2302 112.861 33.3171 110.74 32.7524C107.956 31.9705 105.614 30.233 104.156 27.7136C102.697 25.1941 102.3 22.2838 103.051 19.5471C103.846 16.7236 105.614 14.378 108.133 12.9445C110.652 11.511 113.612 11.1201 116.441 11.902C119.313 12.6839 121.611 14.4214 123.069 16.9408C124.527 19.4603 124.969 22.3706 124.174 25.1073C123.599 27.2792 122.406 29.1036 120.771 30.4936L122.76 33.8818C125.411 31.8837 127.311 29.1905 128.284 25.9326C129.344 22.0666 128.814 18.1137 126.914 14.8124ZM120.727 30.4068L115.336 21.1544L111.801 23.196L117.192 32.4484C118.473 31.9705 119.667 31.2755 120.727 30.4068Z"
          fill="white"
        />
        <path
          d="M90.2772 11.7294V8.03711H65.1328V11.7294H75.6502V36.4024H79.7157V11.7294H90.2772Z"
          fill="white"
        />
        <path
          d="M96.378 8.03711H92.3125V36.4024H96.378V8.03711Z"
          fill="white"
        />
        <path
          d="M36.059 29.4086C36.059 24.6304 33.5843 22.7625 29.3862 20.1562C25.8952 17.9843 23.7298 16.8114 23.7298 14.5527C23.7298 12.6414 25.4533 11.2079 28.1047 11.2079C30.4026 11.2079 32.4354 12.207 34.7775 14.0748V9.47035C32.6563 8.1672 30.491 7.51562 28.2815 7.51562C23.1995 7.51562 19.7969 10.7735 19.7969 15.0739C19.7969 19.1571 22.2715 20.8512 27.1325 23.8919C30.2258 25.8466 31.9051 26.976 31.9051 29.3217C31.9051 31.5805 30.1817 32.9705 27.3093 32.9705C24.5253 32.9705 22.3157 31.624 19.8411 28.8005V34.1868C22.1832 36.0981 24.5695 36.88 27.5744 36.88C32.6563 36.9234 36.059 33.6656 36.059 29.4086Z"
          fill="white"
        />
        <path
          d="M17.852 36.4024V32.7536H4.24131V11.6859H17.4543V8.03711H0.175781V36.4024H17.852Z"
          fill="white"
        />
        <path
          d="M67.6553 22.3711C67.6553 18.2444 66.1086 14.7259 63.1478 11.859C60.2755 9.07894 56.4309 7.55859 52.3212 7.55859C48.2556 7.55859 44.4553 9.07894 41.4945 11.859C38.6221 14.5956 37.0312 18.2879 37.0312 22.1105C37.0312 26.1502 38.5779 29.7991 41.4945 32.6226C44.3669 35.4026 48.2115 36.923 52.3212 36.923C56.4309 36.923 60.1871 35.4895 63.192 32.6226C66.1086 29.8859 67.6553 26.1937 67.6553 22.3711ZM63.2804 22.2408C63.2804 25.1512 62.1757 27.8878 60.0987 29.8859C57.9776 31.971 55.3261 33.057 52.3654 33.057C49.4488 33.057 46.6206 31.9276 44.632 29.8859C42.5993 27.8443 41.4503 25.1512 41.4503 22.2408C41.4503 19.3304 42.5993 16.6372 44.632 14.5956C46.7532 12.554 49.493 11.3812 52.3654 11.3812C55.2819 11.3812 58.0218 12.5106 60.0987 14.5956C62.1757 16.7241 63.2804 19.3304 63.2804 22.2408Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_237_2785">
          <rect
            width="128.647"
            height="41"
            fill="white"
            transform="translate(0.175781)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
