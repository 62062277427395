import { deleteDeclinedOrder } from 'api/orders.api';
import { FullContainerLoader } from 'components/Loader/Loader';
import { useLayoutEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const PaymentDeclined = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const handleEffect = async () => {
    try {
      await deleteDeclinedOrder(id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    handleEffect();
  }, []);

  if (!loading) return <Navigate to="/checkout/details" replace={true} />;

  return <FullContainerLoader />;
};
