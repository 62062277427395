import React from 'react';
import { keyframes, styled } from 'styled-components';
import { useHover } from '../../hooks/useHover';
import { useWindowWidth } from 'hooks/useWindowWidth';

const scale = keyframes`
 0% { scale: 0}
 100% { scale: 1 }
`;

const StyledButton = styled.button<{
  $inactive: boolean;
  $active: boolean;
  $size: number;
  $disabled: boolean;
}>`
  position: relative;
  border: none;
  color: ${({ $inactive, theme }) =>
    $inactive ? theme.colors.secondary : theme.colors.secondaryDark};
  background-color: transparent;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => ($size ? $size + 'px' : '35px')};
  height: ${({ $size }) => ($size ? $size + 'px' : '35px')};
  border-radius: 50%;
  cursor: pointer;
  user-select: none;

  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 950px) {
    width: ${({ $size }) => ($size ? $size + 'px' : '30px')};
    height: ${({ $size }) => ($size ? $size + 'px' : '30px')};
    padding: 0.2rem;
  }

  &:hover {
    background-color: ${({ $active, $disabled }) =>
      !$disabled && ($active ? 'rgba(227, 0, 64, 0.3)' : 'rgba(0, 0, 0, 0.2)')};
  }
`;

const Tooltip = styled.div`
  position: absolute;
  padding: 0.5rem;
  background-color: #18191a;
  white-space: nowrap;
  top: 100%;
  margin-top: 1rem;
  color: white;
  opacity: 0.9;
  border-radius: 0.3rem;
  animation-name: ${scale};
  animation-duration: 0.2s;
`;

interface Props extends React.HTMLProps<HTMLButtonElement> {
  tooltip?: string;
  inactive?: boolean;
  active?: boolean;
  size?: number;
}

export const IconButton = ({
  children,
  tooltip,
  onClick,
  active,
  disabled,
  size,
  inactive = false,
}: Props) => {
  const [hoverRef, isHovered] = useHover();
  const { width } = useWindowWidth();
  return (
    <StyledButton
      $active={active}
      ref={hoverRef}
      onClick={onClick}
      $inactive={inactive}
      $disabled={disabled}
      $size={size}
      type="button"
    >
      {children}
      {width > 1000 && isHovered && tooltip && <Tooltip>{tooltip}</Tooltip>}
    </StyledButton>
  );
};
