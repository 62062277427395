import { FullContainerLoader, SmallLoader } from 'components/Loader/Loader';
import { useIsFilterActive } from 'hooks/useIsFilterActive';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';
import { FilterIcon } from '../../assets/FilterIcon';
import { FourGridItemsIcon } from '../../assets/FourGridItemsIcon';
import { OneGridItemIcon } from '../../assets/OneGridItemIcon';
import { ThreeGridItemsIcon } from '../../assets/ThreeGridItemsIcon';
import { TwoGridItemIcon } from '../../assets/TwoGridItemIcon';
import { Flex } from '../../styles/Containers.styles';
import { Button } from '../Buttons/Button';
import { IconButton } from '../Buttons/IconButton';
import { DropdownWithButton } from '../DropdownWithButton/DropdownWithButton';
import { ProductCard } from '../ProductCard/ProductCard';
import { ListContainer, ProductListContainer } from './ProductList.style';
import './cardTransition.css';
import EndListTrigger from 'components/List/EndListTrigger';

const EmptyListBox = styled.div`
  width: 100%;
  grid-column: 1/-1;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex-direction: column;
  gap: 2rem;
`;

const ActiveFilterDot = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  background-color: ${theme.colors.primary};
  border-radius: 1rem;
`;

type Props = {
  items: SimpleProductType[];
  title: string;
  mobile: boolean;
  filterOpened: boolean;
  onFilterClick: () => void;
  isLoading: boolean;
  tablet: boolean;
  isFetchingNextPage?: any;
  fetchNextPage?: any;
};

const desktopLayoutOptions = [
  {
    numberOfCards: 4,
    icon: <FourGridItemsIcon />,
  },
  {
    numberOfCards: 3,
    icon: <ThreeGridItemsIcon />,
  },
];
const mobileLayoutOptions = [
  {
    numberOfCards: 1,
    icon: <OneGridItemIcon />,
  },
  {
    numberOfCards: 2,
    icon: <TwoGridItemIcon />,
  },
];
const tabletLayoutOptions = [
  {
    numberOfCards: 2,
    icon: <TwoGridItemIcon />,
  },
  {
    numberOfCards: 1,
    icon: <OneGridItemIcon />,
  },
];

export const ProductList = ({
  items,
  title,
  mobile,
  tablet,
  onFilterClick,
  filterOpened,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
}: Props) => {
  const reference = mobile
    ? mobileLayoutOptions
    : tablet
    ? tabletLayoutOptions
    : desktopLayoutOptions;
  const [activeLayout, setActiveLayout] = useState(reference[0].numberOfCards);
  const activeFilter = useIsFilterActive();
  return (
    <ProductListContainer>
      <Flex $jc="space-between">
        <Flex $gap="1rem">
          {reference.map((layout) => (
            <IconButton
              key={layout.numberOfCards}
              onClick={() => {
                setActiveLayout(layout.numberOfCards);
              }}
              inactive={layout.numberOfCards !== activeLayout}
            >
              {layout.icon}
            </IconButton>
          ))}
        </Flex>
        <Flex
          $fs={1.5}
          $bold={700}
          $jc="center"
          style={{ textAlign: 'center' }}
        >
          <h1>{title?.toLocaleUpperCase()}</h1>
        </Flex>
        {!tablet ? (
          <Flex $jc="flex-end">
            <DropdownWithButton />
            {!filterOpened && (
              <Button
                styleType="text"
                width="fit-content"
                onClick={onFilterClick}
              >
                {activeFilter && <ActiveFilterDot />} FILTER <FilterIcon />
              </Button>
            )}
          </Flex>
        ) : (
          <Flex $jc="flex-end">
            <IconButton onClick={onFilterClick}>
              <FilterIcon color={activeFilter && theme.colors.primary} />
            </IconButton>
          </Flex>
        )}
      </Flex>
      <ListContainer $activeLayout={activeLayout}>
        {!isLoading &&
          (items?.length > 0 ? (
            items?.map((product, index) => (
              <ProductCard key={product.id} product={product} index={index} />
            ))
          ) : (
            <EmptyListBox>
              Nema rezultata
              <Link to="/novo">
                <Button styleType="color">Istraži novo</Button>
              </Link>
            </EmptyListBox>
          ))}
        {isLoading && <FullContainerLoader />}
      </ListContainer>
      <EndListTrigger fetchNextPage={fetchNextPage} />
      {isFetchingNextPage && (
        <Flex $noFullHeight $jc="center" $mt="0.5rem">
          <SmallLoader />
        </Flex>
      )}
    </ProductListContainer>
  );
};
