import { styled } from 'styled-components';

export const CardContainer = styled.div<{ $imageLoaded: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 0 0 33.33%;
  justify-content: space-between;
  gap: 0.4rem;
  opacity: ${({ $imageLoaded }) => ($imageLoaded ? 1 : 0)};
  transition: all 400ms ease;
  height: fit-content;
`;

export const CardImage = styled.img`
  width: 100%;
  aspect-ratio: 1/1.3;
  object-fit: cover;
  object-position: top;
`;

export const CardCategory = styled.p`
  font-size: 0.8rem;
  color: gray;
  text-transform: uppercase;
`;

export const ColorDot = styled.div<{
  $color: string;
}>`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`;
