import React from 'react';

export const CheckIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5172 5.4569C15.8172 5.74256 15.8288 6.2173 15.5431 6.51724L8.87644 13.5172C8.73488 13.6659 8.53859 13.75 8.33333 13.75C8.12808 13.75 7.93179 13.6659 7.79023 13.5172L4.4569 10.0172C4.17123 9.71729 4.18281 9.24256 4.48276 8.9569C4.78271 8.67123 5.25744 8.68281 5.5431 8.98276L8.33333 11.9125L14.4569 5.48276C14.7426 5.18281 15.2173 5.17123 15.5172 5.4569Z"
        fill="#E30040"
      />
    </svg>
  );
};
