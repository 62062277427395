import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { SelectButton } from './SelectButton';
import { theme } from 'styles/theme';

const OptionContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const OptionContent = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ $disabled }) =>
    $disabled ? theme.colors.secondary : theme.colors.secondaryDark};
`;

type Props = {
  selected: boolean;
  title: string;
  desc?: string | React.ReactElement;
  onSelect: () => void;
  disabled?: boolean;
};

export const Option = ({
  selected,
  title,
  desc,
  onSelect,
  disabled = false,
}: Props) => {
  return (
    <OptionContainer>
      <OptionContent $disabled={disabled}>
        <SelectButton
          selected={selected}
          disabled={disabled}
          onSelect={() => !disabled && onSelect()}
        />{' '}
        {title}
      </OptionContent>
      <Flex $noFull $bold={300} $noFullHeight>
        {desc}
      </Flex>
    </OptionContainer>
  );
};
