import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const MobileWideImageContainer = styled(Link)`
  width: 100%;
  height: fit-content;
`;

const MobileImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const StyledVideo = styled.video`
  width: 100%;
  object-fit: cover;
`;

type Props = {
  imageSrc?: string;
  videoSrc?: string;
  to: string;
};

export const MobileWideImage = ({ imageSrc, videoSrc, to }: Props) => {
  return (
    <MobileWideImageContainer to={to}>
      {imageSrc && <MobileImage src={imageSrc} />}
      {videoSrc && (
        <StyledVideo src={videoSrc} autoPlay muted loop playsInline />
      )}
    </MobileWideImageContainer>
  );
};
