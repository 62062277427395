/* eslint-disable camelcase */

import Cookies from 'js-cookie';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type CookieType = {
  changeConcent: () => void;
  statistic: boolean;
  setStatistic: React.Dispatch<React.SetStateAction<boolean>>;
  setPreferences: React.Dispatch<React.SetStateAction<boolean>>;
  setMarketing: React.Dispatch<React.SetStateAction<boolean>>;
  acceptConsent: () => void;
  saveAcceptedConsent: () => void;
  preferences: boolean;
  marketing: boolean;
  opened: boolean;
  statisticsCookie: 'true' | 'false' | undefined;
  deniedConcent: () => void;
};

export const CookieContext = createContext<CookieType | undefined>(undefined);

interface CookieProviderProps {
  children: ReactNode;
}

export const CookieProvider: React.FC<CookieProviderProps> = ({ children }) => {
  const statisticsCookie = Cookies.get('statistics') as
    | 'true'
    | 'false'
    | undefined;
  const marketingCookie = Cookies.get('marketing') as
    | 'true'
    | 'false'
    | undefined;
  const [opened, setOpened] = useState(false);
  const [statistic, setStatistic] = useState<boolean>(
    statisticsCookie === undefined
      ? true
      : statisticsCookie === 'true'
      ? true
      : false
  );
  const [marketing, setMarketing] = useState<boolean>(
    marketingCookie === undefined
      ? true
      : marketingCookie === 'true'
      ? true
      : false
  );
  const [preferences, setPreferences] = useState(false);

  useEffect(() => {
    // If no consent cookie is present, show the consent popup
    if (!Cookies.get('consent')) {
      setOpened(true);
    }
    console.log(statisticsCookie, 'STAT EFFECT');
  }, [statisticsCookie]);

  const acceptConsent = () => {
    setOpened(false);
    window.gtag('consent', 'update', {
      analytics_storage: 'granted',
    });
    window.fbq('consent', 'grant');
    setStatistic(true);
    setMarketing(true);
    Cookies.set('consent', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('necessary', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('marketing', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('statistics', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
  };

  const saveAcceptedConsent = () => {
    setOpened(false);
    console.log(statistic, 'statistic');
    console.log(marketing, 'marketing');
    Cookies.set('consent', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('necessary', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('statistics', `${statistic}`, {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('marketing', `${marketing}`, {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    window.gtag('consent', 'update', {
      analytics_storage: statistic ? 'granted' : 'denied',
    });
    window.fbq('consent', marketing ? 'grant' : 'revoke');
  };

  const deniedConcent = () => {
    setOpened(false);
    setStatistic(false);
    setMarketing(false);
    Cookies.set('consent', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('necessary', 'true', {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('statistics', `false`, {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    Cookies.set('marketing', `false`, {
      expires: 10 * 356 * 24 * 60 * 60,
    });
    window.gtag('consent', 'update', {
      analytics_storage: 'denied',
    });
    window.fbq('consent', 'revoke');
  };

  const changeConcent = () => {
    setOpened(true);
    setPreferences(true);
  };

  return (
    <CookieContext.Provider
      value={{
        changeConcent,
        setPreferences,
        setMarketing,
        statistic,
        setStatistic,
        acceptConsent,
        saveAcceptedConsent,
        preferences,
        opened,
        marketing,
        statisticsCookie,
        deniedConcent,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

export const useCookies = () => {
  const cookieContext = useContext(CookieContext);

  if (!cookieContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return cookieContext;
};
