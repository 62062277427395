import { useQuery } from '@tanstack/react-query';
import { getAttributeGroups } from 'api/attributes.api';

export const useAttributeGroups = () => {
  const { isLoading, error, data } = useQuery<{ id: number; name: string }[]>(
    ['attributeGroups'],
    getAttributeGroups
  );

  return { isLoading, error, data };
};
