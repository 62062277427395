import React, { useState } from 'react';
import styled from 'styled-components';
import RippleWrapper from './Containers/RippleContainer';
import { ArrowRightIcon } from '../assets/ArrowRightIcon';
import { ArrowLeftIcon } from '../assets/ArrowLeftIcon';
import { Flex } from '../styles/Containers.styles';

const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100vw;
`;

const Slider = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
  margin: 0 -10px; /* Add negative margin to compensate for the container padding */

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;

const Card = styled.div<{ visibleItems: number }>`
  flex: 0 0 ${({ visibleItems }) => 100 / visibleItems}%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px; /* Add horizontal padding */
`;

const ArrowButtonLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  svg {
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
  }
`;
const ArrowButtonRight = styled.div<{
  $left?: boolean;
}>`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface ImageSliderProps {
  children: React.ReactNode[] | React.ReactNode;
  visibleItems: number;
}

const ImageSlider: React.FC<ImageSliderProps> = ({
  children,
  visibleItems,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const numCards = React.Children.count(children);

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const nextSlide = () => {
    if (currentIndex < numCards - visibleItems) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <SliderContainer>
      <Slider
        style={{
          transform: `translateX(${-currentIndex * (100 / visibleItems)}%)`,
        }}
      >
        {React.Children.map(children, (child, index) => (
          <Card visibleItems={visibleItems} key={index}>
            {child}
          </Card>
        ))}
      </Slider>
      {currentIndex > 0 && (
        <ArrowButtonLeft onClick={prevSlide}>
          <RippleWrapper key={1} shiftX={-13}>
            <Flex $p="0.3rem">
              <ArrowLeftIcon />
            </Flex>
          </RippleWrapper>
        </ArrowButtonLeft>
      )}
      {currentIndex < numCards - visibleItems && (
        <ArrowButtonRight onClick={nextSlide}>
          <RippleWrapper key={2} shiftX={-13}>
            <Flex $p="0.3rem">
              <ArrowRightIcon />
            </Flex>
          </RippleWrapper>
        </ArrowButtonRight>
      )}
    </SliderContainer>
  );
};

export default ImageSlider;
