import { Link } from 'react-router-dom';
import { Flex } from '../../styles/Containers.styles';
import dress from '../../assets/Images/dress.webp';
import underwear from '../../assets/Images/underwear.webp';
import bra from '../../assets/Images/braa.webp';
import {
  ShowcaseContainer,
  ShowcaseItem,
  ShowcaseItemContainer,
  ShowcaseItemImage,
} from './styles/Showcase.style';

export const MobileShowcase = () => {
  return (
    <ShowcaseContainer>
      <Flex $fs={2} $bold={700} $noFull>
        Dobrodošli u Esotiq - gde svaki dan nosi oblik vašeg srca.
      </Flex>
      <Flex $noFull $fs={0.88}>
        Zagrli Strast, Zagrli Stil, Zagrli Esotiq. Pridružite se Esotiq porodici
        ovog meseca i dozvolite da vas vodimo na putovanje puno ljubavi, lepote,
        i strasti. Bilo da izaberete klasičnu eleganciju Esotiq donjeg veša ili
        se odlučite za avanturu sa QПШ kolekcijom by Roberto Kupisz, sigurni smo
        da ćete naći nešto što savršeno odgovara vašem stilu i emocijama. S
        Esotiq, svaki dan je prilika da slavite ljubav.
      </Flex>

      <ShowcaseItemContainer>
        <Link to="/podkategorija/gacice">
          <ShowcaseItem to="/podkategorija/gacice">
            <ShowcaseItemImage src={underwear} />
            <Flex $noFull>GAĆICE</Flex>
          </ShowcaseItem>
        </Link>
        <Link to="/podkategorija/brushalteri">
          <ShowcaseItem to="/podkategorija/brushalteri">
            <ShowcaseItemImage src={bra} />
            <Flex $noFull>BRUSHALTERI</Flex>
          </ShowcaseItem>
        </Link>
        <Link to="/podkategorija/pidzame">
          <ShowcaseItem to="/podkategorija/pidzame">
            <ShowcaseItemImage src={dress} />
            <Flex $noFull>PIDŽAME</Flex>
          </ShowcaseItem>
        </Link>
      </ShowcaseItemContainer>
    </ShowcaseContainer>
  );
};
