import { useQuery } from '@tanstack/react-query';
import { getCustomerCollectionsWithProducts } from 'api/collections';
import { CollectionWithProducts } from 'types/collection.types';

export const useCollectionsWithProducts = () => {
  const { isLoading, error, data } = useQuery<CollectionWithProducts[]>(
    ['collectionsWithProducts'],
    () => getCustomerCollectionsWithProducts()
  );

  return { isLoading, error, collections: data };
};
