import { AddPromoCodeModal } from 'admin/modals/AddPromoCodeModal';
import { DeleteModal } from 'admin/modals/DeleteModal';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { ModalContext } from 'components/Modal/ModalContext';
import { usePromoCodes } from 'hooks/usePromoCodes';
import { useContext } from 'react';
import { Flex } from 'styles/Containers.styles';

export const PromoCodes = () => {
  const { data, deleteMutation, isLoading } = usePromoCodes();

  const { openModal } = useContext(ModalContext);

  return (
    <List
      headerItems={[
        { header: 'Promo kod', span: 20 },
        { header: 'Popust', span: 20 },
      ]}
      actionTitle="Dodaj promo kod"
      action={() => openModal(<AddPromoCodeModal />)}
      loading={isLoading}
    >
      {data?.map((item) => (
        <ListItem.Item
          key={1 + item.id}
          items={[
            {
              item: (
                <Flex style={{ textTransform: 'initial' }}>{item.code}</Flex>
              ),
              span: 20,
            },
            { item: item.discount + '%', span: 20 },
          ]}
          onDeleteClick={() =>
            openModal(
              <DeleteModal
                deleteAction={() => deleteMutation.mutate(item.id)}
              />
            )
          }
        />
      ))}
    </List>
  );
};
