import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCollection } from 'api/collections';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateCollectionForm } from './CreateCollectionForm';

export const CreateCollection = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createMutation = useMutation(
    (data: any) => {
      const dataForm = new FormData();
      dataForm.append(
        'collection',
        JSON.stringify({
          name: data.name,
        })
      );
      dataForm.append('header', data.header);
      dataForm.append('thumbnail', data.thumbnail);
      return createCollection(dataForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['collections']);
        navigate(-1);
      },
      onError: (err: any) => {
        toast.error(err.response.data.message);
      },
    }
  );

  return <CreateCollectionForm mutation={createMutation} />;
};
