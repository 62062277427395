import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const EndListTrigger: React.FC<{ fetchNextPage: () => void }> = ({
    fetchNextPage,
}) => {
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            fetchNextPage?.();
        }
    }, [inView]);

    return <div style={{ minHeight: '1rem' }} ref={ref} />;
};

export default EndListTrigger;
