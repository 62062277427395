/* eslint-disable camelcase */

import { AttributeValue } from 'components/Attribute/Attribute';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Buttons/Button';
import { Divider } from 'components/Drividers/Divider';
import { MobileSlider } from 'components/MobileSlider/MobileSlider';
import { ProductCard } from 'components/ProductCard/ProductCard';
import ImageSlider from 'components/Slider';
import { useColorVariant } from 'hooks/useColorVariant';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, SectionContainer } from 'styles/Containers.styles';
import convertProductToSimpleProduct, {
  convertFullProductToSimpleProduct,
} from 'utils/product.utils';
import { ColorProduct } from './ColorProduct';
import { ExpandableDesc } from './ExpandableDesc';
import {
  ImagesContainer,
  InfoContainer,
  ProductDetailsContainer,
  ProductImage,
} from './ProductDetails.style';
import { SizeContainer } from './SizeContainer';
import { NotFound } from 'customer/pages/NotFound';
import { FullContainerLoader } from 'components/Loader/Loader';
import { CartContext } from 'context/CartContext';
import { Helmet } from 'react-helmet';
import { SizesModal } from 'admin/pages/dashboard/products/SizesModal';
import { ModalContext } from 'components/Modal/ModalContext';

export const ProductDetails = () => {
  const { id } = useParams();
  const { width } = useWindowWidth();
  const [chosenSize, setChosenSize] = useState(null);
  const [openedSize, setOpenedSize] = useState(false);
  const { addToCart } = useContext(CartContext);
  const { data, error, isLoading } = useColorVariant({ id });
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setChosenSize(null);
  }, [id]);

  useEffect(() => {
    if (data) {
      window.gtag('event', 'view_item', {
        currency: 'RSD',
        value: Number((data.price.price / 100).toFixed()),
        items: [
          {
            item_id: data.id,
            item_name: data.name,
            discount: Number(
              ((data.price.basePrice - data.price.price) / 100).toFixed(2)
            ),
            item_category: data.category,
            item_category2: data.subcategory,
            item_variant: data.colorName,
            price: data.price.price,
          },
        ],
      });
    }
  }, [data]);

  if (isLoading) {
    return (
      <Flex style={{ minHeight: '70vh' }} $jc="center" $ai="center">
        <FullContainerLoader />
      </Flex>
    );
  }

  if (!data) {
    return null;
  }

  if (error) {
    return <NotFound />;
  }

  return (
    <Flex $jc="center" $p="0 1rem 4rem 1rem" $position="relative">
      <Helmet>
        <title>{data.name} | Esotiq</title>
        <meta property="description" content={data.description} />
        <meta property="og:title" content={`${data.name} | Esotiq`} />
        <meta property="og:image" content={`${data.images[0]}`} />
      </Helmet>
      <SectionContainer>
        {width > 600 && (
          <Breadcrumbs
            list={[
              {
                name: data.category.name,
                link: 'kategorija/' + data.category.slug,
              },
              {
                name: data.subcategory.key,
                link: 'podkategorija/' + data.subcategory.slug,
              },
              { name: data.name, link: null },
            ]}
          />
        )}
        <Flex $column $gap="4rem">
          <ProductDetailsContainer>
            {width < 600 ? (
              <MobileSlider images={data.images} />
            ) : (
              <ImagesContainer>
                {data.images.map((image) => (
                  <ProductImage src={image} key={image} />
                ))}
              </ImagesContainer>
            )}
            <InfoContainer>
              {(data.isNew || data.isSale) && (
                <Flex $noFullHeight>
                  {data.isNew && data.isSale
                    ? 'NOVO / SNIŽENO'
                    : data.isNew
                    ? 'NOVO'
                    : data.isSale
                    ? 'SNIŽENO'
                    : null}
                </Flex>
              )}
              <Flex $noFullHeight $jc="space-between">
                <Flex
                  $noFull
                  $fs={1.2}
                  $bold={700}
                  style={{ textTransform: 'uppercase' }}
                >
                  <h1>{data.name.toLocaleUpperCase()}</h1>
                </Flex>
                <Flex $noFull $fs={1.1} $bold={600}>
                  {data.price.price > 0
                    ? ` RSD ${(data.price.price / 100).toFixed(2)}`
                    : 'Nema na stanju'}
                </Flex>
              </Flex>
              {data.price.price > 0 && data.isSale && (
                <Flex $noFullHeight>
                  <>
                    Cena Regularna:{' '}
                    <s>RSD {(data.price.basePrice / 100).toFixed(2)}</s>
                    <span style={{ color: '#e30040' }}>
                      -{data.price.discount}%
                    </span>
                  </>
                </Flex>
              )}

              <Divider />
              <Flex $noFullHeight>
                <ImageSlider
                  visibleItems={
                    width > 1200 ? 4 : width < 600 ? 3 : width / 100
                  }
                >
                  {data.colorVariants.map((product) => (
                    <ColorProduct
                      key={product.id}
                      product={product}
                      chosen={product.id === data.id}
                    />
                  ))}
                </ImageSlider>
              </Flex>
              <Divider />
              <Flex $noFullHeight $bold={700} $jc="space-between" $ai="center">
                <p>Izaberi veličinu</p>
                <Flex
                  $maxWidth="fit-content"
                  $pointer
                  style={{
                    textDecoration: 'underline',
                  }}
                  $bold={300}
                  onClick={() => {
                    openModal(<SizesModal />);
                  }}
                >
                  Vodič za veličine
                </Flex>
              </Flex>
              <Flex $noFullHeight style={{ flexWrap: 'wrap' }}>
                {data.items.map((item) =>
                  item.quantity > 0 ? (
                    <Button
                      width="70px"
                      key={item.id}
                      onClick={() => {
                        setChosenSize(item.id);
                      }}
                      styleType={'outline-dark'}
                      disabled={chosenSize === item.id}
                    >
                      <Flex $gap="0.25rem" $ai="center" $jc="center">
                        {item.quantity === 1 && (
                          <div
                            style={{
                              minWidth: '6px',
                              minHeight: '6px',
                              backgroundColor: '#ff7a00',
                              borderRadius: '10px',
                            }}
                          />
                        )}
                        {item.size}
                      </Flex>
                    </Button>
                  ) : (
                    <Button
                      key={item.id}
                      width="70px"
                      styleType="outline-gray"
                      disabled
                    >
                      {item.size}
                    </Button>
                  )
                )}
              </Flex>
              {data.items.some((item) => item.quantity === 1) && (
                <Flex $noFullHeight $ai="center">
                  <div
                    style={{
                      minWidth: '6px',
                      minHeight: '6px',
                      backgroundColor: '#ff7a00',
                      borderRadius: '10px',
                    }}
                  />{' '}
                  Poslednji na lageru
                </Flex>
              )}
              <Flex $noFullHeight $gap="1rem">
                {/* <Button
                  styleType="outline-dark"
                  width="100%"
                  onClick={() => {
                    setOpenedSize(true);
                  }}
                >
                  {data.items.find((item) => item.id === chosenSize)?.size ||
                    'Izaberi veličinu'}
                </Button> */}
                <Button
                  styleType="primary"
                  width="100%"
                  disabled={!chosenSize || data.price.price <= 0}
                  onClick={() => {
                    addToCart(
                      {
                        ...convertFullProductToSimpleProduct(
                          data,
                          data.items.find((size) => size.id === chosenSize)
                        ),
                      },
                      data.items.find((size) => size.id === chosenSize)
                    );
                  }}
                >
                  {data.price.price > 0 ? 'Dodaj u korpu' : 'Nema na stanju'}
                </Button>
              </Flex>
              <Flex $noFullHeight>
                {data.attributes.map((attribute) => (
                  <AttributeValue
                    id={attribute.id}
                    name={attribute.name}
                    key={attribute.id}
                    image={attribute.image}
                  />
                ))}
              </Flex>
              <Flex $column $gap="0" $noFullHeight>
                {data.description && (
                  <ExpandableDesc title="OPIS PROIZVODA" defaultOpened>
                    <div>
                      <p>{data.description}</p>
                    </div>
                  </ExpandableDesc>
                )}
                <Divider />

                <ExpandableDesc title="INFORMACIJE O PROIZVODU" defaultOpened>
                  {data.materialComposition && (
                    <Flex $jc="space-between" $p="0">
                      <Flex $bold={300}>Materijal</Flex>
                      <Flex $jc="flex-end" $bold={400} $fs={0.9}>
                        {data.materialComposition}
                      </Flex>
                    </Flex>
                  )}
                  <Flex $jc="space-between" $p="0">
                    <Flex $bold={300}>DOSTUPAN U VELIČINI</Flex>
                    <Flex $jc="flex-end" $bold={400} $fs={0.9}>
                      {data.items.map((item) => item.size).join(', ')}
                    </Flex>
                  </Flex>
                  <Flex $jc="space-between" $p="0">
                    <Flex $bold={300}>KATALOŠKI BROJ</Flex>
                    <Flex $jc="flex-end" $bold={400} $fs={0.9}>
                      {data.items[0].catalogueId}
                    </Flex>
                  </Flex>
                  <Flex $jc="space-between" $p="0">
                    <Flex $bold={300}>DOSTUPNE BOJE</Flex>
                    <Flex $jc="flex-end" $bold={400} $fs={0.9}>
                      {data.colorVariants
                        .map((item) => item.colorName)
                        .join(', ')}
                    </Flex>
                  </Flex>
                </ExpandableDesc>
              </Flex>
              {data.setColorVariants.length > 0 && (
                <Flex $column $noFullHeight>
                  <Flex $noFullHeight $bold={700}>
                    PROIZVODI IZ SETA
                  </Flex>
                  <ImageSlider visibleItems={width < 600 ? 2 : 3}>
                    {data.setColorVariants.map((product, index) => (
                      <ProductCard
                        key={product.id}
                        product={convertProductToSimpleProduct(product)}
                        index={index}
                      />
                    ))}
                  </ImageSlider>
                </Flex>
              )}
            </InfoContainer>
          </ProductDetailsContainer>
          {data.similarProducts.length > 0 && (
            <Flex $column $gap="1rem" $noFullHeight>
              <Flex $fs={1.4} $bold={700}>
                SLIČNI PROIZVODI
              </Flex>
              <ImageSlider visibleItems={width < 600 ? 3 : 4}>
                {data.similarProducts.map((product, index) => (
                  <ProductCard
                    key={product.id}
                    product={convertProductToSimpleProduct(product)}
                    index={index}
                  />
                ))}
              </ImageSlider>
            </Flex>
          )}
        </Flex>
      </SectionContainer>
      <SizeContainer
        opened={openedSize}
        onClose={() => {
          setOpenedSize(false);
        }}
        onSelect={(value) => {
          setChosenSize(value);
          setOpenedSize(false);
        }}
        sizes={data.items}
        chosenSize={chosenSize}
      />
    </Flex>
  );
};
