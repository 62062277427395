import React from 'react';

export const AddIcon = () => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.875 5.84766V7.88672H0.0546875V5.84766H9.875ZM6.01953 0.867188V13.2891H3.94531V0.867188H6.01953Z"
        fill="#18191A"
      />
    </svg>
  );
};
