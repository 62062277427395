import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSubcategory } from 'api/subcategory.api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateForm } from './CreateForm';

export const CreateSubcategory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createMutation = useMutation(
    (data: any) => {
      const dataForm = new FormData();
      dataForm.append(
        'subcategory',
        JSON.stringify({
          key: data.key,
          attributeIds: data.selected,
          slug: data.slug,
        })
      );
      dataForm.append('image', data.image);
      return createSubcategory(dataForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['subcategories']);
        navigate(-1);
      },
      onError: (err: any) => {
        toast.error(err.response.data.message);
      },
    }
  );

  return <CreateForm mutation={createMutation} />;
};
