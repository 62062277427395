import { SuccessPaymentIcon } from 'assets/SuccessPaymentIcon';
import { Button } from 'components/Buttons/Button';
import { CartContext } from 'context/CartContext';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

export const OrderCreated = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const cart = useContext(CartContext);
  useEffect(() => {
    cart.clearCart();
  }, []);

  return (
    <Flex
      $jc="center"
      $p="7rem 0"
      $bold={700}
      $fs={1.5}
      $column
      $ai="center"
      $gap="3rem"
    >
      <SuccessPaymentIcon />
      PORUDŽBINA KREIRANA
      <Flex $noFullHeight $jc="center">
        <Flex $column $noFull $jc="center" $ai="center">
          <Flex $noFull $bold={300}>
            Broj porudžbine:
          </Flex>
          <Flex $noFull $textcolor={theme.colors.primary}>
            {id}
          </Flex>
        </Flex>
      </Flex>
      <Button styleType="color" onClick={() => navigate('/')}>
        Nazad na kupovinu
      </Button>
    </Flex>
  );
};
