import { DeleteModal } from 'admin/modals/DeleteModal';
import { CircleCheck } from 'components/Inputs/CircleCheck';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import Pagination from 'components/List/Pagination';
import { FullLoader } from 'components/Loader/Loader';
import { Modal } from 'components/Modal/Modal';
import { ModalContext } from 'components/Modal/ModalContext';
import { useParamsSearch } from 'hooks/useParamsSearch';
import { useProducts } from 'hooks/useProducts';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';
import { AddColorVariant } from './AddColorVariant';
import { BulkModal } from './BulkModal';
import { ColorVariant } from './ColorVariant';
import { EditColorVariant } from './EditColorVariant';
import { ProductActions } from './ProductActions';
import { toast } from 'react-toastify';

export const Products = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const {
    products,
    isLoading,
    deleteProductLoading,
    deleteProduct,
    count,
    deleteVariant,
    limit,
  } = useProducts();
  const { searchParams, handleSetUrlSearchParams } = useParamsSearch();
  const page = Number(searchParams.get('page') || 1);
  const openBulk = searchParams.get('bulk');
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [openedVariants, setOpenedVariants] = useState<number | null>(null);
  const allSelected = selectedProducts.length === products?.length;

  const toggleSelected = (id: number) => {
    setSelectedProducts((prev) => {
      const arr = [...prev];
      const index = arr.findIndex((el) => el === id);
      if (index >= 0) {
        arr.splice(index, 1);
      } else {
        arr.push(id);
      }
      return arr;
    });
  };

  useEffect(() => {
    setSelectedProducts([]);
  }, [products]);

  useEffect(() => {
    if (selectedProducts.length < 1 && openBulk === 'open') {
      toast.error('Za bulk akcije morate imati selektovane proizvode');
      handleSetUrlSearchParams('bulk', null);
    }
  }, [openBulk]);

  return (
    <>
      <List
        headerItems={[
          {
            header: (
              <CircleCheck
                checked={allSelected}
                text=""
                onChange={() => {
                  if (allSelected) {
                    setSelectedProducts([]);
                  } else {
                    setSelectedProducts(products.map((item) => item.id));
                  }
                }}
              />
            ),
            span: 4,
          },
          { header: 'ID', span: 4 },
          { header: 'Naziv proizvoda', span: 37 },
          { header: 'Boje', span: 10 },
          { header: 'Cena', span: 10 },
          { header: 'Tag', span: 25 },
          { header: '', span: 10 },
        ]}
        loading={isLoading}
        noAction
      >
        {products?.map((item) => (
          <ListItem.Item
            key={item.id}
            onClick={() => {
              if (openedVariants === item.id) {
                setOpenedVariants(null);
                return;
              }
              setOpenedVariants(item.id);
            }}
            items={[
              {
                span: 4,
                aiCenter: true,

                item: (
                  <CircleCheck
                    checked={selectedProducts.includes(item.id)}
                    text=""
                    onChange={() => {
                      toggleSelected(item.id);
                    }}
                  />
                ),
              },
              {
                span: 4,
                aiCenter: true,

                item: item.id,
              },
              {
                span: 37,
                aiCenter: true,

                item: item.name,
              },
              {
                span: 10,
                aiCenter: true,

                item: item.colorVariants.length,
              },
              {
                span: 10,
                aiCenter: true,

                item: `RSD ${item.price.price / 100}`,
              },
              {
                span: 25,
                aiCenter: true,

                item: item.isDraft
                  ? 'DRAFT'
                  : item.isOutlet
                  ? 'OUTLET'
                  : item.isNew && item.isSale
                  ? 'Sniženo / Novo'
                  : item.isSale
                  ? 'Sniženo'
                  : item.isNew
                  ? 'Novo'
                  : '',
              },
              {
                span: 10,
                overflow: 'visible',
                item: (
                  <ProductActions
                    onEditClick={() => navigate(`edit/${item.id}`)}
                    onPlusClick={() => {
                      openModal(<AddColorVariant id={item.id} />);
                    }}
                    onDeleteClick={() => {
                      openModal(
                        <DeleteModal
                          deleteAction={() => deleteProduct(item.id)}
                        />
                      );
                    }}
                  />
                ),
              },
            ]}
          >
            {item.id === openedVariants && (
              <Flex $mt="1rem">
                {item.colorVariants.map((variant) => (
                  <ColorVariant
                    // name="hello"
                    // id={1}
                    key={variant.id}
                    color={variant.colorName}
                    image={variant.images[0]}
                    onEditClick={() => {
                      openModal(
                        <EditColorVariant productId={item.id} id={variant.id} />
                      );
                    }}
                    onDeleteClick={() => {
                      if (item.colorVariants.length === 1) {
                        openModal(
                          <DeleteModal
                            title="Da li želite da obrišete ceo proizvod?"
                            deleteAction={() => {
                              deleteProduct(item.id);
                            }}
                          />
                        );
                      } else {
                        openModal(
                          <DeleteModal
                            deleteAction={() =>
                              deleteVariant({
                                id: variant.id,
                                productId: item.id,
                              })
                            }
                          />
                        );
                      }
                    }}
                  />
                ))}
              </Flex>
            )}
          </ListItem.Item>
        ))}
        <Flex>
          {count ? (
            <Pagination
              onPageChange={(page) => {
                handleSetUrlSearchParams('page', page.toString());
              }}
              pageSize={limit}
              currentPage={page}
              totalCount={count}
            />
          ) : null}
        </Flex>
      </List>
      {openBulk && (
        <Modal
          noOutsideClose={false}
          closeFunc={() => {
            handleSetUrlSearchParams('bulk', null);
          }}
        >
          <BulkModal
            selectedItems={selectedProducts}
            onClose={() => {
              handleSetUrlSearchParams('bulk', null);
            }}
          />
        </Modal>
      )}
      <FullLoader loading={deleteProductLoading} />
    </>
  );
};
