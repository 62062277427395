import { Input } from 'components/Inputs/Input';
import {
  formatePhoneNumber,
  validatePhoneNumber,
} from 'utils/validation.utils';

type Props = {
  value: string;
  name: string;
  onChange: (name: string, value: string) => void;
};

export const PhoneNumberInput = ({ value, onChange, name }: Props) => {
  const handleChange = (value: string) => {
    // Allow only starting with '+' or a number, followed by numbers only
    if (/^\+?[0-9]*$/.test(value)) {
      onChange(name, value);
    }
  };

  return (
    <Input
      name={name}
      label="Broj telefona"
      required
      value={value}
      pattern="[0|+]{1}[0-9]*"
      onValueChange={(_name, value) => handleChange(value)}
      error={!value || !validatePhoneNumber(formatePhoneNumber(value))}
      errorMessage={
        !validatePhoneNumber(formatePhoneNumber(value)) &&
        'Broj telefona nije validan'
      }
    />
  );
};
