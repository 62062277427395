import { CategoryType } from 'types/category.types';
import axiosInstance from './instance';

export const getCategories = async () => {
  const res = await axiosInstance.get('/v1/categories');

  return res.data.data;
};

export const createCategory = async (data) => {
  const res = await axiosInstance.post('/v1/categories', data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res.data.data;
};

export const getCategoryById = async (id: string) => {
  const res = await axiosInstance.get(`/v1/categories/${id}`);

  return res.data.data as CategoryType;
};

export const getCategoryBySlug = async (slug: string) => {
  const res = await axiosInstance.get(`/v1/categories/${slug}/slug`);

  return res.data.data as CategoryType;
};

export const editCategory = async (id, data) => {
  const res = await axiosInstance.patch(`/v1/categories/${id}`, data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res.data.data;
};

export const deleteCategory = async (id: number) => {
  const res = await axiosInstance.delete(`/v1/categories/${id}`);

  return res.data.data;
};
