import AdaMallLocation from 'assets/locations/ada.webp';
import NisLocation from 'assets/locations/nis.webp';
import NoviSadLocation from 'assets/locations/novi_sad.webp';
import RajicevaLocation from 'assets/locations/rajiceva.webp';
import TerazijeLocation from 'assets/locations/terazije.webp';

export const locations = [
  {
    city: 'Beograd',
    address: 'Ada Mall, 2. sprat',
    image: AdaMallLocation,
    locationLink: 'https://maps.app.goo.gl/KiassuSyMnCgxwm88',
  },
  {
    city: 'Beograd',
    address: 'TC Rajićeva, 2. sprat',
    image: RajicevaLocation,
    locationLink: 'https://maps.app.goo.gl/8yFyfoz2wtEY6EAY6',
  },
  {
    city: 'Beograd',
    address: 'Terazije 29',
    image: TerazijeLocation,
    locationLink: 'https://maps.app.goo.gl/kZnXRU9BDMbnaU8FA',
  },
  {
    city: 'Novi Sad',
    address: 'Zmaj Jovina 13',
    image: NoviSadLocation,
    locationLink: 'https://maps.app.goo.gl/aFpdmwX6dvBLnwPLA',
  },
  {
    city: 'Niš',
    address: 'Forum Shopping Center',
    image: NisLocation,
    locationLink: 'https://maps.app.goo.gl/3f4bCja2VUnJitJw5',
  },
];
