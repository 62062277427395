import { useQuery } from '@tanstack/react-query';
import { getOrderInfoWithID } from 'api/orders.api';
import { OrderWithUUID } from 'types/orders.types';

export const useOrderById = (id: number) => {
  const { isLoading, error, data } = useQuery<OrderWithUUID>(
    ['orderID', { id }],
    () => getOrderInfoWithID(id)
  );

  return { isLoading, error, data };
};
