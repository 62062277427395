import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteAttributeGroup, deleteAttributeValue } from 'api/attributes.api';
import axiosInstance from 'api/instance';
import { toast } from 'react-toastify';
import { AttributeType } from 'types/attribute.types';

export const useAttributesWithValue = (subcategoryId = null) => {
  const queryClient = useQueryClient();
  const { isLoading, error, data } = useQuery<AttributeType[]>(
    ['attributes'],
    async () => {
      const data = await axiosInstance.get('/v1/attributes/values', {
        params: {
          page: 1,
          limit: 20,
          subcategoryId,
        },
      });

      return data.data.data;
    }
  );

  const deleteMutation = useMutation((id: number) => deleteAttributeGroup(id), {
    onSuccess: (_data, id) => {
      const previousAttributes = queryClient.getQueryData([
        'attributes',
      ]) as AttributeType[];
      const updatedAttributes = previousAttributes.filter(
        (attribute) => attribute.id !== id
      );

      queryClient.setQueryData(['attributes'], updatedAttributes);
      return () => queryClient.setQueryData(['attributes'], previousAttributes);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const deleteAttributeValueMutation = useMutation(
    (vars: { id: number; attributeValueId: number }) =>
      deleteAttributeValue(vars.id, vars.attributeValueId),
    {
      onSuccess: (_data, vars) => {
        queryClient.setQueryData(
          ['attributes'],
          (previousAttributes: AttributeType[]) => {
            const updatedAttributes = [...previousAttributes];
            const index = updatedAttributes.findIndex(
              (attributeItem) => attributeItem.id === vars.id
            );

            console.log(index);

            if (index !== -1) {
              const updatedAttributesSelectedValue = {
                values: updatedAttributes[index].values.filter(
                  (val) => val.id !== vars.attributeValueId
                ),
              };

              updatedAttributes[index] = {
                ...updatedAttributes[index],
                ...updatedAttributesSelectedValue,
              };
            }
            return updatedAttributes;
          }
        );
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return {
    isLoading,
    error,
    data,
    deleteMutation,
    deleteAttributeValue,
    deleteAttributeValueMutation,
  };
};
