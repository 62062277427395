import React from 'react';

export const ProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M19.176 15.367c-0.272-0.817-1.037-1.368-1.897-1.368h-10.559c-0.861 0-1.625 0.551-1.897 1.368l-1.878 5.633h2.108l1.667-5h10.558l1.667 5h2.108l-1.877-5.632zM11.999 11c2.761 0 5-2.239 5-5s-2.239-5-5-5c-2.761 0-5 2.239-5 5s2.239 5 5 5zM11.999 3c1.657 0 3 1.343 3 3s-1.343 3-3 3c-1.657 0-3-1.343-3-3s1.343-3 3-3z"></path>
    </svg>
  );
};
