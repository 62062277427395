import { DeleteIcon } from 'assets/DeleteIcon';
import { EditIcon } from 'assets/EditIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { Divider } from 'components/Drividers/Divider';
import styled from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { format } from 'date-fns';
import { theme } from 'styles/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledImage = styled.div<{ image: string }>`
  width: 100%;
  background-color: #000;
  background-image: url(${({ image }) => image});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #6a6a6a;
  gap: 1rem;
  font-size: 0.875rem;
  aspect-ratio: 127/51;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
`;

const Content = styled.div`
  width: 100%;
  border: 1px solid black;
  overflow: hidden;
  border-top: none;
  padding: 1rem;
  border-radius: 0 0 10px 10px;
  gap: 0.7rem;
  display: flex;
  flex-direction: column;
  height: 12rem;
`;

const ViewBlogButton = styled.div`
  font-weight: bold;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const BlogItem = ({ blog, onClick, onDeleteClick, onEditClick }) => {
  return (
    <Container>
      <StyledImage image={blog.imageUrl} />
      <Content>
        <Flex $fs={0.875} $bold={300} $noFull $noFullHeight>
          {format(new Date(blog.createdAt), 'dd. MM. yyyy')}
        </Flex>
        <h1
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            height: 'fit-content',
          }}
        >
          {blog.title}
        </h1>
        <p
          style={{
            fontSize: '0.875rem',
            flex: 1,
            maxHeight: '3.2rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {blog.shortText}
        </p>
        <Divider />
        <Flex $mt="0.1rem" $noFullHeight>
          <ViewBlogButton onClick={onClick}>Pogledaj Blog</ViewBlogButton>
          <Flex $jc="flex-end">
            <IconButton size={24} onClick={onEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton size={24} onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Flex>
        </Flex>
      </Content>
    </Container>
  );
};
