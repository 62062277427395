import { DeleteModal } from 'admin/modals/DeleteModal';
import { removeImageFromVariant, uploadVariantImage } from 'api/products.api';
import { DeleteIcon } from 'assets/DeleteIcon';
import { ImageIcon } from 'assets/ImageIcon';
import { ModalContext } from 'components/Modal/ModalContext';
import { useHover } from 'hooks/useHover';
import { useContext, useId, useRef } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  display: grid;
  grid-template-columns: 13rem 13rem;
  width: 27rem;
  height: 100%;
  gap: 1rem;
  overflow-y: scroll;
`;

const AddImageContainer = styled.div`
  width: 13rem;
  aspect-ratio: 3771/5717;
  background-color: ${theme.colors.imageInputColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const Image = styled.div<{ $image: string }>`
  width: 13rem;
  aspect-ratio: 3771/5717;
  background-image: url(${({ $image }) => $image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 3rem;
    width: 3rem;
  }
`;

const DeleteButton = styled.div`
  width: 30%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 30%;
    height: 30%;
  }
`;

const ImageComp = ({ src, onClick }) => {
  const id = useId();
  const [hoverRef, isHovered] = useHover();

  return (
    <Image key={id} $image={src} ref={hoverRef}>
      {isHovered && (
        <DeleteButton onClick={onClick}>
          <DeleteIcon color="white" />
        </DeleteButton>
      )}
    </Image>
  );
};

export const EditColorVariantImages = ({ images, onChange, id }) => {
  const ref = useRef(null);
  const { openModal } = useContext(ModalContext);

  const removeImage = (index: number) => {
    onChange((images) => {
      const newArray = [...images];
      newArray.splice(index, 1);
      return newArray;
    });
  };

  const onUpload = async (image: File) => {
    const dataForm = new FormData();
    dataForm.append('file', image);
    const res = await uploadVariantImage(dataForm, id);
    onChange([...images, res]);
  };

  const onImageDelete = async (url) => {
    await removeImageFromVariant(url, id);
    return;
  };

  return (
    <Container>
      {images.map((image, index) => (
        <ImageComp
          src={image}
          key={index}
          onClick={() => {
            openModal(
              <DeleteModal
                deleteAction={() => {
                  if (images.length === 1) {
                    toast.error('Poslednja slika ne može biti obrisana.');
                    return;
                  }
                  onImageDelete(image);
                  removeImage(index);
                }}
              />
            );
          }}
        />
      ))}
      <AddImageContainer
        onClick={() => {
          if (ref.current) {
            ref.current.click();
          }
        }}
      >
        <IconImage>
          <ImageIcon />
        </IconImage>
        <Flex $noFullHeight $noFull>
          Dodaj sliku
        </Flex>
      </AddImageContainer>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        value={null}
        type="file"
        ref={ref}
        onChange={(e) => {
          onUpload(e.target.files[0]);
        }}
      />
    </Container>
  );
};
