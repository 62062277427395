import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteSubcategory, getSubcategories } from 'api/subcategory.api';
import { toast } from 'react-toastify';
import { SimpleSubcategoryType } from 'types/subcategory.types';

export const useSubcategories = () => {
  const { isLoading, error, data } = useQuery<SimpleSubcategoryType[]>(
    ['subcategories'],
    getSubcategories,
    { refetchOnWindowFocus: false }
  );

  const queryClient = useQueryClient();

  const deleteMutation = useMutation((id: number) => deleteSubcategory(id), {
    onSuccess: (_data, id) => {
      queryClient.setQueryData(
        ['subcategories'],
        (previousData: SimpleSubcategoryType[]) => {
          const updatedAttributes = [...previousData];
          const index = updatedAttributes.findIndex(
            (attributeItem) => attributeItem.id === id
          );

          updatedAttributes.splice(index, 1);

          return updatedAttributes;
        }
      );
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  return { isLoading, error, data, deleteMutation };
};
