import { CartContext } from 'context/CartContext';
import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const Wrapper = styled.div`
  position: relative;
`;

const Dot = styled.div`
  position: absolute;
  top: 5px;
  right: 0px;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 10rem;
  background-color: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 700;
  color: white;
`;

type Props = {
  children: React.ReactNode;
};

export const CartItemCounter = ({ children }: Props) => {
  const { cartItems } = useContext(CartContext);
  return (
    <Wrapper>
      {children}
      {cartItems.length > 0 && <Dot>{cartItems.length}</Dot>}
    </Wrapper>
  );
};
