import { useQuery } from '@tanstack/react-query';
import { getCategories } from 'api/categories.api';
import { CloseIcon } from 'assets/CloseIcon';
import { SearchIcon } from 'assets/SearchIcon';
import { CartItemCounter } from 'components/CartItemCounter/CartItemCounter';
import { SearchInput } from 'components/Inputs/SearchInput';
import { useAuth } from 'context/AuthContext';
import { populateSubcategories } from 'fakeData/pages';
import { useCustomerCollections } from 'hooks/useCustomerCollections';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CategoryType } from 'types/category.types';
import { Logo } from '../../assets';
import { CartIcon } from '../../assets/CartIcon';
import { MenuIcon } from '../../assets/MenuIcon';
import { ProfileIcon } from '../../assets/ProfileIcon';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { Flex } from '../../styles/Containers.styles';
import { IconButton } from '../Buttons/IconButton';
import { LinkComponent } from './LinkComponent';
import { MobileMenu } from './MobileMenu';
import {
  NavActionContainer,
  NavLinksContainer,
  NavMainContainer,
  NavbarContainer,
} from './Navbar.style';

const Navbar = () => {
  const screenSize = useWindowWidth();
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data } = useQuery<CategoryType[]>(['navigation'], {
    queryFn: getCategories,
  });
  const { collections } = useCustomerCollections();
  const [searchOpened, setSearchOpened] = useState(false);

  return (
    <NavbarContainer>
      {/* <MessageContainer>
        Kurirska služba neće raditi od 01.05. do 06.05.24.
      </MessageContainer> */}
      <NavMainContainer>
        {screenSize?.width < 600 && searchOpened ? (
          <Flex $p="0 1rem" style={{ width: '100%' }} $ai="center">
            <SearchInput
              onClear={() => {
                setSearchOpened(false);
                navigate('/');
              }}
              onEnter={(e) => {
                console.log(e);
                if (e !== '') {
                  navigate(`/pretraga?search=${e}`);
                }
              }}
            />
            <IconButton
              onClick={() => {
                setSearchOpened(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Flex>
        ) : (
          <>
            <Flex $noFull $ai="center" $gap="1rem">
              {screenSize.width < 950 && (
                <IconButton
                  tooltip="Menu"
                  onClick={() => {
                    setOpened(true);
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                <Logo
                  height={screenSize.width < 950 ? 40 : 50}
                  width={screenSize.width < 950 ? 120 : 140}
                />
              </Link>
            </Flex>
            {searchOpened && (
              <SearchInput
                onClear={() => {
                  setSearchOpened(false);
                  navigate('/');
                }}
                onEnter={(e) => {
                  if (e !== '') {
                    navigate(`/pretraga?search=${e}`);
                  }
                }}
              />
            )}
            <NavActionContainer>
              {searchOpened ? (
                <IconButton
                  tooltip="Ugasi pretragu"
                  onClick={() => {
                    setSearchOpened(false);
                  }}
                  key="turn off"
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    tooltip="Pretraži"
                    onClick={() => {
                      setSearchOpened(true);
                    }}
                    key="turn on"
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton
                    tooltip="Moj profil"
                    onClick={() => {
                      if (!user) {
                        navigate('/profile/login');
                      } else {
                        navigate('/profile');
                      }
                    }}
                  >
                    <ProfileIcon />
                  </IconButton>
                  {/* <IconButton tooltip="Favorite">
            <HearthIcon />
          </IconButton> */}
                  <CartItemCounter>
                    <IconButton
                      tooltip="Korpa"
                      onClick={() => navigate('/cart')}
                    >
                      <CartIcon />
                    </IconButton>
                  </CartItemCounter>
                </>
              )}
            </NavActionContainer>
          </>
        )}
      </NavMainContainer>
      {screenSize.width > 950 && (
        <NavLinksContainer>
          {populateSubcategories({ data, collections }).map((page) => (
            <LinkComponent page={page} key={page.name} />
          ))}
          {/* {data.map((page) => (
            <LinkComponent page={page} key={page.name} />
          ))} */}
        </NavLinksContainer>
      )}
      {screenSize.width < 950 && (
        <MobileMenu
          opened={opened}
          close={() => {
            setOpened(false);
          }}
          links={populateSubcategories({ data, collections })}
        />
      )}
    </NavbarContainer>
  );
};

export default Navbar;
