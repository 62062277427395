import React from 'react';

export const Logo = ({
  width = 190,
  height = 61,
}: {
  height: number;
  width: number;
}) => {
  return (
    <svg
      data-v-06a2c554=""
      width={width || '120'}
      height={height || '40'}
      viewBox="0 0 120 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 27.2858H11.1141V25.4429H1.89809V19.414L9.30751 19.3912V17.5939H1.89809V12.566H11.1141V10.7004H0V27.2858Z"
        fill="#010101"
      ></path>{' '}
      <path
        d="M26.705 17.9808C24.4868 17.5258 22.4058 17.0935 22.4058 14.9094C22.4058 13.2486 23.8007 12.2476 26.1333 12.2476C28.283 12.2476 29.7237 13.4989 29.7237 15.3417V15.4099H31.5074V15.3417C31.5074 13.9311 31.0043 12.7026 30.0667 11.8381C29.1062 10.9508 27.7341 10.473 26.1333 10.473C22.8403 10.473 20.622 12.2248 20.622 14.8412C20.622 18.5496 23.6178 19.1411 26.2705 19.6643C28.5345 20.1194 30.4784 20.4834 30.4784 22.5309C30.4784 23.3045 30.1811 25.8526 26.4078 25.8526C23.6407 25.8526 21.9941 24.078 21.9941 22.3717V22.3034H20.1875V22.3717C20.1875 24.9198 22.2914 27.6271 26.2248 27.6271C28.2372 27.6271 29.8609 27.0583 30.9129 25.9891C31.759 25.1245 32.2392 23.9415 32.2392 22.7357C32.2392 19.1183 29.2892 18.5268 26.705 17.9808Z"
        fill="#010101"
      ></path>{' '}
      <path
        d="M66.5703 12.5432H73.7511V27.2857H75.5348V12.5432H82.7155V10.7231H66.5703V12.5432Z"
        fill="#010101"
      ></path>{' '}
      <path
        d="M93.9634 10.6777H92.1797V27.2858H93.9634V10.6777Z"
        fill="#010101"
      ></path>{' '}
      <path
        d="M50.4496 10.4502C45.6929 10.4502 41.8281 14.2951 41.8281 19.0272C41.8281 23.7594 45.6929 27.6043 50.4496 27.6043C55.2062 27.6043 59.071 23.7594 59.071 19.0272C59.0939 14.3178 55.2062 10.4502 50.4496 10.4502ZM50.4496 25.8297C46.6991 25.8297 43.6347 22.7811 43.6347 19.05C43.6347 15.3189 46.6991 12.2703 50.4496 12.2703C54.2 12.2703 57.2644 15.3189 57.2644 19.05C57.2873 22.7811 54.2229 25.8297 50.4496 25.8297Z"
        fill="#010101"
      ></path>{' '}
      <path
        d="M120.102 19.0499C120.102 14.3178 116.237 10.4729 111.481 10.4729C106.724 10.4729 102.859 14.3178 102.859 19.0499C102.859 23.7821 106.724 27.627 111.481 27.627C116.215 27.627 120.102 23.7821 120.102 19.0499ZM104.643 19.0499C104.643 15.3188 107.708 12.2702 111.458 12.2702C115.208 12.2702 118.273 15.3188 118.273 19.0499C118.273 22.7811 115.208 25.8297 111.458 25.8297C107.708 25.8297 104.643 22.7811 104.643 19.0499Z"
        fill="#010101"
      ></path>{' '}
      <path
        d="M111.022 29.4926C110.244 29.3788 109.444 29.3105 108.621 29.3105C107.248 29.3105 106.334 29.4926 106.288 29.4926H106.242L106.585 31.2216V31.2899H106.631C106.722 31.2671 107.477 31.1534 108.621 31.1534C109.352 31.1534 110.084 31.1989 110.793 31.3126C113.857 31.7676 116.304 33.1554 118.088 35.385L118.134 35.4305L119.575 34.293L119.529 34.2475C117.471 31.6539 114.521 29.9931 111.022 29.4926Z"
        fill="#010101"
      ></path>
    </svg>
    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 190 61"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   data-v-7656088e=""
    // >
    //   <path
    //     d="M137.617 6.74354C135.809 6.74354 134.324 5.25218 134.324 3.37177C134.324 1.5562 135.809 0 137.617 0C139.425 0 140.911 1.49136 140.911 3.37177C140.975 5.25218 139.49 6.74354 137.617 6.74354Z"
    //     fill="#E30040"
    //   ></path>{' '}
    //   <path
    //     d="M181.337 60.4966C179.787 61.4044 177.72 60.8857 176.816 59.2646C175.912 57.6436 176.429 55.6335 178.043 54.6609C179.593 53.7531 181.66 54.2718 182.564 55.8929C183.468 57.5139 182.887 59.5888 181.337 60.4966Z"
    //     fill="#E30040"
    //   ></path>{' '}
    //   <path
    //     d="M14.7259 36.5053C12.9177 36.5053 11.4324 35.0139 11.4324 33.1335C11.4324 31.3179 12.9177 29.7617 14.7259 29.7617C16.5341 29.7617 18.0194 31.2531 18.0194 33.1335C18.084 35.0139 16.5986 36.5053 14.7259 36.5053Z"
    //     fill="#E30040"
    //   ></path>{' '}
    //   <path
    //     d="M185.211 22.111C182.176 16.794 177.655 13.3574 171.779 11.866C166.096 10.3746 160.09 11.2824 154.924 14.2651C149.757 17.3127 146.076 22.0461 144.397 27.817C142.783 33.4583 143.493 39.4237 146.27 44.3517C149.241 49.6039 153.89 53.1701 159.703 54.6615C164.417 55.8935 169.389 55.4396 173.91 53.5592L171.004 48.5015C167.969 49.6038 164.675 49.7335 161.575 48.8906C157.507 47.7234 154.084 45.1298 151.953 41.369C149.822 37.6081 149.241 33.2637 150.339 29.1787C151.501 24.964 154.084 21.4626 157.765 19.3228C161.446 17.183 165.773 16.5994 169.906 17.7666C174.104 18.9337 177.462 21.5274 179.593 25.2882C181.724 29.049 182.37 33.3934 181.207 37.4785C180.368 40.7205 178.624 43.4439 176.235 45.5188L179.141 50.5765C183.015 47.5938 185.792 43.5736 187.213 38.7104C188.763 32.9395 187.988 27.0389 185.211 22.111ZM176.17 45.3891L168.291 31.5779L163.125 34.6254L171.004 48.4367C172.877 47.7234 174.62 46.686 176.17 45.3891Z"
    //     fill="#18191A"
    //   ></path>{' '}
    //   <path
    //     d="M131.673 17.5074V11.9958H94.9283V17.5074H110.298V54.3375H116.239V17.5074H131.673Z"
    //     fill="#18191A"
    //   ></path>{' '}
    //   <path
    //     d="M140.587 11.9958H134.646V54.3375H140.587V11.9958Z"
    //     fill="#18191A"
    //   ></path>{' '}
    //   <path
    //     d="M52.4378 43.8977C52.4378 36.7652 48.8214 33.977 42.6865 30.0865C37.5848 26.8444 34.4205 25.0937 34.4205 21.7219C34.4205 18.8688 36.939 16.7291 40.8137 16.7291C44.1718 16.7291 47.1424 18.2204 50.5651 21.0086V14.1354C47.4653 12.1902 44.301 11.2175 41.072 11.2175C33.6455 11.2175 28.673 16.0807 28.673 22.5C28.673 28.5951 32.2894 31.1239 39.393 35.6628C43.9135 38.5807 46.3675 40.2666 46.3675 43.7681C46.3675 47.1398 43.8489 49.2148 39.6513 49.2148C35.5829 49.2148 32.3539 47.2047 28.7376 42.99V51.0303C32.1602 53.8834 35.6474 55.0505 40.0388 55.0505C47.4653 55.1154 52.4378 50.2522 52.4378 43.8977Z"
    //     fill="#18191A"
    //   ></path>{' '}
    //   <path
    //     d="M25.8314 54.3375V48.8908H5.94122V17.4426H25.2502V11.9958H0V54.3375H25.8314Z"
    //     fill="#18191A"
    //   ></path>{' '}
    //   <path
    //     d="M98.6126 33.3932C98.6126 27.2333 96.3524 21.9811 92.0256 17.7016C87.828 13.5517 82.2097 11.2822 76.2039 11.2822C70.2627 11.2822 64.7089 13.5517 60.3822 17.7016C56.1846 21.7866 53.8597 27.2981 53.8597 33.0042C53.8597 39.0345 56.12 44.4812 60.3822 48.6959C64.5798 52.8457 70.1981 55.1152 76.2039 55.1152C82.2097 55.1152 87.6989 52.9754 92.0902 48.6959C96.3524 44.6109 98.6126 39.0993 98.6126 33.3932ZM92.2193 33.1987C92.2193 37.5431 90.6049 41.6281 87.5697 44.6109C84.4699 47.7233 80.5952 49.3443 76.2685 49.3443C72.0063 49.3443 67.8733 47.6584 64.9672 44.6109C61.9966 41.5633 60.3176 37.5431 60.3176 33.1987C60.3176 28.8543 61.9966 24.8341 64.9672 21.7866C68.067 18.739 72.0709 16.9883 76.2685 16.9883C80.5306 16.9883 84.5345 18.6742 87.5697 21.7866C90.6049 24.9638 92.2193 28.8543 92.2193 33.1987Z"
    //     fill="#18191A"
    //   ></path>
    // </svg>
  );
};
