import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editAttributeGroup } from 'api/attributes.api';
import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { Flex, Form } from 'styles/Containers.styles';
import { AttributeType } from 'types/attribute.types';

export const EditAttributeGroup = ({
  attribute,
}: {
  attribute: AttributeType;
}) => {
  const [form, setForm] = useState({
    name: attribute.name,
  });
  const [error, setError] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    () => editAttributeGroup(attribute.id, form),
    {
      onSuccess: () => {
        queryClient.setQueryData(
          ['attributes'],
          (previousAttributes: AttributeType[]) => {
            const updatedAttributes = [...previousAttributes];
            const index = updatedAttributes.findIndex(
              (attributeItem) => attributeItem.id === attribute.id
            );

            if (index !== -1) {
              updatedAttributes[index] = {
                ...updatedAttributes[index],
                ...{ ...attribute, name: form.name },
              };
            }
            closeModal();
            return updatedAttributes;
          }
        );
      },

      onError: () => {
        setError(true);
      },
    }
  );

  const onChange = (prop, value) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };
  return (
    <Flex $column $ai="center">
      <Flex $noFull $fs={1.3} $bold={700}>
        IZMENI GRUPU ATRIBUTA
      </Flex>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          (document.activeElement as HTMLElement).blur();
          if (!form.name) {
            setError(true);
            return;
          }
          updateMutation.mutate();
        }}
      >
        <Flex $column $gap="0">
          <Input
            label="Ime"
            value={form.name}
            name="name"
            autoFocus
            required
            error={error && !form.name}
            onValueChange={onChange}
          />
        </Flex>
        <Button styleType="color" disabled={attribute.name === form.name}>
          {updateMutation.isLoading ? 'Učitavanje' : 'Sačuvaj'}
        </Button>
      </Form>
    </Flex>
  );
};
