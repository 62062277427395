import React from 'react';

export const SubcategoriesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 9.625C9.375 9.27982 9.65482 9 10 9H16.875C17.2202 9 17.5 9.27982 17.5 9.625C17.5 9.97018 17.2202 10.25 16.875 10.25H10C9.65482 10.25 9.375 9.97018 9.375 9.625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 4.625C9.375 4.27982 9.65482 4 10 4H16.875C17.2202 4 17.5 4.27982 17.5 4.625C17.5 4.97018 17.2202 5.25 16.875 5.25H10C9.65482 5.25 9.375 4.97018 9.375 4.625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 14.625C9.375 14.2798 9.65482 14 10 14H16.875C17.2202 14 17.5 14.2798 17.5 14.625C17.5 14.9702 17.2202 15.25 16.875 15.25H10C9.65482 15.25 9.375 14.9702 9.375 14.625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9.5C3 9.22386 3.1722 9 3.38462 9H7.61538C7.8278 9 8 9.22386 8 9.5C8 9.77614 7.8278 10 7.61538 10H3.38462C3.1722 10 3 9.77614 3 9.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.5C3 4.22386 3.1722 4 3.38462 4H7.61538C7.8278 4 8 4.22386 8 4.5C8 4.77614 7.8278 5 7.61538 5H3.38462C3.1722 5 3 4.77614 3 4.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14.5C3 14.2239 3.1722 14 3.38462 14H7.61538C7.8278 14 8 14.2239 8 14.5C8 14.7761 7.8278 15 7.61538 15H3.38462C3.1722 15 3 14.7761 3 14.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
