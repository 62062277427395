import { IconButton } from 'components/Buttons/IconButton';
import React from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const SelectButtonContainer = styled.div<{ $disabled: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid
    ${({ $disabled }) =>
      $disabled ? theme.colors.secondaryLight : theme.colors.secondaryDark};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectedDot = styled.div`
  width: 60%;
  height: 60%;
  background-color: ${theme.colors.primary};
  border-radius: 50%;
`;

type Props = {
  selected: boolean;
  onSelect: () => void;
  disabled: boolean;
};

export const SelectButton = ({ selected, onSelect, disabled }: Props) => {
  return (
    <IconButton onClick={onSelect} disabled={disabled}>
      <SelectButtonContainer $disabled={disabled}>
        {selected && <SelectedDot />}
      </SelectButtonContainer>
    </IconButton>
  );
};
