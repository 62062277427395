import React from 'react';

export const OrdersIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.88788 4.62114C4.88788 3.0836 6.17136 1.83726 7.75471 1.83726C9.33799 1.83726 10.6215 3.0836 10.6215 4.62114V6.01309H12.055V4.62114C12.055 2.3149 10.1297 0.445312 7.75471 0.445312C5.37975 0.445312 3.45446 2.3149 3.45446 4.62114V6.01309H4.88788V4.62114ZM14.0071 6.01309L12.2015 13.6108H3.30789L1.50229 6.01309H0.03125L2.03939 14.4632C2.11443 14.7791 2.40398 15.0027 2.73775 15.0027H12.7717C13.1054 15.0027 13.395 14.7791 13.47 14.4632L15.4782 6.01309H14.0071Z"
        fill="white"
      />
    </svg>
  );
};
