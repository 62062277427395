import { useQueryClient } from '@tanstack/react-query';
import { requestLabels, sendShipmentRequest } from 'api/orders.api';
import { PrinterIcon } from 'assets/PrinterIcon';
import { TruckIcon } from 'assets/TruckIcon';
import { Button } from 'components/Buttons/Button';
import { FullLoader } from 'components/Loader/Loader';
import PDFMerger from 'pdf-merger-js/browser';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from 'styles/Containers.styles';

export const OrdersNav = () => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const base64ToPdf = (data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    // eslint-disable-next-line no-plusplus
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      // eslint-disable-next-line no-plusplus
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const blob = new Blob(byteArrays, { type: 'application/pdf' });

    return URL.createObjectURL(blob);
  };

  const mergePdfs = async (pdf: string) => {
    const merger = new PDFMerger();
    const blob = base64ToPdf(pdf);
    await merger.add(blob);
    const mergedPdf = await merger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);

    return url;
  };

  const printPdfWithUrl = (url: string) => {
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.src = url;
    iframe.style.display = 'none';

    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        //   iframe.contentWindow.print('print', false, null)

        iframe.contentWindow?.print();
      }, 1000);
    };
  };

  const printLabels = async () => {
    setLoading(true);
    try {
      const codes = await requestLabels();
      const pdfs = await mergePdfs(codes);
      printPdfWithUrl(pdfs);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const mergeShipmentPdfs = async (pdfs: string[]) => {
    const merger = new PDFMerger();
    await Promise.all(
      pdfs.map(async (pdf: string) => {
        const blob = base64ToPdf(pdf);
        await merger.add(blob);
      })
    );
    const mergedPdf = await merger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);

    return url;
  };

  const shipmentRequest = async () => {
    setLoading(true);
    try {
      const codes = await sendShipmentRequest();
      const pdfs = await mergeShipmentPdfs(codes);
      printPdfWithUrl(pdfs);
      queryClient.invalidateQueries(['orders']);
      toast.success('Porudžbine su uspešno poslate kurirskoj službi!');
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Flex $jc="space-between">
      <Flex style={{ letterSpacing: 2 }} $ai="center" $fs={1.2} $bold={700}>
        Porudžbine
      </Flex>
      <Flex $ai="center" $noFull>
        <Button
          width="fit-content"
          styleType="outline-dark"
          onClick={() => {
            printLabels();
          }}
        >
          <PrinterIcon /> Odštampaj nalepnice
        </Button>
        <Button
          width="fit-content"
          styleType="outline-dark"
          onClick={shipmentRequest}
        >
          <TruckIcon /> Pozovi kurira
        </Button>
      </Flex>
      <FullLoader loading={loading} />
    </Flex>
  );
};
