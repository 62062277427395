import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const StyledDivider = styled.div<{ $vertical: boolean; $color: string }>`
  width: ${({ $vertical }) => ($vertical ? '1px' : '100%')};
  height: ${({ $vertical }) => (!$vertical ? '1px' : '100%')};
  background-color: ${({ $color }) =>
    $color ? $color : theme.colors.secondaryLight};
`;

export const Divider = ({ vertical = false, color = null, ...rest }) => {
  return <StyledDivider {...rest} $vertical={vertical} $color={color} />;
};
