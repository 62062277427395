import { useInfiniteQuery } from '@tanstack/react-query';
import { getCustomers } from 'api/customers.api';

export const useCustomers = () => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['customers'],
      queryFn: ({ pageParam = 1 }) => {
        return getCustomers({ page: pageParam, limit: 60 });
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
    });

  const customers = data?.pages
    ?.map((data) => (data?.data ? data.data : []))
    .flat(1);

  return {
    customers,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};
