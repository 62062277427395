import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from '../../styles/Containers.styles';

type Props = {
  children: React.ReactNode;
  opened: boolean;
};

const Container = styled.div<{
  $height: any;
}>`
  max-height: ${({ $height }) => ($height ? $height : '0')}px;
  overflow: hidden;
  background-color: white;
  transition: 200ms ease-in all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;

export const ExpandableContainer = ({ opened, children }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const h = opened ? ref.current?.clientHeight : null;
    setHeight(h);
  }, [opened, children]);

  return (
    <Container $height={height}>
      <Flex $column ref={ref} $p="0 0 2rem 0">
        {children}
      </Flex>
    </Container>
  );
};
