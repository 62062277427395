import { OrderStatusType } from 'types/orders.types';

const COLORS = {
  primary: '#E30040',
  secondary: '#747B7F',
  secondaryDark: '#18191A',
  secondaryLight: '#DADCDF',
  borderGray: '#B3B3B3',
  background: '#F5F5F5',
  error: '#FF7A00',
  confirm: '#38D476',
  imageInputColor: '#D9D9D9',
  green: '#219653',
  orange: '#F2994A',
  blue: '#0E99FB',
};

const getColorByStatus = (status: OrderStatusType) => {
  switch (status) {
    case 'created':
      return COLORS.primary;
    case 'confirmed':
      return COLORS.orange;
    case 'pending':
      return COLORS.blue;
    case 'shipped':
      return COLORS.green;
    default:
      return COLORS.secondaryDark;
  }
};

const SHADOWS = {
  shadowBottom: 'box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25)',
  shadowRound: 'box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)',
};

export const theme = {
  colors: COLORS,
  shadows: SHADOWS,
  getColorByStatus,
};
