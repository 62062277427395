import React from 'react';

export const TrashIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M7.39662 21.0002C6.89795 21.0002 6.47195 20.8236 6.11862 20.4705C5.76545 20.1171 5.58887 19.6911 5.58887 19.1925V6.50022H4.58887V5.00022H9.08887V4.11572H15.0889V5.00022H19.5889V6.50022H18.5889V19.1925C18.5889 19.6976 18.4139 20.1252 18.0639 20.4752C17.7139 20.8252 17.2863 21.0002 16.7811 21.0002H7.39662ZM17.0889 6.50022H7.08887V19.1925C7.08887 19.2823 7.1177 19.3561 7.17537 19.4137C7.23303 19.4714 7.30678 19.5002 7.39662 19.5002H16.7811C16.8581 19.5002 16.9286 19.4681 16.9926 19.404C17.0568 19.34 17.0889 19.2695 17.0889 19.1925V6.50022ZM9.49287 17.5002H10.9926V8.50022H9.49287V17.5002ZM13.1851 17.5002H14.6849V8.50022H13.1851V17.5002Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
