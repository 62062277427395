import { Logo } from 'assets';
import { ArrowLeftLocationSlider } from 'assets/ArrowLeftLocationSlider';
import { ArrowRightLocationSlider } from 'assets/ArrowRightLocationSlider';
import { useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-block: 4rem;
  overflow: hidden;
  max-width: 100vw;

  @media screen and (max-width: 950px) {
    padding: 1rem;
  }
`;

const Title = styled.h2`
  color: ${theme.colors.primary};
  font-size: 2rem;
`;

const Desc = styled.p`
  font-weight: lighter;
`;

const CardList = styled.div<{ visibleItems: number; numCards: number }>`
  width: ${({ visibleItems, numCards }) =>
    numCards * (100 / visibleItems) + '%'};
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    ${({ numCards }) => 100 / numCards + '%'}
  );
  grid-auto-flow: column;
  transition: transform 0.3s ease-in-out;
`;

const CardImage = styled.div<{ src: string }>`
  width: 100%;
  height: 11rem;
  background-image: url(${({ src }) => src});
  background-size: cover;
  padding: 2rem;
  background-position: center;
`;

const LogoContainer = styled.div`
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: fit-content;
  margin-left: 1rem;
`;

const MapLink = styled.a`
  color: ${theme.colors.primary};
  font-weight: lighter;

  &:hover {
    text-decoration: underline;
  }
`;

const LocationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LocationCard = ({ location }: { location: any }) => (
  <LocationCardContainer>
    <CardImage src={location.image}>
      <LogoContainer>
        <Logo width={100} height={34} />
      </LogoContainer>
    </CardImage>
    <Flex $ai="flex-end" $noFullHeight>
      <Flex $column>
        <h2>{location.city}</h2>
        <Desc>{location.address}</Desc>
      </Flex>
      <Flex $jc="flex-end">
        {location.locationLink && (
          <MapLink target="_blank" href={location.locationLink}>
            Pogledaj na mapi
          </MapLink>
        )}
      </Flex>
    </Flex>
  </LocationCardContainer>
);

export const StoreLocations = ({
  locations,
  visibleItems,
}: {
  locations: any[];
  visibleItems: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const numCards = locations.length;

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const nextSlide = () => {
    if (currentIndex < numCards - visibleItems) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  return (
    <Container>
      <Flex>
        <Flex $column>
          <Title>Naše lokacije</Title>
          <Desc>Pronađite naš asortiman u Esotiq prodavnicama.</Desc>
        </Flex>
        <Flex $noFull $gap="1rem">
          <ButtonContainer onClick={prevSlide}>
            <ArrowLeftLocationSlider
              color={currentIndex > 0 ? theme.colors.primary : 'black'}
            />
          </ButtonContainer>
          <ButtonContainer onClick={nextSlide}>
            <ArrowRightLocationSlider
              color={
                currentIndex < numCards - visibleItems
                  ? theme.colors.primary
                  : 'black'
              }
            />
          </ButtonContainer>
        </Flex>
      </Flex>

      <CardList
        numCards={numCards}
        visibleItems={visibleItems}
        style={{
          transform: `translateX(${-currentIndex * (100 / numCards)}%)`,
        }}
      >
        {locations.map((location, index) => (
          <Flex key={'location_card_' + index} $p="0.5rem">
            <LocationCard location={location} />
          </Flex>
        ))}
      </CardList>
    </Container>
  );
};
