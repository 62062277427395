import { useSubcategories } from 'hooks/useSubcategories';
import { SimpleSubcategoryType } from 'types/subcategory.types';
import { SelectInput } from './SelectInput';

export const CategorySelect = ({ onChange, initialValue = null }) => {
  const { data } = useSubcategories();
  console.log(initialValue);
  return (
    <SelectInput<SimpleSubcategoryType>
      options={data}
      label="Podkategorije"
      initialValue={initialValue}
      required
      renderOptionName={(option) => option.key}
      name="category"
      onValueChange={(e) => {
        onChange(e);
      }}
    />
  );
};
