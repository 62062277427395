import { BackArrowIcon } from 'assets/BackArrowIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';
import { ProductsNavbar } from './dashboard/ProductsNavbar';

export const NavbarAdmin = ({
  name,
  back,
  withSelect,
}: {
  name: string;
  back?: boolean;
  withSelect?: boolean;
  withSearch?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Flex style={{ letterSpacing: 2 }} $ai="center" $fs={1.2} $bold={700}>
      {back && (
        <IconButton onClick={() => navigate(-1)}>
          <BackArrowIcon />
        </IconButton>
      )}
      <Flex>
        <Flex style={{ whiteSpace: 'nowrap' }} $fs={1.2} $ai="center" $noFull>
          {name}
        </Flex>

        {withSelect && <ProductsNavbar />}
        <Flex $noFull></Flex>
      </Flex>
    </Flex>
  );
};
