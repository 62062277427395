/* eslint-disable camelcase */
import {
  addToCartCall,
  getCart,
  removeItemFromCartCall,
  setCartApiCall,
} from 'api/cart.api';
import { ModalContext } from 'components/Modal/ModalContext';
import { AddToCartModal } from 'components/Modals/AddToCartModal';
import { useDidUpdate } from 'hooks/useDidUpdate';
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from 'styles/Containers.styles';
import { CartItemType } from 'types/cart.types';
import { useAuth } from './AuthContext';

interface CartProviderProps {
  children: React.ReactNode;
}

interface CartContextType {
  cartItems: CartItemType[];
  addToCart: (item: CartItemType, size: Item) => void;
  removeFromCart: (item: CartItemType) => void;
  calculateTotalBasePrice: () => number;
  calculateProductDiscount: () => number;
  calculateTotal: () => number;
  calculateWithPromoCode: (discountPercentage: number) => number;
  clearCart: () => void;
  changeItemQuantity: (id: number, quantity: number) => void;
}

export const CartContext = createContext<CartContextType | undefined>(
  undefined
);

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cartItems, setCartItems] = useState<CartItemType[]>([]);
  const { user, userLoggedOut } = useAuth();
  const { openModal } = useContext(ModalContext);

  const getCartData = async () => {
    if (user) {
      const data = await getCart();
      const cartData: CartItemType[] = JSON.parse(Cookies.get('cart')) || [];

      const filteredCarts = data.map((item) => {
        const index = cartData.findIndex(
          (cookieItem) => item.productItemId === cookieItem.productItemId
        );
        if (index >= 0) {
          const returnItem = cartData.splice(index, 1)[0];
          return returnItem;
        } else {
          return item;
        }
      });
      const connectedCarts = [...filteredCarts, ...cartData];
      setCartApiCall(
        connectedCarts.map((item) => ({
          productItemId: item.productItemId,
          quantity: item.quantity,
        }))
      );
      setCartItems(connectedCarts);
    } else {
      const cartData = Cookies.get('cart');
      if (cartData) {
        setCartItems(JSON.parse(cartData));
      }
    }
  };

  const clearCart = () => {
    setCartItems([]);
    setCartApiCall([]);
  };

  const addToCart = (item: CartItemType, size: Item) => {
    if (item.price.price <= 0) {
      return;
    }
    const index = cartItems.findIndex(
      (product) => product.productItemId === item.productItemId
    );
    window.gtag('event', 'add_to_cart', {
      currency: 'RSD',
      value: item.price,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_variant: item.color,
          price: item.price,
          quantity: 1,
        },
      ],
    });

    if (cartItems?.[index]?.quantity + 1 > size.quantity) {
      toast(
        <Flex $jc="space-between" $ai="center" $textcolor="white">
          <Flex $noFull>
            Trenutno nemamo traženu količinu proizvoda na stanju
          </Flex>
        </Flex>
      );
      return;
    }

    openModal(<AddToCartModal product={item} />);

    if (index >= 0) {
      const newArr = [...cartItems];
      newArr[index] = {
        ...newArr[index],
        quantity: newArr[index].quantity + 1,
      };
      addToCartCall({
        productItemId: item.productItemId,
        quantity: newArr[index].quantity + 1,
      });

      window.fbq('track', 'AddToCart', {
        currency: 'RSD',
        num_items: newArr.length,
        content_type: 'product_group',
        content_ids: newArr.map((item) => item.id),
        contents: newArr.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
        value: Number(
          newArr
            .reduce(
              (total, item) =>
                total + (item.price.basePrice * item.quantity) / 100,
              0
            )
            .toFixed(2)
        ),
      });

      window.gtag('event', 'add_to_cart', {
        currency: 'RSD',
        value: 7.77,
        items: [
          {
            item_id: 'SKU_12345',
            item_name: 'Stan and Friends Tee',
            affiliation: 'Google Merchandise Store',
            coupon: 'SUMMER_FUN',
            discount: 2.22,
            index: 0,
            item_brand: 'Google',
            item_category: 'Apparel',
            item_category2: 'Adult',
            item_category3: 'Shirts',
            item_category4: 'Crew',
            item_category5: 'Short sleeve',
            item_list_id: 'related_products',
            item_list_name: 'Related Products',
            item_variant: 'green',
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            price: 9.99,
            quantity: 1,
          },
        ],
      });

      setCartItems(newArr);
      return;
    }
    addToCartCall({ productItemId: item.productItemId, quantity: 1 });
    setCartItems((prev) => {
      const items = [...prev, item];

      window.fbq('track', 'AddToCart', {
        currency: 'RSD',
        content_type: 'product_group',
        content_ids: items.map((item) => item.id),
        contents: items.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
        value: Number(
          items
            .reduce(
              (total, item) =>
                total + (item.price.basePrice * item.quantity) / 100,
              0
            )
            .toFixed(2)
        ),
      });
      return items;
    });
  };

  const changeItemQuantity = (id, quantity) => {
    const index = cartItems.findIndex(
      (product) => product.productItemId === id
    );

    if (index >= 0) {
      const newArr = [...cartItems];
      newArr[index] = {
        ...newArr[index],
        quantity: quantity,
      };
      addToCartCall({
        productItemId: id,
        quantity,
      });
      setCartItems(newArr);
      return;
    }
  };

  const removeFromCart = (item: CartItemType) => {
    const updatedCartItems = [...cartItems];
    const index = cartItems.findIndex(
      (product) => product.productItemId === item.productItemId
    );
    window.gtag('event', 'remove_from_cart', {
      currency: 'RSD',
      value: item.price,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          item_variant: item.color,
          price: item.price,
          quantity: 1,
        },
      ],
    });
    removeItemFromCartCall(item.productItemId);
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
  };

  const calculateTotalBasePrice = () => {
    return cartItems.reduce(
      (total, item) => total + (item.price.basePrice * item.quantity) / 100,
      0
    );
  };

  const calculateProductDiscount = () => {
    return cartItems.reduce(
      (total, item) =>
        total +
        item.price.basePrice * item.quantity -
        (item.price.price * item.quantity) / 100,
      0
    );
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + (item.price.price * item.quantity) / 100,
      0
    );
  };

  const calculateWithPromoCode = (discountPercentage: number) => {
    if (!discountPercentage) return calculateTotal();
    return (
      calculateTotal() - calculateTotal() * (discountPercentage / 100) || 0
    );
  };

  useEffect(() => {
    getCartData();
  }, [user]);

  useDidUpdate(() => {
    Cookies.set('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  useDidUpdate(() => {
    if (userLoggedOut) {
      console.log('THIS DID IT');
      clearCart();
    }
  }, [userLoggedOut]);

  const cartContextValue: CartContextType = {
    cartItems,
    addToCart,
    removeFromCart,
    calculateTotalBasePrice,
    calculateProductDiscount,
    calculateTotal,
    calculateWithPromoCode,
    clearCart,
    changeItemQuantity,
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );
};
