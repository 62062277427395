import { changePasswordApiCall } from 'api/auth.api';
import { Button } from 'components/Buttons/Button';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { ModalContext } from 'components/Modal/ModalContext';
import { ErrorModal } from 'components/Modals/ErrorModal';
import { SuccessModal } from 'components/Modals/SuccessModal';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, FormContainer } from 'styles/Containers.styles';

export const ResetPasswordForm = () => {
  const [form, setForm] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const { token } = useParams();
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const onChange = (prop, value) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.newPassword.length < 6) {
      openModal(
        <ErrorModal message="Lozinka mora da sadrži najmanje 6 karaktera" />
      );
      return;
    }
    if (form.newPassword !== form.confirmPassword) {
      openModal(<ErrorModal message="Lozinke moraju biti iste" />);
      return;
    }
    try {
      await changePasswordApiCall(token, form.newPassword);
      openModal(<SuccessModal message="Lozinka uspešno promenjena" />);
      navigate('/profile/login');
    } catch (error) {
      openModal(<ErrorModal message={error.response.data.message} />);
    }
  };

  return (
    <Flex $jc="center">
      <FormContainer onSubmit={handleSubmit}>
        <Flex $column $gap="1rem">
          <Flex $noFullHeight $fs={1.5} $bold={700}>
            RESETUJTE LOZINKU
          </Flex>
          <PasswordInput
            label="Nova Lozinka"
            value={form.newPassword}
            name="newPassword"
            onChange={onChange}
          />
          <PasswordInput
            label="Potvrdi Lozinku"
            value={form.confirmPassword}
            name="confirmPassword"
            onChange={onChange}
          />

          <Button
            disabled={form.newPassword.length < 6 || !form.confirmPassword}
            width="100%"
          >
            Resetuj
          </Button>
        </Flex>
      </FormContainer>
    </Flex>
  );
};
