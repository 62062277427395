import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import { Flex, TextSectionContainer } from 'styles/Containers.styles';

const LinkImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const FooterPageRenderer = ({ options }: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <TextSectionContainer style={{ marginBottom: '4rem' }}>
      <Flex $column $gap="3rem" $mt="2rem">
        {options.map((option, index) => (
          <Flex $column $gap="3rem" key={index}>
            {option?.title && (
              <Flex $bold={700} $fs={1.2}>
                {option.title}
              </Flex>
            )}
            {option.text && <Flex>{option.text}</Flex>}
            {option.image && <LinkImage src={option.image} />}
            {option.lines && (
              <Flex $column $gap="1rem">
                {option.lines.map((line, index) => (
                  <Flex style={{ whiteSpace: 'pre-wrap' }} key={'line' + index}>
                    {line}
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </TextSectionContainer>
  );
};
