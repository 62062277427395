/* eslint-disable camelcase */
import { checkPromoCode, checkoutCall } from 'api/cart.api';
import { Button } from 'components/Buttons/Button';
import { Divider } from 'components/Drividers/Divider';
import { ButtonInput } from 'components/Inputs/ButtonInput';
import { Checkbox } from 'components/Inputs/Checkbox';
import { EmailInput } from 'components/Inputs/EmailInput';
import { Input } from 'components/Inputs/Input';
import { PhoneNumberInput } from 'components/Inputs/PhoneNumberInput';
import { Textarea } from 'components/Inputs/Textarea';
import { FullLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { ErrorModal } from 'components/Modals/ErrorModal';
import { Option } from 'components/SelectOpstions/Option';
import { OptionSelect } from 'components/SelectOpstions/OptionSelect';
import { CartContext } from 'context/CartContext';
import { SummaryContainer } from 'customer/pages/cart/Cart.style';
import { CartCard } from 'customer/pages/cart/CartCard';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex, SectionContainer } from 'styles/Containers.styles';
import { CheckoutRequestBody, PromoCodeResponse } from 'types/cart.types';
import { DeliveryMethod, PaymentMethod } from 'types/order.types';
import {
  formatePhoneNumber,
  validateEmail,
  validatePhoneNumber,
} from 'utils/validation.utils';
import { ExpendableItem } from './ExpendableItem';
import { deliveryMethods, paymentMethods } from './preferences';
import { theme } from 'styles/theme';
import { CitySelect } from 'components/Inputs/CitySelect';
import { Helmet } from 'react-helmet';

const RightContainer = styled.div`
  max-width: 40rem;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding: 1rem;
  }
`;

export const CheckoutDetails = () => {
  const { width } = useWindowWidth();
  const { cartItems, calculateTotal, calculateWithPromoCode } =
    useContext(CartContext);
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [billingInfo, setBillingInfo] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [billingType, setBillingType] = useState<'private' | 'company'>(
    'private'
  );
  const [consent, setConsent] = useState(false);
  const [activePromoCode, setActivePromoCode] =
    useState<PromoCodeResponse>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    paymentMethods[0].value
  );
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>(
    deliveryMethods[0].value
  );

  const deliveryFee =
    calculateWithPromoCode(activePromoCode?.discount) > 5000 ? 0 : 310;
  const requiredDetails = [
    'name',
    'lastName',
    'street',
    'houseNumber',
    'postCode',
    'city',
    'phoneNumber',
    'email',
    'country',
  ];
  const [details, setDetails] = useState({
    name: '',
    lastName: '',
    street: '',
    houseNumber: '',
    aptNumber: '',
    postCode: '',
    city: '',
    phoneNumber: '',
    email: '',
    country: 'Srbija',
    comment: '',
  });
  const [billingDetails, setBillingDetails] = useState({
    firstName: '',
    lastName: '',
    street: '',
    houseNumber: '',
    aptNumber: '',
    postCode: '',
    city: '',
    country: 'Srbija',
  });
  const [companyDetails, setCompanyDetails] = useState({
    companyName: '',
    nip: '',
    street: '',
    postalCode: '',
    houseNumber: '',
    aptNumber: '',
    postCode: '',
    city: '',
    country: 'Srbija',
  });

  const requirements =
    !consent ||
    requiredDetails.some((detail) => !details[detail]) ||
    !validateEmail(details.email) ||
    !validatePhoneNumber(formatePhoneNumber(details.phoneNumber)) ||
    (billingInfo &&
      billingType === 'private' &&
      (!billingDetails.firstName ||
        !billingDetails.lastName ||
        !billingDetails.street ||
        !billingDetails.city ||
        !billingDetails.country ||
        !billingDetails.postCode ||
        !billingDetails.houseNumber)) ||
    (billingInfo &&
      billingType === 'company' &&
      (!companyDetails.companyName ||
        !companyDetails.nip ||
        !companyDetails.city ||
        !companyDetails.street ||
        !companyDetails.country ||
        !companyDetails.houseNumber ||
        !companyDetails.postCode));

  const bankFormRef = useRef<HTMLFormElement>();
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const onChange = (name: string, value: string) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onChangeBilling = (name: string, value: string) => {
    setBillingDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onCompanyDetailsChange = (name: string, value: string) => {
    setCompanyDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validatePromoCode = async () => {
    try {
      setPromoCodeLoading(true);
      const code = await checkPromoCode(promoCode);
      setActivePromoCode(code);
    } catch {
      openModal(<ErrorModal message="Ukucani promo kod nije validan" />);
    } finally {
      setPromoCodeLoading(false);
    }
  };

  useEffect(() => {
    window.fbq('track', 'InitiateCheckout', {
      content_category: 'checkout',
      currency: 'RSD',
      content_type: 'product_group',
      content_ids: cartItems.map((item) => item.id),
      contents: cartItems.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
      num_items: cartItems.length,
      value: Number(calculateTotal().toFixed(2)),
    });

    window.gtag('event', 'begin_checkout', {
      currency: 'RSD',
      value: Number(calculateTotal().toFixed(2)),
      items: cartItems.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        item_variant: item.color,
        price: item.price,
        quantity: item.quantity,
      })),
    });
  }, []);

  const checkout = async () => {
    try {
      setCheckoutLoading(true);
      const deliveryAddress: any = {
        firstName: details.name,
        lastName: details.lastName,
        address: details.street,
        houseNumber: details.houseNumber,
        apartmentNumber: details.aptNumber,
        postalCode: details.postCode,
        city: details.city,
        phoneNumber: formatePhoneNumber(details.phoneNumber),
        email: details.email,
        country: details.country,
      };

      const reqData: CheckoutRequestBody = {
        deliveryAddress,
        deliveryMethod,
        paymentMethod,
        items: cartItems.map((item) => ({
          productItemId: item.productItemId,
          quantity: item.quantity,
        })),
      };

      if (activePromoCode) {
        reqData.promoCode = activePromoCode.code;
      }

      if (details.comment) {
        reqData.comment = details.comment;
      }

      if (billingInfo) {
        if (billingType === 'company') {
          reqData.billingInformations = {
            type: 'company',
            companyName: companyDetails.companyName,
            idNumber: companyDetails.nip,
            address: companyDetails.street,
            houseNumber: companyDetails.houseNumber,
            apartmentNumber: companyDetails.aptNumber,
            postalCode: companyDetails.postCode,
            city: companyDetails.city,
            country: companyDetails.country,
            phoneNumber: formatePhoneNumber(details.phoneNumber),
            email: details.email,
          };
        } else {
          reqData.billingInformations = {
            type: 'private',
            firstName: billingDetails.firstName,
            lastName: billingDetails.lastName,
            address: billingDetails.street,
            houseNumber: billingDetails.houseNumber,
            apartmentNumber: billingDetails.aptNumber,
            postalCode: billingDetails.postCode,
            city: billingDetails.city,
            country: billingDetails.country,
            phoneNumber: formatePhoneNumber(details.phoneNumber),
            email: details.email,
          };
        }
      }

      const data = await checkoutCall(reqData);

      window.gtag('event', 'purchase', {
        transaction_id: data.order.uuid,
        value: Number((data.order.price / 100).toFixed(2)),
        currency: 'RSD',
        items: cartItems.map((item) => ({
          item_id: item.id,
          item_name: item.name,
          item_variant: item.color,
          price: item.price,
          quantity: item.quantity,
        })),
      });

      window.fbq('track', 'Purchase', {
        currency: 'RSD',
        content_type: 'product_group',
        content_ids: cartItems.map((item) => item.id),
        contents: cartItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
        value: Number((data.order.price / 100).toFixed(2)),
      });

      if (data.paymentForm) {
        const formData = new FormData();
        formData.append('failUrl', data.paymentForm.failUrl);
        formData.append('currency', '941');
        formData.append('trantype', 'Auth');
        formData.append('okUrl', data.paymentForm.okUrl);
        formData.append('amount', data.paymentForm.amount);
        formData.append('oid', `${data.order.id}`);
        formData.append('clientid', '13IN002726');
        formData.append('storetype', '3d_pay_hosting');
        formData.append('lang', 'sr');
        formData.append('hashAlgorithm', 'ver2');
        formData.append('rnd', data.paymentForm.rnd);
        formData.append('encoding', 'utf-8');
        formData.append('hash', data.paymentForm.hash);
        formData.append(
          'shopurl',
          process.env.REACT_APP_BASE_URL +
            '/checkout/order-declined/' +
            data.order.id
        );

        const form = bankFormRef.current;
        form.action = process.env.REACT_APP_INTESA_ACTION_LINK;
        form.method = 'POST';

        formData.forEach((value, key) => {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = value as string;
          form.appendChild(input);
        });

        form.submit();
      } else {
        navigate('/checkout/order-created/' + data.order.id);
      }
    } catch (error: any) {
      console.log(error.response.data, 'ERR');
      openModal(<ErrorModal message={error?.response?.data.message} />);
    } finally {
      setCheckoutLoading(false);
    }
  };

  if (cartItems.length < 1) {
    return <Navigate to={'/cart'} />;
  }

  return (
    <Flex $jc="center">
      <Helmet>
        <title>Checkout | Esotiq</title>
      </Helmet>
      <SectionContainer>
        <Flex $gap="6rem" $jc="center" $p="0 1rem">
          <RightContainer>
            <Flex $column $gap="0">
              <ExpendableItem title="MOJA KORPA">
                {cartItems.map((item, index) => (
                  <CartCard key={index} product={item} withoutSelect />
                ))}
              </ExpendableItem>
              <Divider />
              <ExpendableItem title="PROMO KOD">
                <ButtonInput
                  error
                  name="promoCode"
                  disabled={!!activePromoCode}
                  label="Promo kod"
                  value={promoCode}
                  onButtonClick={() => {
                    if (activePromoCode) {
                      setActivePromoCode(null);
                    } else {
                      validatePromoCode();
                    }
                  }}
                  onValueChange={(_name, value) => {
                    setPromoCode(value);
                  }}
                />
              </ExpendableItem>
              <Divider />
              <ExpendableItem title="PODACI O DOSTAVI">
                <Flex $gap="2rem">
                  <Input
                    name="name"
                    property="name"
                    label="Ime"
                    required
                    value={details.name}
                    onValueChange={onChange}
                  />
                  <Input
                    name="lastName"
                    property="lastName"
                    label="Prezime"
                    required
                    value={details.lastName}
                    onValueChange={onChange}
                  />
                </Flex>
                <Flex>
                  <Input
                    name="street"
                    property="street"
                    label="Ulica"
                    required
                    fullWidth
                    value={details.street}
                    onValueChange={onChange}
                  />
                </Flex>
                <Flex $gap="2rem">
                  <Input
                    name="houseNumber"
                    property="houseNumber"
                    label="Kućni broj"
                    required
                    value={details.houseNumber}
                    onValueChange={onChange}
                  />
                  <Input
                    name="aptNumber"
                    property="aptNumber"
                    label="Stan"
                    value={details.aptNumber}
                    onValueChange={onChange}
                  />
                </Flex>
                <Flex $gap="2rem" $mobileColumn>
                  <Input
                    name="postCode"
                    property="postCode"
                    label="Poštanski broj"
                    required
                    value={details.postCode}
                    onValueChange={onChange}
                  />
                  {/* HERE WE NEED TO PUT SELECTION */}
                  <CitySelect
                    onChange={(val) => {
                      if (val) {
                        onChange('city', val.name);
                        onChange('postCode', val.zipCode);
                      }
                    }}
                    zipCode={details.postCode}
                  />
                  {/* <Input
                    name="city"
                    property="city"
                    label="Grad"
                    required
                    value={details.city}
                    onValueChange={onChange}
                  /> */}
                </Flex>
                <Flex $gap="2rem" $mobileColumn>
                  <PhoneNumberInput
                    name="phoneNumber"
                    value={details.phoneNumber}
                    onChange={onChange}
                  />
                  <EmailInput
                    name="email"
                    value={details.email}
                    onChange={onChange}
                  />
                </Flex>
                <Flex $gap="2rem">
                  <Input
                    name="country"
                    property="country"
                    label="Država"
                    required
                    value={details.country}
                    onValueChange={onChange}
                  />
                  <Flex />
                </Flex>

                <Flex>
                  <Textarea
                    name="comment"
                    property="comment"
                    label="Dodaj komentar za dostavu (opciono)"
                    value={details.comment}
                    onValueChange={onChange}
                  />
                </Flex>
              </ExpendableItem>
              <Divider />
              <ExpendableItem title="NAČIN ISPORUKE">
                <OptionSelect
                  options={deliveryMethods}
                  renderTitle={(option) => option.name}
                  selectedRule={(option) => deliveryMethod === option.value}
                  onSelect={(option) => setDeliveryMethod(option.value)}
                />
              </ExpendableItem>
              <Divider />
              <ExpendableItem title="INFORMACIJE NAPLAĆIVANJA">
                <Flex $column $gap="3rem">
                  <Checkbox
                    text="Adresa za fakturisanje različita od adrese dostave"
                    checked={billingInfo}
                    onChange={() => {
                      setBillingInfo((prev) => !prev);
                    }}
                  />
                  {billingInfo && (
                    <Flex $column $gap="0">
                      <Flex $noFull>
                        <Option
                          selected={billingType === 'private'}
                          title="Privatna"
                          onSelect={() => setBillingType('private')}
                        />
                        <Option
                          selected={billingType === 'company'}
                          title="Kompanija"
                          onSelect={() => setBillingType('company')}
                        />
                      </Flex>
                      {billingType === 'private' && (
                        <>
                          <Flex $gap="2rem">
                            <Input
                              name="billing-firstName"
                              property="firstName"
                              label="Ime"
                              required
                              value={billingDetails.firstName}
                              onValueChange={onChangeBilling}
                            />
                            <Input
                              name="billing-lastName"
                              property="lastName"
                              label="Prezime"
                              required
                              value={billingDetails.lastName}
                              onValueChange={onChangeBilling}
                            />
                          </Flex>
                          <Flex>
                            <Input
                              name="billing-street"
                              property="street"
                              label="Ulica"
                              required
                              fullWidth
                              value={billingDetails.street}
                              onValueChange={onChangeBilling}
                            />
                          </Flex>
                          <Flex $gap="2rem">
                            <Input
                              name="billing-houseNumber"
                              property="houseNumber"
                              label="Kućni broj"
                              required
                              value={billingDetails.houseNumber}
                              onValueChange={onChangeBilling}
                            />
                            <Input
                              name="billing-aptNumber"
                              property="aptNumber"
                              label="Stan"
                              value={billingDetails.aptNumber}
                              onValueChange={onChangeBilling}
                            />
                          </Flex>
                          <Flex $gap="2rem">
                            <Input
                              name="billing-postCode"
                              property="postCode"
                              label="Poštanski broj"
                              required
                              value={billingDetails.postCode}
                              onValueChange={onChangeBilling}
                            />
                            <Input
                              name="billing-city"
                              property="city"
                              label="Grad"
                              required
                              value={billingDetails.city}
                              onValueChange={onChangeBilling}
                            />
                          </Flex>
                          <Flex $gap="2rem">
                            <Input
                              name="billing-country"
                              property="country"
                              label="Država"
                              required
                              value={billingDetails.country}
                              onValueChange={onChangeBilling}
                            />
                            <Flex />
                          </Flex>
                        </>
                      )}
                      {billingType === 'company' && (
                        <>
                          <Flex $gap="2rem">
                            <Input
                              name="company-companyName"
                              property="companyName"
                              label="Ime Kompanije"
                              required
                              fullWidth
                              value={companyDetails.companyName}
                              onValueChange={onCompanyDetailsChange}
                            />
                          </Flex>
                          <Flex>
                            <Input
                              name="company-nip"
                              property="nip"
                              label="Pib"
                              required
                              fullWidth
                              value={companyDetails.nip}
                              onValueChange={onCompanyDetailsChange}
                            />
                          </Flex>
                          <Flex $gap="2rem">
                            <Input
                              name="company-street"
                              property="street"
                              label="Ulica"
                              required
                              fullWidth
                              value={companyDetails.street}
                              onValueChange={onCompanyDetailsChange}
                            />
                          </Flex>
                          <Flex $gap="2rem">
                            <Input
                              name="company-houseNumber"
                              property="houseNumber"
                              label="Kućni broj"
                              required
                              value={companyDetails.houseNumber}
                              onValueChange={onCompanyDetailsChange}
                            />
                            <Input
                              name="company-aptNumber"
                              property="aptNumber"
                              label="Stan"
                              value={companyDetails.aptNumber}
                              onValueChange={onCompanyDetailsChange}
                            />
                          </Flex>
                          <Flex $gap="2rem">
                            <Input
                              name="company-postCode"
                              property="postCode"
                              label="Poštanski broj"
                              required
                              value={companyDetails.postCode}
                              onValueChange={onCompanyDetailsChange}
                            />
                            <Input
                              name="company-city"
                              property="city"
                              label="Grad"
                              required
                              value={companyDetails.city}
                              onValueChange={onCompanyDetailsChange}
                            />
                          </Flex>
                          <Flex $gap="2rem">
                            <Input
                              name="company-country"
                              property="country"
                              label="Država"
                              required
                              value={companyDetails.country}
                              onValueChange={onCompanyDetailsChange}
                            />
                            <Flex />
                          </Flex>
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
              </ExpendableItem>
              <Divider />
              <ExpendableItem title="NAČIN PLAĆANJA">
                <OptionSelect
                  options={paymentMethods}
                  renderTitle={(option) => option.name}
                  selectedRule={(option) => paymentMethod === option.value}
                  onSelect={(option) => setPaymentMethod(option.value)}
                />
              </ExpendableItem>
              <Divider />
              {width < 1000 && (
                <>
                  <ExpendableItem title="Rezime">
                    <Flex $column $gap="1rem">
                      <Flex $bold={400}>
                        <Flex $bold={300}>Zbir cene proizvoda</Flex>{' '}
                        {calculateTotal().toFixed(2)}
                        RSD
                      </Flex>
                      <Flex $bold={400}>
                        <Flex $bold={300}>Popust</Flex>{' '}
                        {(
                          calculateTotal() -
                          calculateWithPromoCode(activePromoCode?.discount)
                        ).toFixed(2)}
                        RSD
                      </Flex>
                      {!!activePromoCode && (
                        <Flex $bold={400}>
                          <Flex $bold={300}>Cena nakon popusta</Flex>{' '}
                          {calculateWithPromoCode(
                            activePromoCode?.discount
                          ).toFixed(2)}
                          RSD
                        </Flex>
                      )}
                      <Flex $bold={400}>
                        <Flex $bold={300}>Dostava</Flex>{' '}
                        {deliveryFee.toFixed(2)}RSD
                      </Flex>
                      <Flex>
                        <Flex $fs={1.1} $bold={700}>
                          UKUPNO
                        </Flex>
                        <Flex $fs={1.2} $noFull $bold={700}>
                          {(
                            calculateWithPromoCode(activePromoCode?.discount) +
                            deliveryFee
                          ).toFixed(2)}
                          RSD
                        </Flex>
                      </Flex>
                      <Flex>
                        <Flex $bold={300}>
                          PDV uračunat u cenu i nema skrivenih troškova
                        </Flex>
                      </Flex>
                      <Divider />
                      <Button
                        width="100%"
                        disabled={requirements}
                        onClick={checkout}
                      >
                        {paymentMethod === 'card' ? 'Plati i poruči' : 'Poruči'}
                      </Button>
                      <Flex $bold={300}>
                        Svi obavezni podaci moraju biti uneti. Obavezna polja su
                        obeležena sa *
                      </Flex>
                      <Checkbox
                        noTextClick
                        text={
                          <Flex>
                            Saglasan sam sa{' '}
                            <Link
                              to="/regulations"
                              style={{
                                color: theme.colors.primary,
                                textDecoration: 'underline',
                              }}
                            >
                              uslovima kupovine
                            </Link>{' '}
                            *
                          </Flex>
                        }
                        checked={consent}
                        onChange={() => {
                          setConsent((prev) => !prev);
                        }}
                      />
                      <form ref={bankFormRef}></form>
                    </Flex>
                  </ExpendableItem>
                  <Divider />
                </>
              )}
            </Flex>
          </RightContainer>
          {width > 1000 && (
            <SummaryContainer>
              <Flex $noFullHeight $bold={600} $fs={1.7} $ai="center">
                REZIME
              </Flex>
              <Flex $bold={400}>
                <Flex $bold={300}>Zbir cene proizvoda</Flex>{' '}
                {calculateTotal().toFixed(2)}
                RSD
              </Flex>
              <Flex $bold={400}>
                <Flex $bold={300}>Popust</Flex>{' '}
                {(
                  calculateTotal() -
                  calculateWithPromoCode(activePromoCode?.discount)
                ).toFixed(2)}
                RSD
              </Flex>
              {!!activePromoCode && (
                <Flex $bold={400}>
                  <Flex $bold={300}>Cena nakon popusta</Flex>{' '}
                  {calculateWithPromoCode(activePromoCode?.discount).toFixed(2)}
                  RSD
                </Flex>
              )}
              <Flex $bold={400}>
                <Flex $bold={300}>Dostava</Flex> {deliveryFee.toFixed(2)}RSD
              </Flex>
              <Flex>
                <Flex $fs={1.1} $bold={700}>
                  UKUPNO
                </Flex>
                <Flex $fs={1.2} $noFull $bold={700}>
                  {(
                    calculateWithPromoCode(activePromoCode?.discount) +
                    deliveryFee
                  ).toFixed(2)}
                  RSD
                </Flex>
              </Flex>
              <Flex>
                <Flex $bold={300}>
                  PDV uračunat u cenu i nema skrivenih troškova
                </Flex>
              </Flex>
              <Divider />
              <Button width="100%" disabled={requirements} onClick={checkout}>
                {paymentMethod === 'card' ? 'Plati i poruči' : 'Poruči'}
              </Button>
              <Flex $bold={300}>
                Svi obavezni podaci moraju biti uneti. Obavezna polja su
                obeležena sa *
              </Flex>
              <Checkbox
                noTextClick
                text={
                  <Flex>
                    Saglasan sam sa{' '}
                    <Link
                      to="/regulations"
                      style={{
                        color: theme.colors.primary,
                        textDecoration: 'underline',
                      }}
                    >
                      uslovima kupovine
                    </Link>{' '}
                    *
                  </Flex>
                }
                checked={consent}
                onChange={() => {
                  setConsent((prev) => !prev);
                }}
              />
              <form ref={bankFormRef}></form>
            </SummaryContainer>
          )}
          <FullLoader loading={promoCodeLoading || checkoutLoading} />
        </Flex>
      </SectionContainer>
    </Flex>
  );
};
