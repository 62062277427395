import axios from 'axios';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/Auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
const refreshInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

refreshInstance.interceptors.request.use((request) => {
  const token = localStorage.getItem(REFRESH_TOKEN);
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});
axiosInstance.interceptors.request.use((request) => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      try {
        const response = await refreshToken();
        const token = response?.data?.data?.accessToken;
        localStorage.setItem(ACCESS_TOKEN, token);
        console.log(originalRequest.headers);
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: `Bearer ${token}`,
        };
        console.log(originalRequest.headers);
        return await axiosInstance(originalRequest);
      } catch (err) {
        if (err?.request?.status === 401) {
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(REFRESH_TOKEN);
        }
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

async function refreshToken() {
  return refreshInstance.post('/v1/auth/refresh');
}

export default axiosInstance;
