import React from 'react';

export const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M12 11h9v2h-18v-2zM3 20h18v-2h-18v2zM3 4v2h18v-2h-18z"></path>
    </svg>
  );
};
