import { DOTS, usePagination } from 'hooks/usePagination';
import styled from 'styled-components';
import { theme } from 'styles/theme';

const PaginationList = styled.ul`
  display: flex;
  list-style: none;
  gap: 1rem;
  width: 100%;
  justify-content: flex-end;
`;

const PaginationButton = styled.li<{ disabled: boolean }>`
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: white;
  border: 1px solid
    ${({ disabled }) =>
      disabled ? theme.colors.primary : theme.colors.secondaryDark};
  color: ${({ disabled }) =>
    disabled ? theme.colors.primary : theme.colors.secondaryDark};
  align-items: center;
  justify-content: center;
  display: flex;
`;

const PaginationArrowButton = styled.li<{ rotate?: number }>`
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: ${theme.colors.secondaryDark};
  align-items: center;
  justify-content: center;
  display: flex;
  rotate: ${({ rotate }) => rotate || 0}deg;
`;

const Pagination = (props: {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
}) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <PaginationList>
      {currentPage !== 1 && (
        <PaginationArrowButton onClick={onPrevious} rotate={180}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M9.25391 7.41L13.8339 12L9.25391 16.59L10.6639 18L16.6639 12L10.6639 6L9.25391 7.41Z"
              fill="white"
            />
          </svg>
        </PaginationArrowButton>
      )}
      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return null;
        }

        // Render our Page Pills
        return (
          <PaginationButton
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            disabled={currentPage === pageNumber}
          >
            {pageNumber}
          </PaginationButton>
        );
      })}
      {/*  Right Navigation arrow */}
      {currentPage !== lastPage && (
        <PaginationArrowButton onClick={onNext}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M9.25391 7.41L13.8339 12L9.25391 16.59L10.6639 18L16.6639 12L10.6639 6L9.25391 7.41Z"
              fill="white"
            />
          </svg>
        </PaginationArrowButton>
      )}
    </PaginationList>
  );
};

export default Pagination;
