import { Divider } from 'components/Drividers/Divider';
import { ProductCard } from 'components/ProductCard/ProductCard';
import ImageSlider from 'components/Slider';
import { useWindowWidth } from 'hooks/useWindowWidth';
import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex, SectionContainer } from 'styles/Containers.styles';
import { CollectionWithProducts } from 'types/collection.types';
import convertProductToSimpleProduct from 'utils/product.utils';

const CollectionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
`;

const ImageContainer = styled(Link)<{ $image: string }>`
  grid-column: span 2;
  background: url(${({ $image }) => $image}) top no-repeat;
  background-size: contain;
  width: 100%;
  aspect-ratio: 4/3;
`;

const Collection = ({
  collection,
  mobile,
}: {
  collection: CollectionWithProducts;
  mobile: boolean;
}) => {
  if (mobile) {
    return (
      <Flex $column>
        <ImageContainer
          to={`${collection.slug}`}
          $image={collection.thumbnailImage}
        />
        <ImageSlider visibleItems={2}>
          {collection.colorVariants.slice(0, 6).map((product, index) => (
            <ProductCard
              key={product.id}
              product={convertProductToSimpleProduct(product)}
              index={index}
            />
          ))}
        </ImageSlider>
      </Flex>
    );
  }

  return (
    <CollectionContainer>
      <ImageContainer
        to={`${collection.slug}`}
        $image={collection.thumbnailImage}
      />
      {collection.colorVariants.slice(0, 6).map((variant, index) => (
        <ProductCard
          product={convertProductToSimpleProduct(variant)}
          key={variant.id}
          index={index}
        />
      ))}
    </CollectionContainer>
  );
};

export const CollectionsList = ({
  collections,
}: {
  collections: CollectionWithProducts[];
}) => {
  const { width } = useWindowWidth();
  return (
    <>
      <Flex $fs={1.5} $bold={700} $jc="center" $p="0 0 1rem 0">
        KOLEKCIJE
      </Flex>
      <SectionContainer>
        <Flex $column $gap="3rem">
          {collections?.map(
            (collection) =>
              collection.colorVariants.length > 0 && (
                <>
                  <Collection
                    mobile={width < 1000}
                    collection={collection}
                    key={collection.id}
                  />
                  <Divider />
                </>
              )
          )}
        </Flex>
      </SectionContainer>
    </>
  );
};
