import { ArrowLeftIcon } from 'assets/ArrowLeftIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { ModalContext } from 'components/Modal/ModalContext';
import { LogoutModal } from 'components/Modals/LogoutModal';
import { useAuth } from 'context/AuthContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useContext, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex, SectionContainer } from 'styles/Containers.styles';
import { Container } from 'styles/Login/Login.style';

const NavigationText = styled.div`
  font-size: 1.2rem;
  width: 100%;
`;

const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ProfileContainer = styled(Container)`
  max-width: 60rem;
`;

export const ProfileLayout = () => {
  const { logout } = useAuth();
  const { openModal } = useContext(ModalContext);
  const { width } = useWindowWidth();
  const [contentOn, setContentOn] = useState(false);

  return (
    <Flex $jc="center">
      <SectionContainer>
        <Flex $jc="center">
          <ProfileContainer>
            <Flex>
              {(width > 900 || !contentOn) && (
                <Flex
                  $column
                  $noFull
                  style={{ whiteSpace: 'nowrap', minWidth: '20rem' }}
                  $gap="1rem"
                >
                  <Link
                    style={{ width: '100%' }}
                    to="/profile"
                    onClick={() => {
                      setContentOn(true);
                    }}
                  >
                    <NavigationText>Moj Profil</NavigationText>
                  </Link>
                  <Link
                    onClick={() => {
                      setContentOn(true);
                    }}
                    style={{ width: '100%' }}
                    to="/profile/change-password"
                  >
                    <NavigationText>Promena Lozinke</NavigationText>
                  </Link>
                  <Link
                    onClick={() => {
                      setContentOn(true);
                    }}
                    style={{ width: '100%' }}
                    to="/profile/my-orders"
                  >
                    <NavigationText>Moje Kupovine</NavigationText>
                  </Link>
                  <Flex
                    onClick={() => {
                      openModal(<LogoutModal logout={logout} />);
                    }}
                    $pointer
                  >
                    <NavigationText>Odjavi se</NavigationText>
                  </Flex>
                </Flex>
              )}
              {(width > 900 || contentOn) && (
                <Flex $jc="center" $column>
                  {width < 900 && (
                    <Flex $noFullHeight $noFull style={{ rotate: '180deg' }}>
                      <IconButton
                        onClick={() => {
                          setContentOn(false);
                        }}
                      >
                        <ArrowLeftIcon />
                      </IconButton>
                    </Flex>
                  )}
                  <ContentBox>
                    <Outlet />
                  </ContentBox>
                </Flex>
              )}
            </Flex>
          </ProfileContainer>
        </Flex>
      </SectionContainer>
    </Flex>
  );
};
