import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { SimpleSubcategoryType } from 'types/subcategory.types';
import { useHover } from '../../hooks/useHover';
import { StyledNavLink } from '../../styles/Links.styles';
import RippleWrapper from '../Containers/RippleContainer';
import { NavLinkItem } from './Navbar.style';
import { CollectionType } from 'types/collection.types';
import { CategoryType } from 'types/category.types';

const LinkComponentContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 1rem;
  z-index: 2;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
  width: 25rem;
`;
const LinkContainerImage = styled.img`
  width: 25rem;
  height: 25rem;
  object-fit: cover;
`;

type Props = {
  page: {
    name: string;
    link: string;
    slug: string;
    linkImage?: string;
    speacialColor?: string;
    id?: number;
    subcategories?: SimpleSubcategoryType[];
    collections?: CollectionType[];
    outletSubmenu?: (CollectionType | CategoryType)[];
  };
};

export const LinkComponent = ({ page }: Props) => {
  const [ref, isHovered, setHovered] = useHover();
  const location = useLocation();
  const pathnames = location.pathname.split('/');

  useEffect(() => {
    setHovered(false);
  }, [location]);

  return (
    <NavLinkItem key={page.name} ref={ref}>
      <RippleWrapper radius="0.5rem">
        <StyledNavLink
          $specialColor={page?.speacialColor}
          $opened={isHovered}
          to={
            page.link === 'novo'
              ? '/novo'
              : page.link === 'outlet'
              ? 'outlet'
              : page.link === 'na-popustu'
              ? '/na-popustu'
              : page.link === 'kolekcije'
              ? '/kolekcije'
              : `/kategorija/${page?.slug}`
          }
          $active={pathnames.includes(page?.slug?.toString() || page.link)}
        >
          {page.name}
        </StyledNavLink>
      </RippleWrapper>
      {isHovered && page.subcategories?.length > 0 && (
        <LinkComponentContainer>
          <LinkContainer>
            {page.subcategories.map((subcategory) => (
              <StyledNavLink
                $active={pathnames.includes(subcategory.slug.toString())}
                to={`podkategorija/${subcategory.slug}`}
                key={subcategory.key}
              >
                {subcategory.key}
              </StyledNavLink>
            ))}
          </LinkContainer>
          <LinkContainerImage src={page.linkImage} />
        </LinkComponentContainer>
      )}
      {isHovered && page.collections?.length > 0 && (
        <LinkComponentContainer>
          <LinkContainer>
            {page.collections.map((collection) => (
              <StyledNavLink
                $active={pathnames.includes(collection.slug.toString())}
                to={`kolekcije/${collection.slug}`}
                key={collection.name}
              >
                {collection.name}
              </StyledNavLink>
            ))}
          </LinkContainer>
          <LinkContainerImage src={page.linkImage} />
        </LinkComponentContainer>
      )}
      {isHovered && page.outletSubmenu?.length > 0 && (
        <LinkComponentContainer>
          <LinkContainer>
            {page.outletSubmenu.map((item) => (
              <StyledNavLink
                $active={pathnames.includes(item.slug.toString())}
                to={
                  'subcategories' in item
                    ? `/outlet?categoryId=${item.id}`
                    : `/outlet?collectionIds=${item.id}`
                }
                key={item.name}
              >
                {item.name}
              </StyledNavLink>
            ))}
          </LinkContainer>
          <LinkContainerImage src={page.linkImage} />
        </LinkComponentContainer>
      )}
    </NavLinkItem>
  );
};
