import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editSubcategory } from 'api/subcategory.api';
import { FullContainerLoader } from 'components/Loader/Loader';
import { useSubcategory } from 'hooks/useSubcategory';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateForm } from './CreateForm';

export const EditSubcategory = () => {
  const { id } = useParams();
  const { data, isLoading } = useSubcategory({ id });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const editMutation = useMutation(
    (data: {
      key: string;
      selected: number[];
      image: File | string;
      slug: string;
    }) => {
      const dataForm = new FormData();
      dataForm.append(
        'subcategory',
        JSON.stringify({
          key: data.key,
          attributeIds: data.selected,
          slug: data.slug,
        })
      );
      if (typeof data.image !== 'string') {
        dataForm.append('image', data.image);
      }
      return editSubcategory(id, dataForm);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['subcategory', { id }], data);
        navigate(-1);
      },
      onError: (err: any) => {
        toast.error(err.response.data.message);
      },
    }
  );
  if (isLoading) return <FullContainerLoader />;
  return (
    <CreateForm
      defaultKey={data?.key}
      defaultImage={data?.image}
      defaultSlug={data?.slug}
      mutation={editMutation}
      defaultSelected={data?.attributes?.map((item) => ({
        id: item.id,
        name: item.name,
      }))}
    />
  );
};
