import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { ModalContext } from 'components/Modal/ModalContext';
import React, { useContext, useState } from 'react';
import { Flex } from 'styles/Containers.styles';

export const LabelCountModal = ({
  onSave,
}: {
  onSave: (labelCount: number) => void;
}) => {
  const [labelCount, setLabelCount] = useState<number | null>(null);
  const [err, setErr] = useState(false);
  const { closeModal } = useContext(ModalContext);

  return (
    <Flex $column $gap="1rem" $ai="center">
      <Flex $noFullHeight $jc="center" $fs={1.2} $bold={700}>
        Unesite broj nalepnica?
      </Flex>
      <Input
        type="number"
        label="Broj nalepnica"
        value={labelCount}
        placeholder="Unesite broj"
        name="labelCount"
        onValueChange={(_name, value) => {
          setLabelCount(Number(value));
        }}
        error={err && labelCount === null}
        errorMessage="Ovo polje je obavezno!"
      />
      <Flex $jc="center">
        <Button onClick={closeModal}>Odustani</Button>
        <Button
          styleType="color"
          onClick={() => {
            if (labelCount) {
              onSave(labelCount);
              closeModal();
            } else {
              setErr(true);
            }
          }}
        >
          Sacuvaj
        </Button>
      </Flex>
    </Flex>
  );
};
