import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Inputs/Checkbox';
import EndListTrigger from 'components/List/EndListTrigger';
import { FullContainerLoader, SmallLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { useInfiniteProducts } from 'hooks/useInfiniteProducts';
import { useContext, useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const Container = styled.div`
  width: 60vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 1;
  overflow-y: scroll;
`;

export const SelectProductSet = ({
  preselected = [],
  onSave,
  currentProduct = null,
}) => {
  const { products, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteProducts();
  const [selected, setSelected] = useState<number[]>(preselected);
  const { closeModal } = useContext(ModalContext);

  const toggleSelected = (id: number) => {
    const arr = [...selected];
    const index = arr.findIndex((el) => el === id);
    if (index >= 0) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    setSelected(arr);
  };
  return (
    <Container>
      <Flex $fs={1.2} $bold={700} $jc="center" $noFullHeight>
        Izaberi slične proizvode
      </Flex>
      <ScrollContainer>
        {isLoading && <FullContainerLoader />}
        {products?.map(
          (item) =>
            item.id !== currentProduct && (
              <Checkbox
                key={item.id}
                text={item.name}
                checked={selected.includes(item.id)}
                onChange={() => {
                  toggleSelected(item.id);
                }}
              />
            )
        )}
        <EndListTrigger fetchNextPage={fetchNextPage} />
        {isFetchingNextPage && (
          <Flex $noFullHeight $jc="center" $mt="0.5rem">
            <SmallLoader />
          </Flex>
        )}
      </ScrollContainer>
      <Flex $jc="center" $ai="flex-end" $noFullHeight>
        <Button
          styleType="color"
          onClick={() => {
            onSave(selected);
            closeModal();
          }}
        >
          Sačuvaj
        </Button>
      </Flex>
    </Container>
  );
};
