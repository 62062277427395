import React from 'react';
import { Flex } from 'styles/Containers.styles';
import { Option } from './Option';

interface Props<T> {
  options: T[];
  renderTitle: (option: T) => string;
  renderDesc?: (option: T) => string | React.ReactElement;
  selectedRule: (option: T) => boolean;
  renderDisabled?: (option: T) => boolean;
  onSelect: (option: T) => void;
}

export const OptionSelect = <T,>({
  options,
  renderTitle,
  renderDesc,
  selectedRule,
  renderDisabled,
  onSelect,
}: Props<T>) => {
  return (
    <Flex $noFullHeight $column $gap="1rem">
      {options.map((option, index) => (
        <Option
          key={index}
          selected={selectedRule(option)}
          title={renderTitle(option)}
          desc={renderDesc?.(option) || ''}
          onSelect={() => onSelect(option)}
          disabled={renderDisabled?.(option)}
        />
      ))}
    </Flex>
  );
};
