import { Order } from 'types/order.types';
import axiosInstance from './instance';
import { OrderStatusType } from 'types/orders.types';

export const getOrders = async (params) => {
  const res = await axiosInstance.get('/v1/orders', { params });

  if (res.data.data.length < 1) {
    return undefined;
  }

  return {
    nextPage: params.page + 1,
    currentPage: params.page,
    data: res.data.data as Order[],
  };
};

export const getCustomerOrders = async (params) => {
  const res = await axiosInstance.get('/v1/orders', { params });

  if (res.data.data.length < 1) {
    return undefined;
  }

  return {
    nextPage: params.page + 1,
    currentPage: params.page,
    data: res.data.data as Order[],
  };
};

export const getOrderInfoWithUUID = async (uuid: string) => {
  const res = await axiosInstance.get(`/v1/orders/${uuid}/uuid`);

  return res.data.data;
};

export const getOrderInfoWithID = async (id: number) => {
  const res = await axiosInstance.get(`/v1/orders/${id}`);

  return res.data.data;
};

export const deleteDeclinedOrder = async (id: string) => {
  const res = await axiosInstance.post(`v1/orders/${id}/decline`);

  return res.data.data;
};

export const changeOrderStatus = async (
  id: number,
  status: OrderStatusType,
  labelsCount?: number
) => {
  const res = await axiosInstance.put(`/v1/orders/${id}/status`, {
    status,
    labelsCount,
  });

  return res.data.data;
};

export const requestLabels = async () => {
  const data = await axiosInstance.post('/v1/orders/reprint-labels');
  console.log(data.data.data);
  return data.data.data;
};

export const requestLabelById = async (id: number) => {
  const data = await axiosInstance.post(`/v1/orders/${id}/reprint-labels`);
  console.log(data.data.data);
  return data.data.data;
};

export const sendShipmentRequest = async () => {
  const data = await axiosInstance.post('/v1/orders/shipment-pickup');
  return data.data.data;
};
