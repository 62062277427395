import { ArrowDownIcon } from 'assets/ArrowDownIcon';
import { CloseIcon } from 'assets/CloseIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { useFocus } from 'hooks/useFocus';
import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

interface Props<T> extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  name: string;
  label: string;
  errorMessage?: string;
  onValueChange: (value: T) => void;
  iconElement?: React.ReactElement;
  initialValue?: T;
  prefix?: string;
  options: T[];
  optionsTitle?: string;
  moreOptionsTitle?: string;
  moreOptions?: T[];
  renderOptionName: (option: T) => string;
}

const Line = styled.div<{
  $focused: boolean;
}>`
  position: absolute;
  top: 100%;
  left: 50%;
  height: 1px;
  background-color: #000;
`;

const InputContainer = styled.div<{
  $error: boolean;
}>`
  position: relative;
  width: 230px;
  display: flex;
  margin-block: 1rem;
  max-width: 30rem;
  border: 1px solid ${theme.colors.secondaryDark};
  border-radius: 7px;
  align-items: flex-end;
  padding-inline: 1rem;
  letter-spacing: normal;
  font-weight: 400;
  &:hover {
    border-color: ${({ $error }) => !$error && theme.colors.secondaryDark};
  }
`;
const InputContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const StyledLabel = styled.label`
  position: absolute;
  left: 0;
  top: 50%;
  translate: 0 -50%;
  transition: all 200ms ease-in;
  font-weight: 300;
  text-overflow: ellipsis;

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  color: ${theme.colors.error};
  font-size: 0.875rem;
  font-weight: 300;
`;

const StyledInput = styled.input`
  width: 100%;
  overflow: hidden;
  padding-right: 55px;
  border: none;
  padding-block: 0.3rem;
  transition: all 200ms ease-in;
  background-color: transparent;
  text-overflow: ellipsis;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  translate: 0 -50%;
`;

const OptionContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1;
  max-height: 15rem;
  border-radius: 10px;
  overflow-y: scroll;
  border: 1px solid ${theme.colors.secondaryDark};
`;

const Option = styled.div<{ $active: boolean }>`
  width: 100%;
  padding: 0.5rem;
  background-color: transparent;
  color: ${({ $active }) =>
    !$active ? theme.colors.secondaryDark : theme.colors.primary};
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const ListTitle = styled.div`
  padding: 0.5rem;
  color: white;
  background-color: ${theme.colors.secondaryDark};
`;

export function useDidUpdate(callback: () => void, deps: unknown[]) {
  const hasMount = useRef(false);

  useEffect(() => {
    if (hasMount.current) {
      console.log('RAN YES');
      callback();
    } else {
      console.log('RAN NO');
      hasMount.current = true;
    }
  }, deps);
}

export const SelectInputV2 = <T,>({
  error,
  name,
  label,
  errorMessage,
  options,
  moreOptions,
  renderOptionName,
  optionsTitle,
  moreOptionsTitle,
  onValueChange,
  initialValue,
  required,
  ...rest
}: Props<T>) => {
  const [focusRef, isFocused] = useFocus();
  const [activeOption, setActiveOption] = useState(null);
  const [errorShown, setErrorShown] = useState(false);
  const [selected, setSelected] = useState(initialValue || null);
  const [inputValue, setInputValue] = useState(
    initialValue ? renderOptionName(initialValue) : ''
  );

  useDidUpdate(() => {
    if (selected && options) {
      // setInputValue(optionName ? optionName(selected) : selected?.name);
      setInputValue(renderOptionName(selected));
      onValueChange?.(selected);
    }
  }, [selected]);

  useEffect(() => {
    console.log(initialValue);
    setSelected(initialValue);
  }, [initialValue]);

  return (
    <InputContainer $error={errorShown && (error || (!selected && required))}>
      <InputContent>
        {!selected && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
        <StyledInput
          value={inputValue}
          onBlur={() => {
            setErrorShown(true);
          }}
          onKeyDown={(e) => {
            if (activeOption > 0 && e.key === 'ArrowUp') {
              setActiveOption((prev) => prev - 1);
            } else if (e.key === 'Backspace' && selected) {
              setSelected(null);
              setInputValue('');
            } else if (
              activeOption + 1 < options.length &&
              e.key === 'ArrowDown'
            ) {
              setActiveOption((prev) => prev + 1);
            } else if (e.key === 'Enter') {
              setSelected(options[activeOption]);
              setInputValue(renderOptionName(options[activeOption]));
              focusRef.current.blur();
            }
          }}
          readOnly
          autoComplete="off"
          ref={focusRef as React.RefObject<any>}
          id={name}
          name={name}
          {...rest}
        />
      </InputContent>
      <Line $focused={isFocused} />
      {(error || (!selected && required)) && errorShown && (
        <ErrorMessage>
          {(!selected && 'Ovo polje je obavezno') || errorMessage}
        </ErrorMessage>
      )}
      <IconContainer>
        <Flex>
          <div style={{ rotate: isFocused ? '180deg' : '0deg' }}>
            {!isFocused && (
              <IconButton
                onClick={() => {
                  if (!isFocused) {
                    focusRef.current.focus();
                  }
                }}
              >
                <ArrowDownIcon />
              </IconButton>
            )}
            {isFocused && (
              <IconButton>
                <ArrowDownIcon />
              </IconButton>
            )}
          </div>
          {selected && (
            <IconButton
              onClick={() => {
                setInputValue('');
                setSelected(null);
                onValueChange(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Flex>
      </IconContainer>
      {isFocused && (
        <OptionContainer>
          {optionsTitle && <ListTitle>{optionsTitle}</ListTitle>}
          {options.map((option, index) => (
            <Option
              key={'select' + index}
              onMouseEnter={() => setActiveOption(index)}
              $active={index === activeOption}
              onMouseDown={() => {
                setSelected(options[activeOption]);
              }}
            >
              {renderOptionName(option)}
            </Option>
          ))}
          {moreOptionsTitle && <ListTitle>{moreOptionsTitle}</ListTitle>}
          {moreOptions &&
            moreOptions.map((option, index) => (
              <Option
                key={'selectMoreOption' + index}
                onMouseEnter={() => setActiveOption(index)}
                $active={index === activeOption}
                onMouseDown={() => {
                  setSelected(moreOptions[activeOption]);
                }}
              >
                {renderOptionName(option)}
              </Option>
            ))}
        </OptionContainer>
      )}
    </InputContainer>
  );
};
