import { CartItemType } from 'types/cart.types';

export const convertProductToSimpleProduct = (
  product: ColorVariantType
): SimpleProductType => {
  const { name } = product.product;

  const { isNew, isSale, images, id, slug } = product;

  const price = product.price.price;
  const basePrice = product.price.basePrice;
  const color = {
    name: product.colorName,
    color: product.colorHex,
    id: product.id,
  };
  const titleImage = images.length > 0 ? images[0] : '';
  const colors = product.product.colorVariants.map((color) => ({
    name: color.colorName,
    color: color.colorHex,
    id: color.id,
  }));

  return {
    name,
    id,
    price,
    basePrice,
    slug,
    colorVariants: colors,
    isNew,
    isSale,
    titleImage,
    images,
    color,
  };
};

export const convertFullProductToSimpleProduct = (
  product: ProductVariant,
  chosenSize: Item
): CartItemType => {
  const { name, id, images, price } = product;

  return {
    name,
    id,
    price,
    productItemId: chosenSize.id,
    size: chosenSize.size,
    quantity: 1,
    images,
    color: product.colorName,
  };
};

export default convertProductToSimpleProduct;
