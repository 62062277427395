import { ErrorIcon } from 'assets/ErrorIcon';
import { useFocus } from 'hooks/useFocus';
import { useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  value: string;
  name: string;
  label: string;
  errorMessage?: string;
  onValueChange: (name: string, value: string) => void;
  iconElement?: React.ReactElement;
  prefix?: string;
  limit?: number;
}

const Line = styled.div<{
  $focused: boolean;
}>`
  position: absolute;
  top: 100%;
  left: 50%;
  height: 1px;
  translate: -50% 0;
  background-color: #000;
  transition: all 200ms ease-in;
  width: ${({ $focused }) => ($focused ? '100%' : '0%')};
`;

const InputContainer = styled.div<{
  $error: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  margin-block: 1rem;
  border-bottom: 1px solid ${theme.colors.secondaryLight};
  align-items: flex-end;

  &:hover {
    border-color: ${({ $error }) => !$error && theme.colors.secondaryDark};
  }
`;
const InputContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const StyledLabel = styled.label<{
  $focused: boolean;
  $error: boolean;
}>`
  position: absolute;
  left: 0;
  top: ${({ $focused }) => ($focused ? '0' : '50%')};
  font-size: ${({ $focused }) => ($focused ? '0.8' : '1')}rem;
  color: ${({ $error }) => ($error ? theme.colors.error : 'inherit')};
  translate: 0 -50%;
  transition: all 200ms ease-in;
  font-weight: 300;
  cursor: ${({ $focused }) => ($focused ? 'default' : 'text')};
  background-color: white;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  color: ${theme.colors.error};
  font-size: 0.875rem;
  font-weight: 300;
`;

const StyledInput = styled.textarea`
  width: 100%;
  border: none;
  padding-block: 1rem;
  transition: all 200ms ease-in;
  background-color: transparent;
  font-size: 1rem;
  resize: none;
`;

const LimitContainer = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 5px;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  translate: 0 -50%;
`;

export const Textarea = ({
  required,
  label,
  error,
  value,
  errorMessage = 'error',
  name,
  onValueChange,
  iconElement,
  prefix,
  limit = 500,
  ...rest
}: Props) => {
  const [focusRef, isFocused] = useFocus();
  const [errorShown, setErrorShown] = useState(false);
  return (
    <InputContainer $error={errorShown && error}>
      {prefix && (
        <Flex $noFull $p="1rem 0.3rem">
          {prefix}
        </Flex>
      )}
      <InputContent>
        <StyledLabel
          $focused={isFocused || !!value}
          $error={errorShown && error}
          htmlFor={name}
        >
          {label} {required && '*'}
        </StyledLabel>
        <StyledInput
          value={value}
          onBlur={() => {
            setErrorShown(true);
          }}
          rows={1}
          autoComplete="off"
          ref={focusRef as React.RefObject<any>}
          id={name}
          onChange={(e) => onValueChange(name, e.target.value)}
          name={name}
          maxLength={limit}
          {...rest}
        />
      </InputContent>
      <Line $focused={isFocused} />
      {error && errorShown && (
        <ErrorMessage>
          {(required && !value && 'Ovo polje je obavezno') || errorMessage}
        </ErrorMessage>
      )}
      <IconContainer>
        {iconElement || (errorShown && error && <ErrorIcon />)}
      </IconContainer>
      <LimitContainer>
        {value.length} / {limit}
      </LimitContainer>
    </InputContainer>
  );
};
