import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const WebWideImageContainer = styled(Link)`
  width: 100%;
  height: fit-content;
`;

const WebImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

type Props = {
  src: string;
};

export const WebWideImage = ({ src }: Props) => {
  return (
    <WebWideImageContainer to="/novo">
      <WebImage src={src} />
    </WebWideImageContainer>
  );
};
