import { useCollections } from 'hooks/useCollections';
import { CollectionType } from 'types/collection.types';
import { SelectInput } from './SelectInput';

export const CollectionSelect = ({ onChange, initialValue = null }) => {
  const { data } = useCollections();
  return (
    <SelectInput<CollectionType>
      initialValue={initialValue}
      options={data}
      label="Kolekcije"
      renderOptionName={(option) => option.name}
      name="collections"
      onValueChange={(e) => {
        onChange(e);
      }}
    />
  );
};
