import { Logo } from 'assets';
import { Button } from 'components/Buttons/Button';
import { SimpleSwitch } from 'components/Inputs/SimpleSwitch';
import { ModalContext } from 'components/Modal/ModalContext';
import { useCookies } from 'context/CookieContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useContext } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { CookieModal } from './CookieModal';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding-block: 42px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    padding-block: 1rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1270px;
  display: flex;
  gap: 100px;
  align-items: center;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
`;

export const CookiePrompt = () => {
  const { width } = useWindowWidth();
  const { openModal } = useContext(ModalContext);
  const {
    opened,
    acceptConsent,
    saveAcceptedConsent,
    marketing,
    setMarketing,
    statistic,
    setStatistic,
    deniedConcent,
  } = useCookies();
  return opened ? (
    <Container>
      <ContentContainer>
        <div>
          <Logo width={200} height={50} />
        </div>
        <Flex $column $gap="1.2rem">
          <Flex $textcolor={theme.colors.primary} $fs={1.2} $bold={700}>
            Ovaj veb sajt koristi kolačiće
          </Flex>
          <Flex>
            Koristimo kolačiće za personalizaciju sadržaja i oglasa, pružanje
            funkcija društvenih medija i analiziranje saobraćaja. Takođe delimo
            informacije o tome kako koristite sajt sa partnerima za društvene
            medije, oglašavanje i analitiku koji mogu da ih kombinuju sa drugim
            informacijama koje ste im dali ili koje su prikupili na osnovu
            korišćenja usluga.
          </Flex>
          <Flex $gap="1rem" $column={width < 1000}>
            <Flex $noFull $ai="center">
              <SimpleSwitch
                on
                disabled
                onClick={() => {
                  console.log('first');
                }}
              />
              Neophodni
            </Flex>
            <Flex $noFull $ai="center">
              <SimpleSwitch
                on={statistic}
                onClick={() => {
                  setStatistic((prev) => !prev);
                }}
              />
              Statistika
            </Flex>
            <Flex $noFull $ai="center">
              <SimpleSwitch
                on={marketing}
                onClick={() => {
                  setMarketing((prev) => !prev);
                }}
              />
              Marketing
            </Flex>
            <Flex
              $noFull
              $textcolor={theme.colors.primary}
              $pointer
              style={{ textDecoration: 'underline' }}
              $ai="center"
              onClick={() => {
                openModal(<CookieModal />);
              }}
            >
              Više detalja
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9462 12.1939L8.34619 7.59386L9.40002 6.54004L15.0538 12.1939L9.40002 17.8477L8.34619 16.7939L12.9462 12.1939Z"
                  fill="#FF0D4F"
                />
              </svg>
            </Flex>
          </Flex>
        </Flex>
        <Flex $column $noFull={width > 1000} $gap="1rem">
          <Button
            width={width > 1000 ? '10rem' : '100%'}
            styleType="color"
            onClick={acceptConsent}
          >
            Dozvoli sve
          </Button>
          <Button
            width={width > 1000 ? '10rem' : '100%'}
            onClick={saveAcceptedConsent}
          >
            Dozvoli izbor
          </Button>
          <Button
            width={width > 1000 ? '10rem' : '100%'}
            styleType="outline-dark"
            onClick={deniedConcent}
          >
            Odbij
          </Button>
        </Flex>
      </ContentContainer>
    </Container>
  ) : null;
};
