import { Button } from 'components/Buttons/Button';
import { LoginForm } from 'components/LoginForm/LoginForm';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';
import {
  Container,
  ContainerImage,
  LoginContainer,
  StyledLine,
} from 'styles/Login/Login.style';

export const Login = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <LoginContainer>
        <Flex $noFullHeight $fs={1.5} $bold={700} $noFull>
          PRIJAVA
        </Flex>
        <LoginForm />
      </LoginContainer>
      <ContainerImage>
        <Flex $fs={1.5} $bold={700} $noFull $noFullHeight>
          REGISTRACIJA
        </Flex>
        <Flex $column $noFull $gap="2rem" $noFullHeight>
          <Flex $noFullHeight>Šta dobijate registracijom na Esotiq?</Flex>
          <StyledLine />
          <Flex $noFullHeight $fs={1.1} $bold={700}>
            PRISTUP ISTORIJI KUPOVINE
          </Flex>
          {/* <Flex $noFullHeight $fs={1.1} $bold={700}>
            LIST OF FAVORITE PRODUCTS
          </Flex> */}
        </Flex>

        <Button
          width="100%"
          styleType="color"
          onClick={() => navigate('/profile/registration')}
        >
          Registrujte se
        </Button>
      </ContainerImage>
    </Container>
  );
};
