import React from 'react';
import { Flex, TextSectionContainer } from 'styles/Containers.styles';

export const CustomerService = () => {
  return (
    <TextSectionContainer>
      <Flex $column $gap="0.5rem" $mt="2rem">
        <Flex $fs={1.2} $bold={700}>
          Kontakt
        </Flex>
        <p key={92}>
          DM STAR CONSULTING D.O.O. – Ogranak 2 Esotiq, BEOGRAD (ZVEZDARA)
        </p>
        <p key={93}>
          Ul. Bulevar Kralja Aleksandra 458/32 11127 Beograd, (Zvezdara)
        </p>
        <p key={94}>Matični broj: 21430552</p>
        <p key={95}>PIB:111132614</p>
        <p key={96}>Šifra delatnosti: 7022</p>
        <p key={97}>
          E mail:{' '}
          <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>
        </p>

        <p key={98}>
          Tel: <a href="tel: +381 60 1320 929">+381 60 1320 929</a>
        </p>
      </Flex>
    </TextSectionContainer>
  );
};
