import { ColorVariant } from 'admin/pages/dashboard/products/ColorVariant';
import { Button } from 'components/Buttons/Button';
import EndListTrigger from 'components/List/EndListTrigger';
import { FullContainerLoader, SmallLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { useInfiniteProducts } from 'hooks/useInfiniteProducts';
import { useContext, useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  width: 60vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 1;
  overflow-y: scroll;
  gap: 1rem;
`;

const ProductContainer = styled.div`
  padding: 0.875rem 0rem 0.875rem 2rem;
  border-radius: 0.875rem;
  border: 1px solid ${theme.colors.secondaryLight};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SelectSimilarProducts = ({
  preselected = [],
  onSave,
  currentVariant = null,
}) => {
  const { products, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteProducts();
  const [selected, setSelected] = useState<number[]>(preselected);
  const { closeModal } = useContext(ModalContext);

  const toggleSelected = (id: number) => {
    const arr = [...selected];
    const index = arr.findIndex((el) => el === id);
    if (index >= 0) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    setSelected(arr);
  };
  return (
    <Container>
      <Flex $fs={1.2} $bold={700} $jc="center" $noFullHeight>
        Izaberi proizvode za set
      </Flex>
      <ScrollContainer>
        {isLoading && <FullContainerLoader />}

        {products?.map((item) => (
          <ProductContainer key={item.id}>
            <Flex $column>
              <Flex>{item.name}</Flex>
              <Flex>
                {item.colorVariants.map(
                  (variant) =>
                    variant.id !== currentVariant && (
                      <ColorVariant
                        // name="hello"
                        // id={1}
                        key={variant.id}
                        color={variant.colorName}
                        image={variant.images[0]}
                        selected={selected.includes(variant.id)}
                        onClick={() => toggleSelected(variant.id)}
                      />
                    )
                )}
              </Flex>
            </Flex>
          </ProductContainer>
        ))}
        <EndListTrigger fetchNextPage={fetchNextPage} />
        {isFetchingNextPage && (
          <Flex $noFullHeight $jc="center" $mt="0.5rem">
            <SmallLoader />
          </Flex>
        )}
      </ScrollContainer>
      <Flex $jc="center" $ai="flex-end" $noFullHeight>
        <Button
          styleType="color"
          onClick={() => {
            onSave(selected);
            closeModal();
          }}
        >
          Sačuvaj
        </Button>
      </Flex>
    </Container>
  );
};
