import { FullContainerLoader } from 'components/Loader/Loader';
import { useProduct } from 'hooks/useProduct';
import React from 'react';
import { useParams } from 'react-router-dom';
import { EditProductForm } from './EditProductForm';

export const EditProduct = () => {
  const { id } = useParams();
  const { data, isLoading } = useProduct({ id });

  if (isLoading) {
    return <FullContainerLoader />;
  }
  return data && <EditProductForm product={data} />;
};
