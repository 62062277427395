import { PasswordHiddenIcon } from 'assets/PasswordHiddenIcon';
import { PasswordShown } from 'assets/PasswordShown';
import { IconButton } from 'components/Buttons/IconButton';
import { Input } from 'components/Inputs/Input';
import { useState } from 'react';

type Props = {
  value: string;
  name: string;
  onChange: (name: string, value: string) => void;
  label?: string;
};

export const PasswordInput = ({ value, onChange, name, label }: Props) => {
  const [hide, setHide] = useState(true);
  return (
    <Input
      name={name}
      label={label || 'Lozinka'}
      required
      value={value}
      onValueChange={onChange}
      error={!value}
      type={hide ? 'password' : 'text'}
      iconElement={
        <IconButton
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setHide((prev) => !prev);
          }}
        >
          {hide ? <PasswordShown /> : <PasswordHiddenIcon />}
        </IconButton>
      }
    />
  );
};
