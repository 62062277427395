import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

const RippleWrapperContainer = styled.div<{ radius: string; width?: string }>`
  position: relative;
  overflow: hidden;
  border-radius: ${({ radius }) => radius};
  width: ${({ width }) => (width ? width : 'fit-content')};
`;

type RippleEffectProps = {
  x: number;
  y: number;
  size: number;
  color: string;
};

const RippleEffect = styled.span<RippleEffectProps>`
  position: absolute;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  width: 1px;
  height: 1px;
  transform: translate(${({ x }) => x}px, ${({ y }) => y}px) scale(0);
  pointer-events: none;

  animation: rippleEffect 0.3s linear forwards;

  @keyframes rippleEffect {
    to {
      transform: translate(${({ x }) => x}px, ${({ y }) => y}px)
        scale(${({ size }) => size * 2});
      opacity: 0;
    }
  }
`;

const useRippleAnimation = () => {
  const [ripples, setRipples] = useState<
    { x: number; y: number; size: number; color: string }[]
  >([]);
  const rippleRefs = useRef<HTMLSpanElement[]>([]);

  const startRippleAnimation = (
    x: number,
    y: number,
    size: number,
    color: string
  ) => {
    const newRipple = { x, y, size, color };
    setRipples([...ripples, newRipple]);
  };

  useEffect(() => {
    const timerIds: NodeJS.Timeout[] = [];

    ripples.forEach((_, index) => {
      const timerId = setTimeout(() => {
        setRipples((prevRipples) => prevRipples.filter((_, i) => i !== index));
      }, 500);
      timerIds.push(timerId);
    });

    return () => {
      timerIds.forEach((timerId) => clearTimeout(timerId));
    };
  }, [ripples]);

  const RippleAnimations = ripples.map((ripple, index) => (
    <RippleEffect
      key={index}
      ref={(element) =>
        (rippleRefs.current[index] = element as HTMLSpanElement)
      }
      color={ripple.color}
      x={ripple.x}
      y={ripple.y}
      size={ripple.size}
    />
  ));

  return { ripples: RippleAnimations, startRippleAnimation };
};

type RippleWrapperProps = {
  children: React.ReactNode;
  shiftX?: number;
  color?: string;
  radius?: string;
  width?: string;
};

const RippleWrapper: React.FC<RippleWrapperProps> = ({
  children,
  shiftX = 0,
  color = theme.colors.secondaryDark,
  radius = '0',
  width,
}) => {
  const { ripples, startRippleAnimation } = useRippleAnimation();

  const handleRippleClick = (e: React.MouseEvent<HTMLElement>) => {
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left + shiftX;
    const y = e.clientY - rect.top;

    startRippleAnimation(x, y, size, color);
  };

  return (
    <RippleWrapperContainer
      onClick={handleRippleClick}
      width={width}
      radius={radius}
    >
      {ripples}
      {children}
    </RippleWrapperContainer>
  );
};

export default RippleWrapper;
