import { Logo } from 'assets';
import React from 'react';
import { keyframes, styled } from 'styled-components';

const animation = keyframes`
 from {
    scale: 0.8;
    opacity: 0.7;
  }
  to {
    scale: 1;
    opacity: 1;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-column: 1/-1;
`;

const AnimationContainer = styled.div`
  width: fit-content;
  animation: ${animation} ease-in-out 400ms infinite alternate;
`;

const FullContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
`;

export const FullLoader = ({ loading }) => {
  return loading ? (
    <FullContainer>
      <AnimationContainer>
        <Logo width={200} height={80} />
      </AnimationContainer>
    </FullContainer>
  ) : null;
};

export const FullContainerLoader = () => {
  return (
    <LoaderContainer>
      <AnimationContainer>
        <Logo width={200} height={80} />
      </AnimationContainer>
    </LoaderContainer>
  );
};

export const SmallLoader = () => {
  return (
    <AnimationContainer>
      <Logo width={250} height={25} />
    </AnimationContainer>
  );
};
