import React from 'react';

export const SmallSearchIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M20.1314 20.8773L13.8507 14.5963C13.3507 15.0091 12.7757 15.3322 12.1257 15.5656C11.4757 15.7989 10.8032 15.9156 10.1084 15.9156C8.39925 15.9156 6.95275 15.3238 5.76891 14.1403C4.58508 12.9568 3.99316 11.5106 3.99316 9.80181C3.99316 8.09314 4.58491 6.64648 5.76841 5.46181C6.95191 4.27731 8.39808 3.68506 10.1069 3.68506C11.8156 3.68506 13.2622 4.27697 14.4469 5.46081C15.6314 6.64464 16.2237 8.09114 16.2237 9.80031C16.2237 10.5145 16.1038 11.1966 15.8642 11.8466C15.6243 12.4966 15.3044 13.0619 14.9044 13.5426L21.1852 19.8233L20.1314 20.8773ZM10.1084 14.4158C11.3969 14.4158 12.4882 13.9686 13.3824 13.0743C14.2767 12.1801 14.7239 11.0888 14.7239 9.80031C14.7239 8.51181 14.2767 7.42047 13.3824 6.52631C12.4882 5.63197 11.3969 5.18481 10.1084 5.18481C8.81991 5.18481 7.72858 5.63197 6.83441 6.52631C5.94008 7.42047 5.49291 8.51181 5.49291 9.80031C5.49291 11.0888 5.94008 12.1801 6.83441 13.0743C7.72858 13.9686 8.81991 14.4158 10.1084 14.4158Z"
        fill="black"
      />
    </svg>
  );
};
