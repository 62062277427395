import React from 'react';

export const PenIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M4.84537 19.7435H5.94787L17.1691 8.53275L16.0664 7.43001L4.84537 18.641V19.7435ZM3.58887 21V18.1295L17.3511 4.36675C17.4706 4.25842 17.6076 4.17192 17.7621 4.10725C17.9168 4.04259 18.0785 4.01025 18.2474 4.01025C18.4094 4.01025 18.5675 4.04075 18.7216 4.10175C18.8758 4.16259 19.0164 4.25259 19.1434 4.37175L20.2324 5.46601C20.3585 5.58867 20.4495 5.72842 20.5051 5.88525C20.561 6.04225 20.5889 6.19917 20.5889 6.356C20.5889 6.51867 20.558 6.679 20.4961 6.837C20.4343 6.99484 20.3464 7.13359 20.2324 7.25325L6.45937 21H3.58887ZM16.6181 7.98725L16.0664 7.43001L17.1691 8.53275L16.6181 7.98725Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
