import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AddProductSize } from 'admin/modals/AddProductSize';
import { SelectSimilarProducts } from 'admin/modals/SelectSimilarProducts';
import { editColorVariant } from 'api/products.api';
import { Button } from 'components/Buttons/Button';
import { ColorPicker } from 'components/Inputs/ColorPicker';
import { Input } from 'components/Inputs/Input';
import { FullLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { EditColorVariantImages } from './EditColorVariantImages';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 20rem;
  height: 80vh;
`;

export const EditColorVariantForm = ({
  id,
  defaultData,
}: {
  id: number;
  productId: number;
  defaultData: {
    id: number;
    colorName: string;
    colorHex: string;
    images: string[];
    setColorVariantIds: number[];
    sizes: {
      id: number;
      size: string;
      catalogueId: string;
      quantity: number;
    }[];
  };
}) => {
  const queryClient = useQueryClient();
  const [images, setImages] = useState<string[]>(defaultData.images);
  const { openModal } = useContext(ModalContext);
  const [sizes, setSizes] = useState(defaultData.sizes);
  const [productsForSet, setProductsForSet] = useState(
    defaultData.setColorVariantIds
  );
  const [form, setForm] = useState({
    colorHex: defaultData.colorHex,
    colorName: defaultData.colorName,
  });
  const { closeModal } = useContext(ModalContext);

  const { mutate: editColorVariantMutation, isLoading: editLoading } =
    useMutation(
      async (props: { data: any; id: number }) => {
        return await editColorVariant(props.data, props.id);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['products']);
          closeModal();
        },
        onError: (error: any) => {
          toast.error(error.response.data.message);
        },
      }
    );

  const onChange = (prop: string, value: string) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };
  return (
    <Container>
      <EditColorVariantImages
        images={images}
        onChange={(images) => setImages(images)}
        id={id}
      />
      <Flex>
        <Flex $column>
          <Flex $ai="center" $noFullHeight $gap="1rem">
            <ColorPicker
              color={form.colorHex}
              onChange={(color) => onChange('colorHex', color)}
            />
            <Input
              label="Boja"
              value={form.colorName}
              name="colorName"
              // error={!form.name}
              onValueChange={onChange}
            />
          </Flex>
          <Flex $column $gap="1rem">
            <Button
              width="100%"
              styleType="outline-dark"
              onClick={() => {
                openModal(
                  <AddProductSize
                    sizes={sizes}
                    onSave={(items) => setSizes(items)}
                    noOutsideClose
                  />
                );
              }}
            >
              Dodaj veličinu
            </Button>
            <Button
              width="100%"
              styleType="outline-dark"
              onClick={() => {
                openModal(
                  <SelectSimilarProducts
                    preselected={productsForSet}
                    onSave={(value) => {
                      setProductsForSet(value);
                    }}
                    currentVariant={id}
                  />
                );
              }}
            >
              Izaberi set
            </Button>
          </Flex>
          <Flex $jc="flex-end" $ai="flex-end">
            <Button
              onClick={() => {
                const items = sizes.map((item) => {
                  const size: any = {
                    size: item.size,
                    quantity: Number(item.quantity),
                    catalogueId: item.catalogueId,
                  };
                  if (item.id) {
                    size.id = item.id;
                  }
                  return size;
                });
                const colorVariant = {
                  colorName: form.colorName,
                  colorHex: form.colorHex,
                  items: items,
                  setColorVariantIds: productsForSet,
                };
                editColorVariantMutation({ data: colorVariant, id });
              }}
            >
              Sačuvaj
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <FullLoader loading={editLoading} />
    </Container>
  );
};
