import { CloseIcon } from 'assets/CloseIcon';
import { IconButton } from 'components/Buttons/IconButton';
import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { ModalContainer, ModalContent } from './Modal.style';
import { ModalContext } from './ModalContext';

type Props = {
  children: React.ReactElement;
  noOutsideClose: boolean;
  closeFunc?: () => void;
};

const CloseContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 2rem;
  width: 2rem;
`;

export const Modal = ({ noOutsideClose, children, closeFunc }: Props) => {
  const { closeModal } = useContext(ModalContext);
  return (
    <ModalContainer
      onClick={() => {
        if (noOutsideClose) {
          return;
        }
        if (closeFunc) {
          closeFunc?.();
          return;
        }
        closeModal();
      }}
    >
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseContainer>
          <IconButton
            onClick={() => {
              if (closeFunc) {
                closeFunc?.();
                return;
              }
              closeModal();
            }}
          >
            <CloseIcon />
          </IconButton>
        </CloseContainer>
        {children}
      </ModalContent>
    </ModalContainer>
  );
};
