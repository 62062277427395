import React from 'react';

export const ImageIcon = () => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.6"
        y="0.6"
        width="41.8"
        height="40.8"
        rx="4.4"
        stroke="black"
        strokeWidth="1.2"
      />
      <circle cx="13" cy="13" r="3.4" stroke="black" strokeWidth="1.2" />
      <path
        d="M5.5 41.5L30 17L42.5 29.5"
        stroke="black"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
