import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createAttributeGroup } from 'api/attributes.api';
import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Form } from 'styles/Containers.styles';

export const AddAttributeGroup = () => {
  const [form, setForm] = useState({
    name: '',
  });
  const [error, setError] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const queryClient = useQueryClient();

  const updateMutation = useMutation(() => createAttributeGroup(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['attributes']);
      closeModal();
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onChange = (prop, value) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };
  return (
    <Flex $column $ai="center">
      <Flex $noFull $fs={1.3} $bold={700}>
        DODAJ GRUPU ATRIBUTA
      </Flex>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          (document.activeElement as HTMLElement).blur();
          if (!form.name) {
            setError(true);
            return;
          }
          updateMutation.mutate();
        }}
      >
        <Flex $column $gap="0">
          <Input
            label="Ime"
            value={form.name}
            name="name"
            autoFocus
            required
            error={error && !form.name}
            onValueChange={onChange}
          />
        </Flex>
        <Button styleType="color">
          {updateMutation.isLoading ? 'Učitavanje' : 'Sačuvaj'}
        </Button>
      </Form>
    </Flex>
  );
};
