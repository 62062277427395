import { Button } from 'components/Buttons/Button';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext } from 'react';
import { Flex } from 'styles/Containers.styles';

export const DeleteModal = ({ deleteAction, title = null }) => {
  const { closeModal } = useContext(ModalContext);

  const onDelete = async () => {
    deleteAction();
    closeModal();
  };
  return (
    <Flex $column $gap="1rem" $ai="center">
      <Flex $noFullHeight $jc="center" $fs={1.2} $bold={700}>
        {title ?? 'Da li ste sigurni?'}
      </Flex>
      <Flex $jc="center">
        <Button onClick={closeModal}>Odustani</Button>
        <Button styleType="color" onClick={onDelete}>
          Izbriši
        </Button>
      </Flex>
    </Flex>
  );
};
