import axiosInstance from './instance';

export const getBlogsApiCall = async (params) => {
  const res = await axiosInstance.get('/v1/blogs', { params });

  if (res.data.data.length < 1) {
    return undefined;
  }

  return {
    nextPage: params.page + 1,
    currentPage: params.page,
    data: res.data.data,
  };
};

export const createBlogApiCall = async (data) => {
  const res = await axiosInstance.post('/v1/blogs', data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res;
};

export const editBlogApiCall = async (data, id) => {
  const res = await axiosInstance.put(`/v1/blogs/${id}`, data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res;
};

export const deleteBlogApiCall = async (id) => {
  const res = await axiosInstance.delete(`/v1/blogs/${id}`);

  return res;
};
