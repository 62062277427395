import { ArrowDownIcon } from 'assets/ArrowDownIcon';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';
import { generateArray } from 'utils/object.utils';

const Container = styled.div`
  width: 7rem;
  height: 2rem;
  border: solid 1px ${theme.colors.secondaryLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 0.5rem;
  cursor: pointer;
  position: relative;

  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

const SelectContainer = styled.ul`
  width: 100%;
  max-height: 10rem;
  position: absolute;
  top: 100%;
  left: 0;
  border: solid 1px ${theme.colors.secondaryLight};
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: white;
  overflow-y: scroll;
  z-index: 2;
`;

const ListItem = styled.li<{
  $selected?: boolean;
}>`
  height: 2rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  background-color: ${({ $selected }) => $selected && 'rgba(227, 0, 64, 0.3)'};
  color: ${({ $selected }) => $selected && theme.colors.primary};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

type Props = {
  quantity: number;
  availableSizes: number;
  onChange: (quantity: number) => void;
};

export const QuantitySelect = ({
  quantity,
  availableSizes,
  onChange,
}: Props) => {
  const [opened, setOpened] = useState(false);
  return (
    <Container
      onClick={() => {
        setOpened((prev) => !prev);
      }}
    >
      {quantity} <ArrowDownIcon />
      {opened && (
        <SelectContainer>
          {generateArray(availableSizes)?.map((number) => (
            <ListItem
              $selected={number === quantity}
              key={number}
              onClick={() => onChange(number)}
            >
              {number}
            </ListItem>
          ))}
        </SelectContainer>
      )}
    </Container>
  );
};
