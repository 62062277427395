import { useRef, useState } from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  display: flex;

  &&::-webkit-scrollbar {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  flex: 0 0 100%;
  scroll-snap-align: center;
`;

const SliderLine = styled.div`
  width: calc(100% - 2rem);
  position: relative;
  height: 2px;
  background-color: ${theme.colors.secondaryLight};
  border-radius: 2rem;
`;

const SliderMovableLine = styled.div<{
  numberOfItems: number;
  active: number;
}>`
  width: ${({ numberOfItems }) => 100 / numberOfItems}%;
  height: 2px;
  border-radius: 2rem;
  background-color: ${theme.colors.secondaryDark};
  position: absolute;
  left: ${({ active, numberOfItems }) => (active * 100) / numberOfItems}%;
  transition: all 200ms ease;
`;

type Props = {
  images: string[];
};

export const MobileSlider = ({ images }: Props) => {
  const ref = useRef(null);
  const [active, setActive] = useState(0);
  return (
    <Container>
      <ImageContainer
        ref={ref}
        onScroll={() => {
          if (ref.current) {
            const centerOfScreen = window.innerWidth / 2;
            const image = Array.from(ref?.current?.children).findIndex(
              (item: any) => {
                const img = item.getBoundingClientRect();
                return (
                  img.x + img.width / 2 > centerOfScreen - 100 &&
                  img.x + img.width / 2 < centerOfScreen + 100
                );
              }
            );
            if (image >= 0) {
              setActive(image);
            }
          }
        }}
      >
        {images.map((image) => (
          <Image src={image} key={image} />
        ))}
      </ImageContainer>
      <SliderLine>
        <SliderMovableLine numberOfItems={images.length} active={active} />
      </SliderLine>
    </Container>
  );
};
