import React from 'react';

export const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M15.707 11.293l-7-7-1.414 1.414 6.293 6.293-6.293 6.293 1.414 1.414 7-7c0.39-0.39 0.39-1.024 0-1.414z"></path>
    </svg>
  );
};
