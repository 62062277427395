import React, { useState } from 'react';
import { styled } from 'styled-components';
import RippleWrapper from '../Containers/RippleContainer';
import { Flex } from '../../styles/Containers.styles';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import { ExpandableContainer } from '../Containers/ExpandableContainer';

const MobileLinkComponentContainer = styled.div<{
  $specialColor?: string;
  $active?: boolean;
}>`
  position: relative;
  width: 100%;
  border-top: 1px solid #dadcdf;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ $specialColor }) => $specialColor || 'inherit'};
  background-color: ${({ $active }) => $active && 'rgb(218, 220, 223)'};
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  width: 20px;
  height: 20px;
`;

type Props = { title: string; children: React.ReactNode };
export const FilterExpandableItem = ({ title, children }: Props) => {
  const [opened, setOpened] = useState(false);
  return (
    <MobileLinkComponentContainer>
      <RippleWrapper width="100%">
        <Flex
          $jc="space-between"
          $p="1rem"
          onClick={() => {
            setOpened((prev) => !prev);
          }}
        >
          <Flex>{title}</Flex>
          <ArrowContainer>
            <ArrowDownIcon />
          </ArrowContainer>
        </Flex>
      </RippleWrapper>
      <ExpandableContainer opened={opened}>{children}</ExpandableContainer>
    </MobileLinkComponentContainer>
  );
};
