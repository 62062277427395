import { Button } from 'components/Buttons/Button';
import { Divider } from 'components/Drividers/Divider';
// import { Divider } from 'components/Drividers/Divider';
import { EmailInput } from 'components/Inputs/EmailInput';
import { useAuth } from 'context/AuthContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useState } from 'react';
import { styled } from 'styled-components';
import { Flex, FormContainer } from 'styles/Containers.styles';
import { LoginContainer } from 'styles/Login/Login.style';
import { validateEmail } from 'utils/validation.utils';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  height: 20rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    height: fit-content;
    padding-block: 4rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 5rem;
  flex: 1;
  height: 20rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    height: fit-content;
    align-items: center;
    gap: 1rem;
  }
`;

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const { forgotPassword } = useAuth();
  const { width } = useWindowWidth();

  const onSubmit = (e) => {
    e.preventDefault();
    forgotPassword(email);
    setEmail('');
  };

  return (
    <Flex $jc="center" $p={width > 1000 ? '5rem' : '1rem'}>
      <Container>
        <Flex
          $fs={1.5}
          $bold={700}
          $jc={width < 1000 && 'center'}
          $noFullHeight
        >
          RESETUJ LOZINKU
        </Flex>
        <ContentContainer>
          <LoginContainer>
            <FormContainer onSubmit={onSubmit}>
              <LoginContainer>
                <Flex $column $gap="3rem">
                  <EmailInput
                    value={email}
                    name="email"
                    required={false}
                    error={false}
                    onChange={(_prop, value) => setEmail(value)}
                  />
                  <Button width="100%" disabled={!validateEmail(email)}>
                    Resetuj lozinku
                  </Button>
                </Flex>
              </LoginContainer>
            </FormContainer>
          </LoginContainer>
          <Divider vertical />
          <LoginContainer>
            <p>
              Ako ste zaboravili Vašu lozinku, unesite e-mail adresu koju ste
              naveli prilikom registracije, a zatim pritisnite dugme RESETUJ
              LOZINKU.
            </p>
            <p>
              Poslaćemo poruku na navedenu e-mail adresu kako bismo Vam
              omogućili postavljanje nove lozinke.
            </p>
          </LoginContainer>
        </ContentContainer>
      </Container>
    </Flex>
  );
};
