import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { deleteBlogApiCall, getBlogsApiCall } from 'api/blogs.api';
import { toast } from 'react-toastify';

export const useBlogs = () => {
  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['blogs'],
      queryFn: ({ pageParam = 1 }) => {
        return getBlogsApiCall({
          page: pageParam,
          limit: 20,
        });
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
    });
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    (data: { id: number }) => deleteBlogApiCall(data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['blogs']);
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const blogs = data?.pages
    ?.map((data) => (data?.data ? data.data : []))
    .flat(1)
    .map((item) => item);

  return {
    blogs,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    deleteMutation,
  };
};
