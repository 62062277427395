import { useDeliveryLocations } from 'hooks/useDeliveryLocations';
import { DeliveryLocationEntity } from 'types/deliveryLocations.types';
import { SelectInput } from './SelectInput';

export const CitySelect = ({ onChange, zipCode, initialValue = null }) => {
  const { data } = useDeliveryLocations();
  return (
    <SelectInput<DeliveryLocationEntity>
      options={data?.filter((location) => location.zipCode.startsWith(zipCode))}
      label="Grad"
      initialValue={initialValue}
      required
      renderOptionName={(option) => option.name}
      name="category"
      onValueChange={(e) => {
        onChange(e);
      }}
    />
  );
};
