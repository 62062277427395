import { useQuery } from '@tanstack/react-query';
import { getCartSizes } from 'api/cart.api';

export const useCartSizes = (ids: number[]) => {
  const { isLoading, error, data } = useQuery<Item[]>(
    ['cartSizes', ...ids],
    () => {
      if (ids.length > 0) {
        return getCartSizes(ids);
      } else {
        return [];
      }
    }
  );

  return { isLoading, error, data };
};
