import { styled } from 'styled-components';
import { theme } from 'styles/theme';
import bg from 'assets/login-bg.png';

export const ContainerImage = styled.div`
  width: 100%;
  height: 474px;
  background: url(${bg}) no-repeat 50%;
  background-size: cover;
  max-width: 455px;
  padding: 28px 34px 50px;
  border: 2px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledLine = styled.div`
  width: 2rem;
  height: 2px;
  background-color: ${theme.colors.secondaryDark};
`;

export const LoginContainer = styled.div`
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4rem;
  gap: 10rem;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 800px) {
    padding: 1rem;
    gap: 2rem;
  }
`;
