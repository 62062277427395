import AmericanExpress from 'assets/IntesaBranding/AS.png';
import DinaCard from 'assets/IntesaBranding/DinaCard.png';
import IDCheck from 'assets/IntesaBranding/IDCheck.png';
import IntesaLogo from 'assets/IntesaBranding/IntesaLogo.png';
import VisaSecure from 'assets/IntesaBranding/VS.png';
import MaestroCard from 'assets/IntesaBranding/maestroCard.png';
import MasterCard from 'assets/IntesaBranding/masterCard.png';
import VisaCard from 'assets/IntesaBranding/visaCard.png';
import { styled } from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const Group = styled.div`
  display: flex;
  gap: 1rem;
`;

const Image = styled.img`
  height: 3rem;

  @media screen and (max-width: 600px) {
    height: 2rem;
  }
`;

export const IntesaBranding = () => {
  return (
    <Container>
      Opcije plaćanja:
      <Group>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
        >
          <Image src={MasterCard} alt="Master Card Logo" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.maestrocard.com/gateway/about/index.html"
        >
          <Image src={MaestroCard} alt="Maestro Card Logo" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
        >
          <Image src={VisaCard} alt="Visa Card Logo" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.americanexpress.com/serbia/en/homepage.shtml"
        >
          <Image src={AmericanExpress} alt="American Express Card Logo" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
        >
          <Image src={DinaCard} alt="Dina Card Logo" />
        </a>
      </Group>
      <Group>
        <a target="_blank" rel="noreferrer" href="https://www.bancaintesa.rs/">
          <Image src={IntesaLogo} alt="Dina Card Logo" />
        </a>
      </Group>
      <Group>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
        >
          <Image src={IDCheck} alt="Dina Card Logo" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
        >
          <Image src={VisaSecure} alt="Visa Card Logo" />
        </a>
      </Group>
    </Container>
  );
};
