import { ModalWithNoPadding } from 'components/Modal/ModalWithNoPadding';
import { useBlogs } from 'hooks/useBlogs';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { BlogItem } from './BlogItem';
import { BlogDetailsModal } from './BlogDetailsModal';
import { ModalContext } from 'components/Modal/ModalContext';
import { DeleteModal } from 'admin/modals/DeleteModal';
import { EditBlogModal } from './CreateBlogModal/EditBlogModal';
import EndListTrigger from 'components/List/EndListTrigger';
import { SmallLoader } from 'components/Loader/Loader';
import { Flex } from 'styles/Containers.styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 100%;
  flex: 1;
  min-height: 0;
`;

const ListContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
`;

export const Blogs = () => {
  const {
    blogs,
    deleteMutation,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useBlogs();
  const { openModal } = useContext(ModalContext);
  const [openedBlog, setOpenedBlog] = useState(null);
  const [openedEditBlog, setOpenedEditBlog] = useState(null);

  if (!blogs) return <div>No Blogs</div>;
  return (
    <>
      <Container>
        <ListContent>
          {blogs.map((blog) => (
            <BlogItem
              key={blog.id}
              blog={blog}
              onClick={() => {
                setOpenedBlog(blog);
              }}
              onEditClick={() => {
                setOpenedEditBlog(blog);
              }}
              onDeleteClick={() => {
                openModal(
                  <DeleteModal
                    deleteAction={() => {
                      setOpenedBlog(null);
                      deleteMutation.mutate({ id: blog.id });
                    }}
                  />
                );
              }}
            />
          ))}
        </ListContent>
        {hasNextPage && <EndListTrigger fetchNextPage={fetchNextPage} />}
        {isFetchingNextPage && (
          <Flex $noFullHeight $jc="center" $mt="0.5rem">
            <SmallLoader />
          </Flex>
        )}
      </Container>

      {openedBlog && (
        <ModalWithNoPadding
          noOutsideClose={false}
          closeFunc={() => {
            setOpenedBlog(null);
          }}
        >
          <BlogDetailsModal
            blog={openedBlog}
            onEditClick={() => {
              setOpenedEditBlog(openedBlog);
              setOpenedBlog(null);
            }}
            onDeleteClick={() => {
              setOpenedBlog(null);
              openModal(
                <DeleteModal
                  deleteAction={() => {
                    setOpenedBlog(null);
                    deleteMutation.mutate({ id: openedBlog.id });
                  }}
                />
              );
            }}
          />
        </ModalWithNoPadding>
      )}
      {openedEditBlog && (
        <ModalWithNoPadding
          noOutsideClose
          closeFunc={() => {
            setOpenedEditBlog(null);
          }}
        >
          <EditBlogModal
            onClose={() => {
              setOpenedEditBlog(null);
            }}
            blog={openedEditBlog}
          />
        </ModalWithNoPadding>
      )}
    </>
  );
};
