import React from 'react';

export const DeleteIcon = ({ color = null }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.48438 6V15H6.98438V6H5.48438ZM13.0666 16.5H3.9021L3.35302 6H1.851L2.44125 17.2892C2.4621 17.6876 2.79127 18 3.19027 18H13.7785C14.1775 18 14.5067 17.6876 14.5275 17.2892L15.1178 6H13.6157L13.0666 16.5ZM11.4844 3V0.75C11.4844 0.5511 11.4053 0.3603 11.2647 0.219675C11.1241 0.078975 10.9333 0 10.7344 0H6.23438C5.82015 0 5.48438 0.335775 5.48438 0.75V3H0.984375V4.5H1.77255H3.27457H13.694H15.1961H15.9844V3H11.4844ZM9.98438 3H6.98438V1.5H9.98438V3ZM9.98438 6V15H11.4844V6H9.98438Z"
        fill={color || '#18191A'}
      />
    </svg>
  );
};
