import { Button } from 'components/Buttons/Button';
import { ModalContext } from 'components/Modal/ModalContext';
import React, { useContext } from 'react';
import { Flex } from 'styles/Containers.styles';

export const LogoutModal = ({ logout }) => {
  const { closeModal } = useContext(ModalContext);
  const onYes = async () => {
    logout();
    closeModal();
  };
  return (
    <Flex $column $gap="1rem" $ai="center">
      <Flex $noFullHeight $jc="center" $fs={1.2} $bold={700}>
        Da li ste sigurni?
      </Flex>
      <Flex $jc="center">
        <Button onClick={closeModal}>Odustani</Button>
        <Button styleType="color" onClick={onYes}>
          Odjavi me
        </Button>
      </Flex>
    </Flex>
  );
};
