import React from 'react';

export const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path
        fill="white"
        d="M24 4.023c0.006-0.258-0.088-0.507-0.261-0.698s-0.414-0.306-0.671-0.323c-0.027-0.002-0.054-0.002-0.080-0.002h-21.975c-0.045-0-0.090 0.002-0.135 0.007-0.024 0.003-0.047 0.007-0.071 0.011-0.241 0.047-0.456 0.181-0.604 0.377l-0.024 0.033c-0.119 0.171-0.182 0.375-0.179 0.583 0.002 3.65 0.003 7.299 0.001 10.949v0.001c0 1.679 0 3.359 0 5.038 0 0.265 0.105 0.519 0.293 0.707s0.442 0.293 0.707 0.293h21.999c0.265 0 0.52-0.105 0.707-0.293s0.293-0.442 0.293-0.707v-15.977zM19.925 5l-7.925 5.764-7.925-5.764h15.85zM2.001 19v-4.038c0.001-2.999 0.001-5.998 0-8.997l9.411 6.844c0.171 0.124 0.377 0.191 0.588 0.191s0.417-0.067 0.588-0.191l9.412-6.845v13.036h-20z"
      ></path>
    </svg>
  );
};
