import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Inputs/Checkbox';
import { FullContainerLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { useAttributesWithValue } from 'hooks/useAttributesWithValue';
import { useContext, useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const Container = styled.div`
  width: 60vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 1;
  overflow-y: scroll;
`;

export const ChooseAttributes = ({
  subcategoryId,
  preselected = [],
  onSave,
}) => {
  const { data, isLoading } = useAttributesWithValue(subcategoryId);
  const [selected, setSelected] = useState<number[]>(preselected);
  const { closeModal } = useContext(ModalContext);

  const toggleSelected = (id: number) => {
    const arr = [...selected];
    const index = arr.findIndex((el) => el === id);
    if (index >= 0) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    setSelected(arr);
  };
  return (
    <Container>
      <Flex $fs={1.2} $bold={700} $jc="center" $noFullHeight>
        Izaberi atribute proizvoda
      </Flex>
      <ScrollContainer>
        {isLoading && <FullContainerLoader />}
        {data?.map((item) => (
          <Flex key={item.id} $column $noFullHeight>
            <Flex>{item.name}</Flex>
            <Flex style={{ flexWrap: 'wrap' }}>
              {item.values.map((value) => (
                <Checkbox
                  key={value.id}
                  text={value.name}
                  checked={selected.includes(value.id)}
                  onChange={() => {
                    toggleSelected(value.id);
                  }}
                />
              ))}
            </Flex>
          </Flex>
        ))}
      </ScrollContainer>
      <Flex $jc="center" $ai="flex-end" $noFullHeight>
        <Button
          styleType="color"
          onClick={() => {
            onSave(selected);
            closeModal();
          }}
        >
          Sačuvaj
        </Button>
      </Flex>
    </Container>
  );
};
