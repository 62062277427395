import {
  forgotPasswordApiCall,
  editPasswordApiCall,
  editUserApiCall,
  getUserCall,
  loginApiCall,
  logoutCall,
  registerApiCall,
} from 'api/auth.api';
import { ModalContext } from 'components/Modal/ModalContext';
import { ErrorModal } from 'components/Modals/ErrorModal';
import { SuccessModal } from 'components/Modals/SuccessModal';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/Auth';
import { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { RegisterType, UserType } from 'types/user.type';

// Create the AuthContext
const AuthContext = createContext<{
  login: (email: string, password: string) => void;
  forgotPassword: (email: string) => void;
  register: (data: RegisterType) => void;
  logout: () => void;
  user: UserType;
  userLoggedOut: boolean;
  editProfile: (body: { name: string }) => void;
  editPassword: (body: {
    currentPassword: string;
    newPassword: string;
  }) => void;
}>(null);

export const AuthProvider = ({ children }) => {
  const userData = useLoaderData() as UserType;
  const [user, setUser] = useState<UserType>(userData || null);
  const { openModal } = useContext(ModalContext);
  const [userLoggedOut, setUserLoggedOut] = useState(false);

  const login = async (email: string, password: string) => {
    try {
      const res = await loginApiCall(email, password);
      setUser(res.user);
      setUserLoggedOut(false);
      localStorage.setItem(ACCESS_TOKEN, res.tokens.accessToken);
      localStorage.setItem(REFRESH_TOKEN, res.tokens.refreshToken);
    } catch (error) {
      openModal(
        <ErrorModal message=" Molimo Vas unesite ispravne kredencijale." />
      );
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const res = await forgotPasswordApiCall(email);
      openModal(<SuccessModal message={res.message} />);
    } catch (error) {
      openModal(<ErrorModal message="Error" />);
    }
  };

  const getUserData = async () => {
    try {
      const data = await getUserCall();
      setUser(data);
    } catch (error) {
      setUser(null);
    }
  };

  const logout = async () => {
    try {
      await logoutCall();
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      setUserLoggedOut(true);
      setUser(null);
    }
  };

  const register = async (data: RegisterType) => {
    try {
      const res = await registerApiCall(data);
      setUser(res.user);
      localStorage.setItem(ACCESS_TOKEN, res.tokens.accessToken);
      localStorage.setItem(REFRESH_TOKEN, res.tokens.refreshToken);
    } catch (error) {
      openModal(<ErrorModal message={error.response.data.message} />);
    }
  };

  const editProfile = async (body: { name: string }) => {
    try {
      const data = await editUserApiCall(user.id, body);
      setUser(data);
      // TODO ADD SUCCESS MODAL
    } catch (err) {
      openModal(<ErrorModal message={err?.response?.data?.message} />);
    }
  };

  const editPassword = async (body: {
    currentPassword: string;
    newPassword: string;
  }) => {
    try {
      const data = await editPasswordApiCall(user.id, body);
      localStorage.setItem(ACCESS_TOKEN, data.accessToken);
      localStorage.setItem(REFRESH_TOKEN, data.refreshToken);
      openModal(<SuccessModal message="Lozinka uspešno promenjena" />);
    } catch (err) {
      console.log(err);
      openModal(<ErrorModal message={err?.response?.data?.message} />);
    }
  };

  useLayoutEffect(() => {
    if (!user) {
      getUserData();
    }
  }, []);

  const value = {
    login,
    forgotPassword,
    logout,
    register,
    user,
    editProfile,
    editPassword,
    userLoggedOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return authContext;
};
