import React from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.div<{ $on: boolean; disabled: boolean }>`
  width: 3rem;
  background: ${({ theme, $on, disabled }) =>
    disabled ? 'gray' : $on ? theme.colors.primary : 'black'};
  border-radius: 10rem;
  padding: 0.2rem;
  display: flex;
  justify-content: ${({ $on }) => (!$on ? 'flex-start' : 'flex-end')};
  cursor: pointer;
`;

const Switch = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-color: white;
  border-radius: 10rem;
`;

export const SimpleSwitch = ({
  on,
  onClick,
  disabled,
}: {
  on: boolean;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <SwitchContainer
      $on={on}
      disabled={disabled}
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
    >
      <Switch />
    </SwitchContainer>
  );
};
