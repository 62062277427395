import { useSearchParams } from 'react-router-dom';

export const useParamsSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetUrlSearchParams = (key: string, value: string) => {
    setSearchParams((prevParams: URLSearchParams) => {
      if (value === null || value === undefined || value === '') {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  };

  const clearSearchParams = () => {
    setSearchParams({});
  };

  const handleArraySearchParam = (key: string, value: string) => {
    console.log(searchParams.getAll('Bra Type'), 'THIS');
    setSearchParams((prevParams: URLSearchParams) => {
      if (prevParams.get(key)) {
        const index = prevParams
          .get(key)
          ?.split(',')
          ?.findIndex((v) => v === value);
        console.log(index, 'INDEX IS THIS');
        if (index < 0) {
          const list = prevParams.getAll(key);
          list.push(value);
          prevParams.set(key, list.join(','));
        } else {
          const list = prevParams.get(key).split(',');
          list.splice(index, 1);
          if (list.length < 1) {
            prevParams.delete(key);
          } else {
            prevParams.set(key, list.join(','));
          }
        }
      } else {
        prevParams.set(key, value);
      }

      return prevParams;
    });
  };

  return {
    searchParams,
    handleSetUrlSearchParams,
    handleArraySearchParam,
    clearSearchParams,
  };
};
