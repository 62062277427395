import { BackArrowIcon } from 'assets/BackArrowIcon';
import { CartModalIcon } from 'assets/CartModalIcon';
import { Button } from 'components/Buttons/Button';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { CartItemType } from 'types/cart.types';

const TitleContainer = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 32px;
    height: 32px;
    color: #38d476;
  }
`;

const Image = styled.img`
  width: 100px;
  height: 120px;
`;

const IconToCartContainer = styled.div`
  rotate: 180deg;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 600px;
  max-width: 100%;
`;

export const AddToCartModal = ({ product }: { product: CartItemType }) => {
  const { closeModal } = useContext(ModalContext);
  const navigate = useNavigate();
  return (
    <Container>
      <Flex $column $gap="2rem">
        <TitleContainer>
          <CartModalIcon />
          Proizvod dodat u korpu!
        </TitleContainer>
        <Flex $ai="center" $gap="1.2rem">
          <Image src={product.images[0]} />
          <Flex $noFullHeight $column>
            <p>{product.name}</p>
            <h1 style={{ fontSize: '1.2rem' }}>
              <b>RSD {(product.price.price / 100).toFixed(2)}</b>
            </h1>
          </Flex>
        </Flex>
        <Flex $jc="space-between">
          <Button
            width="fit-content"
            styleType="outline-dark"
            onClick={() => {
              closeModal();
            }}
          >
            <BackArrowIcon color="currentColor" />
            Nazad na kupovinu
          </Button>
          <Button
            width="fit-content"
            styleType="color"
            onClick={() => {
              navigate('/cart');
              closeModal();
            }}
          >
            <Flex $ai="center" $gap="1rem">
              Korpa
              <IconToCartContainer>
                <BackArrowIcon color="currentColor" />
              </IconToCartContainer>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
};
