import { sidebarLinks } from 'admin/dashboardPageConfig';
import { WhiteLogo } from 'assets/WhiteLogo';
import { Button } from 'components/Buttons/Button';
import { useAuth } from 'context/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const SidebarContainer = styled.div`
  width: 16rem;
  background-color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-start: 1;
  grid-row-end: 3;
  gap: 4rem;
  color: white;
  padding-block: 1rem;
  overflow: hidden;
`;

const SidebarLink = styled(Link)<{
  $active: boolean;
}>`
  width: 14rem;
  height: 3rem;
  mix-blend-mode: difference;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    border-radius: 0.875rem 0 0 0.875rem;
    mix-blend-mode: difference;
    translate: ${({ $active }) => ($active ? '0' : '100%')};
    transition: 200ms all ease;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

// $isActive={
//   location.pathname === link.link ||
//   link.children?.includes(location.pathname)
// }

export const Sidebar = () => {
  const location = useLocation();
  const { logout } = useAuth();

  return (
    <SidebarContainer>
      <WhiteLogo />
      <Flex $column $ai="flex-end">
        {sidebarLinks.map(
          (link) =>
            link && (
              <SidebarLink
                to={'/dashboard' + link.link}
                key={link.link}
                $active={location.pathname === '/dashboard' + link.link}
              >
                {link.icon} {link.title}
              </SidebarLink>
            )
        )}
        <Flex $p="2rem">
          <Button styleType="color" onClick={logout}>
            Odjavi se
          </Button>
        </Flex>
      </Flex>
    </SidebarContainer>
  );
};
