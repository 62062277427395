import { Link } from 'react-router-dom';

export const options: {
  text?: string;
  title?: string;
  lines?: React.ReactElement[];
}[] = [
  {
    lines: [
      <p key={0}>
        DM STAR CONSULTING d.o.o. Beograd – ogranak 2 ESOTIQ ( u daljem tekstu:
        Esotiq), sa sedištem u Beogradu, opština Zvezdara, Bulevar Kralja
        Aleksandra br. 458/32, MB: 21430552, PIB: 111132614, u okviru svoje
        poslovne aktivnosti, obrađuje podatke o ličnosti u skladu sa svim
        važećim propisima.
      </p>,
      <p key={1}>Molimo Vas da ove informacije pažljivo pročitate!</p>,
      <p key={2}>
        <b>Rukovalac podacima o ličnosti</b>
      </p>,
      <p key={3}>
        Esotiq je Rukovalac podacima o ličnosti u skladu sa Zakonom o zaštiti
        podataka o ličnosti i drugim propisima koji uređuju oblast zaštitu
        podataka o ličnosti. Esotiq, kao Rukovalac podacima o ličnosti, određuje
        svrhu i način obrade podataka o ličnosti i snosi odgovornost za čuvanje
        i korišćenje podataka o ličnosti u pisanoj i/ili elektronskoj formi.
      </p>,
      <p key={4}>
        <b>Obrađivač podataka o ličnosti</b>
      </p>,
      <p key={5}>
        Esotiq, u svom poslovanju, može obrađivati podatke o ličnosti u ime
        Rukovaoca podacima o ličnosti, odnosno u skladu sa njegovim pisanim
        uputstvima u kom slučaju ima svojstvo Obrađivača podataka o ličnosti.
      </p>,
      <p key={6}>
        <b>Lice za zaštitu podataka o ličnosti</b>
      </p>,
      <p key={5}>
        Esotiq je u skladu sa relevantnim propisima iz oblasti zaštite podataka
        o ličnosti imenovao Lice za zaštitu podataka o ličnosti (DPO). Ovom licu
        se možete obratiti vezano za sva pitanja koja se tiču obrade podataka o
        ličnosti koju vrši Esotiq, kao i u pogledu ostvarivanja prava na zaštitu
        podataka o ličnosti.
      </p>,
      <p key={5}>Lice za zaštitu podataka o ličnosti: Danijel Veselinović</p>,
      <p key={5}>
        Kontakt podaci:{' '}
        <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>,{' '}
        <a href="tel: +381 60 1320 929">+381 60 1320 929</a>
      </p>,
      <p key={11}>
        <b>Podaci o ličnosti koje prikuplja Esotiq</b>
      </p>,
      <p key={5}>
        Esotiq prikuplja podatke o ličnosti na osnovu: vrste usluge koju nudimo
        kupcima, korisnicima i klijentima, namera korisnika da se koriste našim
        uslugama, vrsta ugovora koji imamo sa klijentima, kao i od namere kupaca
        da ostvare svoja prava prema zakonima o zaštiti podataka o ličnosti.
      </p>,
      <p key={5}>
        Primarno se fokusiramo na podatke neophodne za ispunjenje ugovornih
        obaveza, uključujući, ali ne ograničavajući se na:
      </p>,
      <ul key={6} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li>osnovne lične podatke (ime i prezime)</li>
        <li>kontakt informacije (adresa, telefon, e-mail)</li>
        <li>
          informacije o korišćenju naše web stranice na{' '}
          <Link to="/">https://esotiq.rs</Link>
        </li>
        <li>informacije o korišćenju Esotiq online prodavnice</li>
        <li>podatke potrebne za ugovor o potrošačkom kreditu</li>
        <li>
          druge relevantne informacije potrebne za ostvarivanje prava klijenata
          prema zakonima o zaštiti potrošača.
        </li>
      </ul>,
      <p key={7}>
        <b>Prikupljanje i korišćenje podataka o ličnosti</b>
      </p>,
      <p key={8}>
        Esotiq upravlja i prikuplja podatke o ličnosti kako bi ostvario
        kupoprodajne odnose za proizvode koje nudi, ispunio zakonske obaveze,
        kao i radi ostvarivanja legitimnih poslovnih interesa. Ako zakon to
        nalaže, za određene svrhe tražićemo eksplicitnu saglasnost osobe čiji se
        podaci obrađuju.
      </p>,
      <p key={9}>
        Situacije u kojima Esotiq obrađuje lične podatke u cilju sklapanja i
        ispunjenja ugovornih obaveza uključuju:
      </p>,
      <ul key={11} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li key={'list' + 0}>trgovina proizvodima i uslugama</li>
        <li key={'list' + 1}>prodajne i marketinške aktivnosti</li>
        <li key={'list' + 2}>administracija ugovornog odnosa</li>
        <li key={'list' + 3}>obrada prigovora i reklamacija</li>
        <li key={'list' + 4}>isporuka naručenih proizvoda</li>
        <li key={'list' + 5}>proces naplate ili prinudne naplate</li>
        <li key={'list' + 6}>
          slanje obaveštenja putem biltena, SMS-a ili Viber-a
        </li>
        <li key={'list' + 7}>
          druge obaveze propisane zakonom koje proizilaze iz poslovanja Esotiq.
        </li>
      </ul>,
      <p key={12}>
        Razlozi za obradu Vaših ličnih podataka na temelju naših legitimnih
        poslovnih interesa su:
      </p>,
      <ul key={11} style={{ listStyle: '-', marginLeft: '1rem' }}>
        <li key={'list' + 6}>
          obaveštavanje o promenama vezanim za uslove poslovanja
        </li>
        <li key={'list' + 7}>sprečavanje i otkrivanje prevare</li>
      </ul>,
      <p key={13}>
        Za sve aktivnosti koje nisu obuhvaćene navedenim razlozima, tražićemo
        dodatnu saglasnost koju možete povući u svakom trenutku (opoziv
        saglasnosti).
      </p>,
      <p key={14}>
        <b>Pristup podacima o ličnosti</b>
      </p>,
      <p key={15}>
        Esotiq sprovodi tehničke i organizacione mere kako bi se osiguralo da se
        podaci o ličnosti koriste samo u svrhe za koje su prikupljeni. Ako je
        potrebno u svrhu izvršenja poslovnih aktivnosti, pristup podacima o
        ličnosti može biti dozvoljen trećim stranama i to:
      </p>,
      <ul key={11} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li key={'list' + 6}>
          državne institucije i organe javne uprave koji imaju zakonsko pravo na
          pristup podacima o ličnosti
        </li>
        <li key={'list' + 7}>pravne zastupnike</li>
        <li key={'list' + 7}>
          servisnim službama, štamparijama, finansijske institucije
        </li>
        <li key={'list' + 7}>
          {' '}
          ostale poslovne saradnike Sve navedene treće strane moraju postupati s
          podacima o ličnosti prema uputstvima kompanije Esotiq, osim kada je
          reč o državnim i javnim organima.
        </li>
      </ul>,
      <p key={16}>
        Sva treća lica dužna su da podatke o ličnosti obrađuju isključivo prema
        uputstvima Esotiq kompanije, izuzev državnih organa i organa javne
        vlasti.
      </p>,
      <p key={17}>
        <b>Obrada podataka o ličnosti</b>
      </p>,
      <p key={18}>
        Podaci o ličnosti koji će biti obrađivani od strane izvršilaca obrade
        navedenih u delu &quot;Pristup podacima o ličnosti&quot; koji su i
        odgovorni za zaštitu i čuvanje poverljivosti podataka o ličnosti, a
        njihove obaveze definisane ugovorom i usklađene sa svim tehničkim,
        organizacionim merama zakonom o zaštiti podataka o ličnosti. Lični
        podaci koje Esotiq sakupi, prema svrhama navedenim u delu
        &quot;Prikupljanje i korišćenje podataka o ličnosti&quot;, neće biti
        dostupni onima koji nemaju odobrenje od strane Esotiq kompanije.
      </p>,
      <p key={20}>
        <b>Ostvarivanje prava</b>
      </p>,
      <p key={20}>
        U skladu sa zakonskim odredbama, lice čije podatke Esotiq obrađuje ima
        pravo na:
      </p>,
      <ul key={19} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li>Pravo na informacije o obradi svojih ličnih podataka</li>
        <li>
          Pravo na povlačenje prethodno datog pristanka u bilo kojem trenutku
          (opoziv saglasnosti), ukoliko Esotiq obrađuje lične podatke na osnovu
          datog pristanka.
        </li>
        <li>Pravo na ispravku i/ili dopunu svojih ličnih podataka</li>
        <li>
          Pravo na brisanje ličnih podataka kada više nisu neophodni za svrhu
          zbog koje su prikupljeni
        </li>
        <li>
          Pravo na podnošenje žalbe direktno Esotiqu ili odgovarajućem
          regulatornom telu.
        </li>
      </ul>,
      <p key={20}>
        Da bi ostvarili navedena prava, Esotiq omogućava korisnicima sledeće
        metode komunikacije:
      </p>,
      <ul key={19} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li>lično, u svakoj Esotiq prodavnici</li>
        <li>
          putem e-maila na:{' '}
          <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>
        </li>
      </ul>,
      <p key={21}>
        Zahtevi u vezi sa ličnim podacima moraju biti podneti lično kako bi
        Esotiq mogao verifikovati identitet osobe koja podnosi zahtev.
      </p>,
      <p key={22}>
        <b>Pravo na prigovor nadzornom organu</b>
      </p>,
      <p key={23}>
        Pored prigovora koji lice čiji se podaci obrađuju može podneti direkrno
        Esotiqu, prigovor na obradu podataka o ličnosti može se podneti i
        kancelariji Poverenika za informacije od javnog značaja i zaštitu
        podataka o ličnosti, na adresu Bulevar kralja Aleksandra 15, 11000
        Beograd, Srbija, na e-mail{' '}
        <a href="mailto: office@poverenik.rs">office@poverenik.rs</a> ili na
        broj telefona <a href="tel:011/3408-900">011/3408-900</a>.
      </p>,
      <p key={24}>
        <b>Čuvanje ličnih podataka</b>
      </p>,
      <p key={25}>
        Dužina čuvanja ličnih podataka zavisi od svrhe zbog koje ih Esotiq
        prikuplja. Podaci sakupljeni isključivo zbog prodajnih transakcija ili
        pružanja drugih usluga (kao što su isporuka, dostava ili reklamacije)
        zadržavaju se do 5 (pet) godina od dana izvršene transakcije. Podaci
        prikupljeni radi slanja obaveštenja putem newsletter-a, SMS-a ili
        Viber-a čuvaju se sve dok je na listi za primanje obaveštenja ili dok
        korisnik ne povuče svoju saglasnost. Podaci koji se prikupljaju zbog
        obrada na osnovu zakonskih obaveza čuvaju se u skladu sa zakonskim
        rokovima koji se odnose na te podatke (na primer, Zakonu o računovodstvu
        i Sl.).
      </p>,
      <p key={30}>
        <b>Kontakt</b>
      </p>,
      <p key={26}>
        Za sva pitanja vezano sa zaštitu podataka o ličnosti i njihovo
        korišćenje, možete nam se obratiti:
      </p>,
      <ul key={11} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li key={'list' + 6}>
          slanjem elektronske pošte na{' '}
          <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>
        </li>
        <li key={'list' + 7}>pravne zastupnike</li>
        <li key={'list' + 7}>
          pisanim putem na adresu: Esotiq, Bulevar Kralja Aleksandra br. 458/32,
          Beograd, Srbija
        </li>
        <li key={'list' + 7}>
          putem obrasca koji možete preuzeti{' '}
          <a href="https://esotiq-prod.fra1.digitaloceanspaces.com/Zahtev_licni_podaci-obrazac-esotiq-doo-beograd.docx">
            ovde ( zahtev za lične podatke)
          </a>
        </li>
        <li key={'list' + 7}>
          pozivom na <a href="tel: +381 60 1320 929">+381 60 1320 929</a>.
        </li>
      </ul>,
      <p key={31}>
        Za komunikaciju sa Esotiqom, radi ostvarivanja prava vezanih za zaštitu
        podataka o ličnosti, potrebno je koristiti komunikacijske kanale
        definisane u poglavlju Ostvarivanje prava kako bi svaki zahtev bio
        prihvaćen, a podnosilac zahteva identifikovan.
      </p>,
      <p key={32}>
        <b>Ažuriranje politike privatnosti</b>
      </p>,
      <p key={32}>
        Esotiq e-prodavnica kontinuirano revidira i ažurira svoju Politiku
        privatnosti kako bi je uskladili sa aktuelnim poslovnim procesima i
        zakonodavstvom. Važeća verzija Politike privatnosti je uvek dostupna na
        našem sajtu na adresi{' '}
        <Link to="/privacy-policy">https://esotiq.rs/privacy-policy</Link>. Ako
        se dogode značajne izmene koje utiču na način na koji obrađujemo Vaše
        lične podatke, obavestićemo Vas odmah putem naše web stranice i direktno
        Vas informisati.
      </p>,
      <p key={33}>
        <b>
          Uslovi obrade ličnih podataka radi pružanja usluge dostave i povrata
          robe
        </b>
      </p>,
      <p key={34}>
        Kako bi omogućila uslugu isporuke i povrata proizvoda, Esotiq
        e-prodavnica prikuplja sledeće podatke:
      </p>,
      <ul key={11} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li key={'list' + 6}>ime I prezime</li>
        <li key={'list' + 7}>adresa isporuke ili adresa za naplatu robe</li>
        <li key={'list' + 7}>kontakt telefon</li>
        <li key={'list' + 7}>e-mail adresa</li>
      </ul>,
      <p key={35}>
        Esotiq e-prodavnica deli ove podatke sa svojim poslovnim
        partnerima-pružaocima logističkih usluga kako bi se osiguralo izvršenje
        ugoverenih usluga. Naši partneri su ovlašćeni od strane Esotiq-a da
        koriste Vaše lične podatke samo u svrhu pružanja dogovorenih usluga.
      </p>,
      <p key={36}>
        <b>Kolačići (&quot;Cookies&quot;) i praćenje internet aktivnosti</b>
      </p>,
      <p key={37}>
        Esotiq e-prodavnica, dostupna na adresi{' '}
        <Link to="/">https://esotiq.rs/</Link>, koristi kolačiće (eng. Cookies),
        radi optimalnog rada, unapređenja sajta i boljeg korisničkog iskustva
        prilikom njegovog korišćenja.
      </p>,
      <p key={38}>
        Kolačić predstavlja tekstualni fajl koji se prenosi na Vaš uređaj (PC,
        tablet, mobilni) kada posetite našu internet stranicu. Esotiq koristi
        ove kolačiće kako bi korisnicima omogućio lakšu i kvalitetniju
        interakciju sa funkcijama koje sajt nudi. Informacije koje prikupljamo
        pomažu nam da zapamtimo Vaša podešavanja i aktivnosti na sajtu, poput
        pregleda proizvoda, navigacije kroz stranice i dodavanja proizvoda u
        korpu i slično. Posetioci naše e-prodavnice mogu u bilo kojem trenutku
        deaktivirati kolačiće putem podešavanja svog internet pretraživača.
        Međutim, ukoliko pristupite sajtu sa deaktiviranim kolačićima, neke od
        funkcija sajta možda neće biti dostupne. Više o našoj politici
        korišćenja kolačića možete saznati <Link to="/cookies">ovde</Link>
        .(politika kolačića)
      </p>,
      <p key={39}>
        <b>
          Obaveštenja putem Newsletter-a, SMS-a i Vibera i korišćenje ličnih
          podataka za marketinške svrhe
        </b>
      </p>,
      <p key={40}>
        Ukoliko želite da budete informisani o Esotiq ponudama putem e-maila,
        možete se prijaviti na našu mailing listu &quot;Egzotične novosti&quot;.
        Za uspešnu prijavu, popunite neophodna polja: ime, prezime, e-mail,
        adresa i broj mobilnog telefona, te označite opciju &quot;Želim da
        primam obaveštenja putem newsletter-a.&quot;
      </p>,
      <p key={40}>
        Za primanje obaveštenja putem SMS-a i Vibera, potrebno je da se
        prijavite na našu SMS i Viber listu, popunjavajući ista polja i
        označavajući opciju &quot;Želim obaveštenja putem SMS-a/Vibera.&quot; U
        bilo kojem trenutku možete povući svoju saglasnost i odjaviti se sa
        liste pozivanjem broja{' '}
        <a href="tel: +381 60 1320 929">+381 60 1320 929</a>, slanjem e-maila na{' '}
        <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>{' '}
        ili pismenim putem na adresu: Bulevar Kralja Aleksandra 458/32 11127,
        Beograd. Na Esotiq web sajtu možete kreirati korisnički nalog
        popunjavanjem potrebnih informacija: ime, prezime, e-mail, adresa i broj
        telefona. Ukoliko želite da deaktivirate svoj nalog, možete to učiniti
        slanjem zahteva na{' '}
        <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>
        ili pismenim putem na gore navedenu adresu.
      </p>,
      <p key={40}>
        U svrhu slanja obaveštenja onima na mailing listi, Esotiq može
        angažovati eksterne poslovne saradnike. U tom kontekstu, Esotiq deli
        samo one podatke koje mu je lice pružilo za tu svrhu. Izabrani saradnik
        koristi podatke isključivo za slanje obaveštenja i to dok postoji
        saglasnost korisnika (do trenutka povlačenja saglasnosti), te ih ne sme
        koristiti u druge svrhe.
      </p>,
      <p key={40}>
        Esotiq neće deliti kontakt podatke namenjene za slanje newsletter-a
        i/ili SMS i Viber obaveštenja sa trećim stranama, osim u gore opisanim
        okolnostima. Ako se lice nije prijavilo za primanje obaveštenja putem
        Newslettera i/ili SMS-a i Vibera, ali je kupac ili registrovani
        korisnik, može od Esotiq-a dobijati obaveštenja vezana za narudžbinu,
        plaćanje ili druge aktivnosti koje je lice samo iniciralo. Dodatno,
        Esotiq može pružiti informacije koje se tiču promena u poslovanju i
        odnosima sa kupcima, klijentima i slično.
      </p>,
    ],
  },
];
