import { useQuery } from '@tanstack/react-query';
import { getCollectionById } from 'api/collections';
import { CollectionType } from 'types/collection.types';

export const useCollection = ({ id }: { id: string }) => {
    const { isLoading, error, data } = useQuery<CollectionType>(
        ['collection', { id }],
        () => getCollectionById(id)
    );

    return { isLoading, error, data };
};
