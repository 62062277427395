import React from 'react';

export const CustomersIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6875 12.1875C13.9971 12.1875 13.4375 12.7471 13.4375 13.4375C13.4375 14.1279 13.9971 14.6875 14.6875 14.6875C15.3779 14.6875 15.9375 14.1279 15.9375 13.4375C15.9375 12.7471 15.3779 12.1875 14.6875 12.1875ZM12.1875 13.4375C12.1875 12.0568 13.3068 10.9375 14.6875 10.9375C16.0682 10.9375 17.1875 12.0568 17.1875 13.4375C17.1875 14.8182 16.0682 15.9375 14.6875 15.9375C13.3068 15.9375 12.1875 14.8182 12.1875 13.4375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6876 15.9316C14.2713 15.9316 13.8668 16.0702 13.538 16.3255C13.2091 16.5808 12.9745 16.9382 12.8712 17.3416C12.7856 17.676 12.4451 17.8776 12.1108 17.792C11.7764 17.7064 11.5747 17.3659 11.6603 17.0315C11.8324 16.3593 12.2234 15.7635 12.7715 15.338C13.3196 14.9126 13.9938 14.6816 14.6876 14.6816C15.3815 14.6816 16.0557 14.9126 16.6038 15.338C17.1519 15.7635 17.5429 16.3593 17.715 17.0315C17.8006 17.3659 17.5989 17.7064 17.2645 17.792C16.9302 17.8776 16.5897 17.676 16.5041 17.3416C16.4008 16.9382 16.1662 16.5808 15.8373 16.3255C15.5085 16.0702 15.104 15.9316 14.6876 15.9316Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24112 4.11612C2.47554 3.8817 2.79348 3.75 3.125 3.75H7.29163C7.56025 3.7511 7.82145 3.83818 8.037 3.99846L8.03849 3.99957L10.2089 5.62346C10.2088 5.62343 10.2089 5.6235 10.2089 5.62346C10.2101 5.62434 10.2117 5.62494 10.2133 5.625H16.875C17.2065 5.625 17.5245 5.7567 17.7589 5.99112C17.9933 6.22554 18.125 6.54348 18.125 6.875V9.375C18.125 9.72018 17.8452 10 17.5 10C17.1548 10 16.875 9.72018 16.875 9.375V6.875H10.2084C9.93975 6.8739 9.67855 6.78682 9.463 6.62654L9.46151 6.62543L7.29112 5.00154C7.29107 5.0015 7.29117 5.00157 7.29112 5.00154C7.28988 5.00066 7.28826 5.00006 7.28675 5L3.125 5L3.125 15.625H9.375C9.72018 15.625 10 15.9048 10 16.25C10 16.5952 9.72018 16.875 9.375 16.875H3.125C2.79348 16.875 2.47554 16.7433 2.24112 16.5089C2.0067 16.2745 1.875 15.9565 1.875 15.625V5C1.875 4.66848 2.0067 4.35054 2.24112 4.11612Z"
        fill="currentColor"
      />
    </svg>
  );
};
