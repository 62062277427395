import { SmallSearchIcon } from 'assets/SmallSearchIcon';
import { useParamsSearch } from 'hooks/useParamsSearch';
import { useState } from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const InputContainer = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  margin-block: 1rem;
  max-width: 30rem;
  border: 1px solid ${theme.colors.secondaryDark};
  padding-inline: 1rem;
  align-items: center;
  gap: 1rem;
  border-radius: 7px;

  svg {
    height: 2rem;
    width: 2rem;
  }

  &:hover {
    border-color: ${theme.colors.secondaryDark};
  }

  @media screen and (max-width: 600px) {
    margin-block: 0.2rem;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  padding-block: 0.7rem;
  transition: all 200ms ease-in;
  background-color: transparent;
`;

export const SearchProductsInput = ({ onEnter = null, initialValue = '' }) => {
  const { handleSetUrlSearchParams } = useParamsSearch();
  const [value, setValue] = useState(initialValue);

  return (
    <InputContainer
      onSubmit={(e) => {
        console.log(value);
        e.preventDefault();
        if (onEnter) {
          onEnter(value);
        } else {
          handleSetUrlSearchParams('search', value);
          handleSetUrlSearchParams('page', null);
        }
      }}
    >
      <StyledInput
        autoComplete="off"
        placeholder="Pretraži"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />

      <SmallSearchIcon />
    </InputContainer>
  );
};
