import { useRef } from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';
import { isColorLight } from 'utils/color.utils';

const ColorCircle = styled.div<{
  $color: string;
  $border: boolean;
}>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  border: ${({ $border }) => $border && '1px solid ' + theme.colors.borderGray};
`;

export const ColorPicker = ({ color = theme.colors.primary, onChange }) => {
  const ref = useRef(null);
  return (
    <div style={{ position: 'relative' }}>
      <input
        style={{ opacity: 0, position: 'absolute', bottom: '0', right: 0 }}
        multiple
        value={null}
        type="color"
        ref={ref}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <ColorCircle
        $color={color}
        $border={isColorLight(color)}
        onClick={() => {
          if (ref.current) {
            ref.current.click();
          }
        }}
      />
    </div>
  );
};
