import { useQuery } from '@tanstack/react-query';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { FullContainerLoader } from 'components/Loader/Loader';
import { useCollectionsWithProducts } from 'hooks/useCollectionsWithProducts';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Helmet } from 'react-helmet';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { CategoryType } from 'types/category.types';
import { findPage } from 'utils/page.utils';
import { CollectionsList } from './CollectionsList';

export const HeaderImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1366px;
  padding-inline: 4rem;

  @media screen and (max-width: 1000px) {
    padding-inline: 1rem;
  }
`;

type PageType = CategoryType;

export const Collections = () => {
  const { data: page, isLoading: pageLoading } = useQuery<PageType>({
    queryKey: [`collections_page`],
    queryFn: async () => await findPage('kolekcije'),
  });
  const { collections } = useCollectionsWithProducts();
  const { width } = useWindowWidth();

  if (pageLoading)
    return (
      <ContentContainer style={{ height: '80vh' }}>
        <FullContainerLoader />
      </ContentContainer>
    );

  if (!page) {
    return null;
  }

  return (
    <Flex $p="0 0 4rem 0">
      <Helmet>
        <title>Kolekcije | Esotiq</title>
      </Helmet>
      <Flex $column $ai="center">
        {width > 1000 && <HeaderImage src={page?.image} />}
        <ContentContainer>
          <Breadcrumbs list={[{ name: 'kolekcije', link: 'kolekcije' }]} />
        </ContentContainer>
        <CollectionsList collections={collections} />
      </Flex>
    </Flex>
  );
};
