import { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

export const ScrollToTopButton = ({ scrollDistance = 100 }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Check if the page has scrolled down N pixels
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > scrollDistance) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= scrollDistance) {
      setIsVisible(false);
    }
  };

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [isVisible, checkScrollTop]);

  return (
    isVisible && (
      <StyledButton onClick={scrollToTop}>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.71895 17.7373L3.71857 16.7368L12.0004 8.45602L20.2822 16.7368L19.2818 17.7373L12.0011 10.4574L4.71895 17.7373Z"
            fill="#FFFDFB"
          />
        </svg>
      </StyledButton>
    )
  );
};
