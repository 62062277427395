import { Logo } from 'assets';
import { ArrowDownIcon } from 'assets/ArrowDownIcon';
import { Button } from 'components/Buttons/Button';
import { ExpandableContainer } from 'components/Containers/ExpandableContainer';
import { Divider } from 'components/Drividers/Divider';
import { SimpleSwitch } from 'components/Inputs/SimpleSwitch';
import { ModalContext } from 'components/Modal/ModalContext';
import { useCookies } from 'context/CookieContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useContext, useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-height: 80vh;
  overflow: scroll;
  gap: 2rem;

  @media screen and (max-width: 1000px) {
    padding: 0;
    max-width: 90vw;
  }
`;

export const CookieModal = () => {
  const { width } = useWindowWidth();
  const [essentialOpened, setEssentialOpened] = useState(width > 1000);
  const [analyticsOpened, setAnalyticsOpened] = useState(width > 1000);
  const [marketingOpened, setMarketingOpened] = useState(width > 1000);
  const { closeModal } = useContext(ModalContext);
  const {
    deniedConcent,
    acceptConsent,
    saveAcceptedConsent,
    statistic,
    setStatistic,
    setMarketing,
    marketing,
  } = useCookies();

  return (
    <Container>
      <Flex $jc="space-between" $column={width < 1000}>
        <Flex $noFull $fs={1.4} $textcolor={theme.colors.primary}>
          Detaljnije o kolačićima
        </Flex>
        <div>
          <Logo width={120} height={40} />
        </div>
      </Flex>
      <Flex $column $gap="0">
        <Divider />
        <Flex $column $gap="0" $p="0.5rem 0">
          <Flex $ai="center">
            <Flex
              $noFullHeight
              $bold={700}
              onClick={() => {
                setEssentialOpened((prev) => !prev);
              }}
              $ai="center"
            >
              <ArrowDownIcon opened={essentialOpened} />
              Neophodni
            </Flex>{' '}
            <SimpleSwitch on disabled />
          </Flex>
          <ExpandableContainer opened={essentialOpened}>
            <Flex $p="1rem 0 0 0">
              Neophodni kolačići pomažu da sajt bude upotrebljiv omogućavajući
              osnovne funkcije kao što su navigacija na stranici i pristup
              bezbednim oblastima veb sajta. Veb sajt ne može da funkcioniše
              pravilno bez ovih kolačića.
            </Flex>
          </ExpandableContainer>
        </Flex>
        <Divider />
        <Flex $column $gap="0" $p="0.5rem 0">
          <Flex $ai="center">
            <Flex
              $noFullHeight
              $bold={700}
              onClick={() => {
                setAnalyticsOpened((prev) => !prev);
              }}
              $ai="center"
            >
              <ArrowDownIcon opened={analyticsOpened} />
              Statistika
            </Flex>{' '}
            <SimpleSwitch
              on={statistic}
              onClick={() => {
                setStatistic((prev) => !prev);
              }}
            />
          </Flex>
          <ExpandableContainer opened={analyticsOpened}>
            <Flex $p="1rem 0 0 0">
              Statistički kolačići pomažu vlasnicima veb sajta da razumeju
              interakciju posetilaca sa veb sajtom anonimnim sakupljanjem
              informacija i izveštavanjem.
            </Flex>
          </ExpandableContainer>
        </Flex>
        <Divider />
        <Flex $column $gap="0" $p="0.5rem 0">
          <Flex $ai="center">
            <Flex
              $noFullHeight
              $bold={700}
              onClick={() => {
                setMarketingOpened((prev) => !prev);
              }}
              $ai="center"
            >
              <ArrowDownIcon opened={marketingOpened} />
              Marketing
            </Flex>{' '}
            <SimpleSwitch
              on={marketing}
              onClick={() => {
                setMarketing((prev) => !prev);
              }}
            />
          </Flex>
          <ExpandableContainer opened={marketingOpened}>
            <Flex $p="1rem 0 0 0">
              Marketing kolačići se koriste za praćenje posetioca na veb
              sajtovima. Namera je da se prikažu reklame koje su relevantne i
              privlačne za pojedinačnog korisnika a time i od veće vrednosti za
              izdavače i treće strane oglašivače.
            </Flex>
          </ExpandableContainer>
        </Flex>
      </Flex>
      <Flex $column={width < 1000} $gap="1rem">
        <Button
          width={width > 1000 ? '10rem' : '100%'}
          styleType="color"
          onClick={() => {
            acceptConsent();
            closeModal();
          }}
        >
          Dozvoli sve
        </Button>
        <Button
          width={width > 1000 ? '10rem' : '100%'}
          onClick={() => {
            saveAcceptedConsent();
            closeModal();
          }}
        >
          Dozvoli izbor
        </Button>
        <Button
          width={width > 1000 ? '10rem' : '100%'}
          styleType="outline-dark"
          onClick={() => {
            deniedConcent();
            closeModal();
          }}
        >
          Odbij
        </Button>
      </Flex>
    </Container>
  );
};
