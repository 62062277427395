import axiosInstance from './instance';

export const getAttributeGroups = async () => {
  const res = await axiosInstance.get('/v1/attributes', {
    params: {
      page: 1,
      limit: 1000,
    },
  });
  return res.data.data;
};

export const createAttributeGroup = async (data: { name: string }) => {
  const res = await axiosInstance.post('/v1/attributes', data);
  return res.data.data;
};

export const editAttributeGroup = async (
  id: number,
  data: { name: string }
) => {
  const res = await axiosInstance.patch(`/v1/attributes/${id}`, data);
  return res.data;
};

export const deleteAttributeGroup = async (id: number) => {
  const res = await axiosInstance.delete(`/v1/attributes/${id}`);
  return res.data.data;
};
export const deleteAttributeValue = async (
  id: number,
  attributeValueId: number
) => {
  const res = await axiosInstance.delete(
    `/v1/attributes/${id}/values/${attributeValueId}`
  );
  return res.data.data;
};

export const addAttributeValueToGroup = async (groupId: number, data) => {
  const res = await axiosInstance.post(
    `/v1/attributes/${groupId}/values`,
    data,
    {
      headers: { 'content-type': 'multipart/form-data' },
    }
  );

  return res.data.data;
};
