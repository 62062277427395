import React from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const ItemContainer = styled.div<{
  $disabled: boolean;
  $active: boolean;
}>`
  flex: 5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 10rem;
  border: 1px solid
    ${({ $active, $disabled }) =>
      $disabled
        ? theme.colors.secondaryLight
        : $active
        ? theme.colors.primary
        : theme.colors.secondaryDark};
  background-color: ${({ $active }) =>
    $active ? theme.colors.primary : 'white'};
  color: ${({ $active, $disabled }) =>
    $disabled ? theme.colors.secondaryLight : $active ? 'white' : null};
  font-size: 0.8rem;
  cursor: ${({ $disabled }) => !$disabled && 'pointer'};
`;

export const SizeItem = ({ title, active, onClick, disabled }) => {
  return (
    <ItemContainer
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      $active={active}
      $disabled={disabled}
    >
      {title}
    </ItemContainer>
  );
};
