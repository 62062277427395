import { CustomerType } from 'types/customer.types';
import axiosInstance from './instance';

export const getCustomers = async (params) => {
  const res = await axiosInstance.get('/v1/customers', { params });

  if (res.data.data.length < 1) {
    return undefined;
  }

  return {
    nextPage: params.page + 1,
    currentPage: params.page,
    data: res.data.data as CustomerType[],
  };
};
