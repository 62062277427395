export const validateEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const validatePhoneNumber = (number: string) => {
  const pattern = /^\+\d{1,3}\d{9}$/;
  return pattern.test(number);
};

export const formatePhoneNumber = (number: string): string => {
  if (number[0] === '0') {
    return number.replace('0', '+381').trim();
  }

  return number;
};
