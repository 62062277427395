import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { Order } from 'types/order.types';
import { format } from 'date-fns';
import { formatStatusText } from 'utils/status.utils';

const OrderItem = styled(Flex)`
  border-bottom: 1px solid ${theme.colors.secondaryLight};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondaryLight};
  }
`;

const ItemColumn = styled(Flex)`
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    font-size: 0.7rem;
  }
`;

const StatusItem = styled.div`
  padding: 0 0.5rem;
  border: 1px solid ${theme.colors.secondaryDark};
`;

export const MyOrdersList = ({ list }: { list: Order[] }) => {
  return (
    <Flex $column $gap="0">
      <Flex
        $bold={300}
        style={{ borderBottom: '1px solid ' + theme.colors.secondaryLight }}
        $p="0.5rem"
      >
        <ItemColumn>Datum</ItemColumn>
        <ItemColumn>Broj porudžbine</ItemColumn>
        <ItemColumn>Cena</ItemColumn>
        <ItemColumn $jc="flex-end">Status</ItemColumn>
      </Flex>
      {list.map((order) => (
        <OrderItem $bold={700} $p="1rem 0.5rem" key={order.id}>
          <ItemColumn>
            {format(new Date(order.createdAt), 'dd. MM. yyyy.')}
          </ItemColumn>
          <ItemColumn>{order.id}</ItemColumn>
          <ItemColumn>RSD {(order.price / 100).toFixed(2)}</ItemColumn>
          <ItemColumn $jc="flex-end">
            <StatusItem>{formatStatusText(order.status)}</StatusItem>
          </ItemColumn>
        </OrderItem>
      ))}
    </Flex>
  );
};
