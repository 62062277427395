import { SimpleProductType } from 'types/products.types';
import axiosInstance from './instance';

export const getProducts = async (params) => {
  const res = await axiosInstance.get('/v1/products', { params });

  if (res.data.data.length < 1) {
    return undefined;
  }

  return {
    nextPage: params.page + 1,
    currentPage: params.page,
    data: res.data.data as SimpleProductType[],
  };
};

export const getProductsCount = async (params) => {
  const res = await axiosInstance.get('/v1/products/count', { params });

  return res.data.data.count;
};

export const getColorVariants = async (params) => {
  const res = await axiosInstance.get('/v1/color-variants', { params });

  if (res.data.data.length < 1) {
    return undefined;
  }

  return {
    nextPage: params.page + 1,
    currentPage: params.page,
    data: res.data.data as ColorVariantType[],
  };
};

export const getProductDetails = async (id: string) => {
  const res = await axiosInstance.get(`/v1/products/${id}`);

  return res.data.data;
};

export const getColorVariantById = async (id: string) => {
  const res = await axiosInstance.get(`/v1/color-variants/${id}`);

  return res.data.data;
};
export const getColorVariantBySlug = async (id: string) => {
  const res = await axiosInstance.get(`/v1/color-variants/slug/${id}`);

  return res.data.data;
};

export const getProductFilters = async (params) => {
  const res = await axiosInstance.get('/v1/color-variants/filters', { params });

  return res.data.data;
};

export const addProductColorVariant = async (data) => {
  const res = await axiosInstance.post('/v1/color-variants', data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res?.data?.data;
};
export const editProductCall = async (data: any, id: number) => {
  const res = await axiosInstance.patch(`/v1/products/${id}`, data);

  return res?.data?.data;
};

export const editColorVariant = async (data: any, id: number) => {
  const res = await axiosInstance.patch(`/v1/color-variants/${id}`, data);

  return res?.data?.data;
};

export const deleteColorVariant = async (id) => {
  const res = await axiosInstance.delete(`/v1/color-variants/${id}`);

  return res?.data?.data;
};

export const deleteProductCall = async (id) => {
  const res = await axiosInstance.delete(`/v1/products/${id}`);

  return res?.data?.data;
};

export const createProduct = async (data) => {
  const res = await axiosInstance.post('/v1/products', data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res?.data?.data;
};

export const uploadVariantImage = async (data, id) => {
  const res = await axiosInstance.post(`/v1/color-variants/${id}/image`, data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res?.data?.data;
};

export const removeImageFromVariant = async (imageUrl, id) => {
  const res = await axiosInstance.post(
    `/v1/color-variants/${id}/delete-image`,
    { imageUrl }
  );

  return res?.data?.data;
};

export const getProductsByIds = async (ids: number[]) => {
  const res = await axiosInstance.get('/v1/color-variants/by-ids', {
    params: { ids },
  });

  return res.data.data;
};
