import { CategoryType } from 'types/category.types';
import { CollectionType } from 'types/collection.types';
import { SimpleSubcategoryType } from 'types/subcategory.types';
import Collections from '../assets/Images/collections.jpg';
import NewHeaderImage from '../assets/Images/new_header.jpg';
import SaleHeaderImage from '../assets/Images/sale_header.jpg';
import OutletHeaderImage from '../assets/Images/outlet_banner.jpg';
import novo from '../assets/navigationImages/Novo.webp';
import donjiVes from '../assets/navigationImages/donji_ves.webp';
import kolekcije from '../assets/navigationImages/kolekcije.webp';
import lepota from '../assets/navigationImages/lepota.webp';
import muskaKolekcija from '../assets/navigationImages/muska_kolekcija.webp';
import naPopustu from '../assets/navigationImages/na_popustu.webp';
import plazniProgram from '../assets/navigationImages/plazni_program.webp';
import spavaci from '../assets/navigationImages/spavaci.webp';
import outlet from '../assets/navigationImages/Outlet.jpg';

export const PAGES = {
  sale: {
    link: 'na-popustu',
    name: 'Na popustu',
    breadcrumbs: ['na popustu'],
    title: 'THIS IS NEW PAGE',
    image: SaleHeaderImage,
  },
  search: {
    link: 'pretraga',
    name: 'Pretraga',
    breadcrumbs: ['pretraga'],
  },
  new: {
    link: 'novo',
    name: 'Novo',
    breadcrumbs: ['novo'],
    title: 'THIS IS SALE PAGE',
    image: NewHeaderImage,
  },
  outlet: {
    link: 'outlet',
    name: 'Outlet',
    breadcrumbs: ['outlet'],
    title: 'THIS IS NEW PAGE',
    image: OutletHeaderImage,
  },
  collections: {
    link: 'kolekcije',
    name: 'Kolekcije',
    breadcrumbs: ['kolekcije'],
    title: 'THIS IS SALE PAGE',
    image: Collections,
  },
};

export const pages: {
  name: string;
  link: string;
  slug: string;
  linkImage?: string;
  speacialColor?: string;
  id?: number;
  subcategories?: SimpleSubcategoryType[];
  collections?: CollectionType[];
  outletSubmenu?: (CollectionType | CategoryType)[];
}[] = [
  {
    name: 'Novo',
    link: 'novo',
    slug: 'novo',
    linkImage: novo,
  },
  {
    name: 'Donji veš',
    link: 'lingerie',
    slug: 'donji-ves',
    id: 1,
    linkImage: donjiVes,
  },

  {
    name: 'NA POPUSTU',
    link: 'na-popustu',
    slug: 'na-popustu',
    linkImage: naPopustu,
    speacialColor: '#e30040',
  },
  {
    name: 'Kolekcije',
    link: 'kolekcije',
    slug: 'kolekcije',
    id: 20,
    linkImage: kolekcije,
  },
  {
    name: 'PLAŽNI PROGRAM',
    link: 'swimwear',
    slug: 'plazni-program',
    id: 2,
    linkImage: plazniProgram,
  },
  {
    name: 'ZA MUŠKARCE',
    link: 'men',
    slug: 'za-muskarce',

    id: 6,
    linkImage: muskaKolekcija,
  },

  {
    name: 'SPAVAĆI PROGRAM',
    link: 'pyjamas-and-clothes',
    slug: 'spavaci-program',
    id: 3,
    linkImage: spavaci,
  },
  {
    name: 'Lepota',
    link: 'beauty',
    slug: 'lepota',
    id: 4,
    linkImage: lepota,
  },
  {
    name: 'Outlet',
    link: 'outlet',
    slug: 'outlet',
    linkImage: outlet,
    outletSubmenu: [],
  },
];

export function populateSubcategories({
  data,
  collections,
}: {
  data: CategoryType[];
  collections: CollectionType[] | null;
}) {
  const populatedPages = [...pages];
  const collectionId = populatedPages.findIndex((item) => item.id === 20);
  populatedPages[collectionId].collections = collections;
  data?.forEach((category) => {
    const index = populatedPages.findIndex((item1) => item1.id === category.id);
    if (index > -1) {
      populatedPages[index] = {
        ...populatedPages[index],
        ...category,
        subcategories: category.subcategories,
      };
    }
  });

  // populate outlet section
  if (populatedPages[populatedPages.length - 1].link === 'outlet') {
    const selectedCollectionsIds = [2, 3];
    const selectedCategoriesIds = [1, 2, 3];
    const outletSubmenuItems = [];
    collections?.forEach(
      (collection) =>
        selectedCollectionsIds.includes(collection.id) &&
        outletSubmenuItems.push({
          type: 'collection',
          ...collection,
        })
    );
    data?.forEach(
      (category) =>
        selectedCategoriesIds.includes(category.id) &&
        outletSubmenuItems.push({
          type: 'category',
          ...category,
        })
    );
    populatedPages[populatedPages.length - 1] = {
      ...populatedPages[populatedPages.length - 1],
      outletSubmenu: outletSubmenuItems,
    };
  }

  return populatedPages;
}
