import React, { createContext, useState } from 'react';
import { Modal } from './Modal';

export const ModalContext = createContext(null);

export type ModalType = {
  closeModal?: () => void;
};

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState([]);

  const openModal = (content) => {
    setModalContent((prev) => [...prev, content]);
  };

  const closeModal = () => {
    setModalContent((prev) => {
      const newArr = [...prev];
      newArr.pop();
      return newArr;
    });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, modalContent }}>
      {children}
      {modalContent.length > 0 &&
        modalContent.map((modal, index) => (
          <Modal
            key={'modal' + index}
            noOutsideClose={modal?.props?.noOutsideClose}
          >
            {React.cloneElement(modal, { closeModal })}
          </Modal>
        ))}
    </ModalContext.Provider>
  );
};
