import { navigationRoutes } from 'admin/dashboardPageConfig';
import { useAuth } from 'context/AuthContext';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';
import { Sidebar } from './Sidebar';

const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 16rem 1fr;
  grid-template-rows: calc(50px + 1rem) 1fr;
  height: 100%;
`;

const NavbarContainer = styled.div`
  height: 100%;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.borderGray};
  padding-inline: 1rem;
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  padding: 1rem;
  overflow: hidden;
`;

const NavRoutes = () => useRoutes(navigationRoutes);

export const Dashboard = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }
  return (
    <DashboardContainer>
      <Sidebar />
      <NavbarContainer>
        <NavRoutes />
      </NavbarContainer>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </DashboardContainer>
  );
};
