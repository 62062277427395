import FourOFour from 'assets/404.svg';
import { Button } from 'components/Buttons/Button';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const Image = styled.img`
  width: 100%;
  max-width: 30rem;
`;

const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem;

  @media screen and (max-width: 900px) {
    padding: 2rem;
  }
`;

export const NotFound = () => {
  const { width } = useWindowWidth();
  const navigate = useNavigate();
  return (
    <Flex $jc="center">
      <Container>
        <Flex $column $ai="center" $p="1rem" style={{ textAlign: 'center' }}>
          <Image src={FourOFour} />
        </Flex>
        <Flex $bold={700} $fs={1.5} $jc="center">
          OVA STRANICA NE POSTOJI.
        </Flex>
        <Flex $jc="center" $fs={1.2}>
          Izvinjavamo se, nešto je pošlo po zlu... Stranica je verovatno je
          premeštena ili obrisana. Pogledajte neku od interesantnih kategorija.
        </Flex>
        <Flex $jc="space-between" $gap="1rem" $column={width < 900}>
          <Button width="100%" styleType="color" onClick={() => navigate(-1)}>
            Prethodna stranica
          </Button>
          <Button
            width="100%"
            styleType="color"
            onClick={() => navigate('/novo')}
          >
            Novo
          </Button>
          <Button
            width="100%"
            styleType="color"
            onClick={() => navigate('/na-popustu')}
          >
            Na popustu
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
};
