export const CartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M8.001 7c0-2.209 1.791-4 4-4s4 1.791 4 4v2h2v-2c0-3.314-2.686-6-6-6s-6 2.686-6 6v2h2v-2zM20.725 9l-2.519 10.917h-12.409l-2.519-10.917h-2.052l2.802 12.141c0.105 0.454 0.509 0.775 0.974 0.775h14c0.466 0 0.87-0.321 0.974-0.775l2.802-12.141h-2.053z"></path>
    </svg>
  );
};
