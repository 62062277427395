import React from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const Container = styled.div`
  width: 100%;
  max-width: 80vw;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SizeTable = styled.table`
  white-space: nowrap;
  min-width: 0px;
  overflow-x: scroll;

  tr td {
    padding-inline: 1rem;
    text-align: center;
    width: fit-content;
  }
  tr th {
    padding-inline: 1rem;
    text-align: center;
  }
`;

const TableContainer = styled.div<{ $height: string }>`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  min-height: ${({ $height }) => $height};
`;

export const SizesModal = () => {
  return (
    <Container>
      <Flex $noFullHeight $bold={700} $jc="center" $fs={1.2}>
        Vodič za veličine
      </Flex>
      <Flex $column>
        <Flex $noFullHeight $bold={700}>
          Ženske veličine
        </Flex>
        <TableContainer $height="300px">
          <SizeTable>
            <tr>
              <th>Veličina</th>
              <th>Visina</th>
              <th>Obim grudi</th>
              <th>Obim struka</th>
              <th>Obim kukova</th>
            </tr>
            <tr>
              <td>XS</td>
              <td>164-170</td>
              <td>82-86</td>
              <td>62-66</td>
              <td>88-92</td>
            </tr>
            <tr>
              <td>S</td>
              <td>164-170</td>
              <td>86-90</td>
              <td>66-70</td>
              <td>92-96</td>
            </tr>
            <tr>
              <td>M</td>
              <td>170-176</td>
              <td>90-94</td>
              <td>70-74</td>
              <td>96-100</td>
            </tr>
            <tr>
              <td>L</td>
              <td>170-176</td>
              <td>94-98</td>
              <td>74-78</td>
              <td>100-104</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>176-182</td>
              <td>98-102</td>
              <td>78-82</td>
              <td>104-108</td>
            </tr>
            <tr>
              <td>XXL</td>
              <td>176-182</td>
              <td>102-106</td>
              <td>82-86</td>
              <td>108-112</td>
            </tr>
            <tr>
              <td>XXXL</td>
              <td>176-182</td>
              <td>106-110</td>
              <td>86-90</td>
              <td>112-116</td>
            </tr>
          </SizeTable>
        </TableContainer>
        <Flex $noFullHeight $bold={700}>
          Veličine grudnjaka
        </Flex>
        <TableContainer $height="400px">
          <SizeTable>
            <tr>
              <th>Veličina</th>
              <th>Obim ispod poprsja</th>
              <th colSpan={9}>Veličina korpi i obim grudi</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>E</th>
              <th>F</th>
              <th>G</th>
              <th>H</th>
              <th>I</th>
            </tr>
            <tr>
              <td>60</td>
              <td>58-62</td>
              <td>72-74</td>
              <td>74-76</td>
              <td>76-78</td>
              <td>78-80</td>
              <td>80-82</td>
              <td>82-84</td>
              <td>84-86</td>
              <td>86-88</td>
              <td>88-90</td>
            </tr>
            <tr>
              <td>65</td>
              <td>63-67</td>
              <td>77-79</td>
              <td>79-81</td>
              <td>81-83</td>
              <td>83-85</td>
              <td>85-87</td>
              <td>87-89</td>
              <td>89-91</td>
              <td>91-93</td>
              <td>93-95</td>
            </tr>
            <tr>
              <td>70</td>
              <td>68-72</td>
              <td>82-84</td>
              <td>84-86</td>
              <td>86-88</td>
              <td>88-90</td>
              <td>90-92</td>
              <td>92-94</td>
              <td>94-96</td>
              <td>96-98</td>
              <td>98-100</td>
            </tr>
            <tr>
              <td>75</td>
              <td>73-77</td>
              <td>87-89</td>
              <td>89-91</td>
              <td>91-93</td>
              <td>93-95</td>
              <td>95-97</td>
              <td>97-99</td>
              <td>99-101</td>
              <td>101-103</td>
              <td>103-105</td>
            </tr>
            <tr>
              <td>80</td>
              <td>78-82</td>
              <td>92-94</td>
              <td>94-96</td>
              <td>96-98</td>
              <td>98-100</td>
              <td>100-102</td>
              <td>102-104</td>
              <td>104-106</td>
              <td>106-108</td>
              <td>108-110</td>
            </tr>
            <tr>
              <td>85</td>
              <td>83-87</td>
              <td>97-99</td>
              <td>99-101</td>
              <td>101-103</td>
              <td>103-105</td>
              <td>105-107</td>
              <td>107-109</td>
              <td>109-111</td>
              <td>111-113</td>
              <td>113-115</td>
            </tr>
            <tr>
              <td>90</td>
              <td>88-92</td>
              <td>102-104</td>
              <td>104-106</td>
              <td>106-108</td>
              <td>108-110</td>
              <td>110-112</td>
              <td>112-114</td>
              <td>114-116</td>
              <td>116-118</td>
              <td>118-120</td>
            </tr>
            <tr>
              <td>95</td>
              <td>93-97</td>
              <td>107-109</td>
              <td>109-111</td>
              <td>111-113</td>
              <td>113-115</td>
              <td>115-117</td>
              <td>117-119</td>
              <td>119-121</td>
              <td>121-123</td>
              <td>123-125</td>
            </tr>
            <tr>
              <td>100</td>
              <td>98-102</td>
              <td>112-114</td>
              <td>114-116</td>
              <td>116-118</td>
              <td>118-120</td>
              <td>120-122</td>
              <td>122-124</td>
              <td>124-126</td>
              <td>126-128</td>
              <td>128-130</td>
            </tr>
          </SizeTable>
        </TableContainer>
        <Flex $noFullHeight $bold={700}>
          Veličine hulahopki / čarapa
        </Flex>
        <TableContainer $height="200px">
          <SizeTable>
            <tr>
              <th>Veličina</th>
              <th>visina</th>
              <th>Težina (kg)</th>
            </tr>
            <tr>
              <td>1/2</td>
              <td>do 160</td>
              <td>do 57</td>
            </tr>
            <tr>
              <td>3</td>
              <td>160-175</td>
              <td>57-70</td>
            </tr>
            <tr>
              <td>4</td>
              <td>175-185</td>
              <td>70-78</td>
            </tr>
            <tr>
              <td>5</td>
              <td>175-185</td>
              <td>78-90</td>
            </tr>
          </SizeTable>
        </TableContainer>
        <Flex $noFullHeight $bold={700}>
          Tabela veličina za muškarce
        </Flex>
        <TableContainer $height="200px">
          <SizeTable>
            <tr>
              <th>Veličina</th>
              <th>Visina</th>
              <th>Obim grudi</th>
              <th>Obim struka</th>
              <th>Obim kukova</th>
            </tr>
            <tr>
              <td>S</td>
              <td>168-174</td>
              <td>90-94</td>
              <td>80-84</td>
              <td>94-98</td>
            </tr>
            <tr>
              <td>M</td>
              <td>174-180</td>
              <td>94-98</td>
              <td>84-88</td>
              <td>98-102</td>
            </tr>
            <tr>
              <td>L</td>
              <td>180-186</td>
              <td>98-102</td>
              <td>88-92</td>
              <td>102-106</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>186-192</td>
              <td>102-106</td>
              <td>92-96</td>
              <td>106-110</td>
            </tr>
            <tr>
              <td>XXL</td>
              <td>192-198</td>
              <td>106-110</td>
              <td>96-100</td>
              <td>110-114</td>
            </tr>
            <tr>
              <td>XXXL</td>
              <td>192-198</td>
              <td>110-114</td>
              <td>100-104</td>
              <td>114-118</td>
            </tr>
          </SizeTable>
        </TableContainer>
        <Flex $noFullHeight $bold={700}>
          Tabela veličina kupaćih kostima
        </Flex>
        <Flex $noFullHeight $bold={700}>
          Veličine bikinija
        </Flex>
        <TableContainer $height="400px">
          <SizeTable>
            <tr>
              <th>Veličina</th>
              <th>Obim ispod poprsja</th>
              <th>Obim grudi</th>
            </tr>
            <tr>
              <td>65B / 70A</td>
              <td>63-67 / 68-72</td>
              <td>79-81 / 82-84</td>
            </tr>
            <tr>
              <td>70B / 75A</td>
              <td>68-72 / 73-77</td>
              <td>84-86 / 87-89</td>
            </tr>
            <tr>
              <td>70C / 75B</td>
              <td>68-72 / 73-77</td>
              <td>86-88 / 89-91</td>
            </tr>
            <tr>
              <td>75C / 80B</td>
              <td>73-77 / 78-82</td>
              <td>91-93 / 94-96</td>
            </tr>
            <tr>
              <td>75D / 80C</td>
              <td>73-77 / 78-82</td>
              <td>93-95 / 96-98</td>
            </tr>
            <tr>
              <td>70E / 75D</td>
              <td>68-72 / 73-77</td>
              <td>90-92 / 93-95</td>
            </tr>
            <tr>
              <td>70F / 75E</td>
              <td>68-72 / 73-77</td>
              <td>92-94 / 95-97</td>
            </tr>
            <tr>
              <td>70G / 75F</td>
              <td>68-72 / 73-77</td>
              <td>94-96 / 97-99</td>
            </tr>
            <tr>
              <td>70H / 75G</td>
              <td>68-72 / 73-77</td>
              <td>96-98 / 99-100</td>
            </tr>
            <tr>
              <td>80D / 85C</td>
              <td>78-82 / 83-87</td>
              <td>98-100 / 101-103</td>
            </tr>
            <tr>
              <td>80E / 85D</td>
              <td>78-82 / 83-87</td>
              <td>100-102 / 103-105</td>
            </tr>
            <tr>
              <td>80F / 85E</td>
              <td>78-82 / 83-87</td>
              <td>102-104 / 105-107</td>
            </tr>
          </SizeTable>
        </TableContainer>
        <Flex $noFullHeight $bold={700}>
          Jednodelni kupaći
        </Flex>
        <TableContainer $height="300px">
          <SizeTable>
            <tr>
              <th>Veličina</th>
              <th>Obim ispod poprsja</th>
              <th>Obim grudi</th>
              <th>Visina</th>
              <th>Obim struka</th>
              <th>Obim kukova</th>
            </tr>
            <tr>
              <td>S/65C</td>
              <td>63-67</td>
              <td>81-83</td>
              <td>164-170</td>
              <td>66-70</td>
              <td>92-96</td>
            </tr>
            <tr>
              <td>M/70C</td>
              <td>68-72</td>
              <td>86-88</td>
              <td>170-176</td>
              <td>70-74</td>
              <td>96-100</td>
            </tr>
            <tr>
              <td>M/70E</td>
              <td>68-72</td>
              <td>90-92</td>
              <td>170-176</td>
              <td>70-74</td>
              <td>96-100</td>
            </tr>
            <tr>
              <td>L/75C</td>
              <td>73-77</td>
              <td>91-93</td>
              <td>170-176</td>
              <td>74-78</td>
              <td>100-104</td>
            </tr>
            <tr>
              <td>L/75E</td>
              <td>73-77</td>
              <td>95-97</td>
              <td>170-176</td>
              <td>74-78</td>
              <td>100-104</td>
            </tr>
            <tr>
              <td>XL/80E</td>
              <td>78-82</td>
              <td>100-102</td>
              <td>176-182</td>
              <td>78-82</td>
              <td>104-108</td>
            </tr>
            <tr>
              <td>XXL/85E</td>
              <td>83-87</td>
              <td>105-107</td>
              <td>176-182</td>
              <td>82-86</td>
              <td>108-112</td>
            </tr>
          </SizeTable>
        </TableContainer>
        <Flex $noFullHeight $bold={700}>
          Donji deo bikinija
        </Flex>
        <TableContainer $height="200px">
          <SizeTable>
            <tr>
              <th>Veličina</th>
              <th>Visina</th>
              <th>Obim struka</th>
              <th>Obim kukova</th>
            </tr>
            <tr>
              <td>S</td>
              <td>164-170</td>
              <td>66-70</td>
              <td>92-96</td>
            </tr>
            <tr>
              <td>M</td>
              <td>170-176</td>
              <td>70-74</td>
              <td>96-100</td>
            </tr>
            <tr>
              <td>L</td>
              <td>170-176</td>
              <td>74-78</td>
              <td>100-104</td>
            </tr>
            <tr>
              <td>XL</td>
              <td>176-182</td>
              <td>78-82</td>
              <td>104-108</td>
            </tr>
          </SizeTable>
        </TableContainer>
      </Flex>
    </Container>
  );
};
