import { useQuery } from '@tanstack/react-query';
import { getProductsByIds } from 'api/products.api';

export const useCustomIdProducts = ({ ids }: { ids: number[] }) => {
  const { isLoading, error, data } = useQuery<ColorVariantType[]>(
    [...ids],
    () => getProductsByIds(ids)
  );

  return { isLoading, error, data };
};
