import React from 'react';

export const SuccessPaymentIcon = ({ color = null }) => {
  return (
    <svg
      width="180"
      height="220"
      viewBox="0 0 180 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.801 210.425L104.714 188.606M125.379 208.777L112.968 189.582M122.14 191.969L131.24 206.099M132.076 195.665L136.06 201.793M96.7789 188.104L111.836 211.475M104.687 212.299L88.9426 188M81.7085 188.203L97.4757 212.811M90.0981 212.955L74.5419 188.737M67.5653 189.531L82.5583 212.822M74.6931 212.272L60.8573 190.853M54.5403 192.548L66.5584 211.208M57.927 209.439L48.6255 195.037M44 199.417L47.7571 205.455"
        stroke={color || '#18191A'}
        strokeLinecap="round"
      />
      <path
        d="M51.7865 54.4549L132.662 57.7793L151.102 81.68C152.538 83.5417 153.273 85.8499 153.176 88.1992L151.777 122.246L24.629 117.02L26.0143 83.3192C26.1194 80.7615 27.2014 78.3415 29.0374 76.5576L51.7865 54.4549Z"
        fill={color || '#18191A'}
      />
      <path
        d="M48.7875 44.4276L136.643 48.0389L156.866 74.251C158.302 76.1127 159.037 78.4209 158.94 80.7702L157.407 118.069L19.2863 112.392L20.8052 75.4391C20.9104 72.8814 21.9924 70.4614 23.8284 68.6776L48.7875 44.4276Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.041 112.629L152.611 117.873L150.814 161.595C150.587 167.113 145.929 171.402 140.411 171.175L32.8247 166.753C27.3065 166.526 23.017 161.869 23.2438 156.351L25.041 112.629Z"
        stroke={color || '#18191A'}
        strokeWidth="3"
      />
      <rect
        x="71.7754"
        y="74.2854"
        width="37.4392"
        height="95.9089"
        transform="rotate(2.3538 71.7754 74.2854)"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.504 57.0507L152.823 65.9986L30.0491 60.952L38.5569 52.6546L145.504 57.0507Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.5874 43.7723L104.37 44.7909L109.187 75.7343L71.7794 74.1966L79.5874 43.7723Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.484 38.1533C91.4119 43.313 90.2185 57.6869 90.2185 57.6869C76.5025 53.6617 70.3797 45.9103 67.0772 42.6546C62.9577 37.0931 59.2299 28.2332 63.9297 21.3926C68.6295 14.5521 77.9848 12.8167 84.8254 17.5164C91.6659 22.2162 92.1258 27.7415 91.484 38.1533Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.7281 44.7338C90.6802 48.161 89.8875 57.7086 89.8875 57.7086C80.7769 55.0349 76.71 49.8862 74.5164 47.7237C71.78 44.0296 69.304 38.1446 72.4257 33.6009C75.5475 29.0572 81.7615 27.9045 86.3052 31.0262C90.8489 34.1479 91.1544 37.818 90.7281 44.7338Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.2014 38.1015C91.3785 43.1957 90.0635 57.5589 90.0635 57.5589C104.269 55.971 111.643 49.3977 115.459 46.7633C120.48 41.9997 125.686 33.9197 122.243 26.3683C118.799 18.8169 109.886 15.4869 102.335 18.9304C94.7835 22.374 93.3733 27.736 92.2014 38.1015Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.1888 44.613C91.6316 48.0623 90.7412 57.788 90.7412 57.788C100.36 56.7128 105.353 52.2618 107.937 50.4781C111.337 47.2525 114.862 41.7814 112.531 36.6682C110.199 31.555 104.164 29.3001 99.0503 31.6318C93.9372 33.9635 92.9823 37.5943 92.1888 44.613Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.957 77.7632L22.0469 72.3898"
        stroke={color || '#18191A'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
