import { FullContainerLoader } from 'components/Loader/Loader';
import { useColorVariantWithoutCache } from 'hooks/useColorVariantWithoutCache';
import { EditColorVariantForm } from './EditColorVariantForm';

export const EditColorVariant = ({ id, productId }) => {
  const { data, isLoading } = useColorVariantWithoutCache({ id });

  if (isLoading) {
    return <FullContainerLoader />;
  }

  return (
    <EditColorVariantForm
      defaultData={{
        id: id,
        colorName: data.colorName,
        colorHex: data.colorHex,
        images: data.images,
        sizes: data.items,
        setColorVariantIds: data.setVariants?.map((variant) => variant.id),
      }}
      productId={productId}
      id={id}
    />
  );
};
