import { DeliveryMethod } from 'types/orders.types';

export const convertDeliveryMethodToUI = (method: DeliveryMethod) => {
  switch (method) {
    case 'cityExpress':
      return 'City Express';
    default:
      return method;
  }
};
