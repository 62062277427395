import MobileHeader from 'assets/Images/mobile_header.webp';
import robertMobile from 'assets/Images/robert_mobile.webp';
import Slider2Image1 from 'assets/Images/slider_2_image_1.webp';
import Slider2Image2 from 'assets/Images/slider_2_image_2.webp';
import robert from 'assets/Images/robert.webp';
import robertText from 'assets/Images/robert_text.webp';
import valentines from 'assets/Images/donji_ves_slika_1.webp';
import valentinesText from 'assets/Images/dodji_ves_slika_2.webp';
import WebHeader from 'assets/Images/web_header.webp';
import { MobileShowcase } from 'components/ComponentBlocks/MobileShowcase';
import { MobileWideImage } from 'components/ComponentBlocks/MobileWideImage';
import { WebCardSlider } from 'components/ComponentBlocks/WebCardSlider';
import WebShowcase from 'components/ComponentBlocks/WebShowcase';
import { WebTwoImage } from 'components/ComponentBlocks/WebTwoImage';
import { WebWideImage } from 'components/ComponentBlocks/WebWideImage';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { SectionContainer } from 'styles/Containers.styles';
import { StoreLocations } from 'components/ComponentBlocks/StoreLocations';
import { locations } from 'constants/Locations';
import { Helmet } from 'react-helmet';

const Home = () => {
  const { width } = useWindowWidth();
  return (
    <>
      <main
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          paddingBottom: '2rem',
          alignItems: 'center',
        }}
      >
        <Helmet>
          <title>Esotiq</title>
        </Helmet>
        {width > 950 ? (
          <>
            <WebWideImage src={WebHeader} />
            <WebShowcase />
            <SectionContainer style={{ minHeight: '0' }}>
              <StoreLocations locations={locations} visibleItems={2} />
            </SectionContainer>
            <SectionContainer style={{ minHeight: '0' }}>
              <WebTwoImage
                img1={Slider2Image1}
                img2={Slider2Image2}
                to="/kategorija/plazni-program"
              />
            </SectionContainer>
            <SectionContainer style={{ minHeight: '0' }}>
              <WebCardSlider
                ids={[1317, 1318, 1303, 1312, 1311, 1314, 1385]}
                hoverable
              />
              {/* 1314 */}
            </SectionContainer>
            <SectionContainer style={{ minHeight: '0' }}>
              <WebTwoImage img1={valentines} img2={valentinesText} to="/novo" />
            </SectionContainer>
            <SectionContainer style={{ minHeight: '0' }}>
              <WebCardSlider ids={[1321, 1332, 1346, 1361, 1368, 1386]} />
            </SectionContainer>
            <SectionContainer style={{ minHeight: '0' }}>
              <WebTwoImage
                img1={robert}
                img2={robertText}
                to="/kolekcije/robert-kupisz"
              />
            </SectionContainer>
            <SectionContainer style={{ minHeight: '0' }}>
              <WebCardSlider ids={[1125, 1148, 1168, 1179, 1181, 1196]} />
            </SectionContainer>

            {/* <Flex $jc="space-between" $p="10rem 1rem">
            <Button styleType="primary">Text</Button>
            <Button styleType="secondary">Text</Button>
            <Button styleType="outline-light">Text</Button>
            <Button styleType="outline-dark">Text</Button>
            <Button styleType="text">Text</Button>
            <Button styleType="color">Text</Button>
          </Flex> */}
          </>
        ) : (
          <>
            <MobileWideImage imageSrc={MobileHeader} to="/novo" />
            <MobileShowcase />
            <StoreLocations locations={locations} visibleItems={1} />

            <MobileWideImage
              imageSrc={Slider2Image1}
              to="/kategorija/plazni-program"
            />
            <WebCardSlider
              perSlide={2}
              ids={[1317, 1318, 1303, 1312, 1311, 1314, 1385]}
            />
            <MobileWideImage
              imageSrc={Slider2Image2}
              to="/kategorija/plazni-program"
            />

            <MobileWideImage imageSrc={valentines} to="/novo" />
            <WebCardSlider
              perSlide={2}
              ids={[957, 961, 880, 1160, 18, 953, 1020, 1013]}
            />
            <MobileWideImage
              imageSrc={robertMobile}
              to="/kolekcije/robert-kupisz"
            />
            <WebCardSlider
              perSlide={2}
              ids={[1125, 1148, 1168, 1179, 1181, 1196]}
            />
            {/* <WebCardSlider perSlide={2} />
          <MobileWideImage src={Image} />
          <WebCardSlider perSlide={2} /> */}
          </>
        )}
      </main>
    </>
  );
};

export default Home;
