import { CategoryType } from 'types/category.types';
import { CollectionType } from 'types/collection.types';
import { SubcategoryType } from 'types/subcategory.types';

export const generatePageBreadcrumbs = (
  page: CategoryType | SubcategoryType | CollectionType
): { name: string; link: string }[] => {
  const array = [];

  if ('headerImage' in page) {
    array.push({ name: 'kolekcije', link: 'kolekcije' });
    array.push({ name: page.name, link: 'kolekcije/' + page.id });
  } else if ('name' in page) {
    if (page.name === 'Novo') {
      array.push({ name: page.name, link: 'novo' });
    } else if (page.name === 'Na popustu') {
      array.push({ name: page.name, link: 'na-popustu' });
    } else {
      array.push({ name: page.name, link: 'kategorija/' + page.slug });
    }
  } else if ('key' in page) {
    array.push({
      name: page.category.name,
      link: 'kategorija/' + page.category.slug,
    });
    array.push({
      name: page.key,
      link: 'podkategorija/' + page.slug,
    });
  }
  console.log(array);
  return array;
};
