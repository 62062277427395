import { useQuery } from '@tanstack/react-query';
import { getProductDetails } from 'api/products.api';

export const useProduct = ({ id }: { id: string }) => {
  const { isLoading, error, data } = useQuery<Product>(
    ['product', { id }],
    () => getProductDetails(id)
  );

  return { isLoading, error, data };
};
