import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPromoCode } from 'api/promoCodes.api';
import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { Flex, Form } from 'styles/Containers.styles';

export const AddPromoCodeModal = () => {
  const [form, setForm] = useState({
    code: '',
    discount: 10,
  });
  const [error, setError] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const queryClient = useQueryClient();

  const updateMutation = useMutation(() => createPromoCode(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['promoCodes']);
      closeModal();
    },
    onError: () => {
      setError(true);
    },
  });

  const onChange = (prop, value) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };
  return (
    <Flex $column $ai="center">
      <Flex $noFull $fs={1.3} $bold={700}>
        DODAJ PROMO KOD
      </Flex>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          (document.activeElement as HTMLElement).blur();
          if (!form.code) {
            setError(true);
            return;
          }
          updateMutation.mutate();
        }}
      >
        <Flex $column $gap="0">
          <Input
            label="Kod"
            value={form.code}
            name="code"
            autoFocus
            error={error && !form.code}
            errorMessage="Promo kod mora biti unet"
            onValueChange={onChange}
          />
          <Input
            label="Popust"
            value={form.discount}
            name="discount"
            type="number"
            max={100}
            min={1}
            error={error && form.discount < 1}
            errorMessage="Popust mora biti veći od 1%"
            onValueChange={onChange}
          />
        </Flex>
        <Button styleType="color">
          {updateMutation.isLoading ? 'Učitavanje' : 'Sačuvaj'}
        </Button>
      </Form>
    </Flex>
  );
};
