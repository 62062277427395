import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  max-width: 100vw;
  background-color: #18191a;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 3rem;
  gap: 2rem;

  svg {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 950px) {
    gap: 1rem;
    padding: 1rem;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
  gap: 10rem;

  @media screen and (max-width: 950px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    white-space: normal;
  }
`;

export const SmallLink = styled(Link)`
  font-size: 0.875rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: space-between;

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;
    gap: 1rem;
    align-items: center;
  }
`;
