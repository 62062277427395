import { styled } from 'styled-components';

export const CartContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;
  padding-block: 3rem;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 800px) {
    padding: 1rem;
  }
`;

export const CartItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 0 40rem;

  @media screen and (max-width: 800px) {
    flex: 1 0 100%;
  }
`;

export const SummaryContainer = styled.div`
  width: 100%;
  flex: 1 0 25rem;
  max-width: 30rem;
  position: sticky;
  top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;

  @media screen and (max-width: 800px) {
    flex: 1 0 100%;
    max-width: 100%;
  }
`;
