import { Button } from 'components/Buttons/Button';
import { BigImageInput } from 'components/Inputs/BigImageInput';
import { Input } from 'components/Inputs/Input';
import { SmallImageInput } from 'components/Inputs/SmallImageInput';
import { FullLoader } from 'components/Loader/Loader';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from 'styles/Containers.styles';

export const CreateCollectionForm = ({
  defaultHeader = null,
  defaultThumbnail = null,
  defaultName = '',
  mutation,
}) => {
  const [form, setForm] = useState({
    header: defaultHeader,
    thumbnail: defaultThumbnail,
    name: defaultName,
  });

  const onChange = (prop: string, value: any) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };

  return (
    <Flex $column>
      <BigImageInput
        onChange={(image) => onChange('header', image)}
        image={form.header}
      />
      <SmallImageInput
        onChange={(image) => onChange('thumbnail', image)}
        image={form.thumbnail}
        size={18}
      />
      <Input
        name="name"
        value={form.name}
        onValueChange={onChange}
        label="Naziv kolekcije"
      />

      <Flex $noFullHeight $jc="flex-end" $mt="1rem">
        <Button
          onClick={() => {
            if (!form.name || !form.header || !form.thumbnail) {
              toast.error('Molimo Vas unesite sve podatke');
              return;
            }
            mutation.mutate({ ...form });
          }}
          disabled={!form.name || !form.header || !form.thumbnail}
        >
          {mutation.isLoading ? 'Učitavanje' : 'Sačuvaj'}
        </Button>
      </Flex>
      <FullLoader loading={mutation.isLoading} />
    </Flex>
  );
};
