import { useAuth } from 'context/AuthContext';
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
  const { user } = useAuth();

  if (!user) {
    // Redirect to the login page if the user is not logged in

    return <Navigate to="/profile/login" replace={true} />;
  }

  return children;
};

export default ProtectedRoute;
