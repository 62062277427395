export const CloseIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
      color={color || 'black'}
    >
      <path d="M18.707 6.707l-1.414-1.414-5.293 5.293-5.293-5.293-1.414 1.414 5.293 5.293-5.293 5.293 1.414 1.414 5.293-5.293 5.293 5.293 1.414-1.414-5.293-5.293 5.293-5.293z"></path>
    </svg>
  );
};
