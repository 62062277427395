import { Checkbox } from 'components/Inputs/Checkbox';
import { PriceRangePicker } from 'components/Inputs/PriceRangePicker';
import { OptionSelect } from 'components/SelectOpstions/OptionSelect';
import { useIsFilterActive } from 'hooks/useIsFilterActive';
import { useParamsSearch } from 'hooks/useParamsSearch';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';
import { CloseIcon } from '../../assets/CloseIcon';
import { Flex } from '../../styles/Containers.styles';
import { IconButton } from '../Buttons/IconButton';
import { FilterExpandableItem } from './FilterExpandableItem';
import { SizeItem } from './SizeItem';

const options = [
  {
    name: 'Uobičajno',
    value: null,
  },
  {
    name: 'Najnovije',
    value: 'newest',
  },
  {
    name: 'Cena opadajuća',
    value: 'priceHighToLow',
  },
  {
    name: 'Cena rastuća',
    value: 'priceLowToHigh',
  },
  { name: 'Sniženje', value: 'sale' },
];

const FilterContainer = styled.div<{
  $opened: boolean;
}>`
  width: ${({ $opened }) => ($opened ? '30rem' : '0')};
  transition: 200ms all ease-in;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: scroll;

  @media screen and (max-width: 1000px) {
    position: fixed;
    top: 0;
    right: 0;
    width: ${({ $opened }) => ($opened ? '100%' : '0')};
    background-color: white;
    height: 100svh;
  }
`;

const SizesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1rem;
`;

type Props = {
  opened: boolean;
  closeFilter: () => void;
  filter: FilterType;
  initialFilters: FilterType;
  isLoading: boolean;
};

export const ProductFilter = ({
  opened,
  closeFilter,
  filter,
  initialFilters,
  isLoading,
}: Props) => {
  const {
    searchParams,
    handleArraySearchParam,
    handleSetUrlSearchParams,
    clearSearchParams,
  } = useParamsSearch();
  const activeFilter = useIsFilterActive();
  const { width } = useWindowWidth();

  return (
    <FilterContainer $opened={opened}>
      <Flex $ai="center" $p="0 1rem 0 0" $noFullHeight>
        <Flex $p="0.5rem 0" $ai="center" $bold={700} $noFullHeight>
          <IconButton onClick={closeFilter}>
            <CloseIcon />
          </IconButton>
          Filteri
        </Flex>
        {activeFilter && (
          <Flex
            $jc="flex-end"
            $textcolor={theme.colors.primary}
            $pointer
            onClick={clearSearchParams}
            $ai="center"
          >
            Ukloni Filtere
          </Flex>
        )}
      </Flex>
      <Flex $column $noFullHeight $gap="0">
        {width < 1000 && (
          <FilterExpandableItem title="SORTIRAJ">
            <Flex $p="0 1rem">
              <OptionSelect
                options={options}
                renderTitle={(option) => option.name}
                selectedRule={(option) =>
                  searchParams.get('sortBy') === option.value
                }
                onSelect={(option) => {
                  handleSetUrlSearchParams('sortBy', option.value);
                }}
              />
            </Flex>
          </FilterExpandableItem>
        )}

        <FilterExpandableItem title="Veličine">
          <SizesContainer>
            {initialFilters?.sizes?.map((size) => (
              <SizeItem
                key={size.name}
                title={size.name}
                disabled={
                  !filter?.sizes.some((s) => s.name === size.name) || isLoading
                }
                active={searchParams
                  ?.get('sizes')
                  ?.split(',')
                  .includes(size.name)}
                onClick={() => handleArraySearchParam('sizes', size.name)}
              />
            ))}
          </SizesContainer>
        </FilterExpandableItem>
        {initialFilters?.attributes.map((attribute) => {
          const filterReference = filter?.attributes.find(
            (at) => at.id === attribute.id
          );
          return (
            <FilterExpandableItem key={attribute.id} title={attribute.name}>
              <Flex $p="0 0.875rem" $column>
                {attribute.values.map((value) => (
                  <Checkbox
                    text={value.name}
                    disabled={
                      !filterReference?.values.some(
                        (at) => at.id === value.id
                      ) || isLoading
                    }
                    checked={searchParams
                      ?.get('attributeValueIds')
                      ?.split(',')
                      .includes(value.id.toString())}
                    key={value.name}
                    onChange={() =>
                      handleArraySearchParam(
                        'attributeValueIds',
                        value.id.toString()
                      )
                    }
                  />
                ))}
              </Flex>
            </FilterExpandableItem>
          );
        })}
        <FilterExpandableItem title="Boje">
          <Flex $p="0 0.875rem" $column>
            {initialFilters?.colors.map((color) => (
              <Checkbox
                text={color.name}
                checked={searchParams
                  ?.get('colors')
                  ?.split(',')
                  .includes(color.name)}
                key={color.name}
                onChange={() => handleArraySearchParam('colors', color.name)}
              />
            ))}
          </Flex>
        </FilterExpandableItem>
        {initialFilters?.subcategories.length > 0 && (
          <FilterExpandableItem title="Kategorije">
            <Flex $p="0 0.875rem" $column>
              {initialFilters?.subcategories.map((subcategory) => (
                <Checkbox
                  text={subcategory.name}
                  checked={searchParams
                    ?.get('subcategoryIds')
                    ?.split(',')
                    .includes(subcategory.id.toString())}
                  key={subcategory.id}
                  onChange={() =>
                    handleArraySearchParam(
                      'subcategoryIds',
                      subcategory.id.toString()
                    )
                  }
                />
              ))}
            </Flex>
          </FilterExpandableItem>
        )}

        <FilterExpandableItem title="Cenovni raspon">
          <Flex $p="0 0.875rem" $column>
            <Flex $p="0 1rem 0 0rem">
              {filter?.priceRange && (
                <PriceRangePicker
                  key={filter?.priceRange?.min + filter?.priceRange?.max}
                  min={filter?.priceRange?.min}
                  max={filter?.priceRange?.max}
                  onMinChange={(min, max) => {
                    handleSetUrlSearchParams('maxPrice', max);
                    handleSetUrlSearchParams('minPrice', min);
                  }}
                  onMaxChange={(max, min) => {
                    handleSetUrlSearchParams('minPrice', min);
                    handleSetUrlSearchParams('maxPrice', max);
                  }}
                />
              )}
            </Flex>
          </Flex>
        </FilterExpandableItem>
      </Flex>
    </FilterContainer>
  );
};
