import { Link } from 'react-router-dom';

export const options: {
  text?: string;
  title?: string;
  lines?: React.ReactElement[];
}[] = [
  { title: 'Opšti uslovi online kupovine' },
  {
    lines: [
      <p key={0}>
        <b>Uslovi online kupovine</b>
      </p>,
      <p key={1}>
        Za uslove online kupovine primenjuje se Zakon o zaštiti potrošača
        (&quot;Službeni glasnik RS&quot;, br. 62/2014, 6/2016 i 44/2018, u
        daljem tekstu: &quot;Zakon&quot;), Odeljak IV. - Zaštita potrošača u
        ostvarivanju prava iz ugovora na daljinu i ugovora koji se zaključuju
        izvan poslovnih prostorija, od člana 26. do člana 36. i član12. ovog
        Zakona.
      </p>,
      <p key={2}>
        Objavljivanjem Opštih uslova online kupovine (u daljem tekstu:
        „Uslovi“), prodavac je ispunio dužnost obaveštavanja potrošača u skladu
        sa članom 26. i čl. 12 Zakona. Informacije iz navedenih članova Zakona
        trgovac pruža potrošaču pre sklapanja ugovora, u skladu sa članom 30
        Zakona. U svakom trenutku, potrošač može zatražiti ove informacije putem
        e-maila na{' '}
        <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>.
      </p>,
    ],
  },
  { title: 'Uslovi' },
  {
    lines: [
      <p key={0}>
        <b>Trgovac</b>
      </p>,
      <p key={1}>
        Trgovac je DM Star Consulting D.O.O. – Ogranak 2 Esotiq sa sedištem u
        Beogradu, opština Zvezdara, Ul. Bulevar Kralja Aleksandra 458/32 11127 ,
        MB: 21430552, PIB: 111132614.
      </p>,
      <p key={2}>
        <b>Potrošač</b>
      </p>,
      <p key={3}>
        Potrošač je svako ko poseti online prodavnicu Esotiq, odabere bar jedan
        proizvod, naruči ga i plati prilikom dostave, uplatnicom ili karticom.
      </p>,
      <p key={4}>
        <b>Proces naručivanja</b>
      </p>,
      <p key={5}>
        Naručivanje u internet prodavnici Trgovca se vrši dodavanjem proizvoda u
        korpu. Narudžbinu je mogućeponištiti u svakom trenutku, u skladu sa
        Uslovima. Pre naručivanja,potrošač treba da potvrdi da je upoznat sa
        Uslovima objavljenim na sajtu Trgovca na adresi{' '}
        <Link to="/">https://esotiq.rs/</Link>.
      </p>,
      <p key={6}>
        Kupoprodajni ugovor se smatra zaključenim kada Trgovac šalje Potrošaču
        potvrdu o prijemu porudžbine, potvrđujući time da je proizvod uspešno
        naručen.
      </p>,
      <p key={7}>
        Narudžbinu može izvršiti i registrovani korisnik i neregistrovani
        korisnik. Registracija nije obavezna za kupovinu.
      </p>,
      <p key={8}>
        Ako Trgovac ne može da isporučinaručeni proizvod jer ga nema na lageru,
        dužan je da u roku od 24h obavesti potrošača putem e-maila. U tom
        slučaju,potrošač može da otkaže kupoprodajni ugovor ili da prihvati novi
        rok isporuke. Ako potrošač otkaže kupoprodajni ugovor, Trgovac je dužan
        da vrati novac uvećan za iznos zakonske zatezne kamate, u skladu sa
        odredbama Zakona o visini zatezne kamate u roku od 14 dana od momenta
        prijema{' '}
        <a href="https://esotiq-prod.fra1.digitaloceanspaces.com/OBRAZAC-ZA-ODUSTANAK-OD-UGOVORA-ESOTIQ.docx">
          obrasca za odustanak
        </a>
        , a što trgovac potvrđuje slanjem obaveštenja na e-mail potrošača.
      </p>,
      <p key={9}>
        Trgovac je obavezan da vrati novac koristeći isti način plaćanja koji je
        potrošač prvobitno upotrebio, osim ako potrošač jasno ne pristane na
        drugi način plaćanja, uz garanciju da zbog tog povrata neće imati
        dodatne troškove.
      </p>,
      <p key={10}>
        Ako potrošač odbije preuzimanje proizvoda koji je naručio i koji je u
        ispravnom i neoštećenom stanju, trgovac ima pravo tražiti od potrošača
        da pokrije sve troškove povezane s dostavom tog proizvoda.
      </p>,
      <p key={11}>
        Trgovac ima pravo da ne sklopi kupoprodajni ugovor ili da ne proda
        proizvod potrošaču ako je jasno da je prikazana cena nerealna u odnosu
        na stvarnu vrednost proizvoda, te da je takva cena posledica očigledne
        greške u sistemu ili greške prouzrokovane ljudskim faktorom.
      </p>,
      <p key={12}>
        Prikazane cene na sajtu su maloprodajne cene sa uračunatim PDV-om. Cene
        na sajtu mogu se razlikovati od cena u fizičkim prodavnicama Trgovca.
      </p>,
      <p key={13}>
        Trgovac je dužan da održava sajt u skladu sa standardima industrije i
        nije odgovoran za kvalitet internet konekcije potrošača.
      </p>,
      <p key={14}>
        <b>Uslovi isporuke</b>
      </p>,
      <p key={15}>
        Korisnički servis trgovca radi od 9h do 17h radnim danima. Proizvode
        naručene online šaljemo u roku od tri radna dana, dok tokom sniženja rok
        može biti pet radnih dana.
      </p>,
      <p key={16}>Isporuka pokriva celu Srbiju osim Kosova i Metohije.</p>,
      <p key={17}>Vreme isporuke zavisi od momenta naručivanja.</p>,
      <p key={18}>
        Ako naručite proizvod u petak slanje proizvoda vrši se u ponedeljak,
        isporuka potrošaču je u utorak između 07h i 16h.
      </p>,
      <p key={19}>
        Rokovi se računaju od trenutka kada trgovac primi porudžbinu (kod
        plaćanja pouzećem) ili kada primi uplatu, odnosno od momenta kada bude
        odobrena autorizacija platne kartice, kada je potrošač optirao za druge
        modele plaćanja proizvoda.
      </p>,
      <p key={20}>
        Vikend se ne računa u rokove dostave. Maksimalni rok isporuke je 30 dana
        od zaključenja ugovora.
      </p>,
      <p key={21}>
        Trošak isporuke je fiksan i iznosi 310 dinara, za narudžbine preko 5 000
        rsd dostava je besplatna.
      </p>,
      <p key={23}>
        Isporuku vrši CITY EXPRESS. U cilju efikasnosti i kvalitetnije
        komunikacije izmeđupotrošača i dostavljača,administratori internet
        prodavnice trgovca kurirskoj službi daju i broj telefona koji je
        potrošač naveo prilikom naručivanja proizvoda. Pored navedenog, potrošač
        u slučaju reklamacije zbog nesaobraznosti, može robu poslati trgovcu
        preko pomenute kurirske službe CITY EXPRESS, o trošku trgovca.
      </p>,
      <p key={24}>
        Kada trgovac preda proizvod kuriru, obavestiće Vas putem emaila.
        Kurirska služba isporučuje radnim danima od 07h do 16h. Na dan isporuke
        naručenog proizvoda potrošačće dobiti SMS obaveštenje nakon čegaće ga
        kontaktirati ovlašćeno lice iz kurirske službe.Ako prva isporuka ne
        uspe, kurir će Vas kontaktirati i dogovoriti novi termin. Ako ni druga
        isporuka ne uspe, proizvod se vraća trgovcu koji ćestupiti u kontakt s
        potrošačem, utvrditi razloge za povrat proizvoda i, ako je potrebno,
        ugovoriti novi datum isporuke..
      </p>,
      <p key={25}>
        Proizvodi su zaštićeni pakovanjem prilikom transporta, tako da se
        prilikom rukovanja u transportu ne mogu oštetiti. Proverite da li na
        pakovanju ili samom proizvodu ima oštećenja. Ako primetite
        oštećenjaimate pravo da odbijete prijem i o tome obavestiti trgovca .
      </p>,
      <p key={26}>
        Ako pakovanje nije oštećeno,potrošač preuzima artikl i potpisuje
        dokument (slip) na kojem su navedeni detalji o pošiljaocu, primaocu i
        ceni.
      </p>,
      <p key={27}>
        <b>Zaštita ličnih podataka</b>
      </p>,
      <p key={28}>
        U ime DM Star-a obavezujemo se da ćemočuvati privatnost svih naših
        kupaca. Prikupljamo samo neophodne, osnovne podatke o kupcima/
        korisnicima i podatke neophodne za poslovanje i informisanje korisnika u
        skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne
        usluge. Dajemo kupcima mogućnost izbora uključujućimogućnost odluke da
        li žele ili ne da se izbrišu sa mailing lista koje se koriste za
        marketinške kampanje. Svi podaci o korisnicima/kupcima se strogo čuvaju
        i dostupni su samo zaposlenima kojima su ti podaci nužni za obavljanje
        posla. Svi zaposleni [ime prodajnog mesta] (i poslovni partneri)
        odgovorni su za poštovanje načela zaštite privatnosti.
      </p>,
      <p key={29}>
        Trgovac prikuplja podatke u skladu sa vrstom usluge koju pruža
        potrošačima i zakonskim odredbama o zaštiti podataka. Ovo uključuje
        osnovne podatke bez kojih trgovac ne može da izvrši svoje obaveze: ime i
        prezime,adresa, br. Telefona, email adresa, podatke o korišćenju sajta i
        webshopa, i druge relevantne informacije. Više informacija možete
        pronaći u <Link to="/privacy-policy">Politici privatnosti</Link>.
      </p>,
      <p key={30}>
        <b>Načini plaćanja</b>
      </p>,
      <p key={31}>Naručene proizvode možete platiti:</p>,
      <ul key={32} style={{ listStyle: 'numeric', marginLeft: '1rem' }}>
        <li key={'list' + 1}>pouzećem</li>
        <li key={'list' + 2}>platnim karticama</li>
      </ul>,
      <p key={33}>Ako plaćatepouzećem,plaćate kuriru prilikom preuzimanja.</p>,
      // <p key={34}>
      //   Ako plaćate uplatnicom, proizvod se šalje nakon što trgovac primi
      //   uplatu.
      // </p>,
      <p key={35}>
        Ako placate platnom karticom, nakon popunjavanja narudžbine, i optiranja
        načina plaćanja platnom karticom, potrošačće biti automatski preusmeren
        na internet prezentaciju banke Banca Intesa AD Beograd gde je potrebno
        uneti podatke sa platne kartice, prilikom unošenja podataka o platnoj
        kartici, poverljive informacija se prenose putem javne mreže u
        zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema,
        kao trenutno najsavremenije kriptografske tehnologije. Sigurnost
        podataka prilikom kupovine, garantuje procesor platnih kartica, Banca
        Intesa AD Beograd, pa se tako kompletni proces naplate obavlja na
        stranicama banke. Niti jednog trenutka podaci o platnoj kartici nisu
        dostupni našem sistemu. Možete koristiti Visa, MasterCard ili Maestro
        kartice.
      </p>,
      <p key={36}>
        Ako transakcija ne prođe, sredstva na korisničkomračununeće biti
        rezervisana i narudžbina će se tretirati kao neizvršena. Korisnik može
        pokušati ponovno da plati karticom ili izabrati drugi način plaćanja.
      </p>,
      <p key={37}>
        Proces obrade narudžbenice započinje kada trgovac primeti uplatu na svom
        računu ili, u slučaju da je korisnik izabrao plaćanjepouzećem, odmah po
        prijemu narudžbine.
      </p>,
      <p key={100}>
        <b>Izjava o PDV</b>
      </p>,
      <p key={101}>PDV uračunat u cenu i nema skrivenih troškova.</p>,
      <p key={102}>
        <b>Izjava o konverziji</b>
      </p>,
      <p key={103}>
        All payments will be effected in Serbian currency – dinar (RSD). The
        amount your credit card account will be charged for is obtained through
        the conversion of the price in Euro into Serbian dinar according to the
        current exchange rate of the Serbian National Bank. When charging your
        credit card, the same amount is converted into your local currency
        according to the exchange rate of credit card associations. As a result
        of this conversion there is a possibility of a slight difference from
        the original price stated in our web site.
      </p>,
      <p key={104}>
        Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar
        (RSD).Za informativni prikaz cena u drugim valutama koristi se srednji
        kurs Narodne Banke Srbije. Iznos za koji će biti zadužena Vaša platna
        kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po
        kursu koji koriste kartičarske organizacije, a koji nama u trenutku
        transakcije ne može biti poznat. Kao rezultat ove konverzije postoji
        mogućnost neznatne razlike od originalne cene navedene na našem sajtu.
      </p>,
      <p key={105}>
        <b>Povraćaj sredstava</b>
      </p>,
      <p key={106}>
        U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno
        platio nekom od platnih kartica, delimično ili u celosti, a bez obzira
        na razlog vraćanja, [upisati IME_PRODAJNOG_MESTA] je u obavezi da
        povraćaj vrši isključivo preko VISA, EC/MC, Maestro, Amex i Dina metoda
        plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj
        sredstava na račun korisnika kartice.
      </p>,
      <p key={38}>
        <b>Reklamacije i vraćanje robe</b>
      </p>,
      <p key={39}>
        Potrošač ima pravo da vrati robu ako je reč o isporuci robe koju nije
        naručio ili ne odgovara onome što je poručio.
      </p>,
      <p key={40}>
        <b>Zamena proizvoda koji je predmet reklamacije</b>
      </p>,
      <p key={41}>
        Prodavac je dužan da korisniku dostavi proizvod koji je u skladu sa
        dogovorom.Smatra se da je isporučena roba saobrazna ugovoru:
      </p>,
      <ul key={42} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li key={'list' + 1}>
          Ako se proizvod poklapa s opisom koji je prodavac naveo i ako poseduje
          karakteristike proizvoda koje je prodavac prezentovao korisniku kao
          uzorak ili model.
        </li>
        <li key={'list' + 2}>
          ako ima svojstva potrebna za naročitu upotrebu za koju je potrošač
          nabavlja, a koja je bila poznata trgovcu ili mu je morala biti poznata
          u vreme zaključenja kupoprodajnog ugovora;
        </li>
        <li key={'list' + 3}>
          Ako ima karakteristike koje su uobičajene za redovnu upotrebu
          proizvoda te vrste.
        </li>
        <li key={'list' + 4}>
          Ako po kvaliteti i performansama odgovara standardima koji su tipični
          za proizvode te vrste i što korisnik može razumno da očekuje,uzimajući
          u obzir prirodu proizvoda i javne izjave o specifičnimkarakteristikama
          proizvoda koje daju prodavac, proizvođač ili njihovi zastupnici,
          posebno ako je izjava data putem reklame ili na pakovanju proizvoda.
        </li>
      </ul>,
      <p key={43}>
        Trgovac je odgovaran i za nesaobraznost nastalu usled nepravilnog
        pakovanja.
      </p>,
      <p key={44}>
        Trgovac odgovara za nesaobraznost robe ugovoru, koja je postojala u času
        prelaska rizika na potrošača, bez obzira na to da li je za nesaobraznost
        znao. Trgovac odgovara i za nesaobraznost robe ugovoru koja se pojavi
        posle prelaska rizika na potrošača, ako potiče od uzroka koji je
        postojao pre tog momenta, kao i ako je potrošač mogao lako uočiti
        nesaobraznost, a trgovac izjavio da je roba saobrazna kupoprodajnom
        ugovoru.
      </p>,
      <p key={45}>
        Trgovac ne odgovara za nesaobraznost robe ugovoru ako je u
        časuzaključenja kupoprodajnog ugovora potrošaču bilo poznato ili mu nije
        moglo ostati nepoznato da roba nije saobrazna ugovoru.
      </p>,
      <p key={46}>
        Trgovac je odgovoran za nesaobraznost robe ugovoru koja se pojavi u roku
        od 2 (dve) godine od dana prelaska rizika na potrošača, odnosno od dana
        predaje robe potrošaču. Ako nesaobraznost nastane u roku od 6 (šest)
        meseci od dana prelaska rizika na potrošača, pretpostavlja se da je
        nesaobraznost postojala u trenutku prelaska rizika, osim ako je to u
        suprotnosti sa prirodom robe i prirodom određene nesaobraznosti.
        Navedeni rokovi ne teku u periodu koji trgovac koristi za otklanjanje
        nesaobraznosti.
      </p>,
      <p key={47}>
        Kupac može podneti prigovor popunjavanjem odgovarajućeg{' '}
        <a href="https://esotiq-prod.fra1.digitaloceanspaces.com/reklamacioni-list_Esotiq.docx">
          formulara za reklamaciju
        </a>{' '}
        koji se može poslati prodavcu putem e-maila na{' '}
        <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>{' '}
        ili poštom na adresu: Ul. Bulevar Kralja Aleksandra 458/32
        11127,Beograd. Prodavac je obavezan da prihvati podneti prigovor. Nakon
        prijema prigovora, prodavac će odmah, ali najkasnije u roku od 8 (osam)
        dana, odgovoriti kupcu pisanim ili elektronskim putem. U svom odgovoru,
        prodavac treba da navede da li prihvata prigovor, razloge ukoliko ga ne
        prihvata, izjašnjenje o zahtevu potrošača o načinu rešavanja i konkretan
        predlog u kom roku će i kako će rešiti reklamaciju ukoliko je prihvata.
        Odluka o rešenju prigovora ne sme biti duža od 15 (petnaest) dana od
        dana kada je prigovor podnet.
      </p>,
      <p key={48}>
        Prodavac je obavezan da postupa prema predloženom rešenju prigovora, ali
        samo ako je dobio saglasnost kupca. Period za rešavanje prigovora se
        prekida kada kupac dobije odgovor od prodavca i ponovo počinje kada
        prodavac dobije povratnu informaciju od kupca. Kupac treba da odgovori
        na prodavčevu poruku najkasnije u roku od 3 (tri) dana od dana kada je
        primio odgovor. Ako kupac ne odgovori u navedenom roku, smatraće se da
        se ne slaže sa prodavčevim predlogom.
      </p>,
      <p key={49}>
        Ako prodavac iz određenih razloga ne može da ispuni zahtev kupca u
        određenom vremenskom roku, mora pisanim putem obavestiti kupca o
        produžetku roka za rešavanje prigovora i navede novi rok za njegovo
        rešavanje, takođe mora dobiti saglasnost kupca i zabeležiti to u
        evidenciji o prigovorima. Rok za rešavanje prigovora može se produžiti
        samo jedanput.
      </p>,
      <p key={50}>
        U slučaju da trgovac usmeno izjavljenu reklamaciju reši u skladu sa
        zahtevom potrošača prilikom njenog izjavljivanja, nije dužan da
        potrošaču bez odlaganja izda pisanu potvrdu ili elektronskim putem
        potvrdi prijem reklamacije, odnosno saopšti broj pod kojim je zavedena
        njegova reklamacija u evidenciji primljenih reklamacija niti da mu bez
        odlaganja a najkasnije u roku od 8 (osam) dana od prijema reklamacije
        pisanim ili elektronskim putem odgovori na izjavljenu reklamaciju.
      </p>,
      <p key={51}>
        Nedostatak originalne ambalaže ne može biti prepreka za rešavanje
        prigovora niti osnova za odbijanje ispravke nesaobraznosti od strane
        prodavca.
      </p>,
      <p key={52}>
        Ukoliko trgovac odbije reklamaciju, dužan je da potrošača obavesti o
        mogućnosti rešavanja spora vansudskim putem i o nadležnim telima za
        vansudsko rešavanje potrošačkih sporova.
      </p>,
      <p key={53}>
        Za slučaj da se pri izjašnjavanju o osnovanosti reklamacije utvrdi da
        isporučeni proizvod nije u skladu sa ugovorom o prodaji, kupac može
        zahtevati od prodavca da besplatno ispravi nesaobraznost popravkom ili
        zamenom, ili može tražiti odgovarajuće smanjenje cene ili raskid ugovora
        za tu robu.
      </p>,
      <p key={54}>
        Kupac ima pravo da prodavac ispravi nesaobraznost proizvoda putem
        popravke ili zamene, bez dodatnih troškova za kupca.
      </p>,
      <p key={55}>
        Ako otklanjanje nesaobraznosti na napred opisan način nije
        moguće,potrošač ima pravo da zahteva odgovarajuće umanjenje cene ili
        raskid ugovora ako:
      </p>,
      <ul key={56} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li key={'list' + 1}>
          Nesaobraznost se ne može ispraviti ni popravkom ni zamenom, ili to
          nije moguće u razumnom vremenskom periodu;
        </li>
        <li key={'list' + 2}>
          Kupac ne može ostvariti pravo na popravku ili zamenu, odnosno ako
          trgovac nije izvršio popravku ili zamenu u primerenom roku;
        </li>
        <li key={'list' + 3}>
          opravka ili zamena ne može da se sprovede bez značajnijih nepogodnosti
          za potrošača zbog prirode robe i njene namene;
        </li>
        <li key={'list' + 4}>
          Otklanjanje nesaobraznosti putem popravke ili zamene bi predstavljalo
          nerealno opterećenje za prodavca. (Nesrazmerno opterećenje za prodavca
          nastaje kada se u poređenju sa smanjenjem cene ili prekidom ugovora,
          stvaraju prekomerni troškovi, uzimajući u obzir vrednost proizvoda
          koju bi imao da je u skladu sa ugovorom, značaj saobraznosti u datoj
          situaciji i mogućnost otklanjanja saobraznosti bez većih neugodnosti
          za kupca).
        </li>
      </ul>,
      <p key={57}>
        Potrošač ne može da raskine ugovor ako je nesaobraznost robe neznatna.
      </p>,
      <p key={58}>
        Prodavac će pristupiti otklanjanju utvrđene nesaobraznosti nakon što oba
        potpišu reklamacioni zapisnik, putem kojeg kupac daje odobrenje da se
        navedena nesaobraznost reši na jedan od prethodno navedenih načina.
      </p>,
      <p key={59}>
        U skladu s karakteristikama proizvoda i svrhom zbog koje ga je kupac
        nabavio, popravka ili zamena treba da se obavi u odgovarajućem
        vremenskom periodu bez većih neprijatnosti za kupca i uz njegovo
        odobrenje. Svi neophodni troškovi da bi se roba saobrazila ugovoru,
        posebno troškovi rada, materijala, preuzimanja i dostave, padaju na
        teret prodavca.
      </p>,
      <p key={60}>
        Ako se neusklađenost pojavi unutar šest meseci od trenutka kada je rizik
        prešao na kupca, kupac može da bira između zahteva da se nesaobraznost
        otkloni zamenom, odgovarajućim umanjenjem cene ili da izjavi da raskida
        ugovor. Ako se neusklađenost javi unutar šest meseci od prelaska rizika
        na kupca, ispravka je moguća popravkom uz eksplicitno odobrenje kupca. U
        slučaju obaveza prema kupcu zbog neusklađenosti proizvoda, prodavac ima
        pravo tražiti od proizvođača u lancu snabdevanja te robe nadoknadu za
        ispunjene obaveze. Prava koja kupac ima zbog neusklađenostirobe ne utiču
        na njegovo pravo da traži odštetu od prodavca zbog neusklađenosti, u
        skladu s opštim pravilima o odgovornosti za štetu.
      </p>,
      <p key={61}>
        Trgovac je odgovoran za nesaobraznost robe ugovoru koja se pojavi u roku
        od dve godine od dana prelaska rizika na potrošača. Ako nesaobraznost
        nastane u roku od šest meseci od dana prelaska rizika na potrošača,
        pretpostavlja se da je nesaobraznost postojala u trenutku prelaska
        rizika, osim ako je to u suprotnosti sa prirodom robe i prirodom
        određene nesaobraznosti. Teret dokazivanja da nije postojala
        nesaobraznost snosi trgovac. Pomenuti rokovi ne teku u periodu koji
        prodavac koristi za otklanjanje nesaobraznosti.Na odnose između trgovca
        i kupca-pravnog lica, u vezi sa ostvarivanjem prava u
        slučajunesaobraznosti primenjuju se odredbe Zakona o obligacionim
        odnosima. Tako uočenemanjkavosti/nesaobraznosti/nedostaci na robi moraju
        da se prijave već prilikom primopredaje, odnosno isporuke, uz priloženi
        račun za kupljenu robu, a u slučaju bez odlaganja po uočavanju te
        manjkavosti, uz priloženi račun za kupljenu robu slanjem obaveštenja u
        pisanoj formi, poštom, uz naznaku &quot;preporučeno.&quot;
      </p>,
      <p key={62}>
        <b>Odustanak od ugovora</b>
      </p>,
      <p key={63}>
        Kupac ima pravo da odustane od ugovora sklopljenog na daljinu bez
        potrebe da navede razlog, u roku od 14 (četrnaest) dana. Rok za
        odustanak od ugovora počinje od dana kada je kupac dobio proizvod. Kupac
        treba da obavesti prodavca o svojoj nameri da odustane od ugovora u
        pisanom obliku. To može postićikorišćenjemobrasca za povlačenje iz
        ugovora na daljinu (koji može poslati na fizičku ili elektronsku adresu
        prodavca). Ugovor se smatra otkazanim kada kupac pošalje prodavcu
        ispunjen{' '}
        <a href="https://esotiq-prod.fra1.digitaloceanspaces.com/OBRAZAC-ZA-ODUSTANAK-OD-UGOVORA-ESOTIQ.docx">
          obrazac za povlačenje iz ugovora
        </a>
        . Prodavac je obavezan da bez odlaganja potvrdi kupcu prijem obrasca
        putem e-maila. Ako se ugovor raskine, kupac mora o svom trošku vratiti
        proizvod prodavcu ili osobi koju prodavac ovlasti, najkasnije u roku od
        14 (četrnaest) dana od dana slanja popunjenog obrasca za povlačenje od
        ugovora. Kupac ima obavezu da snosi direktne troškove
        povraćajaproizvoda, osim ako prodavac nije pristao da preuzme troškove
        na sebe ili ako nije unapred obavestio kupca da će on biti u obavezi da
        ih plati.
      </p>,
      <p key={64}>
        <b>Napomena</b>
      </p>,
      <p key={65}>
        Pravo na povlačenje iz ugovora ne primenjuje se na kupovinu u fizičkim
        prodavnicama, jer se ne tretira kao daljinska kupovina ili kupovina van
        poslovnih objekata. Povrat je moguć samo za artikle koji nisu oštećeni
        i, gde je to izvodljivo u originalnoj ambalaži, sa svim pripadajućim
        dodacima i pratećom dokumentacijom.
      </p>,
      <p key={66}>
        Kada trgovac primi proizvod, proverava se da li je proizvod oštećen.
        Kupac je jedini odgovoran za smanjenje vrednosti robe koja proizlazi iz
        neodgovarajuće upotrebe, odnosno prevazilazi ono što je neophodno da bi
        se ustanovila priroda karakteristika i funkcionalnosti robe. Ovlašćeno
        lice trgovca određuje stepen oštećenja, a na osnovu toga se smanjuje
        iznos koji se refundira kupcu. Ako se proizvod krivicom kupca toliko
        oštetio da se više ne može prodati, povrat novca će biti odbijen i
        proizvod će biti poslat nazad kupcu o njegovom trošku.
      </p>,
      <p key={67}>
        U slučaju zakonitog povlačenja iz ugovora, trgovac je obavezan da kupcu
        odmah, a najkasnije u roku od 14 dana od prijema obrasca za odustanak,
        vrati uplaćeni iznos (uključujući i troškove dostave). Međutim, trgovac
        nije obavezan da vrati dodatne troškove koji proizlaze iz
        kupčevogspecifičnogzahteva za isporuku koji nije najjeftinija uobičajena
        dostava koju je trgovac ponudio. Povraćajsredstava može biti odložen dok
        trgovac ne primi vraćeni artikl ili dok kupac ne pruži dokaz o slanju -
        zavisno o tome koji događaj se prvi desi, osim ako trgovac nije ponudio
        da sam preuzme robu. Ako je proizvod dostavljen kupcu na njegovu kućnu
        adresu prilikom zaključenja ugovora van poslovnih objekata i ne može se
        standardno vratiti poštom zbog svoje prirode, trgovac će preuzeti
        proizvod o svom trošku.
      </p>,
      <p key={68}>
        U skladu sa Zakonom o zaštiti potrošača,potrošač ima pravo da odustane
        od ugovora, te se primenjuju se sledeća pravila:
      </p>,
      <ul key={69} style={{ listStyle: 'numeric', marginLeft: '1rem' }}>
        <li key={'list' + 1}>
          svaki ugovor na daljinu i ugovor koji se zaključuje izvan poslovnih
          prostorija trgovca, na koji se primenjuju pravila sadržana u članovima
          26-36. Zakona o zaštiti potrošača,potrošač može, ne navodeći za to
          razlog, da raskine u roku od 14 (četrnaest) dana;
        </li>
        <li key={'list' + 2}>
          u slučajuzaključenja ugovora o kupoprodaji, rok za odustanak računa se
          od trenutka kada roba dospe u državinu potrošača, odnosno trećeg lica
          koje je odredio potrošač, a koje nije prevoznik;
        </li>
        <li key={'list' + 2}>
          kada potrošač jednom porudžbenicom naruči više vrsta roba koje se
          isporučuju zasebno, rok od 14 (četrnaest) dana počinje da teče kada
          poslednja vrsta naručene robe dospe u državinu potrošača, odnosno
          trećeg lica koje je odredio potrošač, a koje nije prevoznik;
        </li>
        <li key={'list' + 2}>
          kada se isporuka robe sastoji iz više pošiljki i delova, rok od 14
          (četrnaest) dana počinje da tečekada je poslednja pošiljka ili njen
          deo, dospeo u državinu potrošača, odnosno trećeg lica koje je odredio
          potrošač, a koje nije prevoznik;
        </li>
        <li key={'list' + 2}>
          kada je zaključen ugovor na neodređeno vreme sa periodičnim isporukama
          robe, rok od 14 (četrnaest) dana počinje da teče kada prva pošiljka
          robe dospe u državinu potrošača, odnosno trećeg lica koje je odredio
          potrošač, a koje nije prevoznik;
        </li>
        <li key={'list' + 2}>
          ako trgovac ne preda kupcu obaveštenje o uslovima, vremenskom roku i
          postupku za ostvarivanje prava na odustanak od ugovora, na način iz
          člana 29. stav 1. zakona i člana 30. stav 2. ovog zakona, potrošač
          može odustati od ugovora u roku od 12 meseci od dana isteka roka za
          odustanak od ugovora;
        </li>
        <li key={'list' + 2}>
          ako trgovac nije predao potrošaču obaveštenje o uslovima, vremenskom
          roku i postupku za ostvarivanje prava na odustanak od ugovora, na
          način iz člana 29. stav 1. ovog zakona i člana 30. stav 2. ovog
          zakona, pa to učini u roku od 12 meseci od dana zaključenja ugovora,
          rok od 14 dana počinje da teče kada potrošač dobije obrazac za
          odustanak.
        </li>
      </ul>,
      <p key={70}>
        Rok od 14 dana ističe protekom poslednjeg časa poslednjeg dana roka.
      </p>,
      <p key={71}>Isključenje prava na odustanak od ugovora</p>,
      <p key={72}>
        Potrošač nema pravo na odustanak od ugovora, na osnovu člana 36. Zakona
        o zaštiti potrošača, u slučaju:
      </p>,
      <ul key={73} style={{ listStyle: 'numeric', marginLeft: '1rem' }}>
        <li key={'list' + 1}>
          isporuke robe proizvedene prema posebnim zahtevima potrošača ili jasno
          personalizovane;
        </li>
        <li key={'list' + 2}>
          isporuke zapečaćene robe koja se ne može vratiti zbog zaštite zdravlja
          ili higijenskih razloga i koja je otpečaćena nakon isporuke.
        </li>
      </ul>,
      <p key={74}>
        Potrošač nema pravo da odustane od ugovora u slučaju isporuke zapečaćene
        robe koja se ne može vratiti zbog zaštite zdravlja ili higijenskih
        razloga i koja je otpečaćena nakon isporuke. Dakle, potrošač nema pravo
        da odustane od ugovora ako je reč o donjem vešu,
        kupaćimgaćama,kupaćimkostimima, čarapama. U slučaju da je proizvod
        otpakovan pretpostavlja se da je isti korišćen, zbog čegapotrošač nema
        pravo da odustane od ugovora.
      </p>,
      <p key={75}>
        <b>Rešavanje sporova</b>
      </p>,
      <p key={76}>
        Potrošački spor može se rešiti vansudskim putem. Vansudsko rešavanje
        potrošačkih sporova obavlja se na jasan, efikasan, brz i pravičannačin
        pred telom za vansudsko rešavanje potrošačkih sporova (u daljem tekstu:
        “telo”). Nadležno Ministarstvo sačinjava listu tela i javno je
        objavljuje.
      </p>,
      <p key={77}>
        Međutim, vansudsko rešavanje potrošačkih sporova se ne primenjuje:
      </p>,
      <ul key={78} style={{ listStyle: 'numeric', marginLeft: '1rem' }}>
        <li key={'list' + 1}>
          u oblasti medicinskih usluga koje se pružaju pacijentima u svrhu
          lečenja,uključujući izdavanje recepata;
        </li>
        <li key={'list' + 2}>
          u oblasti pružanja usluga od opšteg interesa koje nisu ekonomske
          prirode;
        </li>
        <li key={'list' + 3}>
          u vezi sa zaključenim ugovorima sa javnim pružaocima usluga u oblasti
          visokoškolskog obrazovanja;
        </li>
        <li key={'list' + 4}>
          u potrošačkim sporovima koji su predmet Zakona o zaštiti potrošača,
          ukoliko je vansudsko rešavanje sporova uređeno posebnim zakonom, a
          naročito u oblasti pružanja elektronskih komunikacionih usluga,
          poštanskih usluga, finansijskih usluga, osim finansijskih pogodbi,
          usluga putovanja;
        </li>
        <li key={'list' + 5}>
          za rešavanje sporova po procedurama koje je ustanovio sam trgovac;
        </li>
        <li key={'list' + 6}>
          na neposredne pregovore izmeđupotrošača i trgovca;
        </li>
        <li key={'list' + 7}>
          na pokušaj mirenja strana povodom spora u parničnom postupku;
        </li>
        <li key={'list' + 8}>
          u postupcima koje je trgovac pokrenuo protiv potrošača.
        </li>
      </ul>,
      <p key={79}>
        Potrošač može započeti postupak pred telom samo nakon što je prethodno
        podneo reklamaciju ili izrazio nezadovoljstvo trgovcu. Trgovcu je
        obavezno da se aktivno uključi u postupak alternativnog rešavanja sukoba
        pred telom.
      </p>,
      <p key={80}>
        Vansudsko rešavanje spora izmeđupotrošača i trgovca prema ovom zakonu
        može trajati do 90 dana od dana kada je zahtev podnet. U specifičnim
        situacijama, ako je spor kompleksan, ovaj rok se može produžiti za
        dodatnih do 90 dana. U takvim situacijama, telo odmah informiše i
        potrošača i trgovca o produženju.
      </p>,
      <p key={81}>
        Potrošač može odustati od daljeg učešća u vansudskom rešavanju
        potrošačkog spora do okončanjapostupka.
      </p>,
      <p key={82}>
        Predlog za pokretanje postupka vansudskog rešavanja potrošačkog spora (u
        daljem tekstu: “Predlog”) dostavlja se nadležnom ministarstvu
        (Ministarstvu trgovine, turizma i telekomunikacija) na jedan od
        sledećihnačina: neposredno, preko pošte ili elektronskim putem. Po
        prijemu ovog predloga, nadležno ministarstvo radi daljeg postupanja po
        njemu ga prosleđuje telu koje je po mestu obavljanja vansudskog
        rešavanja potrošačkog spora najbliže mestu prebivališta potrošača ili
        telu za koje Ministarstvo proceni da će postupak vansudskog rešavanja
        spora rešiti na najefikasniji i najcelishodniji način,imajući u vidu
        predlog potrošača (npr. vođenje postupka elektronskim putem, izbor tela
        najbližeg boravištu potrošača i slično).
      </p>,
      <p key={83}>
        Svaka stranka u postupku vansudskog rešavanja potrošačkog spora plaća
        sopstvene troškove (primera radi, troškove zastupanja, putne troškove
        itd). Međutim, rad tela za vansudsko rešavanje potrošačkogspora je
        besplatan za stranke u postupku vansudskog rešavanja potrošačkog spora.
      </p>,
      <p key={84}>
        U procesu vansudskog rešavanja potrošačkog spora, svaka strana snosi
        svoje troškove (kao što su troškovi zastupanja, putni izdaci i slično).
        Ipak, rad tela zaduženog za vansudsko rešavanje spora je besplatno za
        stranke u tom postupku.
      </p>,
      <p key={85}>
        Pokretanje i vođenje postupka vansudskog rešavanja potrošačkog spora, ne
        isključuje i ne utiče na ostvarivanje prava na sudsku zaštitu, u skladu
        sa zakonom. Pored toga, samo učešćepotrošača u postupku vasnudskog
        rešavanja potrošačkog spora ga ne sprečava da naknadu štete zahteva u
        sudskom postupku. Zastarevanje i prekluzivni rokovi ne teku u toku
        postupka vansudskog rešavanja potrošačkogspora, a počinju ponovo da teku
        istekom petnaestog dana od dana okončanja ovog postupka.
      </p>,
      <p key={86}>
        <b>Mogućnost rešavanja potrošačkog spora pred arbitražom</b>
      </p>,
      <p key={87}>
        Spor izmeđupotrošača i trgovca može se rešiti putem arbitraže, ukoliko
        obe strane zaključe sporazum o arbitraži nakon što je spor nastao.
      </p>,
      <p key={88}>
        Arbitražni sporazum je dokument koji su potpisale obe ugovorene strane i
        u kojem nema drugih sporazuma osim onih vezanih za arbitražni postupak.
        Pre nego što potpiše sporazum, trgovac je obavezan da informiše
        potrošača o pravnim implikacijama prihvatanja arbitražnog sporazuma,
        posebno o obaveznosti odluke arbitraže i troškovima ovog postupka.
      </p>,
      <p key={89}>
        <b>Sudsko rešavanje spora</b>
      </p>,
      <p key={90}>
        Ukoliko rešavanje sporova nije moguće na neki od napred opisanih načina,
        postupak se može pokrenuti pred naležnim sudom po mestu prebivališta,
        odnosno boravišta potrošača.
      </p>,
      <p key={91}>
        <b>Podaci o trgovcu</b>
      </p>,
      <p key={92}>
        DM STAR CONSULTING D.O.O. – Ogranak 2 Esotiq, BEOGRAD (ZVEZDARA)
      </p>,
      <p key={93}>
        Ul. Bulevar Kralja Aleksandra 458/32 11127 Beograd, (Zvezdara)
      </p>,
      <p key={94}>Matični broj: 21430552</p>,
      <p key={95}>PIB:111132614</p>,
      <p key={96}>Šifra delatnosti: 7022</p>,
      <p key={97}>
        E mail:{' '}
        <a href="mailto: esotiqwebshop@gmail.com">esotiqwebshop@gmail.com</a>
      </p>,
      <p key={98}>
        Tel: <a href="tel: +381 60 1320 929">+381 60 1320 929</a>
      </p>,
    ],
  },
];
