export const ArrowDownIcon = ({ opened }: { opened?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
      rotate={opened ? '90deg' : '90deg'}
      transform={`rotate(${opened ? 180 : 0})`}
      width={20}
      height={20}
    >
      <path d="M12.707 15.707l7-7-1.414-1.414-6.293 6.293-6.293-6.293-1.414 1.414 7 7c0.39 0.39 1.024 0.39 1.414 0z"></path>
    </svg>
  );
};
