import { CirculedAddIcon } from 'assets/CirculedAddIcon';
import { PenIcon } from 'assets/PenIcon';
import { TrashIcon } from 'assets/TrashIcon';
import { IconButton } from 'components/Buttons/IconButton';
import useClickOutside from 'hooks/useClickOutside';
import { useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  overflow: visible;
`;

const ActionContainer = styled.div`
  width: fit-content;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  padding-right: 2rem;
  overflow: visible;
`;

export const ProductActions = ({ onEditClick, onDeleteClick, onPlusClick }) => {
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => {
    setOpened(false);
  });
  return (
    <Container
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex
        $jc="flex-end"
        $p="0 1.5rem 0 0"
        $ai="center"
        $pointer
        onClick={() => {
          setOpened((prev) => !prev);
        }}
      >
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.39648 18C7.84648 18 7.37571 17.8041 6.98415 17.4123C6.59237 17.0208 6.39648 16.55 6.39648 16C6.39648 15.45 6.59237 14.9792 6.98415 14.5877C7.37571 14.1959 7.84648 14 8.39648 14C8.94648 14 9.41737 14.1959 9.80915 14.5877C10.2007 14.9792 10.3965 15.45 10.3965 16C10.3965 16.55 10.2007 17.0208 9.80915 17.4123C9.41737 17.8041 8.94648 18 8.39648 18ZM16.0888 18C15.5388 18 15.068 17.8041 14.6765 17.4123C14.2847 17.0208 14.0888 16.55 14.0888 16C14.0888 15.45 14.2847 14.9792 14.6765 14.5877C15.068 14.1959 15.5388 14 16.0888 14C16.6388 14 17.1096 14.1959 17.5012 14.5877C17.8929 14.9792 18.0888 15.45 18.0888 16C18.0888 16.55 17.8929 17.0208 17.5012 17.4123C17.1096 17.8041 16.6388 18 16.0888 18ZM23.7812 18C23.2312 18 22.7603 17.8041 22.3685 17.4123C21.9769 17.0208 21.7812 16.55 21.7812 16C21.7812 15.45 21.9769 14.9792 22.3685 14.5877C22.7603 14.1959 23.2312 14 23.7812 14C24.3312 14 24.8019 14.1959 25.1935 14.5877C25.5853 14.9792 25.7812 15.45 25.7812 16C25.7812 16.55 25.5853 17.0208 25.1935 17.4123C24.8019 17.8041 24.3312 18 23.7812 18Z"
            fill="black"
          />
        </svg>
      </Flex>
      {opened && (
        <ActionContainer>
          <IconButton onClick={onPlusClick}>
            <CirculedAddIcon />
          </IconButton>
          <IconButton onClick={onEditClick}>
            <PenIcon />
          </IconButton>
          <IconButton onClick={onDeleteClick}>
            <TrashIcon />
          </IconButton>
        </ActionContainer>
      )}
    </Container>
  );
};
