import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editCollection } from 'api/collections';
import { FullContainerLoader } from 'components/Loader/Loader';
import { useCollection } from 'hooks/useCollection';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateCollectionForm } from './CreateCollectionForm';

export const EditCollection = () => {
    const { id } = useParams();
    const { data, isLoading } = useCollection({ id });
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const editMutation = useMutation(
        (data: any) => {
            const dataForm = new FormData();
            dataForm.append(
                'collection',
                JSON.stringify({
                    name: data.name,
                })
            );
            if (typeof data.header !== 'string') {
                dataForm.append('header', data.header);
            }
            if (typeof data.thumbnail !== 'string') {
                dataForm.append('thumbnail', data.thumbnail);
            }
            return editCollection(id, dataForm);
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(['collection', { id }], data);
                navigate(-1);
            },
            onError: (err: any) => {
                toast.error(err.response.data.message);
            },
        }
    );
    if (isLoading) return <FullContainerLoader />;
    return (
        <CreateCollectionForm
            defaultName={data?.name}
            defaultHeader={data?.headerImage}
            defaultThumbnail={data.thumbnailImage}
            mutation={editMutation}
        />
    );
};
