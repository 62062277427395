import { EmptyCartIcon } from 'assets/EmptyCartIcon';
import { Button } from 'components/Buttons/Button';
import { Divider } from 'components/Drividers/Divider';
import { FullLoader } from 'components/Loader/Loader';
import { CartContext } from 'context/CartContext';
import { useCartSizes } from 'hooks/useCartSizes';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex, SectionContainer } from 'styles/Containers.styles';
import { TapeMessage } from 'styles/TapeMessage';
import { theme } from 'styles/theme';
import {
  CartContainer,
  CartItemsContainer,
  SummaryContainer,
} from './Cart.style';
import { CartCard } from './CartCard';

const CartWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden;
`;

export const Cart = () => {
  const navigate = useNavigate();
  const cartContext = useContext(CartContext);
  const { data, isLoading } = useCartSizes(
    cartContext?.cartItems?.map((item) => item.productItemId)
  ); // THIS DATA WILL BE USED FOR QUANTITY SELECT
  const emptyProductError = data?.some((size) => size.quantity < 1);
  const exceededQuantityError = data?.some(
    (size) =>
      cartContext.cartItems?.find((item) => item.productItemId === size.id)
        ?.quantity > size?.quantity
  );
  const priceZeroError = data?.some(
    (size) =>
      cartContext.cartItems?.find((item) => item.productItemId === size.id)
        ?.price.price <= 0
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <CartWrapper>
      <Helmet>
        <title>Cart | Esotiq</title>
      </Helmet>
      <Flex $jc="center" $p="1rem">
        <SectionContainer>
          {cartContext.cartItems.length < 1 ? (
            <Flex
              $jc="center"
              $p="7rem 0"
              $bold={700}
              $fs={1.5}
              $column
              $ai="center"
              $gap="3rem"
            >
              <EmptyCartIcon />
              VAŠA KORPA JE PRAZNA
              <Button styleType="color" onClick={() => navigate('/novo')}>
                Istraži nove proizvode
              </Button>
            </Flex>
          ) : (
            <CartContainer>
              <CartItemsContainer>
                <Flex $noFullHeight $bold={600} $fs={1.7} $ai="center">
                  KORPA
                  <Flex $noFull $bold={500} $noFullHeight>
                    ({cartContext.cartItems.length})
                  </Flex>
                </Flex>
                {emptyProductError && (
                  <TapeMessage $bgColor={theme.colors.error} $textColor="white">
                    Proizvode koje trenutno nemamo u zalihama morate izbaciti da
                    bi nastavili.{' '}
                  </TapeMessage>
                )}
                {exceededQuantityError && (
                  <TapeMessage $bgColor={theme.colors.error} $textColor="white">
                    Da biste nastavili sa kupovinom molimo Vas proverite
                    raspoložive količine.
                  </TapeMessage>
                )}
                {priceZeroError && (
                  <TapeMessage $bgColor={theme.colors.error} $textColor="white">
                    Da biste nastavili sa kupovinom molimo Vas izbacite
                    proizvode koje nemamo na stanju.
                  </TapeMessage>
                )}
                {cartContext.calculateTotal() < 5000 && (
                  <TapeMessage
                    $bgColor={theme.colors.borderGray}
                    $textColor="white"
                  >
                    Za besplatnu dostavu Vam nedostaje{' '}
                    {(5000 - cartContext.calculateTotal()).toFixed(2)}RSD
                  </TapeMessage>
                )}
                {cartContext.cartItems.map((item, index) => {
                  const availableSize = data?.find(
                    (size) => size.id === item.productItemId
                  );

                  if (!availableSize || availableSize?.quantity >= 1)
                    return null;
                  return (
                    <>
                      <CartCard
                        key={index}
                        product={item}
                        availableSize={availableSize}
                        quantityError
                      />
                      <Divider color={theme.colors.error} />
                    </>
                  );
                })}
                {cartContext.cartItems.map((item, index) => {
                  const availableSize = data?.find(
                    (size) => size.id === item.productItemId
                  );
                  const exceededQuantity =
                    item.quantity > availableSize?.quantity;
                  if (availableSize?.quantity < 1) return null;
                  return (
                    <>
                      <CartCard
                        key={index}
                        product={item}
                        availableSize={data?.find(
                          (size) => size.id === item.productItemId
                        )}
                        exceededQuantity={exceededQuantity}
                      />
                      <Divider />
                    </>
                  );
                })}
              </CartItemsContainer>
              <SummaryContainer>
                <Flex $noFullHeight $bold={600} $fs={1.7} $ai="center">
                  PREGLED
                </Flex>
                <Flex>
                  <Flex $fs={1.1} $bold={700}>
                    UKUPNO
                  </Flex>
                  <Flex $fs={1.2} $noFull $bold={700}>
                    {cartContext.calculateTotal().toFixed(2)}RSD
                  </Flex>
                </Flex>
                <Divider />
                <Button
                  width="100%"
                  onClick={() => {
                    navigate('/checkout/details');
                  }}
                  disabled={
                    emptyProductError || exceededQuantityError || priceZeroError
                  }
                >
                  Kupi
                </Button>
              </SummaryContainer>
            </CartContainer>
          )}
        </SectionContainer>
      </Flex>
      <FullLoader loading={isLoading} />
    </CartWrapper>
  );
};
