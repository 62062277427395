import { AttributesIcon } from 'assets/sidebarLinks/AttributesIcon';
import { CategoryIcon } from 'assets/sidebarLinks/CategoryIcon';
import { CollectionsIcon } from 'assets/sidebarLinks/CollectionsIcon';
import { CustomersIcon } from 'assets/sidebarLinks/CustomersIcon';
import { OrdersIcon } from 'assets/sidebarLinks/OrdersIcon';
import { ProductsIcon } from 'assets/sidebarLinks/ProductsIcon';
import { PromoCodesIcon } from 'assets/sidebarLinks/PromoCodesIcon';
import { SubcategoriesIcon } from 'assets/sidebarLinks/SubcategoriesIcon';
import { NavbarAdmin } from './pages/NavbarAdmin';
import { Attributes } from './pages/dashboard/attributes/Attributes';
import { Categories } from './pages/dashboard/categories/Categories';
import { CreateCategory } from './pages/dashboard/categories/CreateCategory';
import { EditCategory } from './pages/dashboard/categories/EditCategory';
import { Collections } from './pages/dashboard/collections/Collections';
import { CreateCollection } from './pages/dashboard/collections/CreateCollection';
import { EditCollection } from './pages/dashboard/collections/EditCollection';
import { Customers } from './pages/dashboard/customers/Customers';
import { Orders } from './pages/dashboard/orders/Orders';
import { OrdersNav } from './pages/dashboard/orders/OrdersNav';
import { CreateProduct } from './pages/dashboard/products/CreateProduct';
import { EditProduct } from './pages/dashboard/products/EditProduct';
import { Products } from './pages/dashboard/products/Products';
import { PromoCodes } from './pages/dashboard/promoCodes/PromoCodes';
import { CreateSubcategory } from './pages/dashboard/subcategories/CreateSubcategory';
import { EditSubcategory } from './pages/dashboard/subcategories/EditSubcategory';
import { Subcategories } from './pages/dashboard/subcategories/Subcategories';
import { Blogs } from './pages/dashboard/blogs/Blogs';
import { BlogsNavbar } from './pages/dashboard/blogs/BlogsNavbar';

export const dashboardPageConfig = [
  {
    name: 'Porudžbine',
    link: '/orders',
    element: <Orders />,
    navbarElement: <OrdersNav />,
    sidebarLink: {
      title: 'Porudžbine',
      icon: <OrdersIcon />,
    },
  },
  {
    name: 'Proizvodi',
    link: '/products',
    element: <Products />,
    withSearch: true,
    withSelect: true,
    sidebarLink: {
      title: 'Proizvodi',
      icon: <ProductsIcon />,
    },
  },
  {
    name: 'Kreiraj proizvod',
    link: '/products/create',
    element: <CreateProduct />,
    back: true,
  },
  {
    name: 'Izmeni proizvod',
    link: '/products/edit/:id',
    element: <EditProduct />,
    back: true,
  },
  // {
  //   name: 'Nova varijanta boje',
  //   link: '/products/:id/color-variant/create',
  //   element: <AddColorVariant />,
  //   back: true,
  // },
  {
    name: 'Kupci',
    link: '/customers',
    sidebarLink: { title: 'Kupci', icon: <CustomersIcon /> },
    element: <Customers />,
  },
  {
    name: 'Kolekcije',
    link: '/collections',
    element: <Collections />,
    sidebarLink: {
      title: 'Kolekcije',
      icon: <CollectionsIcon />,
    },
  },
  {
    name: 'Nova Kolekcija',
    link: '/collections/create',
    element: <CreateCollection />,
    back: true,
  },
  {
    name: 'Izmeni Kolekciju',
    link: '/collections/edit/:id',
    element: <EditCollection />,
    back: true,
  },
  {
    name: 'Kategorije',
    link: '/categories',
    element: <Categories />,
    sidebarLink: {
      title: 'Kategorije',
      icon: <CategoryIcon />,
    },
  },
  {
    name: 'Nova kategorija',
    link: '/categories/create',
    element: <CreateCategory />,
    back: true,
  },
  {
    name: 'Izmeni kategoriju',
    link: '/categories/edit/:id',
    element: <EditCategory />,
    back: true,
  },
  {
    name: 'Podkategrije',
    link: '/subcategories',
    element: <Subcategories />,
    sidebarLink: {
      title: 'Podkategorije',
      icon: <SubcategoriesIcon />,
    },
  },
  {
    name: 'Nova Podkategorija',
    link: '/subcategories/create',
    element: <CreateSubcategory />,
    back: true,
  },
  {
    name: 'Izmeni Podkategoriju',
    link: '/subcategories/edit/:id',
    element: <EditSubcategory />,
    back: true,
  },
  {
    name: 'Atributi',
    link: '/attribute-groups',
    element: <Attributes />,
    sidebarLink: {
      title: 'Grupe atributa',
      icon: <AttributesIcon />,
    },
  },
  {
    name: 'Blogovi',
    link: '/blogs',
    element: <Blogs />,
    navbarElement: <BlogsNavbar />,
    sidebarLink: {
      title: 'Blogovi',
      icon: <AttributesIcon />,
    },
  },
  {
    name: 'Promo',
    link: '/promo-codes',
    element: <PromoCodes />,
    sidebarLink: {
      title: 'Promo kodovi',
      icon: <PromoCodesIcon />,
    },
  },
];

export const sidebarLinks = dashboardPageConfig.map((link) => {
  if (link.sidebarLink) {
    return {
      link: link.link,
      ...link.sidebarLink,
    };
  }
});

export const dashboardPages = dashboardPageConfig.map((link) => ({
  path: '/dashboard' + link.link,
  element: link.element || link.name,
}));

export const dashboardNavigationPages = dashboardPageConfig.map((link) => ({
  path: '/dashboard' + link.link,
  element: link.name,
}));

export const navigationRoutes = dashboardPageConfig.map((link) => ({
  path: link.link,
  element: link.navbarElement ?? (
    <NavbarAdmin
      name={link.name}
      withSearch={link.withSearch}
      withSelect={link.withSelect}
      back={link.back}
    />
  ),
}));
