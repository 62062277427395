import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

export const Profile = () => {
  const { user, editProfile } = useAuth();
  const [name, setName] = useState(user.name);
  return (
    <Flex $column $gap="1rem">
      <Input
        label="Ime"
        value={name}
        name="name"
        onValueChange={(_name, value) => setName(value)}
      />
      <Flex $column $noFullHeight>
        <Flex $textcolor={theme.colors.secondaryDark} $fs={0.9}>
          Email
        </Flex>
        <Flex>{user.email}</Flex>
      </Flex>
      <Flex $gap="0" $fs={0.9}>
        <p>
          Da biste promenili svoju e-mail adresu, molimo kontaktirajte
          korisnički servis..{' '}
          <Link
            style={{ textDecoration: 'underline', fontWeight: 700 }}
            to="/customer-service"
          >
            Kontakt
          </Link>
        </p>
      </Flex>
      <Button
        disabled={name === user.name}
        onClick={() => editProfile({ name })}
      >
        Sačuvaj
      </Button>
    </Flex>
  );
};
