import { Link } from 'react-router-dom';

export const options: {
  text?: string;
  title?: string;
  lines?: React.ReactElement[];
}[] = [
  {
    lines: [
      <p key={0}>
        <b>Pravila korišćenja online prodavnice</b>
      </p>,
      <p key={1}>
        Pre nego što započnete sa korišćenjem online prodavnice Esotiq, koja se
        nalazi na adresi: <Link to="/">https://esotiq.rs</Link>, preporučujemo
        da detaljno proučite Pravila korišćenja online prodavnice.
      </p>,
      <p key={2}>
        Kada koristite ovu online prodavnicu, potvrđujete da ste se upoznali,
        razumeli i saglasili sa ovim Pravilima korišćenja online prodavnice (u
        daljem tekstu: Pravila). DM Star consulting DOO Beograd zadržava pravo
        da u svakom trenutku ažurira ova Pravila. Aktuelna verzija Pravila je
        uvek dostupna na web stranici Esotiq na adresi{' '}
        <Link to="/terms-condition">https://esotiq.rs/terms-condition</Link>.
        Sve informacije i materijali objavljeni na online prodavnici Esotiq su
        vlasništvo Esotiq-a i mogu se koristiti isključivo u nekomercijalne
        svrhe, uz poštovanje svih autorskih prava definisanih zakonom.
        Materijale sa ove online prodavnice nije dozvoljeno modifikovati,
        kopirati niti javno prikazivati, prenositi, distribuirati ili na bilo
        koji drugi način koristiti u javne ili komercijalne svrhe. Ako želite da
        koristite materijale objavljene na online prodavnici za druge namene,
        potrebno je da kontaktirate Esotiq radi pribavljanja odobrenja.
      </p>,
      <p key={3}>Dok koristite online prodavnicu Esotiq, zabranjeno je:</p>,
      <ul key={4} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
        <li key={'list' + 6}>
          narušavati bilo koje bezbednosne funkcije online prodavnice ili
          funkcije koje onemogućavaju ili ograničavaju kopiranje i pristup
          sadržaju dostupnog na online prodavnici;
        </li>
        <li key={'list' + 7}>
          postavljati ili prenositi na online prodavnicu materijal koji je
          preteći, neprikladan, klevetnički, uvredljiv, eksplicitan ili
          zlostavljački;
        </li>
        <li key={'list' + 7}>
          podsticati bilo koji oblik diskriminacije, mržnje ili netrpeljivosti,
          kao i postavljati komentare koji krše privatnost, prete, ili izazivaju
          druge;
        </li>
        <li key={'list' + 7}>
          davati netačne informacije o svojim ličnim podacima;
        </li>
        <li key={'list' + 7}>
          neovlašćeno koristiti lične podatke drugih osoba ili se lažno
          predstavljati kao neko drugi i lažno zastupati treća lica;
        </li>
        <li key={'list' + 7}>
          postupati protivno zakonu ili pravilima ponašanja;
        </li>
        <li key={'list' + 7}>
          ometati, menjati, presretati ili hakovati online prodavnicu;
        </li>
        <li key={'list' + 7}>
          koristiti specifične softvere ili alate kako biste neovlašćeno
          preuzeli sadržaj sa online prodavnice Esotiq.
        </li>
      </ul>,
      <p key={6}>
        Ukoliko ne postupate prema pravilima navedenim u Pravilima korišćenja
        online prodavnice, Esotiq može privremeno ili trajno ograničiti Vaš
        pristup ovoj online prodavnici. Ovo ne isključuje mogućnost da Esotiq
        podnese prijavu nadležnim organima.
      </p>,
    ],
  },
];
