import { useQuery } from '@tanstack/react-query';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useScrollPosition } from 'context/ScrollContext';
import { useProductColorVariants } from 'hooks/useProductColorVariants';
import { useProductFilters } from 'hooks/useProductFilters';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { CategoryType } from 'types/category.types';
import { CollectionType } from 'types/collection.types';
import { SubcategoryType } from 'types/subcategory.types';
import { generatePageBreadcrumbs } from 'utils/breadcrubms';
import { findPage } from 'utils/page.utils';
import { ProductFilter } from '../../../components/ProductFilter/ProductFilter';
import { ProductList } from '../../../components/ProductList/ProductList';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { Flex } from '../../../styles/Containers.styles';
import { HeaderImage } from './Category.style';
import { Helmet } from 'react-helmet';
import { FullContainerLoader } from 'components/Loader/Loader';

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1366px;
  padding-inline: 4rem;

  @media screen and (max-width: 1000px) {
    padding-inline: 1rem;
  }
`;

type PageType = CategoryType | SubcategoryType | CollectionType;

export const Category = () => {
  const { slug, pageType } = useParams();
  const { data: page, isLoading: pageLoading } = useQuery<PageType>({
    queryKey: [`category_${slug}`],
    queryFn: async () => await findPage(pageType, slug),
  });
  const { width } = useWindowWidth();
  const [filterOpened, setFilterOpened] = useState(false);
  const { products, fetchNextPage, isFetchingNextPage } =
    useProductColorVariants({ page });
  const { data, initialFilters, isLoading } = useProductFilters();
  const { scrollPosition, setScrollPosition } = useScrollPosition();

  useEffect(() => {
    window.scrollTo(0, scrollPosition);

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleFilter = () => {
    setFilterOpened((prev) => !prev);
  };

  if (pageLoading)
    return (
      <ContentContainer style={{ height: '80vh' }}>
        <FullContainerLoader />
      </ContentContainer>
    );

  if (!page) return null;

  return (
    <Flex $p="0 0 4rem 0">
      <Helmet>
        <title>{'name' in page ? page?.name : page?.key} | Esotiq</title>
        <meta
          property="og:title"
          content={`${'name' in page ? page?.name : page?.key} | Esotiq`}
        />
        <meta
          property="og:image"
          content={`${'headerImage' in page ? page.headerImage : page.image}
          `}
        />
      </Helmet>
      <Flex $column $ai="center">
        {width > 1000 && (
          <HeaderImage
            src={'headerImage' in page ? page.headerImage : page.image}
          />
        )}
        <ContentContainer>
          <Breadcrumbs list={generatePageBreadcrumbs(page)} />
          <ProductList
            title={'name' in page ? page?.name : page?.key}
            mobile={width < 400}
            tablet={width < 1000}
            onFilterClick={toggleFilter}
            filterOpened={filterOpened}
            items={products}
            isLoading={isLoading}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </ContentContainer>
      </Flex>
      <ProductFilter
        opened={filterOpened}
        closeFilter={toggleFilter}
        filter={data}
        initialFilters={initialFilters}
        isLoading={isLoading}
      />
    </Flex>
  );
};
