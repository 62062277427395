import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCollection, getCollections } from 'api/collections';
import { toast } from 'react-toastify';
import { CategoryType } from 'types/category.types';
import { CollectionType } from 'types/collection.types';

export const useCollections = () => {
  const { isLoading, error, data } = useQuery<CollectionType[]>(
    ['collections'],
    getCollections,
    { refetchOnWindowFocus: false }
  );
  const queryClient = useQueryClient();

  const deleteMutation = useMutation((id: number) => deleteCollection(id), {
    onSuccess: (_data, id) => {
      queryClient.setQueryData(
        ['collections'],
        (previousData: CategoryType[]) => {
          const updatedAttributes = [...previousData];
          const index = updatedAttributes.findIndex(
            (attributeItem) => attributeItem.id === id
          );

          updatedAttributes.splice(index, 1);

          return updatedAttributes;
        }
      );
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  return { isLoading, error, data, deleteMutation };
};
