import { useQuery } from '@tanstack/react-query';
import { getCategoryById } from 'api/categories.api';
import { CategoryType } from 'types/category.types';

export const useCategory = ({ id }: { id: string }) => {
  const { isLoading, error, data } = useQuery<CategoryType>(
    ['category', { id }],
    () => getCategoryById(id)
  );

  return { isLoading, error, data };
};
