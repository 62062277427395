import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex } from '../../styles/Containers.styles';

const TwoImageContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 1270px) {
    padding: 2rem;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  flex: 1;
  object-position: top;
  height: auto;
`;

const StyledVideo = styled.video`
  width: 100%;
  object-fit: cover;
  flex: 1;
  object-position: top;
  height: auto;
`;

export const WebTwoImage = ({
  img1,
  videoSrc,
  img2,
  to,
}: {
  videoSrc?: string;
  img1?: any;
  img2: any;
  to: string;
}) => {
  return (
    <TwoImageContainer>
      <Link to={to}>
        <Flex>
          {img1 && <StyledImage src={img1} />}
          {videoSrc && <StyledVideo src={videoSrc} autoPlay muted loop />}
        </Flex>
      </Link>
      <Link to={to}>
        <Flex>
          <StyledImage src={img2} />
        </Flex>
      </Link>
    </TwoImageContainer>
  );
};
