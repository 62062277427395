import { Logo } from 'assets';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const NavbarContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

export const CheckoutNav = () => {
  const { width } = useWindowWidth();
  return (
    <NavbarContainer>
      <Link to="/">
        <Logo height={width < 950 ? 25 : 35} width={width < 950 ? 80 : 106} />
      </Link>
    </NavbarContainer>
  );
};
