import { Flex, TextSectionContainer } from 'styles/Containers.styles';
import { options } from './options';

const Returns = () => {
  return (
    <TextSectionContainer style={{ marginBottom: '4rem' }}>
      <Flex $column $gap="3rem" $mt="2rem">
        {options.map((option, index) => (
          <Flex $column $gap="3rem" key={index}>
            {option?.title && <Flex $bold={700}>{option.title}</Flex>}
            {option.text && <Flex>{option.text}</Flex>}
            {option.lines && (
              <Flex $column $gap="1rem">
                {option.lines.map((line, index) => (
                  <Flex style={{ whiteSpace: 'pre-wrap' }} key={'line' + index}>
                    {line}
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </TextSectionContainer>
  );
};

export default Returns;
