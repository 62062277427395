import { Button } from 'components/Buttons/Button';
import { useMemo, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ image: string | null }>`
  width: 100%;
  background-color: #000;
  background-image: url(${({ image }) => image});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #6a6a6a;
  gap: 1rem;
  font-size: 0.875rem;
  aspect-ratio: 127/51;
  min-height: 241px;
  background-size: cover;
  background-position: center;
`;

export const BlogImageInput = ({ onChange, image }) => {
  const ref = useRef(null);
  const src = useMemo(
    () =>
      image
        ? typeof image === 'string'
          ? image
          : URL.createObjectURL(image)
        : null,
    [image]
  );
  return (
    <Container
      image={src}
      onClick={() => {
        if (ref.current) {
          ref.current.click();
        }
      }}
    >
      {!image && (
        <>
          <Button styleType="secondary" square>
            Ubaci Sliku
          </Button>
          Preporučena rezolucija slike je 1270x510px ili veća.
          <br />
          Možete koristiti različite formate slika kao što su JPG, PNG, i drugi.
        </>
      )}
      <input
        accept="image/*"
        style={{ display: 'none' }}
        type="file"
        ref={ref}
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
    </Container>
  );
};
