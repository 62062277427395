import React from 'react';

export const FilterIcon = ({ color = null }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path
        fill={color || null}
        d="M14 4h-11v2h11v-2zM3 20h11v-2h-11v2zM6 15h2v-6h-2v2h-3v2h3v2zM18 16h-2v6h2v-2h3v-2h-3v-2zM18 4v-2h-2v6h2v-2h3v-2h-3zM10 13h11v-2h-11v2z"
      ></path>
    </svg>
  );
};
