import { CloseIcon } from 'assets/CloseIcon';
import { IconButton } from 'components/Buttons/IconButton';
import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { ModalContainer, ModalNoPaddingContent } from './Modal.style';
import { ModalContext } from './ModalContext';
import { theme } from 'styles/theme';

type Props = {
  children: React.ReactElement;
  noOutsideClose: boolean;
  closeFunc?: () => void;
};

const CloseContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 2rem;
  width: 2rem;
`;

export const ModalWithNoPadding = ({
  noOutsideClose,
  children,
  closeFunc,
}: Props) => {
  const { closeModal } = useContext(ModalContext);
  return (
    <ModalContainer
      onClick={() => {
        if (noOutsideClose) {
          return;
        }
        if (closeFunc) {
          closeFunc?.();
          return;
        }
        closeModal();
      }}
    >
      <ModalNoPaddingContent onClick={(e) => e.stopPropagation()}>
        <CloseContainer>
          <IconButton
            onClick={() => {
              if (closeFunc) {
                closeFunc?.();
                return;
              }
              closeModal();
            }}
          >
            <CloseIcon color={theme.colors.borderGray} />
          </IconButton>
        </CloseContainer>
        {children}
      </ModalNoPaddingContent>
    </ModalContainer>
  );
};
