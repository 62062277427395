import { OrderDetailsModal } from 'admin/modals/OrderDetailsModal';
import { format } from 'date-fns';
import EndListTrigger from 'components/List/EndListTrigger';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { SmallLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { ChangeStatusModal } from 'components/Modals/ChangeStatusModal';
import { useOrders } from 'hooks/useOrders';
import { useContext } from 'react';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { convertDeliveryMethodToUI } from 'utils/deliveryUtils';
import { formatPriceForUI } from 'utils/price.utils';
import { formatStatusText } from 'utils/status.utils';

export const Orders = () => {
  const { orders, isLoading, fetchNextPage, isFetchingNextPage } = useOrders();
  const { openModal } = useContext(ModalContext);
  return (
    <>
      <List
        headerItems={[
          { header: 'ID', span: 5 },
          { header: 'DATUM', span: 10 },
          { header: 'KUPAC', span: 18 },
          { header: 'KONTAKT', span: 15 },
          { header: 'PROIZVODI', span: 11, center: true },
          { header: 'CENA', span: 11 },
          { header: 'DOSTAVA', span: 10 },
          { header: 'GRAD', span: 10 },
          { header: 'STATUS', span: 10 },
        ]}
        noAction
        loading={isLoading}
      >
        {orders?.map((item) => (
          <ListItem.Item
            onClick={() => {
              openModal(<OrderDetailsModal id={item.id} />);
            }}
            key={1 + item.id}
            items={[
              {
                span: 5,
                item: '#' + item.id,
              },
              {
                span: 10,
                item: format(new Date(item.createdAt), 'dd. MM. yyyy'),
              },
              {
                span: 18,
                item: (
                  <Flex style={{ whiteSpace: 'pre-wrap' }}>
                    {item.deliveryAddress.firstName +
                      ' ' +
                      item.deliveryAddress.lastName}
                  </Flex>
                ),
              },
              {
                span: 15,
                item: item.deliveryAddress.phoneNumber,
              },
              {
                span: 11,
                item: item.itemsCount,
                center: true,
              },
              {
                span: 11,
                item: formatPriceForUI(item.price),
              },
              {
                span: 10,
                item: convertDeliveryMethodToUI(item.deliveryMethod),
              },
              {
                span: 10,
                item: item.deliveryAddress.city,
              },
              {
                span: 10,
                item: (
                  <Flex
                    $textcolor={theme.getColorByStatus(item.status)}
                    $pointer
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal(
                        <ChangeStatusModal
                          id={item.id}
                          currentStatus={item.status}
                        />
                      );
                    }}
                  >
                    {formatStatusText(item.status)}
                  </Flex>
                ),
              },
            ]}
          />
        ))}

        <EndListTrigger fetchNextPage={fetchNextPage} />
        {isFetchingNextPage && (
          <Flex $noFullHeight $jc="center" $mt="0.5rem">
            <SmallLoader />
          </Flex>
        )}
        {/* {hasNextPage ? "have" : "Dont have"}
                <Button onClick={() => { fetchNextPage() }}>Next page</Button> */}
      </List>
    </>
  );
};
