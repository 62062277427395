import React from 'react';

export const CirculedAddIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M11.4979 17.25H12.7541V13.1755H16.8389V11.9192H12.7541V7.75H11.4979V11.9192H7.33887V13.1755H11.4979V17.25ZM12.0949 22C10.7797 22 9.54645 21.7507 8.39512 21.252C7.24362 20.7533 6.23745 20.0738 5.37662 19.2135C4.51595 18.353 3.83612 17.3469 3.33712 16.1953C2.83828 15.0434 2.58887 13.8094 2.58887 12.4933C2.58887 11.1814 2.8382 9.94833 3.33687 8.794C3.83553 7.6395 4.51503 6.63467 5.37537 5.7795C6.23587 4.92433 7.24195 4.24725 8.39362 3.74825C9.54545 3.24942 10.7795 3 12.0956 3C13.4075 3 14.6405 3.24933 15.7949 3.748C16.9494 4.24667 17.9542 4.92342 18.8094 5.77825C19.6645 6.63308 20.3416 7.63833 20.8406 8.794C21.3395 9.94967 21.5889 11.183 21.5889 12.494C21.5889 13.8092 21.3395 15.0424 20.8409 16.1938C20.3422 17.3453 19.6655 18.3503 18.8106 19.209C17.9558 20.0678 16.9505 20.7477 15.7949 21.2485C14.6392 21.7495 13.4059 22 12.0949 22ZM12.0971 20.7435C14.3881 20.7435 16.3335 19.942 17.9331 18.339C19.5326 16.736 20.3324 14.7869 20.3324 12.4918C20.3324 10.2008 19.5336 8.25542 17.9361 6.65575C16.3386 5.05625 14.3895 4.2565 12.0889 4.2565C9.7992 4.2565 7.85287 5.05525 6.24987 6.65275C4.64687 8.25025 3.84537 10.1993 3.84537 12.5C3.84537 14.7897 4.64687 16.736 6.24987 18.339C7.85287 19.942 9.80195 20.7435 12.0971 20.7435Z"
        fill="black"
      />
    </svg>
  );
};
