import { ArrowDownIcon } from 'assets/ArrowDownIcon';
import { ExpandableContainer } from 'components/Containers/ExpandableContainer';
import RippleWrapper from 'components/Containers/RippleContainer';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const MobileLinkComponentContainer = styled.div<{
  $specialColor?: string;
  $active?: boolean;
}>`
  position: relative;
  width: 100%;
  color: ${({ $specialColor }) => $specialColor || 'inherit'};
  background-color: ${({ $active }) => $active && 'rgb(218, 220, 223)'};
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  width: 20px;
  height: 20px;
`;

type Props = {
  children: React.ReactNode;
  title: string;
  defaultOpened?: boolean;
};

export const ExpandableDesc = ({
  title,
  defaultOpened = false,
  children,
}: Props) => {
  const [opened, setOpened] = useState(defaultOpened);
  return (
    <MobileLinkComponentContainer>
      <RippleWrapper width="100%">
        <Flex
          $jc="space-between"
          $p="1rem 0"
          onClick={() => {
            setOpened((prev) => !prev);
          }}
        >
          <Flex $bold={700}>{title}</Flex>
          <ArrowContainer>
            <ArrowDownIcon />
          </ArrowContainer>
        </Flex>
      </RippleWrapper>
      <ExpandableContainer opened={opened}>{children}</ExpandableContainer>
    </MobileLinkComponentContainer>
  );
};
