import React from 'react';

export const AttributesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 11.875C6.875 11.5298 7.15482 11.25 7.5 11.25H12.5C12.8452 11.25 13.125 11.5298 13.125 11.875C13.125 12.2202 12.8452 12.5 12.5 12.5H7.5C7.15482 12.5 6.875 12.2202 6.875 11.875Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 9.375C6.875 9.02982 7.15482 8.75 7.5 8.75H12.5C12.8452 8.75 13.125 9.02982 13.125 9.375C13.125 9.72018 12.8452 10 12.5 10H7.5C7.15482 10 6.875 9.72018 6.875 9.375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49112 2.86612C3.72554 2.6317 4.04348 2.5 4.375 2.5H7.5C7.84518 2.5 8.125 2.77982 8.125 3.125C8.125 3.47018 7.84518 3.75 7.5 3.75L4.375 3.75L4.375 16.875H15.625V3.75H12.5C12.1548 3.75 11.875 3.47018 11.875 3.125C11.875 2.77982 12.1548 2.5 12.5 2.5H15.625C15.9565 2.5 16.2745 2.6317 16.5089 2.86612C16.7433 3.10054 16.875 3.41848 16.875 3.75V16.875C16.875 17.2065 16.7433 17.5245 16.5089 17.7589C16.2745 17.9933 15.9565 18.125 15.625 18.125H4.375C4.04348 18.125 3.72554 17.9933 3.49112 17.7589C3.2567 17.5245 3.125 17.2065 3.125 16.875V3.75C3.125 3.41848 3.2567 3.10054 3.49112 2.86612Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C9.33696 2.5 8.70107 2.76339 8.23223 3.23223C7.76339 3.70107 7.5 4.33696 7.5 5H12.5C12.5 4.33696 12.2366 3.70107 11.7678 3.23223C11.2989 2.76339 10.663 2.5 10 2.5ZM7.34835 2.34835C8.05161 1.64509 9.00544 1.25 10 1.25C10.9946 1.25 11.9484 1.64509 12.6517 2.34835C13.3549 3.05161 13.75 4.00544 13.75 5V5.625C13.75 5.97018 13.4702 6.25 13.125 6.25H6.875C6.52982 6.25 6.25 5.97018 6.25 5.625V5C6.25 4.00544 6.64509 3.05161 7.34835 2.34835Z"
        fill="currentColor"
      />
    </svg>
  );
};
