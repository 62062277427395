import React from 'react';

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M20 12c0-4.971-4.029-9-9-9s-9 4.029-9 9c0 4.971 4.029 9 9 9 0.986 0 1.935-0.159 2.823-0.452l-1.375-1.698c-0.467 0.098-0.952 0.15-1.449 0.15-3.866 0-7-3.134-7-7s3.134-7 7-7c3.866 0 7 3.134 7 7 0 2.623-1.443 4.91-3.579 6.108l1.273 1.572c0-0 0-0 0-0l2.546 2.971 1.519-1.302-2.484-2.898c1.681-1.635 2.725-3.921 2.725-6.451z"></path>
    </svg>
  );
};
