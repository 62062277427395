import { AddProductSize } from 'admin/modals/AddProductSize';
import { ChooseAttributes } from 'admin/modals/ChooseAttributes';
import { SelectProductSet } from 'admin/modals/SelectProductSet';
import { Button } from 'components/Buttons/Button';
import { CategorySelect } from 'components/Inputs/CategorySelect';
import { Checkbox } from 'components/Inputs/Checkbox';
import { CollectionSelect } from 'components/Inputs/CollectionSelect';
import { ColorPicker } from 'components/Inputs/ColorPicker';
import { FixedTextarea } from 'components/Inputs/FixedTextarea';
import { Input } from 'components/Inputs/Input';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { CategoryType } from 'types/category.types';
import { SimpleSubcategoryType } from 'types/subcategory.types';
import { removeEmpty } from 'utils/object.utils';
import { AddProductImages } from './AddProductImages';
import { createProduct } from 'api/products.api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { FullLoader } from 'components/Loader/Loader';
import { SelectSimilarProducts } from 'admin/modals/SelectSimilarProducts';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;
  overflow-y: scroll;
`;

type ItemType = {
  id: number;
  size: string;
  description: string;
  quantity: number;
  catalogueId: string;
};

export const CreateProductForm = () => {
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: '',
    description: '',
    price: '',
    discount: '',
    colorName: '',
    material: '',
    colorHex: theme.colors.primary,
  });
  const [images, setImages] = useState<File[]>([]);
  const [attributes, setAttributes] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [isSale, setIsSale] = useState(false);
  const [isOutlet, setIsOutlet] = useState(false);
  const [isDraft, setIsDraft] = useState(true);
  const [subcategory, setSubcategory] = useState<SimpleSubcategoryType>(null);
  const [productsForSet, setProductsForSet] = useState<number[]>([]);
  const [similarProducts, setSimilarProducts] = useState<number[]>([]);
  const [collection, setCollection] = useState<CategoryType>(null);
  const [sizes, setSizes] = useState<ItemType[]>([]);
  const queryClient = useQueryClient();

  const onChange = (prop: string, value: string) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };

  useEffect(() => {
    setAttributes([]);
  }, [subcategory]);

  const createMutation = useMutation(
    () => {
      const dataForm = new FormData();
      const items = sizes.map((item) => ({
        size: item.size,
        description: item.description,
        quantity: Number(item.quantity),
        catalogueId: item.catalogueId,
      }));
      const colorVariant = {
        colorName: form.colorName,
        colorHex: form.colorHex,
        items: items,
        setColorVariantIds: productsForSet,
      };
      const product: any = {
        name: form.name,
        description: form.description,
        materialComposition: form.material,
        subCategoryId: subcategory.id,
        isSale: isSale,
        isNew: isNew,
        isDraft: isDraft,
        isOutlet: isOutlet,
        discount: form.discount,
        basePrice: Number(form.price) * 100,
        categoryAttributeValueIds: attributes,
        similarProductIds: similarProducts,
      };

      if (collection) {
        product.collectionId = collection.id;
      }

      dataForm.append('colorVariant', JSON.stringify(colorVariant));
      dataForm.append('product', JSON.stringify(removeEmpty(product)));
      for (let i = 0; i < images.length; i++) {
        dataForm.append('images', images[i]);
      }

      return createProduct(dataForm);
    },
    {
      onSuccess: () => {
        navigate(-1);
        toast.success('Proizvod je uspešno kreiran');
        queryClient.invalidateQueries(['products']);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message);
      },
    }
  );

  const onSave = async () => {
    if (images.length < 1) {
      toast.error('Fotografije moraju biti dodate');
      return;
    } else if (!form.name) {
      toast.error('Ime mora biti dodato');
      return;
    } else if (!subcategory) {
      toast.error('Podkategorija mora biti izabrana');
      return;
    } else if (!form.price) {
      toast.error('Cena mora biti uneta');
      return;
    } else if (sizes.length < 1) {
      toast.error('Veličine moraju biti dodate');
      return;
    } else if (!form.colorName) {
      toast.error('Ime boje mora biti uneto');
      return;
    } else if (isSale && !form.discount) {
      toast.error('Morate uneti procenat popusta');
      return;
    } else if (
      isSale &&
      (Number(form.discount) < 1 || Number(form.discount) > 100)
    ) {
      toast.error('Vrednost popusta mora biti izmedju 1% i 100%');
      return;
    }
    createMutation.mutate();
  };

  return (
    <Container>
      <AddProductImages
        images={images}
        onChange={(images) => setImages(images)}
      />
      <Flex $column>
        <Flex $gap="1rem" $p="0 2rem 0 0">
          <Flex $column $gap="1rem">
            <Input
              label="Ime"
              value={form.name}
              name="name"
              // error={!form.name}
              onValueChange={onChange}
            />
            <FixedTextarea
              name="description"
              value={form.description}
              onValueChange={onChange}
              label="Opis proizvoda"
              error={false}
              limit={10000}
            />

            <FixedTextarea
              name="material"
              value={form.material}
              onValueChange={onChange}
              label="Informacije o proizvodu"
              error={false}
            />
            <Flex $ai="center" $noFullHeight $gap="1rem">
              <ColorPicker
                color={form.colorHex}
                onChange={(color) => onChange('colorHex', color)}
              />
              <Input
                label="Boja"
                value={form.colorName}
                name="colorName"
                // error={!form.name}
                onValueChange={onChange}
              />
            </Flex>
          </Flex>
          <Flex $column>
            <Input
              label="Cena"
              value={form.price}
              name="price"
              // error={!form.name}
              pattern="[0-9]*"
              onValueChange={onChange}
            />
            <Flex $gap="1rem" $noFullHeight>
              <Flex>
                <Checkbox
                  text="Novo"
                  checked={isNew}
                  onChange={() => {
                    setIsNew((prev) => !prev);
                  }}
                />
                <Checkbox
                  text="Draft"
                  checked={isDraft}
                  onChange={() => {
                    setIsDraft((prev) => !prev);
                  }}
                />
                <Checkbox
                  text="Popust"
                  checked={isSale}
                  onChange={() => {
                    setIsSale((prev) => !prev);
                  }}
                />
                <Checkbox
                  text="Outlet"
                  checked={isOutlet}
                  onChange={() => {
                    setIsOutlet((prev) => !prev);
                  }}
                />
              </Flex>
            </Flex>
            <Input
              label="Popust u %"
              value={form.discount}
              name="discount"
              disabled={!isSale}
              pattern="[0-9]*"
              onValueChange={onChange}
            />
            <CategorySelect onChange={(value) => setSubcategory(value)} />
            <CollectionSelect onChange={(value) => setCollection(value)} />
            <Flex $column $gap="1rem" $mt="1rem">
              <Button
                width="100%"
                styleType="outline-dark"
                onClick={() => {
                  if (!subcategory) {
                    toast.error('Podkategorija mora biti izabrana');
                  } else {
                    openModal(
                      <ChooseAttributes
                        subcategoryId={subcategory.id}
                        preselected={attributes}
                        onSave={(value) => {
                          setAttributes(value);
                        }}
                      />
                    );
                  }
                }}
              >
                Dodaj atribute
              </Button>
              <Button
                width="100%"
                styleType="outline-dark"
                onClick={() => {
                  openModal(
                    <AddProductSize
                      sizes={sizes}
                      onSave={(items) => setSizes(items)}
                      noOutsideClose
                    />
                  );
                }}
              >
                Dodaj veličinu
              </Button>
              <Button
                width="100%"
                styleType="outline-dark"
                onClick={() => {
                  openModal(
                    <SelectSimilarProducts
                      preselected={productsForSet}
                      onSave={(value) => {
                        setProductsForSet(value);
                      }}
                    />
                  );
                }}
              >
                Izaberi set
              </Button>
              <Button
                width="100%"
                styleType="outline-dark"
                onClick={() => {
                  openModal(
                    <SelectProductSet
                      preselected={similarProducts}
                      onSave={(value) => {
                        setSimilarProducts(value);
                      }}
                    />
                  );
                }}
              >
                Slični proizvodi
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex $noFullHeight $jc="flex-end" $p="1rem 2rem 0 0">
          <Button onClick={onSave}>Sačuvaj</Button>
        </Flex>
      </Flex>
      <FullLoader loading={createMutation.isLoading} />
    </Container>
  );
};
