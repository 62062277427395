import { useAuth } from 'context/AuthContext';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { styled } from 'styled-components';
import LoginImage from 'assets/AdminLoginImage.jpg';
import { Logo } from 'assets';
import { EmailInput } from 'components/Inputs/EmailInput';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { Button } from 'components/Buttons/Button';
import { Flex } from 'styles/Containers.styles';
import { validateEmail } from 'utils/validation.utils';
const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ImageContainer = styled.div`
  width: 100%;
  background: url(${LoginImage}) center no-repeat;
  background-size: cover;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const Form = styled.form`
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const Login = () => {
  const { user, login } = useAuth();

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const onChange = (name: string, value: string) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(form.email)) {
      return;
    }
    login(form.email, form.password);
  };

  return (
    <LoginContainer>
      <ImageContainer />
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Logo width={160} height={50} />
          <Flex $column $gap="0" $noFullHeight>
            <EmailInput name="email" value={form.email} onChange={onChange} />
            <PasswordInput
              name="password"
              value={form.password}
              onChange={onChange}
            />
          </Flex>
          <Button
            styleType="color"
            type="submit"
            disabled={!validateEmail(form.email) || form.password.length < 5}
          >
            Prijavi se
          </Button>
        </Form>
      </FormContainer>
    </LoginContainer>
  );
};
