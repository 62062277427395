import { SubcategoryType } from 'types/subcategory.types';
import axiosInstance from './instance';

export const getSubcategories = async () => {
  const res = await axiosInstance.get('/v1/subcategories');

  return res.data.data;
};

export const getSubcategoryById = async (id: string) => {
  const res = await axiosInstance.get(`/v1/subcategories/${id}`);

  return res.data.data as SubcategoryType;
};

export const getSubcategoryBySlug = async (slug: string) => {
  const res = await axiosInstance.get(`/v1/subcategories/${slug}/slug`);

  return res.data.data as SubcategoryType;
};

export const editSubcategory = async (id, data) => {
  const res = await axiosInstance.patch(`/v1/subcategories/${id}`, data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res.data.data;
};

export const createSubcategory = async (data) => {
  const res = await axiosInstance.post('/v1/subcategories', data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res.data.data;
};

export const deleteSubcategory = async (id: number) => {
  const res = await axiosInstance.delete(`/v1/subcategories/${id}`);

  return res.data.data;
};
