import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';

export const StyledNavLink = styled(NavLink)<{
  $specialColor?: string;
  $opened?: boolean;
  $active?: boolean;
}>`
  color: ${({ $specialColor }) => $specialColor || 'inherit'};
  padding: 0.5rem;
  font-weight: ${({ $opened, $active }) =>
    $opened || $active ? 700 : 'inherit'};

  &:hover {
    font-weight: 700;
  }
  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
