import React from 'react';

export const PaymentFailedIcon = ({ color = null }) => {
  return (
    <svg
      width="148"
      height="188"
      viewBox="0 0 148 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="147.311"
        height="178.001"
        transform="translate(0 6)"
        fill="white"
      />
      <path
        d="M5.44922 50.5532L144.701 56.2771L141.834 126.033C141.587 132.056 136.503 136.739 130.479 136.491L13.0402 131.664C7.01667 131.416 2.33434 126.332 2.58194 120.309L5.44922 50.5532Z"
        fill="white"
        stroke={color || '#18191A'}
        strokeWidth="3.27473"
      />
      <g clipPath="url(#clip0_7951_67918)">
        <path
          d="M24.25 60.3857L23.2653 84.3803"
          stroke={color || 'black'}
          strokeWidth="1.63679"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.5285 64.9701L21.3486 64.6344C20.3361 64.5929 19.3487 64.9552 18.6034 65.6417C17.8582 66.3282 17.4161 67.2827 17.3746 68.2951C17.333 69.3075 17.6954 70.295 18.3819 71.0402C19.0684 71.7855 20.0228 72.2275 21.0352 72.2691L26.4885 72.4929C27.501 72.5344 28.4554 72.9764 29.1419 73.7217C29.8284 74.467 30.1907 75.4544 30.1492 76.4668C30.1077 77.4792 29.6656 78.4337 28.9204 79.1202C28.1751 79.8067 27.1876 80.169 26.1752 80.1275L16.9046 79.747"
          stroke={color || 'black'}
          strokeWidth="1.63679"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        width="63.3277"
        height="63.3277"
        transform="translate(65.3643 21.5676) rotate(2.35)"
        fill="white"
      />
      <path
        d="M94.6223 80.8658C109.183 81.4633 121.471 70.144 122.069 55.5834C122.666 41.0228 111.347 28.7347 96.7862 28.1371C82.2256 27.5396 69.9375 38.8589 69.3399 53.4195C68.7424 67.9801 80.0617 80.2683 94.6223 80.8658Z"
        stroke={color || 'black'}
        strokeWidth="1.91815"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.935 46.9192L87.467 62.0886"
        stroke={color || 'black'}
        strokeWidth="2.45519"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.1162 46.27L103.286 62.7378"
        stroke={color || 'black'}
        strokeWidth="2.45519"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="54.377"
        y="45.3062"
        width="12.0631"
        height="12.0074"
        transform="rotate(2.35 54.377 45.3062)"
        fill="white"
      />
      <rect
        x="125.991"
        y="48.3894"
        width="11.3006"
        height="12.0074"
        transform="rotate(2.35 125.991 48.3894)"
        fill="white"
      />
      <line
        x1="2.08771"
        y1="109.973"
        x2="142.996"
        y2="115.756"
        stroke={color || 'black'}
        strokeWidth="2.18216"
      />
      <path
        d="M105.218 21.9294L110.968 1.54248"
        stroke={color || '#18191A'}
        strokeWidth="2.60244"
        strokeLinecap="round"
      />
      <path
        d="M91.3146 19.8962L84.9046 4.05082"
        stroke={color || '#18191A'}
        strokeWidth="1.73496"
        strokeLinecap="round"
      />
      <path
        d="M118.313 28.2615L132.939 21.2124"
        stroke={color || '#18191A'}
        strokeWidth="1.73496"
        strokeLinecap="round"
      />
      <mask
        id="mask0_7951_67918"
        maskUnits="userSpaceOnUse"
        x="26"
        y="161"
        width="95"
        height="27"
      >
        <ellipse cx="73.5383" cy="174.113" rx="46.8" ry="13" fill="#E30040" />
      </mask>
      <g mask="url(#mask0_7951_67918)">
        <path d="M96.2994 201.599L59.782 145.121" stroke={color || '#18191A'} />
        <path d="M103.94 201.6L67.4226 145.122" stroke={color || '#18191A'} />
        <path
          d="M111.582 201.599L75.0642 145.121"
          stroke={color || '#18191A'}
        />
        <path d="M119.222 201.6L82.7048 145.122" stroke={color || '#18191A'} />
        <path
          d="M126.864 201.599L90.3464 145.121"
          stroke={color || '#18191A'}
        />
        <path d="M134.505 201.599L97.988 145.121" stroke={color || '#18191A'} />
        <path d="M142.147 201.6L105.63 145.122" stroke={color || '#18191A'} />
        <path
          d="M88.6578 201.365L52.1404 144.887"
          stroke={color || '#18191A'}
        />
        <path d="M81.0162 201.13L44.4988 144.652" stroke={color || '#18191A'} />
        <path
          d="M73.3746 200.896L36.8571 144.418"
          stroke={color || '#18191A'}
        />
        <path d="M65.733 200.662L29.2155 144.184" stroke={color || '#18191A'} />
        <path
          d="M58.0924 200.427L21.5749 143.949"
          stroke={color || '#18191A'}
        />
        <path
          d="M50.4508 200.192L13.9333 143.714"
          stroke={color || '#18191A'}
        />
        <path d="M42.8092 199.958L6.29172 143.48" stroke={color || '#18191A'} />
        <path
          d="M35.1676 199.723L-1.34988 143.245"
          stroke={color || '#18191A'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7951_67918">
          <rect
            width="26.1979"
            height="26.1979"
            fill="white"
            transform="translate(11.2109 58.7551) rotate(2.35)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
