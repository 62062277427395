import { DeleteModal } from 'admin/modals/DeleteModal';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { ModalContext } from 'components/Modal/ModalContext';
import { useSubcategories } from 'hooks/useSubcategories';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const Subcategories = () => {
  const { data, deleteMutation, isLoading } = useSubcategories();
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <List
      headerItems={[{ header: 'Naziv Podkategorije', span: 20 }]}
      actionTitle="Dodaj podkategoriju"
      loading={isLoading}
      action={() => navigate('create')}
    >
      {data?.map((item) => (
        <ListItem.Item
          key={1 + item.id}
          items={[{ item: item.key, span: 20 }]}
          onDeleteClick={() =>
            openModal(
              <DeleteModal
                deleteAction={() => deleteMutation.mutate(item.id)}
              />
            )
          }
          onEditClick={() => navigate(`edit/${item.id}`)}
        />
      ))}
    </List>
  );
};
