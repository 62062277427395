import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  deleteColorVariant,
  deleteProductCall,
  getProducts,
  getProductsCount,
} from 'api/products.api';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useProducts = () => {
  const [searchParams] = useSearchParams();
  const limit = 20;

  const params = {
    search: searchParams.get('search'),
    categoryId: searchParams.get('category'),
    subcategoryIds: [searchParams.get('subcategory')],
    page: searchParams.get('page') || 1,
  };
  const countParams = {
    search: searchParams.get('search'),
    categoryId: searchParams.get('category'),
    subcategoryIds: [searchParams.get('subcategory')],
  };

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['products', params, params.page],
      queryFn: () => {
        return getProducts({ ...params, limit });
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
    });
  const { data: count } = useQuery({
    queryKey: ['productCount', countParams],
    queryFn: () => {
      return getProductsCount(countParams);
    },
  });

  const queryClient = useQueryClient();

  const products = useMemo(
    () => data?.pages?.map((data) => (data?.data ? data.data : [])).flat(1),
    [data]
  );

  const { mutate: deleteVariant } = useMutation(
    (data: { id: number; productId: number }) => deleteColorVariant(data.id),
    {
      onSuccess: (_data, variables) => {
        queryClient.setQueryData(['products'], (previousData: any) => {
          const updatedProducts = [...products];
          const index = updatedProducts.findIndex(
            (productItem) => productItem.id === variables.productId
          );

          const newArr = updatedProducts[index].colorVariants.filter(
            (item) => item.id !== variables.id
          );

          updatedProducts[index].colorVariants = newArr;

          return {
            ...previousData,
            data: updatedProducts,
          };
        });
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const { mutate: deleteProduct, isLoading: deleteProductLoading } =
    useMutation((id: number) => deleteProductCall(id), {
      onSuccess: (_data, id) => {
        const newPagesArray =
          data?.pages.map((page) => ({
            ...page,
            data: page.data.filter((val) => val.id !== id),
          })) ?? [];
        queryClient.setQueryData(['products'], () => {
          return {
            pages: newPagesArray,
            pageParams: data?.pageParams,
          };
        });
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    });

  return {
    products,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    deleteVariant,
    deleteProduct,
    deleteProductLoading,
    count,
    limit,
  };
};
