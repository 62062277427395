import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const BreadcrumbsContainer = styled.ul`
  display: flex;
  gap: 0.875rem;
  list-style: none;
  padding-block: 0.875rem;
  font-size: 0.875rem;
`;

const ListItem = styled.li`
  text-transform: capitalize;
  cursor: pointer;
`;

export const Breadcrumbs = ({
  list,
}: {
  list: { name: string; link: string }[];
}) => {
  const navigate = useNavigate();
  return (
    <BreadcrumbsContainer>
      <ListItem onClick={() => navigate('/')}>ESOTIQ</ListItem>
      {list.map((crumb) => (
        <>
          <ListItem>•</ListItem>
          <ListItem onClick={() => crumb.link && navigate(`/${crumb.link}`)}>
            {crumb.name.toLocaleUpperCase()}
          </ListItem>
        </>
      ))}
    </BreadcrumbsContainer>
  );
};
