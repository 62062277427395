import { OptionSelect } from 'components/SelectOpstions/OptionSelect';
import { useParamsSearch } from 'hooks/useParamsSearch';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { SortByIcon } from '../../assets/SortByIcon';
import { Button } from '../Buttons/Button';

const DropdownWithButtonContainer = styled.div`
  width: fit-content;
  position: relative;
`;

const Menu = styled.dialog<{ $left: number }>`
  position: absolute;
  left: ${({ $left }) => $left}px;
  top: 100%;
  background-color: white;
  border: none;
  padding: 3rem;
  width: fit-content;
  white-space: nowrap;

  &::backdrop {
    background-color: aliceblue;
  }
`;

const options = [
  {
    name: 'Uobičajno',
    value: null,
  },
  {
    name: 'Najnovije',
    value: 'newest',
  },
  {
    name: 'Cena opadajuća',
    value: 'priceHighToLow',
  },
  {
    name: 'Cena rastuća',
    value: 'priceLowToHigh',
  },
  { name: 'Sniženje', value: 'sale' },
];

export const DropdownWithButton = () => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDialogElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const [left, setLeft] = useState(0);
  const { searchParams, handleSetUrlSearchParams } = useParamsSearch();

  useLayoutEffect(() => {
    setLeft(
      ref?.current?.getBoundingClientRect().left +
        ref?.current?.getBoundingClientRect().width >
        window.innerWidth
        ? -(
            container?.current?.getBoundingClientRect().left +
            ref?.current?.getBoundingClientRect().width -
            window.innerWidth +
            16
          )
        : 0
    );
  }, [opened]);

  useEffect(() => {
    console.log(left);
  }, [left]);

  return (
    <DropdownWithButtonContainer ref={container}>
      <Button
        styleType="text"
        width="fit-content"
        onClick={() => {
          setOpened((prev) => !prev);
        }}
      >
        SORTIRAJ <SortByIcon />
      </Button>
      <Menu
        ref={ref}
        $left={left}
        open={opened}
        onClose={() => {
          setOpened(false);
        }}
      >
        <OptionSelect
          options={options}
          renderTitle={(option) => option.name}
          selectedRule={(option) => searchParams.get('sortBy') === option.value}
          onSelect={(option) => {
            setOpened(false);
            handleSetUrlSearchParams('sortBy', option.value);
          }}
        />
      </Menu>
    </DropdownWithButtonContainer>
  );
};
