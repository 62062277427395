import { SuccessPaymentIcon } from 'assets/SuccessPaymentIcon';
import { Button } from 'components/Buttons/Button';
import { Divider } from 'components/Drividers/Divider';
import { FullContainerLoader } from 'components/Loader/Loader';
import { CartContext } from 'context/CartContext';
import { useOrderWithUUID } from 'hooks/useOrderWithUUID';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { Flex, SectionContainer } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { format } from 'date-fns';
import { useAuth } from 'context/AuthContext';

const InfoContainer = styled.div`
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Info = ({ title, content }) => {
  return (
    <Flex $gap="5rem" style={{ textAlign: 'right' }}>
      <Flex $noFull $bold={600} style={{ whiteSpace: 'nowrap' }}>
        {title}:
      </Flex>
      <Flex $bold={300} $jc="flex-end">
        {content}
      </Flex>
    </Flex>
  );
};

const ProductInfo = ({
  product,
  discount = null,
}: {
  product: {
    id: number;
    name: string;
    color: string;
    size: string;
    basePrice: number;
    discount: number;
    price: number;
    quantity: number;
    image: string;
  };
  discount: number | null;
}) => {
  const realPrice = (product.price - (product.price * discount) / 100) / 100;
  return (
    <Flex style={{ backgroundColor: 'rgba(0,0,0,0.05)' }} $p="1rem">
      <Flex $bold={600} $column>
        <Flex>{product.name}</Flex>
        {/* <Flex $textcolor={theme.colors.primary} $bold={300}>
          RSD {(product.price / 100).toFixed(2)}
        </Flex> */}
        <Flex $bold={300} $fs={0.875}>
          Cena: RSD {(product.price / 100).toFixed(2)}
        </Flex>
        {discount && (
          <>
            <Flex $bold={300} $fs={0.875}>
              Popust: RSD {((product.price * discount) / 100 / 100).toFixed(2)}
            </Flex>
            <Flex $bold={300} $fs={0.875}>
              Cena nakon popusta: RSD {realPrice.toFixed(2)}
            </Flex>
          </>
        )}

        <Flex $bold={300} $fs={0.875}>
          Količina: {product.quantity}
        </Flex>
        <Flex $bold={300} $fs={0.875}>
          Boja: {product.color}
        </Flex>
        <Flex $bold={300} $fs={0.875}>
          ID proizvoda: {product.id}
        </Flex>
      </Flex>
      <Flex $bold={300} $jc="flex-end">
        RSD {realPrice.toFixed(2)} x {product.quantity} =
        <Flex $textcolor={theme.colors.primary} $bold={300} $noFull>
          RSD {(realPrice * product.quantity).toFixed(2)}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cart = useContext(CartContext);
  const { isLoading, data } = useOrderWithUUID(id);
  const { user } = useAuth();

  useEffect(() => {
    cart.clearCart();
  }, [user]);

  if (isLoading)
    return (
      <SectionContainer>
        <Flex $jc="center" $ai="center">
          <FullContainerLoader />
        </Flex>
      </SectionContainer>
    );

  return (
    <Flex $jc="center" $p="3rem 1rem" $column $ai="center" $gap="3rem">
      <Flex $jc="center" $bold={700} $fs={1.5}>
        PLAĆANJE USPEŠNO
      </Flex>
      <Flex $jc="center" $bold={700} $fs={1.1}>
        Račun platne kartice zadužen
      </Flex>
      <Flex $gap="1rem" $jc="center">
        <SuccessPaymentIcon color={theme.colors.primary} />
      </Flex>
      <InfoContainer>
        <Flex $fs={1.1} $bold={700}>
          Podaci o Kupcu
        </Flex>
        <Divider />
        {data.billingInformations.firstName && (
          <Info title="Ime" content={data.billingInformations.firstName} />
        )}
        {data.billingInformations.lastName && (
          <Info title="Prezime" content={data.billingInformations.lastName} />
        )}
        {data.billingInformations.companyName && (
          <Info
            title="Naziv kompanije"
            content={data.billingInformations.companyName}
          />
        )}

        {data.billingInformations.idNumber && (
          <Info title="PIB" content={data.billingInformations.idNumber} />
        )}
        <Info title="Email" content={data.billingInformations.email} />
        <Info title="Ulica" content={data.billingInformations.address} />
        <Info
          title="Kućni broj"
          content={data.billingInformations.houseNumber}
        />
        {data.billingInformations.apartmentNumber && (
          <Info
            title="Stan"
            content={data.billingInformations.apartmentNumber}
          />
        )}
        <Info
          title="Poštanski broj"
          content={data.billingInformations.postalCode}
        />
        <Info title="Grad" content={data.billingInformations.city} />
        <Info title="Država" content={data.billingInformations.country} />
      </InfoContainer>
      <InfoContainer>
        <Flex $fs={1.1} $bold={700}>
          Podaci o Trgovcu
        </Flex>
        <Divider />
        <Info
          title="Naziv"
          content="DM STAR CONSULTING d.o.o. Beograd – ogranak 2 ESOTIQ"
        />
        <Info title="PIB" content="111132614" />
        <Info
          title="Adresa"
          content="Beograd, opština Zvezdara, Bulevar Kralja Aleksandra br. 458/32"
        />
      </InfoContainer>
      <InfoContainer>
        <Flex $fs={1.1} $bold={700}>
          Informacije o transakciji
        </Flex>
        <Divider />
        <Info
          title="Datum transakcije"
          content={format(
            new Date(data.orderPaymentTransaction.createdAt),
            'dd. MM. yyyy'
          )}
        />
        <Info
          title="Vreme transakcije"
          content={format(
            new Date(data.orderPaymentTransaction.createdAt),
            'HH:mm'
          )}
        />
        <Info
          title="Auth code"
          content={data.orderPaymentTransaction.authCode}
        />
        <Info title="ID" content={data.id} />
        <Info
          title="Response"
          content={data.orderPaymentTransaction.response}
        />
        <Info
          title="ProcReturnCode"
          content={data.orderPaymentTransaction.procReturnCode}
        />
        <Info
          title="mdStatus"
          content={data.orderPaymentTransaction.mdStatus}
        />
      </InfoContainer>
      <InfoContainer>
        <Flex $fs={1.1} $bold={700}>
          Podaci o narudžbini
        </Flex>
        <Divider />
        <Flex>PROIZVODI:</Flex>
        {data.items.map((item) => (
          <ProductInfo
            key={item.id}
            product={item}
            discount={data.discountPercentage}
          />
        ))}
        <Flex>REZIME:</Flex>
        <Info
          title="Ukupna cena"
          content={`RSD ${(
            data.items.reduce(
              (total, item) =>
                total +
                (item.price * item.quantity * (100 - data.discountPercentage)) /
                  100,
              0
            ) / 100
          ).toFixed(2)}`}
        />
        <Info
          title="Cena dostave"
          content={`RSD ${(data.deliveryFeePrice / 100).toFixed(2)}`}
        />
        <Info
          title="Za plaćanje"
          content={`RSD ${(
            ((data.items.reduce(
              (total, item) => total + item.price * item.quantity,
              0
            ) *
              (100 - data.discountPercentage)) /
              100 +
              data.deliveryFeePrice) /
            100
          ).toFixed(2)}`}
        />
      </InfoContainer>
      <Flex $noFullHeight $jc="center">
        <Flex $column $noFull $jc="center" $ai="center">
          <Flex $noFull $bold={300}>
            Broj porudžbine
          </Flex>
          <Flex $noFull $textcolor={theme.colors.primary}>
            {data.id}
          </Flex>
        </Flex>
      </Flex>
      <Button styleType="color" onClick={() => navigate('/')}>
        Nastavi kupovinu
      </Button>
    </Flex>
  );
};
