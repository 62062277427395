import { useInfiniteQuery } from '@tanstack/react-query';
import { getProducts } from 'api/products.api';
import { useMemo } from 'react';

export const useInfiniteProducts = () => {
  const limit = 20;

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['products_infinite_scroll'],
      queryFn: ({ pageParam = 1 }) => {
        return getProducts({
          page: pageParam,
          limit: 20,
        });
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
    });

  const products = useMemo(
    () => data?.pages?.map((data) => (data?.data ? data.data : [])).flat(1),
    [data]
  );

  return {
    products,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    limit,
  };
};
