import { useFocus } from 'hooks/useFocus';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  value: string;
  name: string;
  label: string;
  disabled: boolean;
  errorMessage?: string;
  onValueChange: (name: string, value: string) => void;
  iconElement?: React.ReactElement;
  prefix?: string;
  onButtonClick: () => void;
}

const InputContainer = styled.div<{
  $error?: boolean;
}>`
  position: relative;
  width: 100%;
  display: flex;
  margin-block: 1rem;
  border: 1px solid ${theme.colors.secondaryDark};
  border-radius: 2px;

  &:hover {
    border-color: ${({ $error }) => !$error && theme.colors.secondaryDark};
  }
`;
const InputContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin-inline: 0.5rem;
`;

const StyledLabel = styled.label<{
  $focused: boolean;
  $error?: boolean;
}>`
  position: absolute;
  left: 0;
  background-color: white;
  padding: 0.2rem;
  top: ${({ $focused }) => ($focused ? '0' : '50%')};
  font-size: ${({ $focused }) => ($focused ? '0.8' : '1')}rem;
  color: ${({ $error }) => ($error ? theme.colors.error : 'inherit')};
  translate: 0 -50%;
  transition: all 200ms ease-in;
  font-weight: 300;
  cursor: ${({ $focused }) => ($focused ? 'default' : 'text')};
`;

// const ErrorMessage = styled.div`
//   position: absolute;
//   top: calc(100% + 5px);
//   color: ${theme.colors.error};
//   font-size: 0.875rem;
//   font-weight: 300;
// `;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  padding-block: 1rem;
  transition: all 200ms ease-in;
  background-color: transparent;
`;
const Button = styled.div`
  border: none;
  background-color: ${theme.colors.secondaryDark};
  color: white;
  padding-inline: 3rem;
  flex: 1;
  display: flex;
  height: 100%;
  height: 3.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

// const IconContainer = styled.div`
//   position: absolute;
//   right: 0;
//   top: 50%;
//   translate: 0 -50%;
// `;

export const ButtonInput = ({
  required,
  label,
  value,
  disabled,
  onButtonClick,
  name,
  onValueChange,
  ...rest
}: Props) => {
  const [focusRef, isFocused] = useFocus();
  return (
    <InputContainer>
      <InputContent>
        <StyledLabel $focused={isFocused || !!value} htmlFor={name}>
          {label} {required && '*'}
        </StyledLabel>
        <StyledInput
          value={value}
          autoComplete="off"
          ref={focusRef as React.RefObject<any>}
          id={name}
          onChange={(e) => onValueChange(name, e.target.value)}
          name={name}
          disabled={disabled}
          {...rest}
        />
        {/* 
        <IconContainer>
          {iconElement || (errorShown && error && <ErrorIcon />)}
        </IconContainer> */}
      </InputContent>
      <Button onClick={onButtonClick}>
        {disabled ? 'Deaktiviraj' : 'Aktiviraj'}
      </Button>
    </InputContainer>
  );
};
