import { DeleteModal } from 'admin/modals/DeleteModal';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { ModalContext } from 'components/Modal/ModalContext';
import { useCategories } from 'hooks/useCategories';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const Categories = () => {
  const { data, deleteMutation, isLoading } = useCategories();
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <List
      headerItems={[{ header: 'Naziv kategorije', span: 20 }]}
      actionTitle="Dodaj kategoriju"
      loading={isLoading}
      action={() => navigate('create')}
    >
      {data?.map((item) => (
        <ListItem.Item
          key={1 + item.id}
          items={[{ item: item.name, span: 20 }]}
          onDeleteClick={() =>
            openModal(
              <DeleteModal
                deleteAction={() => deleteMutation.mutate(item.id)}
              />
            )
          }
          onEditClick={() => navigate(`edit/${item.id}`)}
        />
      ))}
    </List>
  );
};
