import { CloseIcon } from 'assets/CloseIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { OptionSelect } from 'components/SelectOpstions/OptionSelect';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const Container = styled.div<{
  $opened: boolean;
}>`
  width: ${({ $opened }) => ($opened ? 'calc(100% / 3)' : 0)};
  position: absolute;
  right: 0;
  height: 100%;
  background-color: white;
  top: 1;
  transition: all 200ms ease-in;

  @media screen and (max-width: 600px) {
    width: ${({ $opened }) => ($opened ? '100%' : 0)};
    height: 100vh;
    position: fixed;
    top: 0;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100svh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  overflow: scroll;

  top: 0px;
`;

type Props = {
  opened: boolean;
  onClose: () => void;
  chosenSize: number;
  onSelect: (value: number) => void;
  sizes: Item[];
};

export const SizeContainer = ({
  opened,
  onClose,
  chosenSize,
  onSelect,
  sizes,
}: Props) => {
  return (
    <Container $opened={opened}>
      <Content>
        <Flex $ai="center" $bold={700} $noFullHeight>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          IZABERI VELIČINU
        </Flex>

        <OptionSelect<Item>
          options={sizes}
          renderTitle={(option) => option.size}
          renderDesc={(option) =>
            option.quantity === 1 ? (
              <Flex>Poslednji na lageru</Flex>
            ) : option.quantity < 1 ? (
              <Flex
                // ?This functionality is out for MVP
                // onClick={() => {
                //   openModal(<Availability />);
                // }}
                $pointer
              >
                Nema na lageru
              </Flex>
            ) : (
              ''
            )
          }
          selectedRule={(option) => option.id === chosenSize}
          onSelect={(option) => onSelect(option.id)}
          renderDisabled={(option) => option.quantity < 1}
        />
      </Content>
    </Container>
  );
};
