import { styled } from 'styled-components';

export const ProductListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ListContainer = styled.ul<{
  $activeLayout: number;
}>`
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: repeat(${({ $activeLayout }) => $activeLayout}, 1fr);
  min-height: 70vh;
`;
