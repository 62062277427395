import { useQuery } from '@tanstack/react-query';
import { getColorVariantBySlug } from 'api/products.api';

export const useColorVariant = ({ id }: { id: string }) => {
  const { isLoading, error, data, refetch } = useQuery<ProductVariant>(
    ['colorVariant', { id }],
    () => getColorVariantBySlug(id)
  );

  return { isLoading, error, data, refetch };
};
