import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChooseAttributes } from 'admin/modals/ChooseAttributes';
import { SelectProductSet } from 'admin/modals/SelectProductSet';
import { editProductCall } from 'api/products.api';
import { Button } from 'components/Buttons/Button';
import { CategorySelect } from 'components/Inputs/CategorySelect';
import { Checkbox } from 'components/Inputs/Checkbox';
import { CollectionSelect } from 'components/Inputs/CollectionSelect';
import { FixedTextarea } from 'components/Inputs/FixedTextarea';
import { Input } from 'components/Inputs/Input';
import { FullLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Flex } from 'styles/Containers.styles';
import { SimpleCollectionType } from 'types/collection.types';
import { SimpleProductType } from 'types/products.types';
import { SimpleSubcategoryType } from 'types/subcategory.types';

export const EditProductForm = ({ product }: { product: Product }) => {
  const [form, setForm] = useState({
    name: product.name,
    description: product.description,
    price: product.price.basePrice / 100,
    discount: product.discount,
    material: product.materialComposition,
  });
  const [attributes, setAttributes] = useState(
    product.attributes
      .map((at) => at.values)
      .flat(1)
      .map((value) => value.id)
  );
  const [similarProducts, setSimilarProducts] = useState(
    product.similarProducts.map((pr) => pr.id)
  );
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isNew, setIsNew] = useState(product.isNew);
  const [isSale, setIsSale] = useState(product.isSale);
  const [isDraft, setIsDraft] = useState(product.isDraft);
  const [isOutlet, setIsOutlet] = useState(product.isOutlet);
  const [subcategory, setSubcategory] = useState<SimpleSubcategoryType>(
    product.subcategory
  );
  const [collection, setCollection] = useState<SimpleCollectionType>(
    product.collection
  );
  const { openModal } = useContext(ModalContext);

  const onChange = (prop: string, value: string) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };

  const { mutate: editProductMutation, isLoading: editLoading } = useMutation(
    async (props: { data: any; id: number }) =>
      await editProductCall(props.data, props.id),
    {
      onSuccess: (returnData: SimpleProductType) => {
        queryClient.invalidateQueries(['product']);
        queryClient.setQueryData(['products'], (previousData: any) => {
          const products = previousData?.pages
            ?.map((data) => (data?.data ? data.data : []))
            .flat(1);
          navigate(-1);
          toast.success('Proizvod je sačuvan');
          if (products) {
            const updatedProducts = [...products, returnData];
            return {
              ...previousData,
              products: {
                ...products,
                products: updatedProducts,
              },
            };
          }
        });
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <Flex>
      <Flex $column>
        <Input
          label="Ime"
          value={form.name}
          name="name"
          // error={!form.name}
          onValueChange={onChange}
        />
        <Flex $column $gap="1rem">
          <FixedTextarea
            name="description"
            value={form.description}
            onValueChange={onChange}
            label="Opis proizvoda"
            error={false}
            limit={10000}
          />
          <FixedTextarea
            name="material"
            value={form.material}
            onValueChange={onChange}
            label="Informacije o proizvodu"
            error={false}
          />
        </Flex>
      </Flex>
      <Flex $column>
        <Input
          label="Cena"
          value={form.price}
          name="price"
          // error={!form.name}
          pattern="[0-9]*"
          onValueChange={onChange}
        />
        <Flex $gap="1rem" $noFullHeight>
          <Flex>
            <Checkbox
              text="Novo"
              checked={isNew}
              onChange={() => {
                setIsNew((prev) => !prev);
              }}
            />
            <Checkbox
              text="Draft"
              checked={isDraft}
              onChange={() => {
                setIsDraft((prev) => !prev);
              }}
            />
            <Checkbox
              text="Na popustu"
              checked={isSale}
              onChange={() => {
                setIsSale((prev) => !prev);
              }}
            />
            <Checkbox
              text="Outlet"
              checked={isOutlet}
              onChange={() => {
                setIsOutlet((prev) => !prev);
              }}
            />
          </Flex>
        </Flex>
        <Input
          label="Popust u %"
          value={form.discount}
          name="discount"
          disabled={!isSale}
          pattern="[0-9]*"
          // error={!form.name}
          onValueChange={onChange}
        />
        <CategorySelect
          initialValue={product.subcategory}
          onChange={(value) => setSubcategory(value)}
        />
        <CollectionSelect
          initialValue={product.collection}
          onChange={(value) => setCollection(value)}
        />
        <Flex $column $gap="1rem" $mt="1rem">
          <Button
            width="100%"
            styleType="outline-dark"
            onClick={() => {
              if (!subcategory) {
                toast.error('Podkategorija mora biti izabrana.');
              } else {
                openModal(
                  <ChooseAttributes
                    subcategoryId={subcategory.id}
                    preselected={attributes}
                    onSave={(value) => {
                      setAttributes(value);
                    }}
                  />
                );
              }
            }}
          >
            Dodaj atribute
          </Button>
          <Button
            width="100%"
            styleType="outline-dark"
            onClick={() => {
              openModal(
                <SelectProductSet
                  preselected={similarProducts}
                  onSave={(value) => {
                    setSimilarProducts(value);
                  }}
                  currentProduct={product.id}
                />
              );
            }}
          >
            Slični proizvodi
          </Button>
        </Flex>
        <Flex>
          <Button
            onClick={() => {
              if (!subcategory) {
                toast.error('Podkategorija mora biti izabrana');
                return;
              } else if (!form.price) {
                toast.error('Cena proizvoda mora biti uneta');
                return;
              } else if (!form.name) {
                toast.error('Naziv proizvoda mora biti unet');
                return;
              } else if (isSale && !form.discount) {
                toast.error('Procenat popusta mora biti unet');
                return;
              } else if (
                isSale &&
                (Number(form.discount) < 1 || Number(form.discount) > 100)
              ) {
                toast.error('Vrednost popusta mora biti izmedju 1% i 100%');
                return;
              }
              const data: any = {
                name: form.name,
                description: form.description,
                materialComposition: form.material,
                subCategoryId: subcategory?.id,
                isSale,
                isNew,
                isDraft,
                isOutlet,
                discount: isSale ? form.discount : null,
                basePrice: form.price * 100,
                categoryAttributeValueIds: attributes,
                similarProductIds: similarProducts,
              };

              if (collection?.id) {
                data.collectionId = collection?.id || null;
              }

              editProductMutation({
                data,
                id: product.id,
              });
            }}
          >
            Sačuvaj
          </Button>
        </Flex>
      </Flex>
      <FullLoader loading={editLoading} />
    </Flex>
  );
};
