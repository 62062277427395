import { Button } from 'components/Buttons/Button';
import { BigImageInput } from 'components/Inputs/BigImageInput';
import { Input } from 'components/Inputs/Input';
import { MultiSelectAdminInput } from 'components/Inputs/MultiSelectAdminInput';
import { useSubcategories } from 'hooks/useSubcategories';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from 'styles/Containers.styles';

export const CreateCategoryForm = ({
  defaultImage = null,
  defaultName = '',
  defaultSlug = '',
  defaultSelected = [],
  mutation,
}) => {
  const [form, setForm] = useState({
    image: defaultImage,
    name: defaultName,
    slug: defaultSlug,
  });
  const [selected, setSelected] = useState(
    defaultSelected.map((item) => item.id)
  );
  const { data, isLoading } = useSubcategories();

  const onChange = (prop: string, value: any) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };
  const toggleSelected = (id: number) => {
    const arr = [...selected];
    const index = arr.findIndex((el) => el === id);
    if (index >= 0) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    setSelected(arr);
  };

  return (
    <Flex $column>
      <BigImageInput
        onChange={(image) => onChange('image', image)}
        image={form.image}
      />
      <Flex $noFullHeight>
        <Input
          name="name"
          value={form.name}
          onValueChange={onChange}
          label="Naziv kategorije"
        />
        <Input
          name="slug"
          value={form.slug}
          onValueChange={onChange}
          label="URL slug"
        />
      </Flex>
      <MultiSelectAdminInput
        itemList={data ? [...data] : []}
        isLoading={isLoading}
        renderText={(item) => item.key}
        renderChecked={(item) => selected.includes(item.id)}
        onCheck={(item) => {
          toggleSelected(item.id);
        }}
      />
      <Flex $noFullHeight $jc="flex-end" $mt="1rem">
        <Button
          onClick={() => {
            if (!form.name || !form.image || !form.slug) {
              toast.error('Molimo Vas unesite sve podatke');
              return;
            }
            mutation.mutate({ ...form, subcategoryIds: selected });
          }}
          disabled={!form.name || !form.image || !form.slug}
        >
          {mutation.isLoading ? 'Učitavanje' : 'Sačuvaj'}
        </Button>
      </Flex>
    </Flex>
  );
};
