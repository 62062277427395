import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCategory } from 'api/categories.api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateCategoryType } from 'types/category.types';
import { CreateCategoryForm } from './CreateCategoryForm';

export const CreateCategory = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createMutation = useMutation(
    (data: CreateCategoryType) => {
      const dataForm = new FormData();
      dataForm.append(
        'category',
        JSON.stringify({
          name: data.name,
          subcategoryIds: data.subcategoryIds,
          slug: data.slug,
        })
      );
      dataForm.append('image', data.image);
      return createCategory(dataForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['categories']);
        navigate(-1);
      },
      onError: (err: any) => {
        toast.error(err.response.data.message);
      },
    }
  );

  return <CreateCategoryForm mutation={createMutation} />;
};
