/* eslint-disable camelcase */

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { Flex } from '../../styles/Containers.styles';
import {
  CardCategory,
  CardContainer,
  CardImage,
  ColorDot,
} from './ProductCard.style';

export const ProductCard = ({
  product,
  index,
  hoverable = false,
}: {
  product: SimpleProductType;
  index: number;
  hoverable?: boolean;
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(true);
  const [hovered, setHovered] = useState(false);

  return (
    <CardContainer
      $imageLoaded={isImageLoaded}
      onMouseEnter={() => {
        if (hoverable && product.images?.[1]) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onClick={() => {
        window.gtag('event', 'select_item', {
          item_list_id: product.id,
          item_list_name: product.name,
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              discount: Number(
                ((product.basePrice - product.price) / 100).toFixed(2)
              ),
              index: index,
              item_brand: 'Esotiq',
              item_variant: product.color.name,
              price: Number(product.price.toFixed(2)),
            },
          ],
        });
      }}
    >
      <Link to={'/product/' + product.slug}>
        <CardImage
          src={product.images[0]}
          onLoadStart={() => {
            setIsImageLoaded(false);
          }}
          onLoad={() => {
            setIsImageLoaded(true);
          }}
          hidden={hovered}
        />
        {hoverable && (
          <CardImage
            hidden={!hovered}
            src={product.images[1]}
            onLoadStart={() => {
              setIsImageLoaded(false);
            }}
            onLoad={() => {
              setIsImageLoaded(true);
            }}
          />
        )}
      </Link>
      <CardCategory>
        {product.isNew && product.isSale
          ? 'NOVO / SNIŽENO'
          : product.isNew
          ? 'NOVO'
          : product.isSale
          ? 'SNIŽENO'
          : null}
      </CardCategory>

      <Flex $uppercase $noFullHeight $fs={1}>
        {product.name}
      </Flex>
      <div>
        <Flex $fs={0.8} $inline>
          {product.price > 0 && product.isSale && (
            <s>RSD {(product.basePrice / 100).toFixed(2)}</s>
          )}
        </Flex>
        <Flex $noFullHeight $fs={0.9}>
          <b style={{ color: product.isSale && theme.colors.primary }}>
            {product.price <= 0
              ? 'Nema na stanju'
              : `RSD ${(product.price / 100).toFixed(2)}`}
          </b>{' '}
        </Flex>
      </div>
      <Flex $noFullHeight style={{ flexWrap: 'wrap' }}>
        {product.colorVariants?.map((color) => (
          <ColorDot key={color.name} $color={color.color} />
        ))}
      </Flex>
    </CardContainer>
  );
};
