import React from 'react';

export const OneGridItemIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M21 2h-18c-0.552 0-1 0.448-1 1v18c0 0.552 0.448 1 1 1h18c0.552 0 1-0.448 1-1v-18c0-0.552-0.448-1-1-1zM20 20h-16v-16h16v16z"></path>
    </svg>
  );
};
