import { ImageIcon } from 'assets/ImageIcon';
import { useMemo, useRef } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div<{
  $image: string;
}>`
  width: 100%;
  aspect-ratio: 32/5;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  background: url(${({ $image }) => $image}) center no-repeat;
  background-size: contain;
  background-color: ${({ $image }) =>
    $image ? 'white' : theme.colors.imageInputColor};
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const IconImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 3rem;
    width: 3rem;
  }
`;
export const BigImageInput = ({ image, onChange }) => {
  const ref = useRef(null);
  const src = useMemo(
    () =>
      image
        ? typeof image === 'string'
          ? image
          : URL.createObjectURL(image)
        : null,
    [image]
  );

  return (
    <Container
      onClick={() => {
        if (ref.current) {
          ref.current.click();
        }
      }}
      $image={src}
    >
      {!image && (
        <>
          <IconImage>
            <ImageIcon />
          </IconImage>
          <Flex $noFullHeight $noFull>
            Dodaj sliku
          </Flex>
        </>
      )}
      <input
        accept="image/*"
        style={{ display: 'none' }}
        type="file"
        ref={ref}
        onChange={(e) => {
          const file = e.target.files[0];
          onChange(file);
        }}
      />
    </Container>
  );
};
