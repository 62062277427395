import { requestLabelById } from 'api/orders.api';
import { PrinterIcon } from 'assets/PrinterIcon';
import { Button } from 'components/Buttons/Button';
import { Divider } from 'components/Drividers/Divider';
import { FullContainerLoader, FullLoader } from 'components/Loader/Loader';
import { useOrderById } from 'hooks/useOrderById';
import PDFMerger from 'pdf-merger-js/browser';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  width: 30rem;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 1rem;
`;

const InfoContainer = styled.div`
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Info = ({ title, content }) => {
  return (
    <Flex $gap="5rem" style={{ textAlign: 'right' }}>
      <Flex $noFull $bold={600} style={{ whiteSpace: 'nowrap' }}>
        {title}:
      </Flex>
      <Flex $bold={300} $jc="flex-end">
        {content}
      </Flex>
    </Flex>
  );
};

const ProductInfo = ({
  product,
  discount = null,
}: {
  product: {
    id: number;
    name: string;
    color: string;
    size: string;
    basePrice: number;
    discount: number;
    price: number;
    quantity: number;
    image: string;
    catalogueId: string;
  };
  discount: number | null;
}) => {
  const realPrice = (product.price - (product.price * discount) / 100) / 100;
  return (
    <Flex style={{ backgroundColor: 'rgba(0,0,0,0.05)' }} $p="1rem">
      <Flex $bold={600} $column>
        <Flex>{product.name}</Flex>
        {/* <Flex $textcolor={theme.colors.primary} $bold={300}>
            RSD {(product.price / 100).toFixed(2)}
          </Flex> */}
        <Flex $bold={300} $fs={0.875}>
          Cena: RSD {(product.price / 100).toFixed(2)}
        </Flex>
        {discount && (
          <>
            <Flex $bold={300} $fs={0.875}>
              Popust: RSD {((product.price * discount) / 100 / 100).toFixed(2)}
            </Flex>
            <Flex $bold={300} $fs={0.875}>
              Cena nakon popusta: RSD {realPrice.toFixed(2)}
            </Flex>
          </>
        )}

        <Flex $bold={300} $fs={0.875}>
          Količina: {product.quantity}
        </Flex>
        <Flex $bold={300} $fs={0.875}>
          Veličina: {product.size}
        </Flex>
        <Flex $bold={300} $fs={0.875}>
          Boja: {product.color}
        </Flex>
        <Flex $bold={300} $fs={0.875}>
          ID proizvoda: {product.id}
        </Flex>
        <Flex $bold={300} $fs={0.875}>
          Kataloški broj: {product.catalogueId}
        </Flex>
      </Flex>
      <Flex $bold={300} $jc="flex-end">
        RSD {realPrice.toFixed(2)} x {product.quantity} =
        <Flex $textcolor={theme.colors.primary} $bold={300} $noFull>
          RSD {(realPrice * product.quantity).toFixed(2)}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const OrderDetailsModal = ({ id }: { id: number }) => {
  const { data, isLoading } = useOrderById(id);
  const [loading, setLoading] = useState(false);

  const base64ToPdf = (data: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    // eslint-disable-next-line no-plusplus
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      // eslint-disable-next-line no-plusplus
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const blob = new Blob(byteArrays, { type: 'application/pdf' });

    return URL.createObjectURL(blob);
  };

  const mergePdfs = async (pdf: string) => {
    const merger = new PDFMerger();
    const blob = base64ToPdf(pdf);
    await merger.add(blob);
    const mergedPdf = await merger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);

    return url;
  };

  const printPdfWithUrl = (url: string) => {
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.src = url;
    iframe.style.display = 'none';

    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        //   iframe.contentWindow.print('print', false, null)

        iframe.contentWindow?.print();
      }, 1000);
    };
  };

  const printLabels = async () => {
    setLoading(true);
    try {
      const codes = await requestLabelById(id);
      const pdfs = await mergePdfs(codes);
      printPdfWithUrl(pdfs);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Flex
        $noFullHeight
        $jc="space-between"
        $fs={1.2}
        $bold={700}
        $ai="center"
        $column
      >
        Detalji narudžbine {id}
        <Flex>
          <Button width="100%" styleType="outline-dark" onClick={printLabels}>
            <PrinterIcon />
            Odštampaj nalepnicu
          </Button>
        </Flex>
      </Flex>
      {isLoading && <FullContainerLoader />}
      {data && (
        <>
          <InfoContainer>
            <Info
              title="Način plaćanja"
              content={data.paymentMethod === 'card' ? 'Kartica' : 'Po uzeću'}
            />

            <Divider />
            <Flex $fs={1.1} $bold={700}>
              Podaci o dostavi
            </Flex>
            <Divider />

            <Info title="Ime" content={data?.deliveryAddress.firstName} />
            <Info title="Prezime" content={data?.deliveryAddress.lastName} />
            <Info title="Email" content={data?.deliveryAddress.email} />
            <Info title="Ulica" content={data?.deliveryAddress.address} />
            <Info
              title="Kućni broj"
              content={data?.deliveryAddress.houseNumber}
            />
            {data?.deliveryAddress.apartmentNumber && (
              <Info
                title="Stan"
                content={data?.deliveryAddress.apartmentNumber}
              />
            )}
            <Info
              title="Poštanski broj"
              content={data?.deliveryAddress.postalCode}
            />
            <Info title="Grad" content={data?.deliveryAddress.city} />
            <Info title="Država" content={data?.deliveryAddress.country} />
            {data?.comment && <Info title="Komentar" content={data.comment} />}
          </InfoContainer>
          <InfoContainer>
            <Flex $fs={1.1} $bold={700}>
              Podaci o platiocu
            </Flex>
            <Divider />
            {data?.billingInformations.firstName && (
              <Info title="Ime" content={data?.billingInformations.firstName} />
            )}
            {data?.billingInformations.lastName && (
              <Info
                title="Prezime"
                content={data?.billingInformations.lastName}
              />
            )}
            {data?.billingInformations.companyName && (
              <Info
                title="Naziv kompanije"
                content={data?.billingInformations.companyName}
              />
            )}
            {data?.billingInformations.idNumber && (
              <Info title="PIB" content={data?.billingInformations.idNumber} />
            )}
            <Info title="Email" content={data?.billingInformations.email} />
            <Info title="Ulica" content={data?.billingInformations.address} />
            <Info
              title="Kućni broj"
              content={data?.billingInformations.houseNumber}
            />
            {data?.billingInformations.apartmentNumber && (
              <Info
                title="Stan"
                content={data?.billingInformations.apartmentNumber}
              />
            )}
            <Info
              title="Poštanski broj"
              content={data?.billingInformations.postalCode}
            />
            <Info title="Grad" content={data?.billingInformations.city} />
            <Info title="Država" content={data?.billingInformations.country} />
          </InfoContainer>
          <InfoContainer>
            <Flex $fs={1.1} $bold={700}>
              Podaci o narudžbini
            </Flex>
            <Divider />
            <Flex>PROIZVODI:</Flex>
            {data?.items?.map((item) => (
              <ProductInfo
                key={item.id}
                product={item}
                discount={data.discountPercentage}
              />
            ))}
            <Flex>REZIME:</Flex>
            <Info
              title="Ukupna cena"
              content={`RSD ${(
                data?.items?.reduce(
                  (total, item) =>
                    total +
                    (item.price *
                      item.quantity *
                      (100 - data.discountPercentage)) /
                      100,
                  0
                ) / 100
              ).toFixed(2)}`}
            />
            <Info
              title="Cena dostave"
              content={`RSD ${(data.deliveryFeePrice / 100).toFixed(2)}`}
            />
            <Info
              title="Za plaćanje"
              content={`RSD ${(
                ((data?.items?.reduce(
                  (total, item) => total + item.price * item.quantity,
                  0
                ) *
                  (100 - data.discountPercentage)) /
                  100 +
                  data.deliveryFeePrice) /
                100
              ).toFixed(2)}`}
            />
          </InfoContainer>
        </>
      )}
      <FullLoader loading={loading} />
    </Container>
  );
};
