import BrafittingImage from 'assets/brafitting.jpeg';
import { FooterPageRenderer } from 'components/FooterPagesRenderer/FooterPageRenderer';
import { Link } from 'react-router-dom';

const options = [
  { image: BrafittingImage },
  {
    text: 'Perfect fit in just a few simple steps! In ESOTIQ stores, we have helped to select thousands of bras for our clients. Now You can find out what’s your size without leaving home! By answering a few questions, you will find out which ESOTIQ bra size fits you best! All You need to do is:',
  },
  {
    text: (
      <ul style={{ marginLeft: '1rem', listStyle: 'lower-alpha' }}>
        <li>
          log in to the customer&lsquo;s account - don&lsquo;t have an account
          yet?{' '}
          <Link to="/register" style={{ fontWeight: 700 }}>
            Register here.
          </Link>
        </li>
        <li>go to the PERFECT FIT tab</li>
        <li>start the quiz by clicking GET STARTED</li>
      </ul>
    ),
  },
];

export const Brafitting = () => {
  return <FooterPageRenderer options={options} />;
};
