import { styled } from 'styled-components';

export const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100vw;
  position: relative;
`;

export const NavMainContainer = styled.div`
  width: 100%;
  padding: 13px 40px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 100%;
  }

  @media screen and (max-width: 950px) {
    padding: 0rem;
  }
`;

export const NavActionContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 950px) {
    gap: 0.5rem;
  }
`;

export const NavLinksContainer = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  padding-top: 13px;
`;

export const NavLinkItem = styled.li`
  text-transform: uppercase;
  font-size: 0.85rem;
  padding-inline: 1.1rem;
  padding-block: 0.5rem;
`;

export const MessageContainer = styled.div`
  width: 100%;
  padding-inline: 1rem;
  text-align: center;
  padding-block: 0.2rem;
  background-color: rgb(248, 238, 224);
`;
