import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getCategories } from 'api/categories.api';
import { ModalProvider } from 'components/Modal/ModalContext';
import ProtectedRoute from 'components/ProtectedRoute';
import { AuthProvider } from 'context/AuthContext';
import { CartProvider } from 'context/CartContext';
import { ScrollPositionProvider } from 'context/ScrollContext';
import { NotFound } from 'customer/pages/NotFound';
import { Brafitting } from 'customer/pages/brafitting/Brafitting';
import { Cart } from 'customer/pages/cart/Cart';
import { CheckoutDetails } from 'customer/pages/checkout/CheckoutDetails';
import CheckoutLogin from 'customer/pages/checkout/CheckoutLogin';
import { Layout } from 'customer/pages/checkout/Layout';
import { Cookies } from 'customer/pages/cookies/Cookies';
import { CustomerService } from 'customer/pages/customerService/CustomerService';
import { ForgotPassword } from 'customer/pages/forgotPassword/ForgotPassword';
import { Profile } from 'customer/pages/profile/Profile';
import { ProfileLayout } from 'customer/pages/profile/ProfileLayout';
import { ChangePassword } from 'customer/pages/profile/changePassword/ChangePassword';
import { Login } from 'customer/pages/profile/login/Login';
import { Registration } from 'customer/pages/profile/registration/Registration';
import { ResetPassword } from 'customer/pages/resetPassword/ResetPassword';
import Returns from 'customer/pages/returns/Returns';
import { Terms } from 'customer/pages/terms/Terms';
import { createRef } from 'react';
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useOutlet,
} from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { ThemeProvider } from 'styled-components';
import { Footer } from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import GlobalStyles from '../styles/global.styles';
import { theme } from '../styles/theme';
import './pageTransition.css';
import { Category } from './pages/category/Category';
import { ProductDetails } from './pages/category/productDetails/ProductDetails';
import { Collections } from './pages/collections/Collections';
import { ResetPasswordForm } from './pages/forgotPassword/ResetPasswordForm';
import Home from './pages/home/Home';
import { OrderCreated } from './pages/orderCreated/OrderCreated';
import { PaymentDeclined } from './pages/paymentDeclined/PaymentDeclined';
import { PaymentFailed } from './pages/paymentFailed/PaymentFailed';
import { PaymentSuccess } from './pages/paymentSuccess/PaymentSuccess';
import { PrivacyPolicy } from './pages/privacyPolicy/PrivacyPolicy';
import { MyOrders } from './pages/profile/my-orders/MyOrders';
import { Regulations } from './pages/regulations/Regulations';
import { CookiePrompt } from 'components/CookiePrompt/CookiePrompt';
import { CookieProvider } from 'context/CookieContext';
import { ScrollToTopButton } from 'components/Buttons/ScrollToTopButton';

export const queryClient = new QueryClient();

const animatedRoutes = [
  { path: '/', index: true, element: <Home />, nodeRef: createRef() },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    nodeRef: createRef(),
  },
  {
    path: '/auth/resetPassword/:token',
    element: <ResetPasswordForm />,
    nodeRef: createRef(),
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    nodeRef: createRef(),
  },
  {
    path: '/customer-service',
    element: <CustomerService />,
    nodeRef: createRef(),
  },
  {
    path: '/brafitting',
    element: <Brafitting />,
    nodeRef: createRef(),
  },
  {
    path: '/returns',
    element: <Returns />,
    nodeRef: createRef(),
  },
  {
    path: '/terms-condition',
    element: <Terms />,
    nodeRef: createRef(),
  },
  {
    path: '/regulations',
    element: <Regulations />,
    nodeRef: createRef(),
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    nodeRef: createRef(),
  },
  {
    path: '/cookies',
    element: <Cookies />,
    nodeRef: createRef(),
  },
  {
    path: '/cart',
    errorElement: <div>ERROR</div>,
    nodeRef: createRef(),
    element: <Cart />,
  },

  { path: '/profile/login', element: <Login />, nodeRef: createRef() },
  {
    path: '/profile/registration',
    element: <Registration />,
    nodeRef: createRef(),
  },
  {
    path: '/kolekcije',
    element: <Collections />,
    nodeRef: createRef(),
  },
  {
    path: '/:pageType',
    element: <Category />,
    nodeRef: createRef(),
  },
  {
    path: '/:pageType/:slug',
    nodeRef: createRef(),
    errorElement: <NotFound />,
    element: <Outlet />,
    children: [
      {
        path: '',
        index: true,
        // loader: async ({ params }: any) => {
        //   const page = await queryClient.fetchQuery({
        //     queryKey: [`category_${params.id}`],
        //     queryFn: () => findPage(params.pageType, params.id),
        //   });
        //   // const page = await findPage(params.pageType, params.id);
        //   if (page) {
        //     return page;
        //   } else {
        //     throw new Error();
        //   }
        // },
        element: <Category />,
      },
    ],
  },
  { path: 'product/:id', nodeRef: createRef(), element: <ProductDetails /> },
];

const CustomerContent = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const { nodeRef }: any =
    animatedRoutes.find((route) => route.path === location.pathname) ?? {};

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        unmountOnExit
        classNames="fade"
        timeout={200}
        nodeRef={nodeRef}
      >
        {() => (
          <div ref={nodeRef} className="fade">
            {currentOutlet}
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ModalProvider>
        <AuthProvider>
          <CartProvider>
            <ScrollPositionProvider>
              <QueryClientProvider client={queryClient}>
                <Outlet />
                <CookiePrompt />
              </QueryClientProvider>
            </ScrollPositionProvider>
          </CartProvider>
        </AuthProvider>
      </ModalProvider>
    ),
    children: [
      {
        path: '/',
        loader: () => {
          return queryClient.fetchQuery(['navigation'], getCategories);
        },
        element: (
          <>
            <Navbar />
            <Outlet />
            <ToastContainer
              position="bottom-center"
              theme="dark"
              hideProgressBar
              closeButton={false}
              transition={Zoom}
              limit={1}
            />
            <Footer />
            <ScrollToTopButton />
          </>
        ),
        children: [
          {
            path: '',
            element: <CustomerContent />,
            children: animatedRoutes,
          },
          {
            path: '/profile',
            element: (
              <ProtectedRoute>
                <ProfileLayout />
              </ProtectedRoute>
            ),
            children: [
              {
                path: '',
                index: true,
                element: <Profile />,
              },
              {
                path: 'change-password',
                index: true,
                element: <ChangePassword />,
              },
              {
                path: 'my-orders',
                index: true,
                element: <MyOrders />,
              },
            ],
          },
        ],
      },

      {
        path: '/checkout',
        element: <Layout />,
        children: [
          { index: true, element: <div>INDEX</div> },
          {
            path: 'payment-success/:id',
            errorElement: <div>ERROR</div>,
            element: <PaymentSuccess />,
          },
          {
            path: 'payment-failed/:id',
            errorElement: <div>ERROR</div>,
            element: <PaymentFailed />,
          },
          { path: '/checkout/order-created/:id', element: <OrderCreated /> },
          { path: '/checkout/login', element: <CheckoutLogin /> },
          {
            path: '/checkout/order-declined/:id',
            element: <PaymentDeclined />,
          },
          {
            path: '/checkout/registration',
            element: <Registration link="/checkout/details" />,
          },
          { path: '/checkout/details', element: <CheckoutDetails /> },
        ],
      },
    ],
  },
]);

export const CustomerApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <CookieProvider>
        <GlobalStyles />
        <RouterProvider router={router} fallbackElement={<div>LOADING</div>} />
      </CookieProvider>
    </ThemeProvider>
  );
};
