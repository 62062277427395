import { CustomerApp } from 'customer/CustomerApp';
import { AdminApp } from 'admin/AdminApp';

function App() {
  return process.env.REACT_APP_ROLE === 'customer' ? (
    <CustomerApp />
  ) : (
    <AdminApp />
  );
}

export default App;
