import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editCategory } from 'api/categories.api';
import { FullContainerLoader } from 'components/Loader/Loader';
import { useCategory } from 'hooks/useCategory';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateCategoryType } from 'types/category.types';
import { CreateCategoryForm } from './CreateCategoryForm';

export const EditCategory = () => {
  const { id } = useParams();
  const { data, isLoading } = useCategory({ id });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const editMutation = useMutation(
    (data: CreateCategoryType) => {
      const dataForm = new FormData();
      dataForm.append(
        'category',
        JSON.stringify({
          name: data.name,
          subcategoryIds: data.subcategoryIds,
          slug: data.slug,
        })
      );
      if (typeof data.image !== 'string') {
        dataForm.append('image', data.image);
      }
      return editCategory(id, dataForm);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['category', { id }], data);
        navigate(-1);
      },
      onError: (err: any) => {
        toast.error(err.response.data.message);
      },
    }
  );
  if (isLoading) return <FullContainerLoader />;
  return (
    <CreateCategoryForm
      defaultName={data?.name}
      defaultImage={data?.image}
      defaultSlug={data?.slug}
      mutation={editMutation}
      defaultSelected={data?.subcategories?.map((item) => ({
        id: item.id,
        key: item.key,
      }))}
    />
  );
};
