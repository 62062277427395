import { DeleteIcon } from 'assets/DeleteIcon';
import { Button } from 'components/Buttons/Button';
import { IconButton } from 'components/Buttons/IconButton';
import { Input } from 'components/Inputs/Input';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  width: 60vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
`;

const Sidebar = styled.div`
  width: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.colors.borderGray};
  overflow-y: scroll;
`;

const SidebarItem = styled.div<{
  $active?: boolean;
}>`
  padding-block: 0.5rem;
  width: 100%;
  cursor: pointer;
  color: ${({ $active }) =>
    $active ? theme.colors.primary : theme.colors.secondary};
`;

type ItemType = {
  id?: number;
  size: string;
  // description: string;
  quantity: number;
  catalogueId: string;
  tempId: number;
};

const newItem = () =>
  ({
    tempId: Math.floor(Math.random() * 10000),
    size: '',
    // description: '',
    quantity: 0,
    catalogueId: '',
  } as ItemType);

const Form = ({ item, onItemChange }) => {
  const onChange = (prop, value) => {
    onItemChange(item.tempId, prop, value);
  };
  return (
    <>
      <Input
        label="Veličina"
        value={item.size}
        name="size"
        // error={!form.name}
        onValueChange={onChange}
      />
      {/* <FixedTextarea
        name="description"
        value={item.description}
        onValueChange={onChange}
        label="Informacije o proizvodu"
        error={false}
      /> */}
      <Input
        label="Katalog Id"
        value={item.catalogueId}
        name="catalogueId"
        // error={!form.name}
        onValueChange={onChange}
      />
      <Input
        label="Količina proizvoda"
        value={item.quantity}
        name="quantity"
        // error={!form.name}
        pattern="[0-9]*"
        onValueChange={onChange}
      />
    </>
  );
};

export const AddProductSize = ({
  sizes = null,
  onSave,
}: {
  sizes: any;
  onSave: (items: any[]) => any;
  noOutsideClose: boolean;
}) => {
  const [items, setItems] = useState<ItemType[]>(
    sizes.length > 0
      ? sizes?.map((size) => ({
          ...size,
          tempId: size.id || Math.floor(Math.random() * 10000),
        }))
      : [newItem()]
  );
  const [activeItem, setActiveItem] = useState(items[0].tempId);
  const { closeModal } = useContext(ModalContext);

  const onItemChange = (id, prop: string, value) => {
    const newArr = [...items];
    const index = newArr.findIndex((item) => item.tempId === id);
    newArr[index] = { ...newArr[index], [prop]: value };
    setItems(newArr);
  };

  const deleteSize = (id) => {
    const newArr = [...items];
    setActiveItem(newArr[0].tempId);

    if (newArr.length === 1) {
      return;
    }
    const index = newArr.findIndex((item) => item.tempId === id);
    newArr.splice(index, 1);
    setItems(newArr);
  };

  return (
    <Container>
      <Flex $column $noFullHeight>
        <Flex $noFullHeight $jc="center" $fs={1.2} $bold={700}>
          Dodavanje Veličina
        </Flex>
      </Flex>
      <Flex>
        <Sidebar>
          {items.map((item) => (
            <SidebarItem
              key={item.tempId}
              $active={item.tempId === activeItem}
              onClick={() => setActiveItem(item.tempId)}
            >
              <Flex $jc="space-between">
                {item.size || 'Nepoznato'}
                <IconButton
                  onClick={() => {
                    deleteSize(item.tempId);
                  }}
                  size={24}
                >
                  <DeleteIcon />
                </IconButton>
              </Flex>
            </SidebarItem>
          ))}
          <SidebarItem
            onClick={() => {
              const addItem = newItem();
              setActiveItem(addItem.tempId);
              setItems((prev) => [...prev, addItem]);
            }}
          >
            Dodaj novu veličinu
          </SidebarItem>
        </Sidebar>
        <Flex $ai="center" $column $gap="1rem">
          <Flex $column $ai="center">
            {items.find((item) => item.tempId === activeItem) && (
              <Form
                onItemChange={(id, prop, value) =>
                  onItemChange(id, prop, value)
                }
                item={items.find((item) => item.tempId === activeItem)}
              />
            )}
          </Flex>
          <Flex $jc="flex-end" $noFullHeight>
            <Button
              styleType="color"
              onClick={() => {
                if (
                  items.some(
                    (item) =>
                      !item.size || !item.catalogueId || item.quantity < 0
                  )
                ) {
                  toast.error('Sva polja moraju biti popunjena');
                  return;
                }
                onSave(
                  items.map((item) => ({
                    id: item.id,
                    size: item.size,
                    quantity: item.quantity,
                    catalogueId: item.catalogueId,
                  }))
                );
                closeModal();
              }}
            >
              Sačuvaj sve veličine
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
