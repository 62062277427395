import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { useCustomers } from 'hooks/useCustomers';
import React from 'react';

export const Customers = () => {
  const { customers, isLoading } = useCustomers();
  return (
    <List
      headerItems={[
        { header: 'Ime i prezime', span: 28 },
        { header: 'Telefon', span: 20 },
        { header: 'Email', span: 22 },
        { header: 'Grad', span: 23 },
      ]}
      actionTitle="Kreiraj proizvod"
      //   action={() => navigate('create')}
      noAction
      loading={isLoading}
    >
      {customers?.map((item) => (
        <ListItem.Item
          key={1 + item.idNumber}
          items={[
            {
              span: 28,
              item: item.companyName || item.firstName + ' ' + item.lastName,
            },
            {
              span: 20,
              item: item.phoneNumber,
            },
            {
              span: 22,
              item: item.email,
            },
            {
              span: 20,
              item: item.city,
            },
          ]}
        />
      ))}
    </List>
  );
};
