import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editBlogApiCall } from 'api/blogs.api';
import { Button } from 'components/Buttons/Button';
import { BorderInput } from 'components/Inputs/BorderInput';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { BlogImageInput } from './BlogImageInput';
import { AdminEditor } from './RichTextEditor';

const Container = styled.div`
  width: 600px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  h1 {
    font-size: 2.125rem;
  }
`;

const ContentForm = styled.div`
  width: 100%;
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const EditBlogModal = ({ onClose, blog }) => {
  const [value, setValue] = useState(blog.content);
  const [image, setImage] = useState(blog.imageUrl);
  const [title, setTitle] = useState(blog.title);
  const [id] = useState(blog.id);
  const queryClient = useQueryClient();

  const editMutation = useMutation(
    () => {
      const dataForm = new FormData();

      const blog: any = {
        content: value,
        title: title,
        shortText: title,
        metaTitle: title,
        metaContent: title,
        metaTags: title,
        urlPath: `${title.toLowerCase().split(' ').join('-')}`,
      };

      if (image && typeof image !== 'string') {
        dataForm.append('image', image);
      }
      dataForm.append('blog', JSON.stringify(blog));

      return editBlogApiCall(dataForm, id);
    },
    {
      onSuccess: () => {
        onClose();
        toast.success('Blog je uspešno sačuvan');
        queryClient.invalidateQueries(['blogs']);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message);
      },
    }
  );

  return (
    <Container>
      <BlogImageInput
        image={image}
        onChange={(val) => {
          setImage(val);
        }}
      />
      <ContentForm>
        <Flex $column $noFullHeight>
          <BorderInput
            placeholder="Naslov Bloga"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          />
          <Flex
            $jc="flex-end"
            $fs={0.8}
            $noFullHeight
            $textcolor={theme.colors.borderGray}
          >
            Limit 100 karaktera!
          </Flex>
        </Flex>
        <AdminEditor
          value={value}
          onChange={(val) => {
            setValue(val);
          }}
        />
        <Flex $noFullHeight $jc="flex-end">
          <Button square styleType="outline-dark" onClick={onClose}>
            Otkaži
          </Button>
          <Button
            styleType="color"
            square
            onClick={() => {
              editMutation.mutate();
            }}
          >
            Sačuvaj
          </Button>
        </Flex>
      </ContentForm>
    </Container>
  );
};
