import { Outlet } from 'react-router-dom';
import { CheckoutNav } from './CheckoutNav';
import { Flex, SectionContainer } from 'styles/Containers.styles';
import { Footer } from 'components/Footer/Footer';

export const Layout = () => {
  return (
    <>
      <CheckoutNav />
      <Flex $jc="center">
        <SectionContainer>
          <Outlet />
        </SectionContainer>
      </Flex>
      <Footer />
    </>
  );
};
