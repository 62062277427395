import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addAttributeValueToGroup } from 'api/attributes.api';
import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { SmallImageInput } from 'components/Inputs/SmallImageInput';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Form } from 'styles/Containers.styles';
import { AttributeType } from 'types/attribute.types';

export const AddAttributeValue = ({
  attribute,
}: {
  attribute: AttributeType;
}) => {
  const [form, setForm] = useState({
    name: '',
    image: null,
  });
  const [error, setError] = useState(false);
  const { closeModal } = useContext(ModalContext);

  const queryClient = useQueryClient();

  const createMutation = useMutation(
    (data: typeof form) => {
      const dataForm = new FormData();
      dataForm.append(
        'categoryAttributeValue',
        JSON.stringify({
          name: data.name,
        })
      );
      dataForm.append('image', data.image);
      return addAttributeValueToGroup(attribute.id, dataForm);
    },
    {
      onSuccess: (data: AttributeType) => {
        queryClient.setQueryData(
          ['attributes'],
          (previousAttributes: AttributeType[]) => {
            const updatedAttributes = [...previousAttributes];
            const index = updatedAttributes.findIndex(
              (attributeItem) => attributeItem.id === attribute.id
            );

            console.log(index);

            if (index !== -1) {
              updatedAttributes[index] = {
                ...data,
              };
            }
            closeModal();
            return updatedAttributes;
          }
        );
        closeModal();
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const onChange = (prop, value) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    (document.activeElement as HTMLElement).blur();
    if (!form.name) {
      setError(true);
      return;
    }
    if (!form.image) {
      setError(true);
      toast.error('Slika mora biti dodata.');
      return;
    }
    createMutation.mutate(form);
  };

  return (
    <Flex $column $ai="center">
      <Flex $noFull $fs={1.3} $bold={700}>
        DODAJ VREDNOST ATRIBUTA
      </Flex>
      <Form onSubmit={handleSubmit}>
        <Flex $column $gap="0">
          <Flex $jc="center">
            <SmallImageInput
              image={form.image}
              onChange={(value) => onChange('image', value)}
            />
          </Flex>
          <Input
            label="Ime"
            value={form.name}
            name="name"
            autoFocus
            required
            error={error && !form.name}
            onValueChange={onChange}
          />
        </Flex>
        <Button styleType="color">
          {createMutation.isLoading ? 'Učitavanje' : 'Sačuvaj'}
        </Button>
      </Form>
    </Flex>
  );
};
