import React from 'react';

export const ThreeGridItemsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M6.5 3h-3c-0.552 0-1 0.448-1 1v16c0 0.552 0.448 1 1 1h3c0.552 0 1-0.448 1-1v-16c0-0.552-0.448-1-1-1zM5.5 19h-1v-14h1v14zM20.5 3h-3c-0.552 0-1 0.448-1 1v16c0 0.552 0.448 1 1 1h3c0.552 0 1-0.448 1-1v-16c0-0.552-0.448-1-1-1zM19.5 19h-1v-14h1v14zM13.5 3h-3c-0.552 0-1 0.448-1 1v16c0 0.552 0.448 1 1 1h3c0.552 0 1-0.448 1-1v-16c0-0.552-0.448-1-1-1zM12.5 19h-1v-14h1v14z"></path>
    </svg>
  );
};
