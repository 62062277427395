import { styled } from 'styled-components';
import { theme } from './theme';

export const Flex = styled.div<{
  $fs?: number;
  $position?: string;
  $bold?: number;
  $pointer?: boolean;
  $textcolor?: string;
  $noOpacity?: boolean;
  $mt?: string;
  $mr?: string;
  $mb?: string;
  $column?: boolean;
  $gap?: string;
  $ai?: string;
  $jc?: string;
  $js?: string;
  $p?: string;
  $noFull?: boolean;
  $noFullHeight?: boolean;
  $inline?: boolean;
  $textRight?: boolean;
  $uppercase?: boolean;
  $maxWidth?: string;
  $mobileColumn?: boolean;
}>`
  font-size: ${({ $fs }) => ($fs ? $fs : 1)}rem;
  position: ${({ $position }) => ($position ? $position : 'inline-block')};
  padding: ${({ $p }) => ($p ? $p : '0')};
  font-weight: ${({ $bold }) => ($bold ? $bold : 'inherit')};
  cursor: ${({ $pointer }) => ($pointer ? 'pointer' : 'normal')};
  margin-top: ${({ $mt }) => ($mt ? $mt : 0)};
  margin-right: ${({ $mr }) => ($mr ? $mr : 0)};
  margin-bottom: ${({ $mb }) => ($mb ? $mb : 0)};
  flex-direction: ${({ $column }) => ($column ? 'column' : 'row')};
  align-items: center;
  width: ${({ $noFull }) => ($noFull ? 'fit-content' : '100%')};
  gap: ${({ $gap }) => ($gap ? $gap : '0.3rem')};
  display: flex;
  align-items: ${({ $ai }) => $ai || 'flex-start'};
  justify-content: ${({ $jc }) => $jc || 'flex-start'};
  color: ${({ $textcolor }) => ($textcolor ? $textcolor : 'inherit')};
  height: ${({ $noFullHeight }) => ($noFullHeight ? 'fit-content' : '100%')};
  ${({ $inline }) => $inline && 'display: inline;'};
  ${({ $textRight }) => $textRight && 'text-align: right;'};
  ${({ $uppercase }) => $uppercase && 'text-transform: uppercase;'};
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`};

  @media screen and (max-width: 1000px) {
    ${({ $mobileColumn }) =>
      $mobileColumn && `flex-direction: column; gap: 0;`};
  }
`;

export const SectionContainer = styled.section`
  width: 100%;
  max-width: 1270px;
  height: 100%;
  min-height: 80vh;
`;

export const TextSectionContainer = styled.section`
  width: 100%;
  margin-inline: 4rem;
  padding-inline: 4rem;
  max-width: 1366px;
  height: 100%;
  min-height: 60vh;

  a {
    color: ${theme.colors.primary};
    text-decoration: underline;
  }

  @media screen and (max-width: 1200px) {
    padding-inline: 1rem;
    margin-inline: 0rem;
    overflow: hidden;
  }
`;

export const FormContainer = styled.form`
  max-width: 25rem;
  width: 100%;
  padding: 1rem;
  padding-block: 5rem;
`;

export const Form = styled.form`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;
