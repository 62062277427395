import React from 'react';

export const BackArrowIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.2929 12.707L8.2929 18.707L9.7071 17.2928L5.4142 12.9999L22 12.9999V10.9999L5.4142 10.9999L9.7071 6.70703L8.2929 5.29283L2.2929 11.2928C1.9024 11.6833 1.9024 12.3165 2.2929 12.707Z"
        fill={color || '#18191A'}
      />
    </svg>
  );
};
