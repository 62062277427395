import { Input } from 'components/Inputs/Input';
import React from 'react';
import { validateEmail } from 'utils/validation.utils';

type Props = {
  value: string;
  name: string;
  error?: boolean;
  onChange: (name: string, value: string) => void;
  required?: boolean;
};

export const EmailInput = ({
  value,
  error,
  onChange,
  name,
  required = true,
}: Props) => {
  return (
    <Input
      name={name}
      label="E-mail"
      required={required}
      value={value}
      onValueChange={onChange}
      error={error ?? (!value || !validateEmail(value))}
      errorMessage={!validateEmail(value) && 'E-mail adresa nije validna'}
    />
  );
};
