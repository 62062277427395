import { useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'api/instance';
import { Button } from 'components/Buttons/Button';
import { Input } from 'components/Inputs/Input';
import { FullLoader } from 'components/Loader/Loader';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex } from 'styles/Containers.styles';

export const BulkModal = ({
  selectedItems,
  onClose,
}: {
  selectedItems: number[];
  onClose: () => void;
}) => {
  const [activeAction, setActiveAction] = useState<
    'draft' | 'new' | 'discount' | 'outlet' | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [discountError, setDiscountError] = useState(false);
  const queryClient = useQueryClient();
  console.log(selectedItems);

  const bulkDraftEdit = async (draft: boolean) => {
    setLoading(true);
    try {
      await axiosInstance.put('/v1/products/bulk/change-draft', {
        productIds: selectedItems,
        isDraft: draft,
      });
      queryClient.invalidateQueries(['products']);
      onClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const bulkNewEdit = async (inNew: boolean) => {
    setLoading(true);
    try {
      await axiosInstance.put('/v1/products/bulk/change-new', {
        productIds: selectedItems,
        isNew: inNew,
      });
      queryClient.invalidateQueries(['products']);
      onClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const bulkOutletEdit = async (isOutlet: boolean) => {
    setLoading(true);
    try {
      await axiosInstance.put('/v1/products/bulk/change-outlet', {
        productIds: selectedItems,
        isOutlet: isOutlet,
      });
      queryClient.invalidateQueries(['products']);
      onClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const bulkDiscountEdit = async () => {
    if (discount < 1 || discount > 100) {
      toast.error('Vrednost popusta mora biti izmedju 1% i 100%');
      setDiscountError(true);
      return;
    }
    setLoading(true);
    try {
      await axiosInstance.put('/v1/products/bulk/change-discount', {
        productIds: selectedItems,
        discount: discount,
      });
      queryClient.invalidateQueries(['products']);
      onClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex $column $gap="1rem">
      <Flex $noFullHeight $jc="center" $fs={1.2} $bold={700}>
        Bulk{' '}
        {activeAction === 'draft'
          ? 'Draft'
          : activeAction === 'discount'
          ? 'Popust'
          : activeAction === 'outlet'
          ? 'Outlet'
          : activeAction === 'new'
          ? 'Novo'
          : 'Akcije'}
      </Flex>
      {activeAction === null && (
        <>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction('draft');
            }}
          >
            Bulk edit draft
          </Button>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction('new');
            }}
          >
            Bulk edit tag novo
          </Button>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction('outlet');
            }}
          >
            Bulk edit tag outlet
          </Button>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction('discount');
            }}
          >
            Bulk edit popusta
          </Button>
        </>
      )}
      {activeAction === 'draft' && (
        <>
          <Button
            width="100%"
            onClick={() => {
              bulkDraftEdit(true);
            }}
            styleType="color"
          >
            Aktiviraj draft
          </Button>
          <Button
            width="100%"
            styleType="outline-dark"
            onClick={() => {
              bulkDraftEdit(false);
            }}
          >
            Deaktiviraj draft
          </Button>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction(null);
            }}
          >
            Odustani
          </Button>
        </>
      )}
      {activeAction === 'outlet' && (
        <>
          <Button
            width="100%"
            onClick={() => {
              bulkOutletEdit(true);
            }}
            styleType="color"
          >
            Aktiviraj outlet
          </Button>
          <Button
            width="100%"
            styleType="outline-dark"
            onClick={() => {
              bulkOutletEdit(false);
            }}
          >
            Deaktiviraj outlet
          </Button>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction(null);
            }}
          >
            Odustani
          </Button>
        </>
      )}
      {activeAction === 'new' && (
        <>
          <Button
            width="100%"
            onClick={() => {
              bulkNewEdit(true);
            }}
            styleType="color"
          >
            Promeni u novo
          </Button>
          <Button
            width="100%"
            onClick={() => {
              bulkNewEdit(false);
            }}
            styleType="outline-dark"
          >
            Isključi novo
          </Button>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction(null);
            }}
          >
            Odustani
          </Button>
        </>
      )}
      {activeAction === 'discount' && (
        <>
          <Input
            label="Popust u %"
            value={discount}
            name="discount"
            error={discountError && (discount < 1 || discount > 100)}
            errorMessage="Vrednost popusta mora biti izmedju 1% i 100%"
            pattern="[0-9]*"
            onValueChange={(_name, value) => {
              setDiscount(value);
            }}
          />
          <Button
            width="100%"
            onClick={() => {
              bulkDiscountEdit();
            }}
            disabled={!discount}
            required
            styleType="color"
          >
            Sačuvaj popust
          </Button>
          <Button
            width="100%"
            onClick={() => {
              setActiveAction(null);
            }}
          >
            Odustani
          </Button>
        </>
      )}
      <FullLoader loading={loading} />
    </Flex>
  );
};
