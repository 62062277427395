import axiosInstance from './instance';

export const getCollections = async () => {
  const res = await axiosInstance.get('/v1/collections', {
    params: {
      page: 1,
      limit: 20,
    },
  });

  return res.data.data;
};

export const getCollectionById = async (id: string) => {
  const res = await axiosInstance.get(`/v1/collections/${id}`);

  return res.data.data;
};

export const getCollectionBySlug = async (slug: string) => {
  const res = await axiosInstance.get(`/v1/collections/${slug}/slug`);

  return res.data.data;
};

export const editCollection = async (id, data) => {
  const res = await axiosInstance.patch(`/v1/collections/${id}`, data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res.data.data;
};

export const createCollection = async (data) => {
  const res = await axiosInstance.post('/v1/collections', data, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return res.data.data;
};

export const deleteCollection = async (id: number) => {
  const res = await axiosInstance.delete(`/v1/collections/${id}`);

  return res.data.data;
};

export const getCustomerCollections = async () => {
  const res = await axiosInstance.get('/v1/collections');

  return res.data.data;
};

export const getCustomerCollectionsWithProducts = async () => {
  const res = await axiosInstance.get('/v1/collections/with-products');

  return res.data.data;
};
