import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const ColorProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 0.875rem;
  cursor: pointer;
`;

const ColorProductImage = styled.img<{ $active: boolean }>`
  width: 100%;
  object-fit: contain;
  border: ${({ theme, $active }) =>
    $active && '1px solid ' + theme.colors.primary};
`;

export const ColorProduct = ({
  product,
  chosen,
}: {
  product: ColorVariant;
  chosen: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <ColorProductContainer onClick={() => navigate(`/product/${product.slug}`)}>
      <ColorProductImage src={product.images[0]} $active={chosen} />
      <p>{product.colorName}</p>
    </ColorProductContainer>
  );
};
