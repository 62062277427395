import { useQuery } from '@tanstack/react-query';
import { getDeliveryLocations } from 'api/deliveryLocations.api';
import { DeliveryLocationEntity } from 'types/deliveryLocations.types';

export const useDeliveryLocations = () => {
  const { isLoading, error, data } = useQuery<DeliveryLocationEntity[]>(
    ['delivery_locations'],
    getDeliveryLocations,
    { refetchOnWindowFocus: false }
  );

  return { isLoading, error, data };
};
