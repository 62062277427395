import { CookieModal } from 'components/CookiePrompt/CookieModal';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Flex, TextSectionContainer } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

export const Cookies = () => {
  // return <FooterPageRenderer options={options} />;
  const { openModal } = useContext(ModalContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <TextSectionContainer style={{ marginBottom: '4rem' }}>
      <Flex $column $gap="3rem" $mt="2rem">
        {[
          {
            title: 'Politika kolačića (Cookies Policy)',
          },
          {
            lines: [
              <p key={0}>
                Esotiq web sajt, dostupan na adresi{' '}
                <Link to="/">https://esotiq.rs/</Link>, koristi kolačiće (eng.
                Cookies) kako bi se osiguralo njegovo pravilno funkcionisanje,
                unapredilo korisničko iskustvo i omogućilo dalje unapređenje.
                Kada posetite ovu web stranicu, Vaš uređaj (npr. računar,
                tablet, mobilni telefon) automatski šalje malu količinu
                informacije, odnosno kolačiće. Ovo je uobičajena praksa na
                mnogim web sajtovima. Međutim, u skladu sa Zakonom o zaštiti
                podataka o ličnosti, Esotiq je dužan da prethodno zatraži vašu
                saglasnost za korišćenjekolačića.Korišćenjem ovog sajta, dajete
                saglasnost za upotrebu kolačića. Iako postoji opcija da se
                kolačići blokiraju, ukoliko to uradite, bićeteuskraćeni za neke
                funkcionalnosti sajta.
              </p>,
              <p key={1}>
                <b>Šta je kolačić (Cookie)?</b>
              </p>,
              <p key={2}>
                Kolačić (eng. Cookie) predstavlja tekstualni fajl koji se šalje
                na Vaš uređaj(računar, tablet, mobilni telefon) kada posetite
                određeni web sajt. Uglavnom, kolačići čuvaju određene postavke
                za web sajt, kao što su izabrani jezik ili adresa, kako bi Vam
                pri sledećoj poseti prikazali personalizovane informacije. Pored
                toga, kolačići mogu da čuvaju i neke lične podatke, poput vašeg
                imena ili e-mail adrese, što zahteva vašu saglasnost. Ako ne
                date saglasnost, web sajt neće imati pristup ovim informacijama
                niti može pristupiti drugim datotekama na uređaju posetioca.
                Iako proces čuvanja i slanja kolačića nije vidljiv vama kao
                korisnicima, imate opciju da prilagodite postavke kolačića na
                svom uređaju,prihvatajući ili odbijajućikolačiće, ili izbrisati
                sačuvanekolačiće prilikom zatvaranja pretraživača.
              </p>,
              <p key={3}>
                <b>Kako onemogućiti (blokirati) kolačiće?</b>
              </p>,
              <p key={4}>
                Podešavanja za kolačiće možete prilagoditi ili deaktivirati na
                internet pretraživaču koji upotrebljavate. Ako
                isključitekolačiće,naićićete na ograničenja u
                korišćenjuodređenih funkcija na sajtu čije ste
                kolačićedeaktivirali. Više informacija o podešavanjima kolačića
                možete pronaći na:
              </p>,
              <ul key={4} style={{ listStyle: 'inside', marginLeft: '1rem' }}>
                <li key={'list' + 0}>Chrome</li>
                <li key={'list' + 1}>Firefox</li>
                <li key={'list' + 2}>Microsoft Edge</li>
                <li key={'list' + 3}>Opera (na engleskom)</li>
                <li key={'list' + 4}>Safari (na engleskom),</li>
              </ul>,
              <p key={5}>
                kao i na web adresama:{' '}
                <a
                  target="_blank"
                  href="http://www.allaboutcookies.org/"
                  rel="noreferrer"
                >
                  http://www.allaboutcookies.org/
                </a>{' '}
                i{' '}
                <a
                  target="_blank"
                  href="http://www.youronlinechoices.eu/"
                  rel="noreferrer"
                >
                  http://www.youronlinechoices.eu/
                </a>
                .
              </p>,
              <p key={9902}>
                Promeni svoje kolacice{' '}
                <span
                  style={{ color: theme.colors.primary, cursor: 'pointer' }}
                  onClick={() => {
                    openModal(<CookieModal />);
                  }}
                >
                  ovde
                </span>
              </p>,
              <p key={6}>
                <b>Vrste kolačića prema trajanju</b>
              </p>,
              <p key={7}>
                Kolačići se, sa obzirom na vreme trajanja, mogu klasifikovati
                kao dugotrajni i sesijski kolačići.
              </p>,
              <p key={8}>
                <b>I. Dugotrajni kolačići</b>
              </p>,
              <p key={9}>
                Dugotrajni kolačići ili pripremljeni kolačići (eng. Persistent
                Cookies) su oni koji čuvaju informacije poput korisničkog imena
                i lozinke, tako da ne morate da se ulogujete svaki put kada
                posetite određenuinternet stranicu. Ovi kolačići ostanu sačuvani
                na vašem uređajučak i nakon zatvaranja pretraživača, i tu mogu
                biti danima, mesecima ili čak godinama.
              </p>,
              <p key={10}>
                <b>II. Sesijski kolačići</b>
              </p>,
              <p key={11}>
                Sesijski kolačići ili privremeni kolačići (eng. Session Cookies)
                su kolačići koji čuvaju informacije samo privremeno. Za razliku
                od dugotrajnih kolačića, sesijski kolačići se automatski brišu
                kada zatvorite pretraživač.
              </p>,
              <p key={12}>
                <b>
                  KATEGORIZACIJA KOLAČIĆA NA OSNOVU INTERNET DOMENA KOME
                  PRIPADAJU
                </b>
              </p>,
              <p key={13}>
                Kolačići se, na osnovu internet domena sa kojeg dolaze, mogu
                svrstati u dve glavne grupe: kolačićiizvorne stranice i kolačići
                spoljnih stranica.
              </p>,
              <p key={14}>
                <b>I. Kolačići izvorne stranice</b>
              </p>,
              <p key={15}>
                Kolačići izvorne stranice (eng. First – Party Cookies) potiču
                direktno sa veb sajta koji trenutno pregledate na vašem uređaju.
                Ovi kolačići mogu biti dugotrajni (eng. Persistent Cookies) ili
                sesijski (eng. Session Cookies). Zahvaljujući ovim kolačićima,
                veb sajt može sačuvati informacije koje će iskoristiti prilikom
                Vaše sledeće posete.
              </p>,
              <p key={16}>
                <b>II. Kolačići spoljnih stranica</b>
              </p>,
              <p key={17}>
                Kolačići spoljnih stranica (eng. Third – Party cookies) dolaze
                sa drugih veb sajtova, odnosno sa reklama drugih internet
                prezentacija (popup reklame ili druge) koje se nalaze na sajtu
                koji trenutno posmatrate. Ovi kolačićiomogućavaju sajtu da prati
                Vaše online aktivnosti u marketinške svrhe i prilagođava prikaz
                oglasa tako da Vam prikaže reklame koje su relevantne za Vas i
                Vaše interesovanje.
              </p>,
              <p key={18}>
                <b>VRSTE KOLAČIĆA PREMA SVRSI</b>
              </p>,
              <p key={19}>
                Na osnovu svrhe korišćenja,kolačići se mogu svrstati u
                funkcionalne i analitičke.
              </p>,
              <p key={20}>
                <b>I. Funkcionalni kolačići</b>
              </p>,
              <p key={21}>
                Kolačići za funkcionalnost (eng. Functionality cookies)
                omogućavaju sajtu da zapamti informacije koje ste prethodno
                uneli na datoj internet prezentaciji. Ovi kolačići samo
                skupljaju i čuvajuisključivo anonimne podatke i ne mogu pratiti
                Vaše aktivnosti na drugim veb sajtovima.
              </p>,
              <p key={22}>
                <b>II. Analitički kolačići</b>
              </p>,
              <p key={23}>
                Kolačići za analizu (eng. Analytics Cookies) koriste se za
                proučavanje i analiziranje ponašanja (praćenjeinteresa)
                korisnika na veb sajtu. Ako onemogućite ovu vrstu kolačića, sajt
                neće biti u mogućnosti da evidentira Vaše posete.
              </p>,
              <p key={24}>
                <b>KOLAČIĆI KOJE UPOTREBLJAVA esotiq.rs</b>
              </p>,
              <p key={25}>
                Esotiq poseduje veb sajt na adresi:{' '}
                <Link to="/">https://esotiq.rs/</Link>. Ovaj sajt koristi
                kolačiće kako bi unapredio korisničko iskustvo.
              </p>,
              <p key={26}>
                Veb sajt Esotiq koristi kolačiće izvorne stranice (eng. First –
                Party Cookies) i to trajne kolačiće (eng. Persistent Cookies)
                kao njihovu podvrstu. Ovi kolačići su neophodni za ispravno
                funkcionisanje sajta i za naručivanje proizvoda putem online
                prodavnice na navedenoj adresi.
              </p>,
              <p key={27}>
                Ovaj sajt takođe pruža pristup korisnicima Facebook-a pristup
                fan page stranici{' '}
                <a
                  target="_blank"
                  href="https://www.facebook.com/esotiqserbia?mibextid=LQQJ4d"
                  rel="noreferrer"
                >
                  Esotiq
                </a>
                , korisnicima Instagram-a pristup instagram profilu{' '}
                <a
                  target="_blank"
                  href="https://instagram.com/esotiq.rs?igshid=MzMyNGUyNmU2YQ=="
                  rel="noreferrer"
                >
                  Esotiq
                </a>
                , i korisnicima Tik-Tok-a pristup profilu{' '}
                <a
                  target="_blank"
                  href="https://www.tiktok.com/@esotiqsrbija"
                  rel="noreferrer"
                >
                  Esotiq
                </a>
                .
              </p>,
            ],
          },
        ].map((option, index) => (
          <Flex $column $gap="3rem" key={index}>
            {option?.title && (
              <Flex $bold={700} $fs={1.2}>
                {option.title}
              </Flex>
            )}
            {option.lines && (
              <Flex $column $gap="1rem">
                {option.lines.map((line, index) => (
                  <Flex style={{ whiteSpace: 'pre-wrap' }} key={'line' + index}>
                    {line}
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </TextSectionContainer>
  );
};
