import { useSearchParams } from 'react-router-dom';
import { removeEmpty } from 'utils/object.utils';

export const useIsFilterActive = () => {
  const [searchParams] = useSearchParams();

  const params = removeEmpty({
    attributeValueIds:
      searchParams.get('attributeValueIds')?.split(',') || null,
    colors: searchParams.get('colors')?.split(',') || null,
    sizes: searchParams.get('sizes')?.split(',') || null,
    maxPrice: searchParams.get('maxPrice') || null,
    minPrice: searchParams.get('minPrice') || null,
  });

  return Object.values(params).length > 0;
};
