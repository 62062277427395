import { useInfiniteQuery } from '@tanstack/react-query';
import { getColorVariants } from 'api/products.api';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CategoryType } from 'types/category.types';
import { CollectionType } from 'types/collection.types';
import { SubcategoryType } from 'types/subcategory.types';
import { removeEmpty } from 'utils/object.utils';
import convertProductToSimpleProduct from 'utils/product.utils';

type PageType = CategoryType | SubcategoryType | CollectionType;

export const useProductColorVariants = ({ page }: { page?: PageType }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  console.log(page, 'PAGE TYPE');

  const pageType =
    page &&
    ('link' in page && page.link === 'pretraga'
      ? 'pretraga'
      : 'subcategories' in page
      ? 'kategorija'
      : 'key' in page
      ? 'podkategorija'
      : 'kolekcije');

  const params =
    page &&
    removeEmpty({
      subcategoryIds:
        pageType === 'podkategorija'
          ? [page.id]
          : searchParams.get('subcategoryIds')?.split(',') || null,
      categoryId:
        pageType === 'kategorija'
          ? page.id
          : searchParams.get('categoryId') || null,
      isNew: 'link' in page && page.link === 'novo' ? true : null,
      isSale: 'link' in page && page.link === 'na-popustu' ? true : null,
      isOutlet: 'link' in page && page.link === 'outlet' ? true : null,
      collectionIds:
        pageType === 'kolekcije'
          ? [page.id]
          : location.pathname === '/outlet'
          ? [searchParams.get('collectionIds')]
          : null,
      sorting: searchParams.get('sortBy') || 'default',
      query: searchParams.get('search') || null,
      attributeValueIds:
        searchParams.get('attributeValueIds')?.split(',') || null,
      colors: searchParams.get('colors')?.split(',') || null,
      sizes: searchParams.get('sizes')?.split(',') || null,
      maxPrice: searchParams.get('maxPrice') || null,
      minPrice: searchParams.get('minPrice') || null,
    });

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['colorVariants', params, pageType, page?.id],
      queryFn: ({ pageParam = 1 }) => {
        return getColorVariants({
          ...params,
          page: pageParam,
          limit: 60,
        });
      },
      getNextPageParam: (lastPage) => {
        return lastPage?.nextPage;
      },
    });

  const products = data?.pages
    ?.map((data) => (data?.data ? data.data : []))
    .flat(1)
    .map((item) => convertProductToSimpleProduct(item));

  return {
    products,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};
