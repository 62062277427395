import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { Checkbox } from './Checkbox';
import { FullContainerLoader } from 'components/Loader/Loader';

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  border: 1px solid ${theme.colors.borderGray};
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-template-rows: 2rem;
  padding: 1rem;
  gap: 1rem;
`;

type Props = {
  isLoading: boolean;
  itemList: any[];
  renderChecked: (item: any) => boolean;
  renderText: (item: any) => string;
  onCheck: (item: any) => void;
};

export const MultiSelectAdminInput = ({
  itemList,
  renderChecked,
  renderText,
  onCheck,
  isLoading,
}: Props) => {
  return isLoading ? (
    <FullContainerLoader />
  ) : (
    <Container>
      {itemList?.map((item, index) => (
        <Flex key={'item ' + index}>
          <Checkbox
            checked={renderChecked(item)}
            text={renderText(item)}
            onChange={() => {
              onCheck(item);
            }}
          />
        </Flex>
      ))}
    </Container>
  );
};
