import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getUserCall } from 'api/auth.api';
import { FullContainerLoader } from 'components/Loader/Loader';
import { ModalProvider } from 'components/Modal/ModalContext';
import { AuthProvider } from 'context/AuthContext';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider, styled } from 'styled-components';
import GlobalStyles from 'styles/global.styles';
import { theme } from 'styles/theme';
import { dashboardPages } from './dashboardPageConfig';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Login } from './pages/login/Login';

const queryClient = new QueryClient();

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 1rem;
`;

const AdminContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0px 0px 40px 0px #00000063;
`;

const router = createBrowserRouter([
  {
    path: '/',
    loader: async () => {
      try {
        const data = await getUserCall();
        return data;
      } catch (error) {
        return null;
      }
    },
    element: (
      <QueryClientProvider client={queryClient}>
        <ModalProvider>
          <AuthProvider>
            <Outlet />
            <ToastContainer
              hideProgressBar
              theme="dark"
              closeButton={false}
              limit={1}
            />
          </AuthProvider>
        </ModalProvider>
      </QueryClientProvider>
    ),
    children: [
      { path: '/', index: true, element: <Navigate to="/dashboard" /> },
      { path: '/login', element: <Login /> },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          {
            path: '',
            index: true,
            element: <Navigate to="/dashboard/orders" />,
          },
          ...dashboardPages,
        ],
      },
    ],
  },
]);

export const AdminApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <AdminContainer>
          <RouterProvider
            router={router}
            fallbackElement={<FullContainerLoader />}
          />
        </AdminContainer>
      </Container>
    </ThemeProvider>
  );
};
