import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCategory, getCategories } from 'api/categories.api';
import { toast } from 'react-toastify';
import { CategoryType } from 'types/category.types';

export const useCategories = () => {
  const { isLoading, error, data } = useQuery<CategoryType[]>(
    ['categories'],
    getCategories
  );

  const queryClient = useQueryClient();

  const deleteMutation = useMutation((id: number) => deleteCategory(id), {
    onSuccess: (_data, id) => {
      queryClient.setQueryData(
        ['categories'],
        (previousData: CategoryType[]) => {
          const updatedAttributes = [...previousData];
          const index = updatedAttributes.findIndex(
            (attributeItem) => attributeItem.id === id
          );

          updatedAttributes.splice(index, 1);

          return updatedAttributes;
        }
      );
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  return { isLoading, error, data, deleteMutation };
};
