export function isColorLight(hexColor: string): boolean {
  // Remove any '#' if present
  hexColor = hexColor.replace(/^#/, '');

  // Convert hex to RGB
  const red = parseInt(hexColor.slice(0, 2), 16);
  const green = parseInt(hexColor.slice(2, 4), 16);
  const blue = parseInt(hexColor.slice(4, 6), 16);

  // Calculate relative luminance using the formula for sRGB
  const relativeLuminance =
    0.2126 * (red / 255) + 0.7152 * (green / 255) + 0.0722 * (blue / 255);

  // Determine if the color is light based on a threshold
  const isLight = relativeLuminance > 0.5;

  return isLight;
}
