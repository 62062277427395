import React from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  value: string;
  name: string;
  label?: string;
  errorMessage?: string;
  onValueChange: (name: string, value: string) => void;
  iconElement?: React.ReactElement;
  prefix?: string;
  limit?: number;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 30rem;
`;

const Input = styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.colors.borderGray};
  border-radius: 0.5rem;
  padding: 0.5rem;
  resize: none;
`;

const StyledLabel = styled.label<{
  $error?: boolean;
}>`
  font-size: 0.9rem;
  color: ${({ $error }) => ($error ? theme.colors.error : 'inherit')};
  translate: 0 -50%;
  transition: all 200ms ease-in;
  font-weight: 300;
`;

export const FixedTextarea = ({
  //   required,
  label,
  //   error,
  value,
  name,
  onValueChange,
  limit = 500,
  ...rest
}: Props) => {
  return (
    <Container>
      {label && <StyledLabel>{label}</StyledLabel>}
      <Input
        {...rest}
        onChange={(e) => {
          onValueChange(name, e.target.value);
        }}
        value={value}
        maxLength={limit}
      />
    </Container>
  );
};
