import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const CheckboxContainer = styled.div<{
  $disabled: boolean;
  $checked: boolean;
}>`
  min-width: 1.3rem;
  min-height: 1.3rem;
  border-radius: 100px;
  border: 1px solid
    ${({ $disabled, $checked }) =>
      $disabled
        ? theme.colors.secondaryLight
        : $checked
        ? theme.colors.primary
        : theme.colors.secondaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  background-color: ${theme.colors.primary};
  border-radius: 100px;
`;

type Props = {
  checked: boolean;
  text: string | React.ReactNode;
  onChange: () => void;
  disabled?: boolean;
  noTextClick?: boolean;
};

export const CircleCheck = ({
  checked,
  text,
  onChange,
  disabled,
  noTextClick,
}: Props) => {
  return (
    <Flex
      $noFullHeight
      $gap="0.5rem"
      $pointer
      $noFull
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) {
          onChange();
        }
      }}
    >
      <CheckboxContainer $disabled={disabled} $checked={checked}>
        {checked && <Circle />}
      </CheckboxContainer>
      <Flex
        $p="0.35rem 0"
        $textcolor={disabled && theme.colors.secondaryLight}
        onClick={(e) => {
          if (noTextClick) {
            e.stopPropagation();
          }
        }}
      >
        {text}
      </Flex>
    </Flex>
  );
};
