import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deletePromoCode, getPromoCodes } from 'api/promoCodes.api';
import { PromoCodeType } from 'types/promoCode.types';

export const usePromoCodes = () => {
  const { isLoading, error, data } = useQuery<PromoCodeType[]>(
    ['promoCodes'],
    getPromoCodes
  );

  const queryClient = useQueryClient();

  const deleteMutation = useMutation((id: number) => deletePromoCode(id), {
    // onSuccess, invalidate the relevant query to trigger a refetch
    // In this example, we assume you have a 'items' query that fetches the list of items
    onSuccess: () => {
      queryClient.invalidateQueries(['promoCodes']);
    },
  });

  return { isLoading, error, data, deleteMutation };
};
