import { styled, keyframes } from 'styled-components';

const openingAnimation = keyframes`
    0%{
        scale: 0;
    }
    100% {
        scale: 1;
    }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalContent = styled.div`
  min-width: 25rem;
  background-color: white;
  animation: ${openingAnimation};
  animation-duration: 200ms;
  padding: 2rem;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;
export const ModalNoPaddingContent = styled.div`
  min-width: 25rem;
  background-color: white;
  animation: ${openingAnimation};
  animation-duration: 200ms;
  border-radius: 5px;
  position: relative;
  max-height: 94vh;
  overflow: scroll;
`;
