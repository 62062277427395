import { ImageIcon } from 'assets/ImageIcon';
import { useId, useMemo, useRef } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  display: grid;
  grid-template-columns: 13rem 13rem;
  width: 27rem;
  height: 100%;
  gap: 1rem;
  overflow-y: scroll;
`;

const AddImageContainer = styled.div`
  width: 13rem;
  aspect-ratio: 3771/5717;
  background-color: ${theme.colors.imageInputColor};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const Image = styled.div<{ $image: string }>`
  width: 13rem;
  aspect-ratio: 3771/5717;
  background-image: url(${({ $image }) => $image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const IconImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 3rem;
    width: 3rem;
  }
`;

const ImageComp = ({ src, onClick }) => {
  const id = useId();
  const url = useMemo(() => {
    return URL.createObjectURL(src);
  }, [src]);
  return <Image onClick={onClick} key={id} $image={url} />;
};

export const AddProductImages = ({ images, onChange }) => {
  const ref = useRef(null);

  const removeImage = (index: number) => {
    onChange((images) => {
      const newArray = [...images];
      newArray.splice(index, 1);
      return newArray;
    });
  };

  return (
    <Container>
      {images &&
        Array.from(images).map((image, index) => (
          <ImageComp
            src={image}
            key={index}
            onClick={() => {
              removeImage(index);
            }}
          />
        ))}
      <AddImageContainer
        onClick={() => {
          if (ref.current) {
            ref.current.value = null;
            ref.current.click();
          }
        }}
      >
        <IconImage>
          <ImageIcon />
        </IconImage>
        <Flex $noFullHeight $noFull>
          Dodaj sliku
        </Flex>
      </AddImageContainer>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        multiple
        value={null}
        type="file"
        ref={ref}
        onChange={(e) => {
          onChange((images) => [...images, ...e.target.files]);
        }}
      />
    </Container>
  );
};
