import React from 'react';

export const EmptyCartIcon = () => {
  return (
    <svg
      width="145"
      height="204"
      viewBox="0 0 145 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5156 139.104C20.998 142.5 34.4116 145.698 43.3501 145.707C76.6209 145.739 108.172 142.081 116.498 140.5C120.801 139.838 130.498 137 133.22 132.296"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0547 120.734C16.5 123.5 27 127.214 40.5557 127.102C76.1331 126.808 108.583 123.665 117.5 122C122.107 121.295 133.5 119 136.769 112.794"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.1554 53.6874C36.3506 49.7879 38.7597 47.1584 42.3833 47.1584C46.0068 47.1584 49.1612 49.7137 50.2716 53.6875M83.4484 52.3899C84.6331 48.6156 87.3697 46.0007 90.9932 46.0007C94.6168 46.0007 97.3991 49.5054 96.2226 53.6387"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.65 98.5058C127.821 103.699 103.364 107.953 74.6365 108.907C45.9819 109.859 21.3492 107.254 11.1497 102.746"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="72.3504"
        cy="63.0259"
        rx="69.9522"
        ry="13.1529"
        transform="rotate(-1.90302 72.3504 63.0259)"
        fill="white"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.264 90.6448C142.505 97.905 112.227 104.803 74.6353 106.052C37.0437 107.301 6.37404 102.428 6.13281 95.168"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.14844 94.9993L2.71711 67.4993M142.217 90.9993L142.217 61.9993"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.728 95.3889C137.29 117.07 133.274 134.27 129.798 145.685C126.748 155.702 117.642 162.133 107.222 163.169L65.8303 167.286C45.6615 169.291 25.7229 160.685 19.0814 141.535C15.3736 130.845 11.9575 117.166 10.2802 100.453"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="25"
        y="177"
        width="95"
        height="27"
      >
        <ellipse cx="72.6438" cy="190.506" rx="46.8" ry="13" fill="#E30040" />
      </mask>
      <g mask="url(#mask0)">
        <path d="M95.4029 217.992L58.8855 161.514" stroke="#18191A" />
        <path d="M103.044 217.992L66.5261 161.514" stroke="#18191A" />
        <path d="M110.684 217.992L74.1667 161.514" stroke="#18191A" />
        <path d="M118.329 217.992L81.8113 161.514" stroke="#18191A" />
        <path d="M125.969 217.992L89.4519 161.514" stroke="#18191A" />
        <path d="M133.61 217.992L97.0925 161.514" stroke="#18191A" />
        <path d="M141.251 217.992L104.733 161.514" stroke="#18191A" />
        <path d="M87.7623 217.757L51.2448 161.279" stroke="#18191A" />
        <path d="M80.1217 217.523L43.6042 161.045" stroke="#18191A" />
        <path d="M72.4771 217.289L35.9597 160.811" stroke="#18191A" />
        <path d="M64.8365 217.054L28.3191 160.576" stroke="#18191A" />
        <path d="M57.1959 216.82L20.6784 160.342" stroke="#18191A" />
        <path d="M49.5552 216.586L13.0378 160.108" stroke="#18191A" />
        <path d="M41.9146 216.351L5.39719 159.873" stroke="#18191A" />
        <path d="M34.274 216.117L-2.24344 159.639" stroke="#18191A" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.5 127.001C112.5 127.002 112.5 127.001 112.5 127.002C109.017 151.511 93.1728 157.74 82.382 157.802C65.8802 157.769 56.8023 148.815 51.2142 132.878C51.1232 132.619 51.0412 132.364 50.9661 132.099C50.1868 129.356 45.5962 112.377 45.7149 93.5516C45.7549 87.208 48.9513 84.6919 53.3307 84.4464C57.7102 84.2008 60.8742 88.1438 60.8399 93.5812C60.7885 101.737 60.8937 122.072 65.7083 138.252C69.1476 149.81 75.818 152.356 83.3799 152.371C90.8023 152.386 96.3012 147.694 98.3861 135.685C98.4647 135.233 98.5501 134.781 98.625 134.327C98.9745 132.212 99.9101 124.817 99.4997 108.001C99.4999 99.2421 100.196 94.6583 100.427 93.3789C100.473 93.1242 100.522 92.8721 100.573 92.6183C101.626 87.3561 104.263 84.3731 108.539 84.1333C112.731 83.8983 116.218 87.428 115.833 93.0214C115.798 93.5315 115.707 94.0341 115.618 94.5375C115.288 96.4043 114.455 101.711 114 110.001C113.424 120.479 112.504 126.973 112.5 127.001Z"
        fill="white"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle r="4" transform="matrix(-1 0 0 1 53.4375 92)" fill="#18191A" />
      <circle r="4" transform="matrix(-1 0 0 1 108.438 92)" fill="#18191A" />
      <path
        d="M50.4412 32.6014L34.375 23"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M64.4531 26L59.9531 2"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M84.9531 29.5L100.953 18"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
