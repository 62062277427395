export const footerLinks = [
  {
    title: 'KORISNIČKI SERVIS',
    links: [
      { title: 'Politika privatnosti', link: '/privacy-policy' },
      {
        title: 'Uslovi korišćenja internet prodavnice',
        link: '/terms-condition',
      },
      {
        title: 'Opšti uslovi online kupovine',
        link: '/regulations',
      },
      { title: 'Politika kolačića', link: 'cookies' },
    ],
  },
];
