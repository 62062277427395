import React from 'react';

export const EditIcon = ({ color = '#18191A' }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.39326 13.4273L1.57323 13.4274L1.57347 10.6068L6.6763 5.50375L5.56393 4.39138L0.230703 9.72476C0.0832197 9.87224 0.00039334 10.0723 0.000314683 10.2809L0 14.2139C0 14.4226 0.0828265 14.6227 0.23031 14.7702C0.377871 14.9177 0.577976 15.0006 0.786576 15.0006L4.71906 15.0005C4.92766 15.0005 5.12769 14.9176 5.27525 14.7701L10.6089 9.4364L9.49657 8.32402L4.39326 13.4273ZM14.7697 4.16335L10.8371 0.230388C10.6896 0.0828265 10.4895 0 10.2809 0C10.0723 0 9.87224 0.0828265 9.72468 0.230388L7.23241 2.72273L6.6763 3.27892L7.23249 3.83511L11.1651 7.76784L11.7213 8.32402L14.7696 5.27572C15.0768 4.96857 15.0768 4.47051 14.7697 4.16335ZM11.7213 6.09927L8.90106 3.27892L10.2809 1.89903L13.1011 4.71954L11.7213 6.09927Z"
        fill={color}
      />
    </svg>
  );
};
