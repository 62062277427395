import React from 'react';

export const ArrowLeftLocationSlider = ({ color }: { color: string }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8333 29.423L14.4104 20.0001L23.8333 10.5771L25.5896 12.3334L17.9229 20.0001L25.5896 27.6667L23.8333 29.423Z"
        fill={color}
      />
    </svg>
  );
};
