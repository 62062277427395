import React from 'react';

export const SortByIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path d="M18.2 19.766l4.8-4.8-1.131-1.131-3.434 3.434v-13.269h-1.6v13.269l-3.434-3.434-1.131 1.131 4.8 4.8c0.312 0.312 0.819 0.312 1.131 0zM5.8 4.234l-4.8 4.8 1.131 1.131 3.434-3.434v13.269h1.6v-13.269l3.434 3.434 1.131-1.131-4.8-4.8c-0.312-0.312-0.819-0.312-1.131 0z"></path>
    </svg>
  );
};
