import { Button } from 'components/Buttons/Button';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { ModalContext } from 'components/Modal/ModalContext';
import { ErrorModal } from 'components/Modals/ErrorModal';
import { useAuth } from 'context/AuthContext';
import React, { useContext, useState } from 'react';
import { Flex } from 'styles/Containers.styles';

export const ChangePassword = () => {
  const { editPassword } = useAuth();
  const { openModal } = useContext(ModalContext);
  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const onChange = (name: string, value: string) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    if (form.newPassword !== form.confirmPassword) {
      openModal(<ErrorModal message="Lozinke moraju biti iste" />);
      return;
    }
    if (form.newPassword === form.currentPassword) {
      openModal(
        <ErrorModal message="Trenutna i nova lozinka se moraju razlikovati" />
      );
      return;
    }
    if (form.newPassword.length < 6) {
      openModal(
        <ErrorModal message="Nova lozinka mora sadržati najmanje 6 karaktera" />
      );
      return;
    }
    if (form.currentPassword.length < 6) {
      openModal(
        <ErrorModal message="Trenutna lozinka mora sadržati najmanje 6 karaktera" />
      );
      return;
    }
    editPassword({
      currentPassword: form.currentPassword,
      newPassword: form.newPassword,
    });
  };

  return (
    <Flex $column>
      <PasswordInput
        name="currentPassword"
        label="Trenutna lozinka"
        value={form.currentPassword}
        onChange={onChange}
      />
      <PasswordInput
        name="newPassword"
        label="Nova lozinka"
        value={form.newPassword}
        onChange={onChange}
      />
      <PasswordInput
        name="confirmPassword"
        label="Potvrdite novu lozinku"
        value={form.confirmPassword}
        onChange={onChange}
      />
      <Button
        disabled={
          !form.currentPassword || !form.newPassword || !form.confirmPassword
        }
        onClick={onSubmit}
      >
        Sačuvaj
      </Button>
    </Flex>
  );
};
