import React from 'react';

export const ArrowRightLocationSlider = ({ color }: { color: string }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0771 20.0001L14.4104 12.3334L16.1667 10.5771L25.5896 20.0001L16.1667 29.423L14.4104 27.6667L22.0771 20.0001Z"
        fill={color}
      />
    </svg>
  );
};
