import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AddProductSize } from 'admin/modals/AddProductSize';
import { SelectProductSet } from 'admin/modals/SelectProductSet';
import { addProductColorVariant } from 'api/products.api';
import { Button } from 'components/Buttons/Button';
import { ColorPicker } from 'components/Inputs/ColorPicker';
import { Input } from 'components/Inputs/Input';
import { FullLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { AddProductImages } from './AddProductImages';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 20rem;
  height: 80vh;
`;

export const AddColorVariant = ({ id }) => {
  const [images, setImages] = useState<File[]>([]);
  const { openModal, closeModal } = useContext(ModalContext);
  const [sizes, setSizes] = useState([]);
  const [productsForSet, setProductsForSet] = useState([]);
  const queryClient = useQueryClient();
  const [form, setForm] = useState({
    colorHex: theme.colors.primary,
    colorName: '',
  });

  const { mutate: mutation, isLoading: addLoading } = useMutation(
    async (data: any) => await addProductColorVariant(data),
    {
      onSuccess: () => {
        closeModal();
        queryClient.invalidateQueries(['products']);
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const onChange = (prop: string, value: string) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };
  return (
    <Container>
      <AddProductImages
        images={images}
        onChange={(images) => setImages(images)}
      />
      <Flex>
        <Flex $column>
          <Flex $ai="center" $noFullHeight $gap="1rem">
            <ColorPicker
              color={form.colorHex}
              onChange={(color) => onChange('colorHex', color)}
            />
            <Input
              label="Boja"
              value={form.colorName}
              name="colorName"
              // error={!form.name}
              onValueChange={onChange}
            />
          </Flex>
          <Flex $column $gap="1rem">
            <Button
              width="100%"
              styleType="outline-dark"
              onClick={() => {
                openModal(
                  <AddProductSize
                    sizes={sizes}
                    onSave={(items) => setSizes(items)}
                    noOutsideClose
                  />
                );
              }}
            >
              Dodaj veličinu
            </Button>
            <Button
              width="100%"
              styleType="outline-dark"
              onClick={() => {
                openModal(
                  <SelectProductSet
                    preselected={productsForSet}
                    onSave={(value) => {
                      setProductsForSet(value);
                    }}
                  />
                );
              }}
            >
              Izaberi set
            </Button>
          </Flex>
          <Flex $jc="flex-end" $ai="flex-end">
            <Button
              onClick={() => {
                if (images.length < 1) {
                  toast.error('Slike moraju biti dodate');
                  return;
                } else if (!form.colorName) {
                  toast.error('Boja mora biti imenovana');
                  return;
                } else if (sizes.length < 1) {
                  toast.error('Veličine moraju biti dodate');
                  return;
                }
                const dataForm = new FormData();

                const items = sizes.map((item) => ({
                  size: item.size,
                  description: item.description,
                  quantity: Number(item.quantity),
                  catalogueId: item.catalogueId,
                }));
                const colorVariant = {
                  colorName: form.colorName,
                  colorHex: form.colorHex,
                  items: items,
                  setColorVariantIds: productsForSet,
                };

                dataForm.append('colorVariant', JSON.stringify(colorVariant));
                dataForm.append('productId', JSON.stringify(id));
                for (let i = 0; i < images.length; i++) {
                  dataForm.append('images', images[i]);
                }

                mutation(dataForm);
              }}
            >
              Sačuvaj
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <FullLoader loading={addLoading} />
    </Container>
  );
};
