import React from 'react';

export const CategoryIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 5C6.25 4.65482 6.52982 4.375 6.875 4.375H16.875C17.2202 4.375 17.5 4.65482 17.5 5C17.5 5.34518 17.2202 5.625 16.875 5.625H6.875C6.52982 5.625 6.25 5.34518 6.25 5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 10C6.25 9.65482 6.52982 9.375 6.875 9.375H16.875C17.2202 9.375 17.5 9.65482 17.5 10C17.5 10.3452 17.2202 10.625 16.875 10.625H6.875C6.52982 10.625 6.25 10.3452 6.25 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 15C6.25 14.6548 6.52982 14.375 6.875 14.375H16.875C17.2202 14.375 17.5 14.6548 17.5 15C17.5 15.3452 17.2202 15.625 16.875 15.625H6.875C6.52982 15.625 6.25 15.3452 6.25 15Z"
        fill="currentColor"
      />
      <path
        d="M3.4375 5.9375C3.95527 5.9375 4.375 5.51777 4.375 5C4.375 4.48223 3.95527 4.0625 3.4375 4.0625C2.91973 4.0625 2.5 4.48223 2.5 5C2.5 5.51777 2.91973 5.9375 3.4375 5.9375Z"
        fill="currentColor"
      />
      <path
        d="M3.4375 10.9375C3.95527 10.9375 4.375 10.5178 4.375 10C4.375 9.48223 3.95527 9.0625 3.4375 9.0625C2.91973 9.0625 2.5 9.48223 2.5 10C2.5 10.5178 2.91973 10.9375 3.4375 10.9375Z"
        fill="currentColor"
      />
      <path
        d="M3.4375 15.9375C3.95527 15.9375 4.375 15.5178 4.375 15C4.375 14.4822 3.95527 14.0625 3.4375 14.0625C2.91973 14.0625 2.5 14.4822 2.5 15C2.5 15.5178 2.91973 15.9375 3.4375 15.9375Z"
        fill="currentColor"
      />
    </svg>
  );
};
