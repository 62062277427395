import { PenIcon } from 'assets/PenIcon';
import { TrashIcon } from 'assets/TrashIcon';
import { useHover } from 'hooks/useHover';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const Container = styled.div<{ $selected: boolean; $pointer: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  position: relative;
  color: ${({ $selected }) => $selected && theme.colors.primary};
  cursor: ${({ $pointer }) => $pointer && 'pointer'};
`;

const Image = styled.img<{ $selected: boolean }>`
  width: 4rem;
  height: 5rem;
  object-fit: contain;
  border: ${({ $selected }) => $selected && '1px solid' + theme.colors.primary};
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const IconButton = styled.div<{ $bgColor: string }>`
  width: 2.1rem;
  height: 2.1rem;
  background-color: ${({ $bgColor }) => $bgColor};
  color: white;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 70%;
    height: 70%;
  }
`;

export const ColorVariant = ({
  color,
  image,
  onDeleteClick = null,
  onEditClick = null,
  selected = false,
  onClick = null,
}) => {
  const [hoverRef, isHovered] = useHover();
  return (
    <Container
      ref={hoverRef}
      $selected={selected}
      onClick={onClick}
      $pointer={!!onClick}
    >
      <ImageContainer>
        <Image src={image} $selected={selected} />
        {isHovered && (!!onDeleteClick || !!onEditClick) && (
          <IconContainer>
            {onDeleteClick && (
              <IconButton
                onClick={onDeleteClick}
                $bgColor={theme.colors.primary}
              >
                <TrashIcon color="white" />
              </IconButton>
            )}
            {onEditClick && (
              <IconButton onClick={onEditClick} $bgColor={theme.colors.blue}>
                <PenIcon color="white" />
              </IconButton>
            )}
          </IconContainer>
        )}
      </ImageContainer>
      <p>{color}</p>
    </Container>
  );
};
