export const removeEmpty = (obj) => {
  return Object.keys(obj)
    .filter(function (k) {
      return obj[k] != null;
    })
    .reduce(function (acc, k) {
      acc[k] = obj[k];
      return acc;
    }, {});
};

export const generateArray = (n: number): number[] => {
  const maxLength = 10;
  if (n <= maxLength) {
    return Array.from({ length: n }, (_, i) => i + 1);
  } else {
    return Array.from({ length: maxLength }, (_, i) => i + 1);
  }
};
