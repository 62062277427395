import { DeleteIcon } from 'assets/DeleteIcon';
import { EditIcon } from 'assets/EditIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { Divider } from 'components/Drividers/Divider';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const Container = styled.div`
  width: 600px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  h1 {
    font-size: 2.125rem;
  }
`;

const StyledImage = styled.div<{ image: string }>`
  width: 100%;
  background-color: #000;
  background-image: url(${({ image }) => image});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #6a6a6a;
  gap: 1rem;
  font-size: 0.875rem;
  aspect-ratio: 127/51;
  background-size: cover;
  background-position: center;
  min-height: 241px;
`;

const Content = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  min-height: 0;

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }
  ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }
`;

const Title = styled.h1`
  font-size: 20px !important;
`;

export const BlogDetailsModal = ({ blog, onDeleteClick, onEditClick }) => {
  return (
    <Container>
      <StyledImage image={blog.imageUrl} />
      <Content>
        <Flex $fs={0.875} $bold={300} $noFull $noFullHeight>
          {format(new Date(blog.createdAt), 'dd. MM. yyyy')}
        </Flex>
        <Title>{blog.title}</Title>
        <div
          dangerouslySetInnerHTML={{ __html: blog.content }}
          style={{ height: '100%', whiteSpace: 'pre-wrap', flex: 1 }}
        />
        <Divider />
        <Flex $noFullHeight $mt="0.4rem">
          <Flex $jc="flex-end">
            <IconButton size={24} onClick={onEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton size={24} onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Flex>
        </Flex>
      </Content>
    </Container>
  );
};
