import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

export const ShowcaseContainer = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  align-items: center;
  text-align: center;
  padding-inline: 3rem;
  max-width: 100vw;
  padding-block: 3rem;

  @media screen and (max-width: 950px) {
    padding: 0;
  }
`;

export const ShowcaseItemContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow: scroll;
  min-width: 0;
  justify-content: center;
`;

export const ShowcaseItem = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 7.25rem;
  align-items: center;
`;

export const ShowcaseItemImage = styled.img`
  width: 100%;
`;
