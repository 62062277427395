import { AddAttributeGroup } from 'admin/modals/AddAtrributeGroup';
import { AddAttributeValue } from 'admin/modals/AddAttributeValue';
import { DeleteModal } from 'admin/modals/DeleteModal';
import { EditAttributeGroup } from 'admin/modals/EditAtrributeGroup';
import {
  AttributeValue,
  AttributeValueWrapper,
} from 'components/Attribute/Attribute';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { ModalContext } from 'components/Modal/ModalContext';
import { useAttributesWithValue } from 'hooks/useAttributesWithValue';
import { useContext } from 'react';

export const Attributes = () => {
  const { isLoading, data, deleteMutation, deleteAttributeValueMutation } =
    useAttributesWithValue();
  const { openModal } = useContext(ModalContext);

  return (
    <List
      headerItems={[{ header: 'Naziv grupe', span: 20 }]}
      actionTitle="Dodaj grupu"
      action={() => openModal(<AddAttributeGroup />)}
      loading={isLoading}
    >
      {data?.map((item) => (
        <ListItem.Item
          key={1 + item.id}
          items={[{ item: item.name, span: 20 }]}
          onAddClick={() => openModal(<AddAttributeValue attribute={item} />)}
          onEditClick={() => openModal(<EditAttributeGroup attribute={item} />)}
          onDeleteClick={() =>
            openModal(
              <DeleteModal
                deleteAction={() => deleteMutation.mutate(item.id)}
              />
            )
          }
        >
          {item.values.length > 0 && (
            <AttributeValueWrapper>
              {item.values.map((value) => (
                <AttributeValue
                  key={value.id}
                  {...value}
                  onDeleteClick={() =>
                    openModal(
                      <DeleteModal
                        deleteAction={() => {
                          deleteAttributeValueMutation.mutate({
                            id: item.id,
                            attributeValueId: value.id,
                          });
                        }}
                      />
                    )
                  }
                />
              ))}
            </AttributeValueWrapper>
          )}
        </ListItem.Item>
      ))}
    </List>
  );
};
