import { DeliveryMethod, PaymentMethod } from 'types/order.types';

export const paymentMethods: {
  name: string;
  value: PaymentMethod;
}[] = [
  {
    name: 'Kartica',
    value: 'card',
  },
  {
    name: 'Pri preuzimanju',
    value: 'onArrival',
  },
];

export const deliveryMethods: {
  name: string;
  value: DeliveryMethod;
}[] = [
  {
    name: 'City Express',
    value: 'cityExpress',
  },
];
