import { OrderStatusType } from 'types/orders.types';

export const formatStatusText = (status: OrderStatusType) => {
  switch (status) {
    case 'confirmed':
      return 'Potvrđeno';
    case 'created':
      return 'Novo';
    case 'pending':
      return 'U obradi';
    case 'shipped':
      return 'Na dostavi';
    default:
      return status;
  }
};
