import { DeleteModal } from 'admin/modals/DeleteModal';
import { List } from 'components/List/List';
import { ListItem } from 'components/List/ListItem';
import { ModalContext } from 'components/Modal/ModalContext';
import { useCollections } from 'hooks/useCollections';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const Collections = () => {
  const { isLoading, data, deleteMutation } = useCollections();
  const { openModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <List
      headerItems={[{ header: 'Naziv kolekcije', span: 20 }]}
      actionTitle="Dodaj kolekciju"
      action={() => navigate('create')}
      loading={isLoading}
    >
      {data?.map((item) => (
        <ListItem.Item
          key={1 + item.id}
          items={[
            {
              span: 20,
              item: item.name,
            },
          ]}
          onEditClick={() => navigate(`edit/${item.id}`)}
          onDeleteClick={() =>
            openModal(
              <DeleteModal
                deleteAction={() => {
                  deleteMutation.mutate(item.id);
                }}
              />
            )
          }
        />
      ))}
    </List>
  );
};
