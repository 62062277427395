import axiosInstance from './instance';

export const getPromoCodes = async () => {
  const res = await axiosInstance.get('/v1/promo-codes');

  return res.data.data;
};

export const createPromoCode = async (data: {
  code: string;
  discount: number;
}) => {
  const res = await axiosInstance.post('/v1/promo-codes', data);
  return res.data.data;
};

export const deletePromoCode = async (id: number) => {
  const res = await axiosInstance.delete(`/v1/promo-codes/${id}`);
  return res.data.data;
};
