import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

button {
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

h1 {
  font-size: inherit;
}

body {
  display: flex;
  max-width: 100vw;
  color: ${({ theme }) => theme.colors.secondaryDark};
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}
/* 
#root {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-x: hidden;

} */


a {
  color: inherit;
  text-decoration: none;
}

input {
  font-size: 1rem;
  line-height: 1.5;
}

textarea {
  font-family: 'Inter', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
}
* { 
    box-sizing: border-box;
    margin:0;
    padding: 0;
    ::-webkit-scrollbar {
    display: none;
  }
  }
*:focus {
    outline: none;

}
`;

export default GlobalStyles;
