import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../styles/theme';
import RippleWrapper from '../Containers/RippleContainer';

interface Props extends React.HTMLProps<HTMLButtonElement> {
  styleType?:
    | 'primary'
    | 'secondary'
    | 'outline-light'
    | 'outline-dark'
    | 'text'
    | 'outline-gray'
    | 'color';
  children: React.ReactNode;
  width?: string;
  square?: boolean;
}

const ButtonContainer = styled.button<{
  width?: string;
  disabled: boolean;
  square: boolean;
}>`
  width: ${({ width }) => width || '163px'};
  padding: ${({ width }) => (width ? '0 1rem' : 0)};
  height: 36px;
  border-radius: ${({ square }) => (square ? '7px' : '100px')};
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: all 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  white-space: nowrap;

  svg {
    fill: currentColor;
    height: 24px;
    width: 24px;
  }
`;

const PrimaryButton = styled(ButtonContainer)`
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  color: ${({ theme }) => theme.colors.background};
`;

const SecondaryButton = styled(ButtonContainer)`
  /* background-color: ${({ theme }) => theme.colors.background}; */
  background-color: white;
  color: ${({ theme }) => theme.colors.secondaryDark};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryLight};
  }
`;
const OutlineLightButton = styled(ButtonContainer)`
  border: 1px solid white;
  background-color: white;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;
const OutlineGrayButton = styled(ButtonContainer)`
  color: ${({ theme }) => theme.colors.secondaryDark};
  border: 1px solid ${({ theme }) => theme.colors.secondaryDark};
  background-color: 'white';
`;
const OutlineDarkButton = styled(ButtonContainer)`
  color: ${({ theme, disabled }) =>
    disabled ? 'white' : theme.colors.secondaryDark};
  border: 1px solid ${({ theme }) => theme.colors.secondaryDark};
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.secondaryDark : 'white'};
  opacity: ${({ disabled }) => (disabled ? 0.9 : 1)};

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? 'white' : theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;
const TextButton = styled(ButtonContainer)`
  color: ${({ theme }) => theme.colors.secondaryDark};

  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const ColorButton = styled(ButtonContainer)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
`;

export const Button = ({
  styleType = 'primary',
  children,
  width,
  disabled,
  onClick,
  square,
}: Props) => {
  switch (styleType) {
    case 'color':
      return (
        <RippleWrapper
          width={width}
          radius={square ? '7px' : '100px'}
          color={theme.colors.secondaryLight}
        >
          <ColorButton
            onClick={onClick}
            disabled={disabled}
            square={square}
            width={width}
          >
            {children}
          </ColorButton>
        </RippleWrapper>
      );
    case 'secondary':
      return (
        <RippleWrapper
          width={width}
          radius={square ? '7px' : '100px'}
          color={theme.colors.primary}
        >
          <SecondaryButton
            onClick={onClick}
            disabled={disabled}
            square={square}
            width={width}
          >
            {children}
          </SecondaryButton>
        </RippleWrapper>
      );
    case 'outline-light':
      return (
        <RippleWrapper
          width={width}
          radius={square ? '7px' : '100px'}
          color={theme.colors.primary}
        >
          <OutlineLightButton
            onClick={onClick}
            disabled={disabled}
            square={square}
            width={width}
          >
            {children}
          </OutlineLightButton>
        </RippleWrapper>
      );
    case 'outline-gray':
      return (
        <RippleWrapper
          width={width}
          radius={square ? '7px' : '100px'}
          color={theme.colors.primary}
        >
          <OutlineGrayButton
            onClick={onClick}
            disabled={disabled}
            square={square}
            width={width}
          >
            {children}
          </OutlineGrayButton>
        </RippleWrapper>
      );
    case 'outline-dark':
      return (
        <RippleWrapper
          width={width}
          radius={square ? '7px' : '100px'}
          color={theme.colors.primary}
        >
          <OutlineDarkButton
            onClick={onClick}
            disabled={disabled}
            square={square}
            width={width}
          >
            {children}
          </OutlineDarkButton>
        </RippleWrapper>
      );
    case 'text':
      return (
        <RippleWrapper
          width={width}
          radius={square ? '7px' : '100px'}
          color={theme.colors.secondaryLight}
        >
          <TextButton
            onClick={onClick}
            disabled={disabled}
            square={square}
            width={width}
          >
            {children}
          </TextButton>
        </RippleWrapper>
      );
    default:
      return (
        <RippleWrapper
          width={width}
          radius={square ? '7px' : '100px'}
          color={theme.colors.secondaryLight}
        >
          <PrimaryButton
            onClick={onClick}
            disabled={disabled}
            square={square}
            width={width}
          >
            {children}
          </PrimaryButton>
        </RippleWrapper>
      );
  }
};
