import { ArrowDownIcon } from 'assets/ArrowDownIcon';
import { ExpandableContainer } from 'components/Containers/ExpandableContainer';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';

const MobileLinkComponentContainer = styled.div<{
  $specialColor?: string;
  $active?: boolean;
}>`
  position: relative;
  width: 100%;
  text-transform: uppercase;
  color: ${({ $specialColor }) => $specialColor || 'inherit'};
  background-color: ${({ $active }) => $active && 'rgb(218, 220, 223)'};
`;

const ArrowContainer = styled.div`
  width: 20px;
  height: 20px;
`;

type Props = {
  children: React.ReactNode;
  title: string;
};

export const ExpendableItem = ({ title, children }: Props) => {
  const [opened, setOpened] = useState(true);
  return (
    <MobileLinkComponentContainer>
      <Flex
        $jc="space-between"
        $p="1rem 0"
        $pointer
        $ai="center"
        onClick={() => {
          setOpened((prev) => !prev);
        }}
      >
        <Flex $bold={700} $fs={1.4}>
          {title}
        </Flex>
        <ArrowContainer>
          <ArrowDownIcon />
        </ArrowContainer>
      </Flex>
      <ExpandableContainer opened={opened}>{children}</ExpandableContainer>
    </MobileLinkComponentContainer>
  );
};
