import React, { useEffect } from 'react';

export default function useClickOutside(callback: () => void) {
  const ref = React.useRef<HTMLDivElement>(null);
  const rootElement = document.getElementById('root');

  // Registers the Click Event
  function registerClickEventListener() {
    if (!rootElement) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rootElement.addEventListener('click', (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    });
  }

  // un-registers the Click Event
  function unRegisterClickEventListener() {
    if (!rootElement) return;

    rootElement.addEventListener('click', (event) => {
      if (event.target === ref.current) {
        callback();
      }
    });
  }

  useEffect(() => {
    registerClickEventListener();

    return () => {
      unRegisterClickEventListener();
    };
  }, []);

  return ref;
}
