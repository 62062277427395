import { Button } from 'components/Buttons/Button';
import { EmailInput } from 'components/Inputs/EmailInput';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';

export const LoginForm = ({ ifUserNavigate = null }) => {
  const { login, user } = useAuth();

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const onChange = (name: string, value: string) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  if (user) {
    return <Navigate to={ifUserNavigate || '/profile'} replace={true} />;
  }

  return (
    <Flex $column $gap="1rem">
      <Flex $column $noFullHeight>
        <EmailInput name="email" value={form.email} onChange={onChange} />
        <PasswordInput
          name="password"
          value={form.password}
          onChange={onChange}
        />
      </Flex>
      <Flex $noFullHeight $jc="flex-end" $fs={0.875}>
        <Link to="/forgot-password" style={{ textDecoration: 'underline' }}>
          Zaboravili ste šifru?
        </Link>
      </Flex>
      <Button
        width="100%"
        disabled={!form.email || !form.password}
        onClick={() => {
          login(form.email, form.password);
        }}
      >
        Prijava
      </Button>
    </Flex>
  );
};
