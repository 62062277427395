import { RegisterType, UserType } from 'types/user.type';
import axiosInstance from './instance';

export const loginApiCall = async (email: string, password: string) => {
  const response = await axiosInstance.post('/v1/auth/sign-in', {
    email,
    password,
  });
  return response.data.data as {
    user: UserType;
    tokens: {
      accessToken: string;
      refreshToken: string;
    };
  };
};

export const forgotPasswordApiCall = async (email: string) => {
  const response = await axiosInstance.post('/v1/auth/forgot-password', {
    email,
  });
  return response.data;
};

export const registerApiCall = async (data: RegisterType) => {
  const response = await axiosInstance.post('/v1/auth/sign-up', {
    ...data,
  });
  return response.data.data as {
    user: UserType;
    tokens: {
      accessToken: string;
      refreshToken: string;
    };
  };
};

export const getUserCall = async () => {
  const response = await axiosInstance.get('/v1/users');
  return response.data.data;
};

export const logoutCall = async () => {
  return axiosInstance.post('/v1/users/logout');
};

export const editUserApiCall = async (id, data) => {
  const res = await axiosInstance.patch(`/v1/users/${id}`, data);

  return res.data.data;
};

export const editPasswordApiCall = async (
  id: string,
  data: { currentPassword: string; newPassword: string }
) => {
  const res = await axiosInstance.put(`/v1/users/${id}/change-password`, data);

  return res.data.data;
};

export const changePasswordApiCall = async (
  token: string,
  password: string
) => {
  const res = await axiosInstance.post(`/v1/auth/change-password`, {
    token,
    password,
  });

  return res.data;
};
