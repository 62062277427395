import { EmptyOrdersIcon } from 'assets/EmptyOrdersIcon';
import { Button } from 'components/Buttons/Button';
import { FullContainerLoader } from 'components/Loader/Loader';
import { useMyOrders } from 'hooks/useMyOrders';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'styles/Containers.styles';
import { MyOrdersList } from './MyOrdersList';

export const MyOrders = () => {
  const navigate = useNavigate();
  const { isLoading, orders } = useMyOrders();

  if (isLoading) {
    <FullContainerLoader />;
  }

  return orders?.length > 0 ? (
    <MyOrdersList list={orders} />
  ) : (
    <Flex $jc="center" $bold={700} $fs={1.5} $column $ai="center" $gap="3rem">
      <EmptyOrdersIcon />
      VAŠA ISTORIJA KUPOVINE JE PRAZNA
      <Button width="200px" styleType="color" onClick={() => navigate('/novo')}>
        Pretražite nove proizvode
      </Button>
    </Flex>
  );
};
