import { Button } from 'components/Buttons/Button';
import { FullContainerLoader } from 'components/Loader/Loader';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { ItemColumn, ListHeader } from './ListItem';

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
  height: 100%;
  min-height: 0;
`;

const ButtonContainer = styled.div`
  width: 160px;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-left: 1rem;
`;

export const List = ({
  headerItems,
  actionTitle = null,
  action = null,
  children,
  loading = false,
  noAction = false,
  customItem = null,
}) => {
  return (
    <ListContainer>
      <ListHeader>
        <Flex $noFullHeight $bold={700} $gap="1rem">
          {headerItems.map((item, index) => (
            <ItemColumn
              $span={item.span || 100}
              key={'header' + index}
              $center={item.center}
            >
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.header}
              </div>
            </ItemColumn>
          ))}
        </Flex>

        {customItem && (
          <Flex $jc="flex-end" $noFull>
            {customItem}
          </Flex>
        )}
        {!noAction ? (
          <ButtonContainer>
            <Button styleType="color" onClick={action}>
              {actionTitle}
            </Button>
          </ButtonContainer>
        ) : null}
      </ListHeader>
      {loading ? <FullContainerLoader /> : children}
    </ListContainer>
  );
};
