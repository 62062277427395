import { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import './PriceRangePicker.style.css';

export const PriceRangePicker = ({ min, max, onMinChange, onMaxChange }) => {
  const [minVal, setMinVal] = useState(null);
  const [maxVal, setMaxVal] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (minVal) {
        onMinChange(minVal, maxVal ?? max);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [minVal]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (maxVal) {
        onMaxChange(maxVal, minVal ?? min);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [maxVal]);

  return (
    <ReactSlider
      className="horizontal-slider"
      thumbClassName="example-thumb"
      trackClassName="example-track"
      defaultValue={[min, max] as never}
      min={min}
      max={max}
      //   ariaLabel={['Lower thumb', 'Upper thumb']}
      ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
      renderThumb={(props, state) => (
        <div {...props}>
          <div
            style={{
              position: 'absolute',
              top: state.index === 0 && '-1.5rem',
              bottom: state.index === 1 && '-1.2rem',
              right: state.index === 1 && '0',
              left: state.index === 0 && '0',
            }}
          >
            RSD {(state.valueNow / 100).toFixed()}
          </div>
        </div>
      )}
      pearling
      minDistance={10}
      onChange={(value, index) => {
        if (index === 0) {
          setMinVal(value[0]);
        } else {
          setMaxVal(value[1]);
        }
      }}
    />
  );
};
