import { getCategoryBySlug } from 'api/categories.api';
import { getCollectionBySlug } from 'api/collections';
import { getSubcategoryBySlug } from 'api/subcategory.api';
import { PAGES } from 'fakeData/pages';

export const findPage = async (type: string, id?: string) => {
  if (type === 'na-popustu') {
    return PAGES.sale;
  } else if (type === 'outlet') {
    return PAGES.outlet;
  } else if (type === 'novo') {
    return PAGES.new;
  } else if (type === 'kolekcije' && !id) {
    return PAGES.collections;
  } else if (type === 'pretraga') {
    return PAGES.search;
  } else if (type === 'podkategorija') {
    const page = await getSubcategoryBySlug(id);
    return page;
  } else if (type === 'kategorija') {
    const page = await getCategoryBySlug(id);
    return page;
  } else if (type === 'kolekcije') {
    const page = await getCollectionBySlug(id);
    return page;
  } else {
    throw new Error();
  }
};
