import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changeOrderStatus } from 'api/orders.api';
import { FullLoader } from 'components/Loader/Loader';
import { ModalContext } from 'components/Modal/ModalContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { OrderStatusType } from 'types/orders.types';
import { formatStatusText } from 'utils/status.utils';
import { LabelCountModal } from './LabelCountModal';

const statuses: OrderStatusType[] = [
  'created',
  'pending',
  'confirmed',
  'shipped',
];

const StatusItem = styled(Flex)`
  padding: 0.5rem;
  border-radius: 0.5rem;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const ChangeStatusModal = ({
  id,
  currentStatus,
}: {
  id: number;
  currentStatus: string;
}) => {
  const queryClient = useQueryClient();
  const { closeModal, openModal } = useContext(ModalContext);
  const { mutate: changeStatusMutation, isLoading: changeLoading } =
    useMutation(
      async (vars: { status: OrderStatusType; labelsCount?: number }) => {
        return await changeOrderStatus(id, vars.status, vars.labelsCount);
      },
      {
        onSuccess: (returnData: any) => {
          queryClient.setQueryData(['orders'], (previousData: any) => {
            console.log(returnData);
            const newPagesArray =
              previousData?.pages.map((page) => ({
                ...page,
                data: page?.data?.map((order) =>
                  order.id !== id ? order : returnData
                ),
              })) ?? [];
            closeModal();

            console.log(previousData);

            console.log({
              ...previousData,
              pages: newPagesArray,
            });

            return {
              ...previousData,
              pages: newPagesArray,
            };
          });
        },
        onError: (error: any) => {
          toast.error(error.response.data.message);
        },
      }
    );

  return (
    <Flex $column $gap="1rem" $ai="center">
      <Flex $noFullHeight $jc="center" $bold={700}>
        PROMENA STATUSA PORUDŽBINE
      </Flex>
      {statuses.map((status) => (
        <StatusItem
          $textcolor={theme.getColorByStatus(status)}
          key={status}
          $pointer
          $bold={700}
          onClick={() => {
            if (currentStatus === status) {
              toast.error('Ovaj status je već aktivan');
            } else if (status === 'confirmed') {
              openModal(
                <LabelCountModal
                  onSave={(labelsCount) => {
                    changeStatusMutation({ status, labelsCount });
                  }}
                />
              );
            } else {
              changeStatusMutation({ status });
            }
          }}
        >
          {formatStatusText(status)}
        </StatusItem>
      ))}
      <FullLoader loading={changeLoading} />
    </Flex>
  );
};
