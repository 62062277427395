import { CloseIcon } from 'assets/CloseIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { QuantitySelect } from 'components/Inputs/QuantitySelect';
import { CartContext } from 'context/CartContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useContext } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { CartItemType } from 'types/cart.types';

const CardContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  min-height: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;
`;

const CardImage = styled.img`
  width: 10rem;
  object-fit: contain;
  @media screen and (max-width: 800px) {
    width: 8rem;
  }
`;

export const CartCard = ({
  product,
  availableSize,
  withoutSelect = false,
  quantityError = false,
  exceededQuantity = false,
}: {
  product: CartItemType;
  availableSize?: Item;
  withoutSelect?: boolean;
  quantityError?: boolean;
  exceededQuantity?: boolean;
}) => {
  const { removeFromCart, changeItemQuantity } = useContext(CartContext);
  const { width } = useWindowWidth();

  console.log(product);

  return (
    <>
      <CardContainer style={{ color: quantityError && theme.colors.error }}>
        <CardImage src={product.images[0]} />
        <ContentContainer>
          <Flex $column>
            <Flex $jc="space-between" $noFullHeight>
              {product.name.toLocaleUpperCase()}
              <IconButton onClick={() => removeFromCart(product)}>
                <CloseIcon />
              </IconButton>
            </Flex>
            <Flex $noFullHeight $column>
              <Flex $noFull $bold={200}>
                Veličina: <Flex $bold={700}>{product?.size}</Flex>
              </Flex>
              <Flex $noFull $bold={200}>
                Boja: <Flex $bold={700}>{product.color}</Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            $ai={width < 500 ? 'flex-start' : 'flex-end'}
            $column={width < 500}
          >
            <Flex $noFullHeight $bold={200} $ai="flex-end" $gap="1rem">
              {quantityError ? (
                <Flex $noFullHeight $textcolor={theme.colors.error} $bold={700}>
                  Ovaj proizvod trenutno nije na zalihama
                </Flex>
              ) : (
                <>
                  {' '}
                  Količina:{' '}
                  {withoutSelect ? (
                    <Flex $bold={700}>{product.quantity}</Flex>
                  ) : (
                    <QuantitySelect
                      quantity={product?.quantity}
                      availableSizes={availableSize?.quantity}
                      onChange={(quantity) => {
                        changeItemQuantity(product.productItemId, quantity);
                      }}
                    />
                  )}
                </>
              )}
            </Flex>

            <Flex $noFullHeight $column $textRight>
              {product.price.price > 0 &&
                product.price.basePrice > product.price.price && (
                  <Flex $inline>
                    <s>
                      {(
                        (product.price.basePrice * product.quantity) /
                        100
                      ).toFixed(2)}{' '}
                      RSD
                    </s>
                  </Flex>
                )}

              {product.price.price > 0 ? (
                <Flex $bold={700} $fs={1.2} $inline>
                  {((product.price.price * product.quantity) / 100).toFixed(2)}{' '}
                  RSD
                </Flex>
              ) : (
                <Flex $bold={700} $fs={1.2} $inline>
                  Nema na stanju
                </Flex>
              )}
            </Flex>
          </Flex>
          {exceededQuantity && (
            <Flex
              $noFull
              $noFullHeight
              $bold={200}
              $textcolor={theme.colors.error}
            >
              Trenutno nemamo izabranu količinu proizvoda na stanju, pokušajte
              da smanjite količinu ili izbacite proizvod iz korpe.
            </Flex>
          )}
        </ContentContainer>
      </CardContainer>
    </>
  );
};
