import { useEffect, useRef } from 'react';

export function useDidUpdate(callback, deps) {
  const hasMount = useRef(false);

  useEffect(() => {
    if (hasMount.current) {
      console.log('RAN YES');
      callback();
    } else {
      console.log('RAN NO');
      hasMount.current = true;
    }
  }, deps);
}
