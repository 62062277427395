import { DeleteIcon } from 'assets/DeleteIcon';
import { useHover } from 'hooks/useHover';
import React from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { AttributeValueType } from 'types/attribute.types';

const Image = styled.img`
  width: 100%;
  max-width: 5rem;
`;

const AttributeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
`;

const DeleteButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-color: red;
  border-radius: 10rem;
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.35rem;
  cursor: pointer;
`;

interface Props extends AttributeValueType {
  onDeleteClick?: () => void;
}

export const AttributeValue = ({ name, image, onDeleteClick }: Props) => {
  const [hoverRef, isHovered] = useHover();
  return (
    <AttributeContainer ref={hoverRef}>
      <Image src={image} />
      <Flex $fs={0.785} $jc="center" style={{ textAlign: 'center' }}>
        {name}
      </Flex>
      {isHovered && onDeleteClick && (
        <DeleteButton onClick={onDeleteClick}>
          <DeleteIcon color="white" />
        </DeleteButton>
      )}
    </AttributeContainer>
  );
};

export const AttributeValueWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(4rem, 1fr));
  gap: 1rem;
`;
