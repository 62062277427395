import React from 'react';

export const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      aria-hidden="true"
    >
      <path
        fill="white"
        d="M20.147 13.662c-0.587-0.029-1.064-0.145-1.579-0.377-1.14-0.513-2.648-0.487-3.687 0.551l-0.7 0.7c-0.952-0.632-1.914-1.487-2.763-2.384-0.76-0.804-1.392-1.601-1.821-2.247l0.644-0.595c0.97-0.896 1.187-2.261 0.772-3.404-0.271-0.749-0.463-1.495-0.589-2.368-0.218-1.508-1.482-2.798-3.14-2.762l-3.1 0.068c-1.673 0.036-3.073 1.476-2.845 3.232 1.753 13.544 12.916 18.404 19.151 18.698 1.644 0.077 2.76-1.296 2.804-2.73l0.096-3.143c0.059-1.931-1.619-3.159-3.245-3.238zM21.297 19.983c-0.016 0.534-0.388 0.809-0.711 0.794-5.569-0.262-15.669-4.647-17.262-16.956-0.062-0.482 0.323-0.963 0.906-0.976l3.1-0.067c0.516-0.011 1.024 0.404 1.117 1.048 0.146 1.008 0.371 1.887 0.689 2.764 0.177 0.487 0.055 0.973-0.249 1.253l-1.241 1.146c-0.332 0.307-0.417 0.798-0.207 1.199 0.483 0.922 1.415 2.163 2.529 3.34 1.117 1.181 2.487 2.372 3.881 3.147 0.39 0.217 0.877 0.149 1.193-0.167l1.256-1.256c0.304-0.304 0.86-0.408 1.451-0.142 0.744 0.335 1.465 0.51 2.302 0.551 0.853 0.042 1.36 0.63 1.344 1.179l-0.096 3.143z"
      ></path>
    </svg>
  );
};
