import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Inputs/Checkbox';
import { EmailInput } from 'components/Inputs/EmailInput';
import { Input } from 'components/Inputs/Input';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import { ModalContext } from 'components/Modal/ModalContext';
import { ErrorModal } from 'components/Modals/ErrorModal';
import { useAuth } from 'context/AuthContext';
import { useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Flex, FormContainer } from 'styles/Containers.styles';
import { validateEmail } from 'utils/validation.utils';

export const Registration = ({ link = null }) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptedTerms: false,
    acceptEmail: false,
  });

  const { register, user } = useAuth();
  const { openModal } = useContext(ModalContext);

  const onChange = (prop, value) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = form;
    if (password.length < 6) {
      openModal(
        <ErrorModal message="Lozinka mora da sadrži najmanje 6 karaktera" />
      );
      return;
    } else if (password !== confirmPassword) {
      openModal(<ErrorModal message="Lozinke moraju biti iste" />);
      return;
    }
    register({ name, email, password });
  };

  if (user) {
    return <Navigate to={link ?? '/profile'} />;
  }

  return (
    <Flex $jc="center">
      <FormContainer onSubmit={handleSubmit}>
        <Flex $column $gap="1rem">
          <Flex $noFullHeight $fs={1.5} $bold={700}>
            REGISTRACIJA
          </Flex>
          <Input
            label="Ime"
            value={form.name}
            name="name"
            onValueChange={onChange}
          />
          <EmailInput value={form.email} name="email" onChange={onChange} />
          <PasswordInput
            label="Lozinka"
            value={form.password}
            name="password"
            onChange={onChange}
          />
          <PasswordInput
            label="Potvrdi lozinku"
            value={form.confirmPassword}
            name="confirmPassword"
            onChange={onChange}
          />
          <Checkbox
            onChange={() =>
              setForm((prev) => ({
                ...prev,
                acceptedTerms: !prev.acceptedTerms,
              }))
            }
            checked={form.acceptedTerms}
            text={
              <p>
                * Prihvatam{' '}
                <Link
                  style={{ textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  to="/regulations"
                >
                  uslove korišćenja internet prodavnice
                </Link>{' '}
                i{' '}
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: 'underline' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  politiku privatnosti
                </Link>
              </p>
            }
          />
          {/* <Checkbox
          onChange={() =>
            setForm((prev) => ({
              ...prev,
              acceptEmail: !prev.acceptEmail,
            }))
          }
          checked={form.acceptEmail}
          text="I agree to receive by electronic means to the e-mail address provided, commercial information regarding services and products of ESOTIQ & HENDERSON S.A. based in Gdańsk, in particular information on promotions and other marketing activities."
        /> */}
          <Button
            disabled={
              !form.email ||
              !form.password ||
              !form.confirmPassword ||
              !form.acceptedTerms ||
              !validateEmail(form.email)
            }
            width="100%"
          >
            Registrujte se
          </Button>
        </Flex>
      </FormContainer>
    </Flex>
  );
};
