import { Button } from 'components/Buttons/Button';
import { ModalWithNoPadding } from 'components/Modal/ModalWithNoPadding';
import { useState } from 'react';
import { Flex } from 'styles/Containers.styles';
import { CreateBlogModal } from './CreateBlogModal/CreateBlogModal';

export const BlogsNavbar = () => {
  const [searchActive] = useState(false);
  const [createBlogOpen, setCreateBlogOpen] = useState(false);
  return (
    <Flex $jc="space-between">
      <Flex style={{ letterSpacing: 2 }} $ai="center" $fs={1.2} $bold={700}>
        Blogovi
      </Flex>
      <Flex $ai="center" $jc="flex-end">
        {!searchActive && (
          <>
            <Button
              square={true}
              styleType="color"
              onClick={() => {
                setCreateBlogOpen(true);
              }}
            >
              Kreiraj blog
            </Button>
            {/* <Button
              square
              width="fit-content"
              onClick={() => {
                setSearchActive(true);
              }}
            >
              <SearchIcon />
            </Button> */}
          </>
        )}
        {/* {searchActive && (
          <>
            <IconButton
              onClick={() => {
                handleSetUrlSearchParams('search', null);
                handleSetUrlSearchParams('page', null);
                setSearchActive(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            <SearchProductsInput initialValue={search} />
          </>
        )} */}
        {createBlogOpen && (
          <ModalWithNoPadding
            closeFunc={() => {
              setCreateBlogOpen(false);
            }}
            noOutsideClose
          >
            <CreateBlogModal
              onClose={() => {
                setCreateBlogOpen(false);
              }}
            />
          </ModalWithNoPadding>
        )}
      </Flex>
    </Flex>
  );
};
