import React from 'react';

export const ErrorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16ZM11 14H13V6H11V14ZM12 1C5.9249 1 1 5.9249 1 12C1 18.0751 5.9249 23 12 23C18.0751 23 23 18.0751 23 12C23 5.9249 18.0751 1 12 1ZM12 21C7.0294 21 3 16.9706 3 12C3 7.0294 7.0294 3 12 3C16.9706 3 21 7.0294 21 12C21 16.9706 16.9706 21 12 21Z"
        fill="#FF7A00"
      />
    </svg>
  );
};
