import { useQuery } from '@tanstack/react-query';
import { getColorVariantById } from 'api/products.api';

export const useColorVariantWithoutCache = ({ id }: { id: string }) => {
  const { isLoading, error, data, refetch } = useQuery<ColorVariantEditDetails>(
    ['colorVariant', { id }],
    () => getColorVariantById(id),
    { cacheTime: 1 }
  );

  return { isLoading, error, data, refetch };
};
