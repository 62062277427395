import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Inputs/Checkbox';
import { PasswordInput } from 'components/Inputs/PasswordInput';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Flex,
  FormContainer,
  SectionContainer,
} from 'styles/Containers.styles';

export const ResetPassword = () => {
  const [form, setForm] = useState({
    password: '',
    confirmPassword: '',
    acceptedTerms: false,
  });

  const onChange = (prop, value) => {
    setForm((prev) => ({
      ...prev,
      [prop]: value,
    }));
  };
  return (
    <SectionContainer>
      <Flex $jc="center">
        <FormContainer>
          <Flex $jc="center" $gap="1rem" $column>
            <Flex $fs={1.5} $bold={700}>
              RESET YOUR PASSWORD
            </Flex>
            <PasswordInput
              name="password"
              value={form.password}
              onChange={onChange}
              label="New password"
            />
            <PasswordInput
              label="Confirm password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={onChange}
            />
            <Checkbox
              onChange={() =>
                setForm((prev) => ({
                  ...prev,
                  acceptedTerms: !prev.acceptedTerms,
                }))
              }
              checked={form.acceptedTerms}
              text={
                <p>
                  * I accept{' '}
                  <Link
                    style={{ textDecoration: 'underline' }}
                    to="/terms-condition"
                  >
                    Sales regulations
                  </Link>{' '}
                  and{' '}
                  <Link
                    to="/privacy-policy"
                    style={{ textDecoration: 'underline' }}
                  >
                    Privacy policy
                  </Link>
                </p>
              }
            />
            <Button width="100%" onClick={() => console.log('RESET PASSWORD')}>
              Reset your password
            </Button>
          </Flex>
        </FormContainer>
      </Flex>
    </SectionContainer>
  );
};
