import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import { Flex } from '../../styles/Containers.styles';
import { StyledNavLink } from '../../styles/Links.styles';
import { ExpandableContainer } from '../Containers/ExpandableContainer';
import RippleWrapper from '../Containers/RippleContainer';
import { SimpleSubcategoryType } from 'types/subcategory.types';
import { CollectionType } from 'types/collection.types';

const MobileLinkComponentContainer = styled.div<{
  $specialColor?: string;
  $active?: boolean;
}>`
  position: relative;
  width: 100%;
  border-top: 1px solid #dadcdf;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ $specialColor }) => $specialColor || 'inherit'};
  background-color: ${({ $active }) => $active && 'rgb(218, 220, 223)'};
`;

const SubpageLink = styled(StyledNavLink)`
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
`;

const ArrowContainer = styled.div`
  width: 20px;
  height: 20px;
`;

type Props = {
  page: {
    name: string;
    link: string;
    linkImage?: string;
    speacialColor?: string;
    slug: string;
    id?: number;
    subcategories?: SimpleSubcategoryType[];
    collections?: CollectionType[];
  };
  opened: boolean;
  close: () => void;
  open: () => void;
};

const MobileLinkComponent = ({ page, opened, close, open }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split('/');

  return (
    <MobileLinkComponentContainer
      $specialColor={page.speacialColor}
      onClick={() => {
        if (page?.subcategories?.length > 0 || page?.collections?.length > 0) {
          opened ? close() : open();
        } else {
          navigate(
            page.link === 'novo'
              ? '/novo'
              : page.link === 'na-popustu'
              ? '/na-popustu'
              : page.link === 'outlet'
              ? '/outlet'
              : page.link === 'kolekcije'
              ? '/kolekcije'
              : `/kategorija/${page?.slug}`
          );
        }
      }}
      $active={opened || pathnames.includes(page.link)}
    >
      <RippleWrapper width="100%">
        <Flex $jc="space-between" $p="1rem">
          <Flex>{page.name}</Flex>
          {(page?.subcategories?.length > 0 ||
            page?.collections?.length > 0) && (
            <ArrowContainer>
              <ArrowDownIcon />
            </ArrowContainer>
          )}
        </Flex>
      </RippleWrapper>
      <ExpandableContainer opened={opened}>
        {page?.subcategories?.length > 0 && (
          <Flex $column $gap="1.5rem" $mt="1rem" $p="0 1rem">
            <SubpageLink
              key={page.name}
              to={`/kategorija/${page.slug}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Prikaži sve: {page.name}
            </SubpageLink>
            {page?.subcategories?.map((subcategory) => (
              <SubpageLink
                key={subcategory.key}
                to={'/podkategorija/' + subcategory.slug}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {subcategory.key}
              </SubpageLink>
            ))}
          </Flex>
        )}
        {page?.collections?.length > 0 && (
          <Flex $column $gap="1.5rem" $mt="1rem" $p="0 1rem">
            <SubpageLink
              key={page.name}
              to={`/kolekcije`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Prikaži sve: {page.name}
            </SubpageLink>
            {page?.collections?.map((collection) => (
              <SubpageLink
                key={collection.name}
                to={'/kolekcije/' + collection.slug}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {collection.name}
              </SubpageLink>
            ))}
          </Flex>
        )}
      </ExpandableContainer>
    </MobileLinkComponentContainer>
  );
};

export default MobileLinkComponent;
