import { InstagramIcon } from 'assets/SocialMedia/InstagramIcon';
import { EmailIcon } from '../../assets/EmailIcon';
import { PhoneIcon } from '../../assets/PhoneIcon';
import { FacebookIcon } from '../../assets/SocialMedia/FacebookIcon';
import { Flex } from '../../styles/Containers.styles';
import {
  FooterContainer,
  FooterLinks,
  FooterLinksContainer,
  SmallLink,
} from './Footer.style';
import { IntesaBranding } from './IntesaBranding';
import { footerLinks } from './footerData';
import { TikTokIcon } from 'assets/SocialMedia/TikTokIcon';

export const Footer = () => {
  // const { openModal } = useContext(ModalContext);
  return (
    <FooterContainer>
      <FooterLinks>
        {footerLinks.map((collection) => (
          <Flex
            $column
            $noFull
            key={collection.title}
            $gap="0.875rem"
            $noFullHeight
          >
            <p>{collection.title}</p>
            {collection.links.map((link) => (
              <SmallLink key={link.title} to={link.link}>
                {link.title}
              </SmallLink>
            ))}
          </Flex>
        ))}
        <Flex $jc="flex-end" $noFullHeight>
          <Flex $column $noFull $gap="0.875rem">
            <p>KONTAKT</p>
            <Flex
              $gap="1rem"
              $ai="center"
              $pointer
              style={{ textDecoration: 'underline' }}
            >
              <EmailIcon />{' '}
              <a href="mailto: esotiqwebshop@gmail.com">
                esotiqwebshop@gmail.com
              </a>
            </Flex>
            <Flex
              $gap="1rem"
              $ai="center"
              style={{ textDecoration: 'underline' }}
            >
              <PhoneIcon /> <a href="tel: +381 60 1320 929">+381 60 1320 929</a>
            </Flex>
            <Flex>Odgovaramo na pitanja od 9:00 - 17:00</Flex>
          </Flex>
        </Flex>
      </FooterLinks>

      <IntesaBranding />
      <FooterLinksContainer>
        <Flex $gap="1rem">
          <a
            href="https://www.facebook.com/esotiqserbia?mibextid=LQQJ4d"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://instagram.com/esotiq.rs?igshid=MzMyNGUyNmU2YQ=="
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.tiktok.com/@esotiqsrbija"
            target="_blank"
            rel="noreferrer"
          >
            <TikTokIcon />
          </a>
        </Flex>
        <Flex $jc="center">Copyright © 2023 Esotiq. Sva prava zadržana.</Flex>
        <Flex></Flex>
      </FooterLinksContainer>
    </FooterContainer>
  );
};
