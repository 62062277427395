import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { SimpleSubcategoryType } from 'types/subcategory.types';
import { CloseIcon } from '../../assets/CloseIcon';
import { IconButton } from '../Buttons/IconButton';
import MobileLinkComponent from './MobileLinkComponent';
import { NavMainContainer } from './Navbar.style';

const MobileMenuContainer = styled.div<{
  $opened: boolean;
}>`
  width: 100vw;
  height: 100svh;
  position: fixed;
  background-color: white;
  left: 0;
  top: 0;
  transition: 250ms all ease-in;
  overflow-y: scroll;
  min-height: 0;
  z-index: 5;
  transform: translateX(${({ $opened }) => ($opened ? '0' : '-100%')});
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
`;

type Props = {
  opened: boolean;
  close: () => void;
  links: {
    name: string;
    link: string;
    slug: string;
    linkImage?: string;
    speacialColor?: string;
    id?: number;
    subcategories?: SimpleSubcategoryType[];
  }[];
};

export const MobileMenu = ({ opened, close, links }: Props) => {
  const location = useLocation();
  const [openedLink, setOpenedLink] = useState<number | null>(null);

  useEffect(() => {
    close();
  }, [location.pathname]);
  return (
    <MobileMenuContainer $opened={opened}>
      <NavMainContainer>
        <IconButton
          tooltip="Menu"
          onClick={() => {
            close();
          }}
        >
          <CloseIcon />
        </IconButton>
      </NavMainContainer>

      {links.map((page, index) => (
        <MobileLinkComponent
          page={page}
          key={page.name}
          opened={openedLink === index}
          open={() => {
            setOpenedLink(index);
          }}
          close={() => {
            setOpenedLink(null);
          }}
        />
      ))}
    </MobileMenuContainer>
  );
};
