import { styled } from 'styled-components';

export const ProductDetailsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: auto;
  }
`;

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    padding-inline: 1rem;
  }
`;

export const ProductImage = styled.img`
  width: 100%;
  object-fit: contain;
`;
