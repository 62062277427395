import React from 'react';

export const CartModalIcon = () => {
  return (
    <svg
      data-v-5d75e9c9=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
    >
      <path
        data-v-5d75e9c9=""
        d="M5.78459 20.5C5.37947 20.5 5.01634 20.3785 4.69519 20.1356C4.37404 19.8926 4.15641 19.5737 4.04231 19.1788L1.66539 10.65C1.58847 10.3654 1.63783 10.1026 1.81346 9.86158C1.98911 9.62054 2.22757 9.50003 2.52884 9.50003H7.24999L11.2461 3.51738C11.3294 3.39046 11.4365 3.29174 11.5673 3.22123C11.6981 3.15073 11.8404 3.11548 11.9942 3.11548C12.148 3.11548 12.2903 3.15234 12.4211 3.22605C12.5519 3.29977 12.6589 3.40009 12.7423 3.527L16.7192 9.50003H21.4711C21.7724 9.50003 22.0124 9.62054 22.1913 9.86158C22.3701 10.1026 22.4179 10.3654 22.3345 10.65L19.9384 19.1788C19.8243 19.5737 19.6067 19.8926 19.2855 20.1356C18.9644 20.3785 18.6012 20.5 18.1961 20.5H5.78459ZM5.73074 19H18.2692C18.3397 19 18.4006 18.9792 18.4519 18.9375C18.5032 18.8958 18.5384 18.8397 18.5577 18.7692L20.7 11H3.29996L5.44226 18.7692C5.4615 18.8397 5.49675 18.8958 5.54804 18.9375C5.59932 18.9792 5.66022 19 5.73074 19ZM9.06919 9.50003H14.9154L11.9846 5.08848L9.06919 9.50003Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
