import {
  CartItemType,
  CheckoutRequestBody,
  CheckoutResponse,
  PromoCodeResponse,
} from 'types/cart.types';
import axiosInstance from './instance';

export const getCartSizes = async (ids: number[]) => {
  const res = await axiosInstance.get('/v1/product-items/by-ids', {
    params: { ids },
  });

  return res.data.data;
};

export const addToCartCall = async (data: {
  productItemId: number;
  quantity: number;
}) => {
  try {
    const res = await axiosInstance.post('/v1/cart', data);

    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const setCartApiCall = async (
  data: {
    productItemId: number;
    quantity: number;
  }[]
) => {
  try {
    const res = await axiosInstance.post('/v1/cart/set', { items: data });

    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const removeItemFromCartCall = async (productItemId: number) => {
  try {
    const res = await axiosInstance.delete(`/v1/cart/${productItemId}`);

    return res.data.data;
  } catch {
    return null;
  }
};

export const getCart = async () => {
  try {
    const res = await axiosInstance.get('/v1/cart');

    return res.data.data as CartItemType[];
  } catch {
    return [];
  }
};

export const checkPromoCode = async (code: string) => {
  const res = await axiosInstance.get(`/v1/promo-codes/${code}/activate`);

  return res.data.data as PromoCodeResponse;
};

export const checkoutCall = async (data: CheckoutRequestBody) => {
  const res = await axiosInstance.post('/v1/cart/checkout', data);

  return res.data.data as CheckoutResponse;
};
