import { AddIcon } from 'assets/AddIcon';
import { DeleteIcon } from 'assets/DeleteIcon';
import { EditIcon } from 'assets/EditIcon';
import { IconButton } from 'components/Buttons/IconButton';
import React, { Suspense } from 'react';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const Container = styled.div`
  padding: 0.875rem 0rem 0.875rem 2rem;
  border-radius: 0.875rem;
  border: 1px solid ${theme.colors.secondaryDark};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ListHeader = styled.div`
  padding: 0.5rem 0rem 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionContainer = styled.div`
  width: 160px;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  padding-right: 1rem;
  align-items: center;
  height: 100%;
`;

export const ItemColumn = styled.div<{
  $span: number;
  $center: boolean;
  $aiCenter?: boolean;
  $overflow?: string;
}>`
  width: ${({ $span }) => $span}%;
  display: flex;
  white-space: pre-wrap;
  overflow: ${({ $overflow }) => $overflow || 'hidden'};
  text-overflow: ellipsis;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  align-items: flex-start;
  height: 100%;
  align-items: ${({ $aiCenter }) => ($aiCenter ? 'center' : 'flex-start')};
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
`;

const ListItem = ({ children }) => {
  return <Container>{children}</Container>;
};

type ItemProps = {
  items: {
    item: any;
    span: number;
    center?: boolean;
    aiCenter?: boolean;
    overflow?: string;
  }[];
  onAddClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  additionalEdit?: () => void;
  children?: React.ReactElement | null;
  onClick?: () => void;
};

ListItem.Item = function Item({
  items,
  children,
  onAddClick,
  onEditClick,
  onDeleteClick,
  additionalEdit,
  onClick,
}: ItemProps) {
  return (
    <Container onClick={onClick} style={{ cursor: onClick && 'pointer' }}>
      <Flex $jc="space-between">
        <Flex $gap="1rem">
          {items.map((item, index) => (
            <ItemColumn
              $span={item.span || 100}
              key={index}
              $center={item.center}
              $aiCenter={item.aiCenter}
              $overflow={item.overflow}
            >
              {item.item}
            </ItemColumn>
          ))}
        </Flex>
        {(onAddClick || onEditClick || onDeleteClick) && (
          <ActionContainer>
            {onAddClick && (
              <IconButton size={24} onClick={onAddClick}>
                <AddIcon />
              </IconButton>
            )}
            {onEditClick && (
              <IconButton size={24} onClick={onEditClick}>
                <EditIcon />
              </IconButton>
            )}
            {onDeleteClick && (
              <IconButton size={24} onClick={onDeleteClick}>
                <DeleteIcon />
              </IconButton>
            )}
            {additionalEdit && (
              <IconButton onClick={additionalEdit} size={24}>
                <EditIcon color="blue" />
              </IconButton>
            )}
          </ActionContainer>
        )}
      </Flex>
      <Suspense fallback={null}>{children}</Suspense>
    </Container>
  );
};

ListItem.Title = function Title({ children }) {
  return (
    <Flex $noFullHeight $bold={700}>
      {children}
    </Flex>
  );
};

export { ListItem };
