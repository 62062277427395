import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createBlogApiCall } from 'api/blogs.api';
import { Button } from 'components/Buttons/Button';
import { BorderInput, BorderTextarea } from 'components/Inputs/BorderInput';
import { FullLoader } from 'components/Loader/Loader';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';
import { BlogImageInput } from './BlogImageInput';
import { AdminEditor } from './RichTextEditor';

const Container = styled.div`
  width: 600px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  h1 {
    font-size: 2.125rem;
  }
`;

const ContentForm = styled.div`
  width: 100%;
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CreateBlogModal = ({ onClose }) => {
  const [value, setValue] = useState('');
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [shortText, setShortText] = useState('');
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    () => {
      const dataForm = new FormData();

      const blog: any = {
        content: value,
        title: title,
        shortText: shortText,
        metaTitle: title,
        metaContent: title,
        metaTags: title,
        urlPath: `${title.toLowerCase().split(' ').join('-')}`,
      };

      if (image) {
        dataForm.append('image', image);
      }
      dataForm.append('blog', JSON.stringify(blog));

      return createBlogApiCall(dataForm);
    },
    {
      onSuccess: () => {
        onClose();
        toast.success('Blog je uspešno kreiran');
        queryClient.invalidateQueries(['blogs']);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message);
      },
    }
  );

  return (
    <Container>
      <BlogImageInput
        image={image}
        onChange={(val) => {
          setImage(val);
        }}
      />
      <ContentForm>
        <Flex $column $noFullHeight>
          <BorderInput
            placeholder="Naslov Bloga"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
          />
          <Flex
            $jc="flex-end"
            $fs={0.8}
            $noFullHeight
            $textcolor={theme.colors.borderGray}
          >
            Limit 100 karaktera!
          </Flex>
          <BorderTextarea
            placeholder="Kratak opis bloga"
            onChange={(e) => {
              setShortText(e.target.value);
            }}
            rows={3}
            value={shortText}
          />
        </Flex>
        <AdminEditor
          value={value}
          onChange={(val) => {
            setValue(val);
          }}
        />
        <Flex $noFullHeight $jc="flex-end">
          <Button square styleType="outline-dark" onClick={onClose}>
            Otkaži
          </Button>
          <Button
            styleType="color"
            square
            onClick={() => {
              if (!value) return toast.error('Blog mora sadržati kontent.');
              if (!title) return toast.error('Blog mora sadržati naslov.');
              if (!image) return toast.error('Blog mora sadržati sliku.');
              if (!shortText)
                return toast.error('Blog mora sadržati kreatak opis.');

              if (title.length > 99)
                return toast.error(
                  'Blog mora sadržati manje od 100 karaktera.'
                );
              createMutation.mutate();
            }}
          >
            Kreiraj
          </Button>
        </Flex>
      </ContentForm>
      <FullLoader loading={createMutation.isLoading} />
    </Container>
  );
};
