export const options: {
  text?: string;
  title?: string;
  lines?: string[];
}[] = [
  {
    text: 'Items purchased in the ESOTIQ.com online store can be returned within 30 days from the date of receipt of the order.',
  },
  {
    text: 'When buying in the ESOTIQ.com online store, you have the opportunity to withdraw from the contract, exchange and request goods in accordance with the applicable rules. To improve each of these processes, we recommend the following scenario:',
  },
  {
    title: 'WITHDRAWAL FROM THE CONTRACT',
    text: 'When buying in the ESOTIQ.com online store, you have the opportunity to withdraw from the contract, exchange and request goods in accordance with the applicable rules. To improve each of these processes, we recommend the following scenario:',
    lines: [
      'Mona Ok warehouse Laidzes Street 10 LV-1002, Riga, LATVIA marked "Return Esotiq" (Mona Ok noliktava Laidzes iela 10 LV-1002, Rīga, LATVIJA)',
      '1. Fill in the TERMINATION form - you can download it HERE',
      '2.* Return the returned goods together with the completed and signed form to the following address:',
      `Mona Ok warehouse\nLaidzes Street 10\nLV-1002, Riga, LATVIA\nmarked "Return Esotiq"`,
      `(Mona Ok noliktava \nLaidzes iela 10 \nLV-1002, Rīga, LATVIJA)`,
      '3. Inform us about returns by email: e-shop@monaok.com',
      'You can also return the goods to the ESOTIQ store in person at Domina - Ieriķu Street 3, LV-1084, Riga. In order to return the goods, you will need the order number and the e-mail address used in the purchase process and the specified contacts.',
    ],
  },
  {
    title: 'EXCHANGE',
    text: 'In the ESOTIQ.COM online store, the exchange of goods consists of the return of the selected product and the execution of a separate order. If it turns out that the purchased product is too big and you are interested in ordering another size, use the return procedure and order the product in another size in a separate order.',
  },
  {
    text: 'Important! Exchange of goods within one transaction is not possible and a separate payment must be made when ordering a new product.',
  },
  {
    title: 'COMPLAINT',
    lines: [
      '1. In case of claims, contact us by e-mail e-shop@monaok.com to determine the delivery method to the Mona Ok warehouse. To avoid misunderstandings, please do not send shipments until the mode of transport has been agreed with the store staff.',
      '2. Attach the completed and signed complaint form to the package with the complaint - you can download it HERE.',
      '3. * Send only the advertised goods to the following address:',
      `Mona Ok warehouse\nLaidzes Street 10\nLV-1002, Riga, LATVIA\nmarked "Return Esotiq"`,
      '* The package must be returned to the specified address.',
      '** Does not apply to on-site stores:',
    ],
  },
  {
    lines: [
      `Shopping center Domina\nEsotiq store\nIeriķu Street 3,\nLV-1084, Riga\nLATVIA`,
    ],
  },
];
