import { LoginForm } from 'components/LoginForm/LoginForm';
import { Flex } from 'styles/Containers.styles';
import { LoginSide } from './LoginSide';
import { Container, LoginContainer } from 'styles/Login/Login.style';

const CheckoutLogin = () => {
  return (
    <Container>
      <LoginContainer>
        <Flex $noFullHeight $fs={1.5} $bold={700} $noFull>
          PRIJAVA
        </Flex>
        <LoginForm ifUserNavigate="/checkout/details" />
      </LoginContainer>
      <LoginSide />
    </Container>
  );
};

export default CheckoutLogin;
