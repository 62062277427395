import { useQuery } from '@tanstack/react-query';
import { getProductFilters } from 'api/products.api';
import { useParams, useSearchParams } from 'react-router-dom';
import { removeEmpty } from 'utils/object.utils';

export const useProductFilters = () => {
  const { pageType, id } = useParams();
  const [searchParams] = useSearchParams();

  const params = removeEmpty({
    subcategoryIds:
      pageType === 'subcategory'
        ? [id]
        : searchParams.get('subcategoryIds')?.split(',') || null,
    categoryId:
      pageType === 'category'
        ? id
        : location.pathname === '/outlet'
        ? searchParams.get('categoryId')
        : null,
    isNew: location.pathname === '/novo' ? true : null,
    isOutlet: location.pathname === '/outlet' ? true : null,
    isSale: location.pathname === '/na-popustu' ? true : null,
    collectionIds:
      pageType === 'kolekcije'
        ? [id]
        : location.pathname === '/outlet'
        ? [searchParams.get('collectionIds')]
        : null,
    query:
      location.pathname === '/pretraga' ? searchParams.get('search') : null,
    attributeValueIds:
      searchParams.get('attributeValueIds')?.split(',') || null,
    colors: searchParams.get('colors')?.split(',') || null,
    sizes: searchParams.get('sizes')?.split(',') || null,
  });

  const { isLoading, error, data } = useQuery<FilterType>({
    queryKey: ['filters', params],
    queryFn: () => getProductFilters(params),
  });

  const { data: initialFilters } = useQuery<FilterType>({
    queryKey: [
      'filters',
      pageType,
      id,
      location.pathname,
      location.pathname === '/search' && searchParams.get('search'),
    ],
    queryFn: () =>
      getProductFilters({
        subcategoryIds: pageType === 'subcategory' ? [id] : null,
        categoryId: pageType === 'category' ? id : null,
        collectionIds: pageType === 'collections' ? [id] : null,
        isNew: location.pathname === '/novo' ? true : null,
        isSale: location.pathname === '/na-popustu' ? true : null,
        query:
          location.pathname === '/search' ? searchParams.get('search') : null,
      }),
  });

  return { isLoading, error, data, initialFilters };
};
