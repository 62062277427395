import { CloseIcon } from 'assets/CloseIcon';
import { SearchIcon } from 'assets/SearchIcon';
import { Button } from 'components/Buttons/Button';
import { IconButton } from 'components/Buttons/IconButton';
import { SearchProductsInput } from 'components/Inputs/SearchProductsInput';
import { SelectInputV2 } from 'components/Inputs/SelectInputV2';
import { useCategories } from 'hooks/useCategories';
import { useParamsSearch } from 'hooks/useParamsSearch';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex } from 'styles/Containers.styles';
import { CategoryType } from 'types/category.types';
import { SimpleSubcategoryType } from 'types/subcategory.types';

export const ProductsNavbar = () => {
  const { searchParams, handleSetUrlSearchParams } = useParamsSearch();
  const navigate = useNavigate();
  const { data } = useCategories();
  const search = searchParams.get('search');
  const [searchActive, setSearchActive] = useState(!!search);

  const initialCategoryValue = useMemo(() => {
    if (searchParams.get('category') && Array.isArray(data)) {
      const category = data.find(
        (category) => category.id.toString() === searchParams.get('category')
      );
      return category;
    } else {
      return null;
    }
  }, [data]);

  const initialSubcategoryValue = useMemo(() => {
    if (searchParams.get('subcategory') && Array.isArray(data)) {
      let subcategory: SimpleSubcategoryType | null = null;
      data.find((category) => {
        const selected = category.subcategories.find(
          (sub) => sub.id.toString() === searchParams.get('subcategory')
        );
        if (selected) {
          subcategory = selected;
          return;
        }
      });
      return subcategory;
    } else {
      return null;
    }
  }, [data]);

  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(
    initialCategoryValue
  );

  return (
    <Flex $ai="center" $jc="flex-end">
      {!searchActive && (
        <>
          <SelectInputV2
            name="category"
            label="Kategorije"
            options={data}
            initialValue={initialCategoryValue}
            renderOptionName={(item) => item?.name}
            onValueChange={(item) => {
              setSelectedCategory(item);
              if (item) {
                handleSetUrlSearchParams('category', item.id.toString());
                handleSetUrlSearchParams('page', null);
              } else {
                handleSetUrlSearchParams('category', null);
                handleSetUrlSearchParams('page', null);
              }
            }}
          />
          <SelectInputV2
            name="subcategory"
            label="Podkategorije"
            options={
              selectedCategory
                ? selectedCategory.subcategories
                : data
                ? [...data.map((category) => category.subcategories)].flat(1)
                : []
            }
            initialValue={initialSubcategoryValue}
            renderOptionName={(item) => item.key}
            onValueChange={(item) => {
              if (item) {
                handleSetUrlSearchParams('subcategory', item.id.toString());
                handleSetUrlSearchParams('page', null);
              } else {
                handleSetUrlSearchParams('subcategory', null);
                handleSetUrlSearchParams('page', null);
              }
            }}
          />
          <Button
            square={true}
            styleType="outline-dark"
            onClick={() => {
              handleSetUrlSearchParams('bulk', 'open');
            }}
          >
            Bulk akcije
          </Button>

          <Button
            square={true}
            styleType="color"
            onClick={() => {
              navigate('create');
            }}
          >
            Kreiraj proizvod
          </Button>
          <Button
            square
            width="fit-content"
            onClick={() => {
              setSearchActive(true);
            }}
          >
            <SearchIcon />
          </Button>
        </>
      )}
      {searchActive && (
        <>
          <IconButton
            onClick={() => {
              handleSetUrlSearchParams('search', null);
              handleSetUrlSearchParams('page', null);
              setSearchActive(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <SearchProductsInput initialValue={search} />
        </>
      )}
    </Flex>
  );
};
