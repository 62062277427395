import { CheckIcon } from 'assets/CheckIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { styled } from 'styled-components';
import { Flex } from 'styles/Containers.styles';
import { theme } from 'styles/theme';

const CheckboxContainer = styled.div<{
  $disabled: boolean;
}>`
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid
    ${($disabled) =>
      $disabled ? theme.colors.secondaryLight : theme.colors.secondaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  checked: boolean;
  text: string | React.ReactNode;
  onChange: () => void;
  disabled?: boolean;
  noTextClick?: boolean;
};

export const Checkbox = ({
  checked,
  text,
  onChange,
  disabled,
  noTextClick,
}: Props) => {
  return (
    <Flex
      $noFullHeight
      $gap="0.5rem"
      $pointer
      $noFull
      onClick={(e) => {
        e.preventDefault();

        if (!disabled) {
          onChange();
        }
      }}
    >
      <IconButton active={checked} disabled={disabled}>
        <CheckboxContainer $disabled={disabled}>
          {checked && <CheckIcon />}
        </CheckboxContainer>
      </IconButton>
      <Flex
        $p="0.35rem 0"
        $textcolor={disabled && theme.colors.secondaryLight}
        onClick={(e) => {
          if (noTextClick) {
            e.stopPropagation();
          }
        }}
      >
        {text}
      </Flex>
    </Flex>
  );
};
