import React from 'react';
import { Flex } from 'styles/Containers.styles';

export const ErrorModal = ({ message }: { message: string }) => {
  return (
    <Flex $column $gap="1rem" $ai="center">
      <Flex $noFullHeight $jc="center" $bold={700}>
        DOŠLO JE DO GREŠKE
      </Flex>
      <Flex $jc="center">{message}</Flex>
    </Flex>
  );
};
