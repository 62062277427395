import styled from 'styled-components';

export const BorderInput = styled.input`
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-size: 0.875rem;
`;

export const BorderTextarea = styled.textarea`
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-size: 0.875rem;
`;
