import { useQuery } from '@tanstack/react-query';
import { getOrderInfoWithUUID } from 'api/orders.api';
import { OrderWithUUID } from 'types/orders.types';

export const useOrderWithUUID = (uuid: string) => {
  const { isLoading, error, data } = useQuery<OrderWithUUID>(
    ['orderUid', { uuid }],
    () => getOrderInfoWithUUID(uuid)
  );

  return { isLoading, error, data };
};
