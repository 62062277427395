import { ProductCard } from 'components/ProductCard/ProductCard';
import { useCustomIdProducts } from 'hooks/useCustomIdProducts';
import { styled } from 'styled-components';
import ImageSlider from '../Slider';
import convertProductToSimpleProduct from 'utils/product.utils';

const SliderContainer = styled.div`
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-x: scroll;
  max-width: 100vw;

  @media screen and (max-width: 950px) {
    padding: 1rem;
  }
`;

const Container = styled.div`
  width: 100%;

  @media screen and (max-width: 1270px) {
    padding: 2rem;
  }

  @media screen and (max-width: 950px) {
    padding: 0;
  }
`;

type Props = {
  perSlide?: number;
  ids: number[];
  hoverable?: boolean;
};

export const WebCardSlider = ({ perSlide = 3, ids, hoverable }: Props) => {
  const { data } = useCustomIdProducts({ ids });
  return (
    <Container>
      <SliderContainer>
        <ImageSlider visibleItems={perSlide}>
          {data &&
            data?.map((product, index) => (
              <ProductCard
                key={product.id}
                product={convertProductToSimpleProduct(product)}
                index={index}
                hoverable={hoverable}
              />
            ))}
        </ImageSlider>
      </SliderContainer>
    </Container>
  );
};
