import { useQuery } from '@tanstack/react-query';
import { getCustomerCollections } from 'api/collections';
import { CollectionType } from 'types/collection.types';

export const useCustomerCollections = () => {
  const { isLoading, error, data } = useQuery<CollectionType[]>(
    ['collections'],
    () => getCustomerCollections()
  );

  return { isLoading, error, collections: data };
};
