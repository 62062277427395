import React from 'react';

export const EmptyOrdersIcon = () => {
  return (
    <svg
      width="180"
      height="220"
      viewBox="0 0 180 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.801 210.425L104.714 188.606M125.379 208.777L112.968 189.582M122.14 191.969L131.24 206.099M132.076 195.665L136.06 201.793M96.7789 188.104L111.836 211.475M104.687 212.299L88.9426 188M81.7085 188.203L97.4757 212.811M90.0981 212.955L74.5419 188.737M67.5653 189.531L82.5583 212.822M74.6931 212.272L60.8573 190.853M54.5403 192.548L66.5584 211.208M57.927 209.439L48.6255 195.037M44 199.417L47.7571 205.455"
        stroke="#18191A"
        strokeLinecap="round"
      />
      <path
        d="M22.0002 82.4999C22.0017 86.6799 21.301 98.2406 19.7769 103.946C16.6906 115.5 13.7695 119.622 17.4562 122.363C20.285 124.465 27.6121 121.256 30.5002 113.5M28.5002 107C27.0272 113.639 22.2992 120.705 19.2695 120.446C16.9491 120.249 21.1961 114.278 22.9742 109.88C24.7671 105.446 25.7144 103.125 26.2671 100.446"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0391 141.701C39.5215 145.098 52.9351 148.296 61.8735 148.305C95.1444 148.337 126.695 144.679 135.021 143.098C139.325 142.436 149.021 139.598 151.743 134.894"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5762 123.332C35.0215 126.098 45.5215 129.812 59.0772 129.7C94.6546 129.406 127.105 126.263 136.021 124.598C140.629 123.893 152.021 121.598 155.291 115.392"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.169 101.104C146.34 106.297 121.884 110.551 93.1561 111.505C64.5014 112.457 39.8688 109.852 29.6692 105.344"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="90.8719"
        cy="65.6249"
        rx="69.9522"
        ry="13.1529"
        transform="rotate(-1.90302 90.8719 65.6249)"
        fill="white"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.786 93.2442C161.027 100.504 130.748 107.402 93.1568 108.651C55.5651 109.9 24.8955 105.027 24.6543 97.7673"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6699 97.5985L21.2386 70.0986M160.738 93.5985L160.738 64.5985"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.247 97.9866C155.81 119.667 151.793 136.867 148.317 148.283C145.267 158.3 136.162 164.731 125.742 165.767L84.3498 169.883C64.181 171.889 44.2424 163.282 37.6009 144.133C33.8931 133.442 30.4771 119.764 28.7997 103.051"
        stroke="#18191A"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.021 129.598C131.021 129.599 131.021 129.599 131.021 129.6C127.539 154.109 111.694 160.338 100.903 160.399C84.4017 160.367 75.3238 151.412 69.7357 135.476C69.6447 135.216 69.5627 134.961 69.4876 134.697C68.7083 131.953 64.1177 114.975 64.2364 96.1493C64.2764 89.8056 67.4728 87.2896 71.8522 87.044C76.2317 86.7985 79.3957 90.7414 79.3614 96.1788C79.31 104.335 79.4152 124.669 84.2298 140.85C87.6691 152.408 94.3395 154.954 101.901 154.969C109.324 154.983 114.823 150.292 116.908 138.283C116.986 137.831 117.072 137.378 117.147 136.925C117.496 134.81 118.432 127.414 118.021 110.598C118.021 101.84 118.717 97.256 118.948 95.9765C118.994 95.7219 119.044 95.4697 119.094 95.216C120.147 89.9538 122.784 86.9708 127.061 86.731C131.252 86.496 134.74 90.0257 134.355 95.6191C134.32 96.1291 134.228 96.6317 134.139 97.1351C133.809 99.002 132.977 104.309 132.521 112.598C131.945 123.076 131.025 129.571 131.021 129.598Z"
        fill="white"
        stroke="#18191A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="4"
        cy="4"
        r="4"
        transform="matrix(-1 0 0 1 75.9609 90.5985)"
        fill="#18191A"
      />
      <circle
        cx="4"
        cy="4"
        r="4"
        transform="matrix(-1 0 0 1 130.961 90.5985)"
        fill="#18191A"
      />
      <mask
        id="mask0_7951_69061"
        maskUnits="userSpaceOnUse"
        x="20"
        y="-1"
        width="140"
        height="79"
      >
        <path
          d="M159.691 66.2437C159.855 71.1623 129.162 76.2057 90.55 77.4886C51.9378 78.7715 22.1065 76.1884 20.7038 70.1041C18.0015 25 84.8895 1.28279 123.502 -0.000146245C159.691 -0.000146421 159.691 35.5001 159.691 66.2437Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_7951_69061)">
        <path
          d="M104.432 56.8959L100.495 60.006C99.8476 58.4025 97.4919 53.8105 93.1333 53.7183C88.7747 53.6262 86.7002 57.2161 86.2078 59.0226C84.6523 57.3999 80.4938 54.7831 76.3036 57.2974C72.1134 59.8117 72.8729 64.7705 73.7764 66.9356C67.6615 66.1249 64.5927 66.5798 58.883 67.5702C46.4106 69.7336 47.2082 78.5795 65.2008 85.8316C65.2008 85.8316 83.1003 102.748 94.8992 98.7304C100.277 96.899 104.032 94.0225 107.388 88.0932C112.343 79.3406 113.153 73.2258 115.239 71.2259L118.013 68.5593C120.1 66.5594 126.243 66.008 135.197 61.428C141.263 58.3254 144.296 54.6956 146.353 49.3999C150.866 37.7814 134.726 19.1781 134.726 19.1781C134.282 10.8971 130.934 -0.751371 125.664 4.30113C117.717 13.1486 116.68 21.0686 115.485 26.9472C113.36 25.953 108.438 24.9846 105.748 29.0647C103.059 33.1449 105.498 37.4103 107.053 39.033C105.228 39.4486 101.553 41.3695 101.461 45.7281C101.369 50.0867 105.857 52.6344 107.432 53.349L104.432 56.8959Z"
          fill="white"
        />
        <path
          d="M89.5 61.5009L80.5 64.0009C76.5 61.9088 72.5 58.8521 65.9995 55.5009C64.4888 54.512 60.4995 53.5009 56.9995 52.0009C54.4995 50.9295 49.4995 51.9894 47.9995 52.5009C51.1995 50.9009 60 49.1676 64 49.5009C76 52.5009 86 59.501 89.5 61.5009Z"
          fill="white"
        />
        <path
          d="M60.4503 74.4131L52.5779 77.1356C43.8688 72.8521 38.6912 66.957 34 59.501C33.007 57.9228 30.7704 58.2153 29.1363 59.1486L28 60.001C28.3138 59.6872 28.704 59.3955 29.1363 59.1486L31.9995 57.0006C33.4995 56.4891 36 55.3521 39 58.8521C44.1413 66.4075 55.5 71.001 60.4503 74.4131Z"
          fill="white"
        />
        <path
          d="M51.8698 76.8513C38 68.0005 34.9995 60.4285 32.5 59.0005C30.6318 57.9334 26 62.0005 26 62.0005L29.4362 59.5463C29.4787 59.516 29.5218 59.4898 29.5685 59.4665C31.6035 58.4527 36.1307 56.3594 37.5 57.5005C40.5 60.0005 46.5 67.0005 53 70.0005"
          stroke="#18191A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.396 60.3844C105.675 68.8532 104.149 79.4583 96.9435 86.3655C88.1098 94.8333 74.0841 94.5368 65.6162 85.703C75.5243 68.1971 92.013 61.7324 101.396 60.3844Z"
          stroke="#18191A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.577 60.5701L105.054 57.2369M66.9492 87.0912C66.9492 87.0912 83.5231 102.611 95.322 98.5925C100.7 96.7611 104.455 93.8846 107.811 87.9552C112.765 79.2027 113.576 73.0878 115.662 71.0879C116.749 70.0466 117.053 69.7547 117.053 69.7547"
          stroke="#18191A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.0968 56.0801C45.2043 52.9715 57.6084 46.7146 68.402 50.2489C72.553 51.6081 75.999 53.9999 78.999 56.0801"
          stroke="#18191A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.6316 60.3938C70.0236 57.8755 67.4704 55.9112 60.2919 52.8458C53.1134 49.7804 43.777 54.5916 41.6901 56.6766"
          stroke="#18191A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.6257 85.6938C47.633 78.4417 46.8354 69.5958 59.3078 67.4324C65.0176 66.4419 68.0863 65.9871 74.2012 66.7978C73.2977 64.6327 72.5382 59.6739 76.7284 57.1596C80.9186 54.6452 85.0771 57.262 86.6326 58.8847C87.125 57.0783 89.1995 53.4883 93.5581 53.5805C97.9167 53.6727 100.272 58.2647 100.92 59.8682"
          stroke="#18191A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.347 53.7138C116.627 58.3461 127.287 57.2704 134.493 50.3632C143.327 41.8954 143.623 27.8696 135.155 19.0359C117.246 28.1951 110.09 44.3958 108.347 53.7138Z"
          stroke="#18191A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.521 53.904L105.044 57.2371M136.482 20.4286C136.482 20.4286 151.287 37.6437 146.774 49.2622C144.717 54.5579 141.684 58.1877 135.618 61.2903C126.664 65.8703 120.521 66.4217 118.434 68.4216C117.348 69.463 117.043 69.7549 117.043 69.7549"
          stroke="#18191A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.149 19.0403C134.705 10.7592 131.357 -0.889191 126.086 4.1633C118.14 13.0107 117.103 20.9308 115.908 26.8094C113.783 25.8152 108.86 24.8468 106.171 28.9269C103.482 33.0071 105.921 37.2725 107.476 38.8952C105.65 39.3108 101.976 41.2317 101.884 45.5903C101.792 49.9489 106.28 52.4966 107.855 53.2112"
          stroke="#18191A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.1073 72.9717C78.745 77.1155 89.44 86.8802 97.641 85.6911"
          stroke="#18191A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.002 28.9787C126.031 31.7892 135.335 42.8874 133.8 51.0308"
          stroke="#18191A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
