import { useQuery } from '@tanstack/react-query';
import { getSubcategoryById } from 'api/subcategory.api';
import { SubcategoryType } from 'types/subcategory.types';

export const useSubcategory = ({ id }: { id: string }) => {
  const { isLoading, error, data } = useQuery<SubcategoryType>(
    ['subcategory', { id }],
    () => getSubcategoryById(id)
  );

  return { isLoading, error, data };
};
