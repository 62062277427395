import { CloseIcon } from 'assets/CloseIcon';
import { SearchIcon } from 'assets/SearchIcon';
import { IconButton } from 'components/Buttons/IconButton';
import { useParamsSearch } from 'hooks/useParamsSearch';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { theme } from 'styles/theme';

const InputContainer = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  margin-block: 1rem;
  max-width: 30rem;
  border-bottom: 1px solid ${theme.colors.secondaryLight};
  align-items: center;
  gap: 1rem;

  svg {
    height: 2rem;
    width: 2rem;
  }

  &:hover {
    border-color: ${theme.colors.secondaryDark};
  }

  @media screen and (max-width: 600px) {
    margin-block: 0.2rem;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  padding-block: 0.7rem;
  transition: all 200ms ease-in;
  background-color: transparent;
`;

export const SearchInput = ({ onEnter = null, onClear = null }) => {
  const { searchParams, handleSetUrlSearchParams } = useParamsSearch();
  const [value, setValue] = useState('');

  useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <InputContainer
      onSubmit={(e) => {
        console.log(value);
        e.preventDefault();
        if (onEnter) {
          onEnter(value);
        } else {
          handleSetUrlSearchParams('search', value);
        }
      }}
    >
      <SearchIcon />
      <StyledInput
        autoComplete="off"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {searchParams.get('search') && (
        <IconButton
          onClick={() => {
            if (onClear) {
              onClear();
            } else {
              handleSetUrlSearchParams('search', null);
              setValue('');
            }
          }}
          type="button"
        >
          <CloseIcon />
        </IconButton>
      )}
    </InputContainer>
  );
};
